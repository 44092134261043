import { cx } from "@libs/utils/cx";
import { ReactComponent as ArchyLogo } from "@libs/assets/components/logo-archy-with-name.svg";
import { semibold16 } from "assets/styles/textSize";

type Props = {
  title?: string;
  children: React.ReactNode;
  includeBranding?: boolean;
};
export const SignInWrapper: React.FC<Props> = ({ children, title }) => {
  return (
    <>
      {title && (
        <>
          <div className="flex flex-col justify-center items-center gap-y-2">
            <ArchyLogo className="inline ml-[1px] text-secondaryTheme w-36" />
          </div>
          {title && <div className={cx("text-slate-900", semibold16)}>{title}</div>}
        </>
      )}
      {children}
    </>
  );
};
