import { useCallback, useEffect, useState } from "react";
import { minutesToMilliseconds } from "date-fns";
import { formatAsISODate, MINUTE_IN_SECONDS, SECOND_IN_MS } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { half } from "@libs/utils/math";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getPracticeInfoByUuid } from "api/user/queries";
import { KioskPinForm } from "components/PatientForms/PatientKiosk/KioskPinForm";
import { ConfirmDOBForm } from "components/ConfirmDOBForm";
import { preparePatientFormTasksQuery } from "api/forms/queries";
import {
  LoadedPatientFormTasks,
  getFormTaskExpiry,
} from "components/PatientForms/PatientFormTasks/LoadedPatientFormTasks";
import { getIsKioskCodeOrDOBError } from "utils/getIsKioskCodeOrDOBError";
import { usePathParams } from "hooks/usePathParams";
import { PublicPracticeInstrumentation } from "components/Main/PublicPracticeInstrumentation";

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const KIOSK_IDLE_TIMEOUT = MINUTE_IN_SECONDS * SECOND_IN_MS * 2;
const KIOSK_IDLE_VALUES = {
  // Reloads page after two minutes (1 minute of inactivity, one minute with prompt)
  timeout: KIOSK_IDLE_TIMEOUT,
  // Prompts user after 1 minute
  promptBeforeIdle: half(KIOSK_IDLE_TIMEOUT),
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  eventsThrottle: 10 * SECOND_IN_MS,
  name: "kiosk",
};

const APP_RELOAD_MINUTES = 30;
const RELOAD_APP_TIMEOUT = minutesToMilliseconds(APP_RELOAD_MINUTES);

// Reloads kiosk page every 30 minutes if no input is entered, to pull any released changes to the kiosk
const useReloadAppWhenNoInput = (hasEnteredInput: boolean) => {
  useEffect(() => {
    if (!hasEnteredInput) {
      const timeout = setTimeout(() => {
        window.location.reload();
      }, RELOAD_APP_TIMEOUT);

      return () => {
        clearTimeout(timeout);
      };
    }

    return undefined;
  }, [hasEnteredInput]);
};

export const PatientKioskRoute: React.FC = () => {
  const { practiceUuid } = usePathParams("kiosk");
  const [pin, setPin] = useState("");
  const hasEnteredInput = useBoolean(false);
  const [submittedDob, setSubmittedDob] = useState("");
  const errorOccurred = useBoolean(false);
  const [expiresAt, setExpiresAt] = useState<Date>(getFormTaskExpiry());

  useReloadAppWhenNoInput(hasEnteredInput.isOn);

  const [tasksQuery, practiceQuery] = useApiQueries([
    preparePatientFormTasksQuery({
      args: { practiceUuid, data: { dob: submittedDob, kioskCode: pin } },
      queryOptions: {
        enabled: Boolean(pin && submittedDob),
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: () => {
          setExpiresAt(getFormTaskExpiry());
        },
        onError: () => {
          setPin("");
          setSubmittedDob("");
          errorOccurred.on();
          hasEnteredInput.off();
        },
      },
    }),
    getPracticeInfoByUuid({
      args: { practiceUuid },
    }),
  ]);

  const disableError = errorOccurred.off;
  const handlePinSubmitted = useCallback(
    (submittedPin: string) => {
      setPin(submittedPin);
      disableError();
    },
    [disableError]
  );
  const handleDobSubmitted = useCallback((dob: Date) => {
    setSubmittedDob(formatAsISODate(dob));
  }, []);
  const isFormSubmissionError = getIsKioskCodeOrDOBError(tasksQuery.error);

  const handleSessionExpired = useCallback(() => {
    tasksQuery.refetch();
    setExpiresAt(getFormTaskExpiry());
  }, [tasksQuery]);

  return (
    <QueryResult queries={[practiceQuery]}>
      {practiceQuery.data ? (
        <>
          <PublicPracticeInstrumentation practice={practiceQuery.data} />
          {pin ? (
            submittedDob ? (
              submittedDob &&
              !isFormSubmissionError && (
                <QueryResult queries={[tasksQuery]}>
                  {tasksQuery.data && (
                    <LoadedPatientFormTasks
                      formTasksResponse={tasksQuery.data}
                      practice={practiceQuery.data}
                      token={tasksQuery.data.token}
                      print={false}
                      isKiosk
                      idleConfig={KIOSK_IDLE_VALUES}
                      expiresAt={expiresAt}
                      onSessionExpired={handleSessionExpired}
                    />
                  )}
                </QueryResult>
              )
            ) : (
              <ConfirmDOBForm practice={practiceQuery.data} onSubmit={handleDobSubmitted} />
            )
          ) : (
            <KioskPinForm
              error={errorOccurred.isOn ? "Unsuccessful login attempt. Please try again." : undefined}
              practice={practiceQuery.data}
              onSubmit={handlePinSubmitted}
              onChange={(entered) => {
                hasEnteredInput.set(entered !== "");
              }}
            />
          )}
        </>
      ) : null}
    </QueryResult>
  );
};
