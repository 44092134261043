import React, { FormEvent } from "react";
import OTPInput from "otp-input-react";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { Form } from "@libs/components/UI/Form";
import { PasswordGuidelines } from "components/SignIn/PasswordGuidelines";
import { ForgotPasswordInputType, ForgotPasswordProps } from "components/ForgotPassword";
import { FormFieldPassword } from "components/UI/FormFieldPassword";
import { ResetPasswordValidationResults, SignInErrors } from "components/SignIn/validationUtil";
import { cxSignInStyles } from "components/SignIn/cxSignInStyles";

interface Props {
  handleForgotPassword: (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
  handleInputChange: (inputName: ForgotPasswordInputType, value: string) => void;
  handleResetPassword: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  forgotPasswordState: ForgotPasswordProps;
  validateResult: ResetPasswordValidationResults;
}

export const OTP_LENGTH = 6;

const cxStyles = {
  verificationCode: "w-10 mr-2.5 box-border rounded-[5px]",
};

const ResendCode = ({ handleForgotPassword }: { handleForgotPassword: Props["handleForgotPassword"] }) => {
  const { t } = useTranslation();

  return (
    <Button className="text-sm" onClick={(e) => handleForgotPassword(e)} theme="link">
      {t("app.page.labels.resendCode")}
    </Button>
  );
};

const ErrorMessage = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <div className="flex mt-2 text-red text-xs">
      <ErrorIcon className="h-4 w-4 mr-1" />
      {errorMessage}
    </div>
  );
};

export const ResetPasswordForm: React.FC<Props> = ({
  handleForgotPassword,
  handleInputChange,
  handleResetPassword,
  forgotPasswordState,
  validateResult,
}) => {
  const { t } = useTranslation();
  const otpErrorMessage = React.useMemo(() => {
    if (validateResult.otp.$error) {
      return validateResult.otp.$error;
    } else if (forgotPasswordState.cognitoErrorCode) {
      return t(`cognito.${forgotPasswordState.cognitoErrorCode}`);
    }

    return "";
  }, [forgotPasswordState.cognitoErrorCode, t, validateResult.otp.$error]);

  return (
    <Form id="reset-password-form" className="space-y-5" onSubmit={(e) => handleResetPassword(e)}>
      <div className="font-sans text-sm">Please enter the code sent to your email address.</div>
      <div className="space-y-3">
        <div className="font-sans text-sm">Verification Code</div>
        <OTPInput
          autoFocus
          disabled={false}
          inputClassName={
            validateResult.otp.$error || forgotPasswordState.cognitoErrorCode
              ? cx("border border-red", cxStyles.verificationCode)
              : cx("border border-greyLight", cxStyles.verificationCode)
          }
          // Styles set in `inputStyles` take precedence over
          // `inputClassName` and we need to override the built-in
          // defaults which forces us to style here and in the CSS class.
          inputStyles={{ marginRight: "10px", height: "40px" }}
          onChange={(value: string) => handleInputChange("otp", value)}
          OTPLength={OTP_LENGTH}
          otpType="number"
          placeholder="999999"
          value={forgotPasswordState.otp}
        />
        {forgotPasswordState.cognitoErrorCode === "LimitExceededException" ? (
          <div className="flex items-end">
            <ErrorMessage errorMessage={t("cognito.LimitExceededException")} />
          </div>
        ) : (
          <>
            {otpErrorMessage && <ErrorMessage errorMessage={otpErrorMessage} />}
            <div className="flex mt-3">
              <span className="mr-1 font-sans text-sm">{t("app.page.labels.dontReceiveCode")}</span>
              <ResendCode handleForgotPassword={handleForgotPassword} />
            </div>
          </>
        )}
      </div>
      <PasswordGuidelines />
      <FormFieldPassword
        displayErrorMessage={validateResult.newPassword.$error !== SignInErrors.PASSWORD_MISMATCH}
        error={validateResult.newPassword.$error}
        id="newPassword"
        layout="labelOut"
        label="New Password"
        name="newPassword"
        onChange={(event) => handleInputChange("newPassword", event.target.value)}
        placeholder="Enter Password"
        value={forgotPasswordState.newPassword}
      />
      <FormFieldPassword
        error={validateResult.confirmPassword.$error}
        id="confirmPassword"
        layout="labelOut"
        label="Confirm Password"
        name="confirmPassword"
        onChange={(event) => handleInputChange("confirmPassword", event.target.value)}
        placeholder="Enter Password"
        value={forgotPasswordState.confirmPassword}
      />
      <AsyncButton
        className={cxSignInStyles.button}
        displayLoadingText
        isLoading={forgotPasswordState.isLoading}
        type="submit"
      >
        {forgotPasswordState.isLoading ? "Sending" : "Confirm Password"}
      </AsyncButton>
    </Form>
  );
};
