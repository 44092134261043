import { useTranslation } from "react-i18next";
import React from "react";
import { AddressVO, ContactDetailsVO, ContactVO } from "@libs/api/generated-api";
import { parsePhoneNumber } from "@libs/utils/phone";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";
import GooglePlacesComponent from "components/google-places-component/google-places-component";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { ReadOnlyAddress } from "components/UI/ReadOnlyAddress";
import { useUSStateOptions } from "hooks/useUSStateOptions";
import { EMPTY_CELL } from "components/UI/GridTableComponents";
import { AccountValidationResult } from "components/Account/validation";

type Props = {
  edit: boolean;
  contact: ContactVO;
  contactDetails: Partial<ContactDetailsVO>;
  onContactDetailsUpdated: (partial: Partial<ContactDetailsVO>) => void;
  canEditEmail: boolean;
  validation: AccountValidationResult["contactDetails"];
};
// eslint-disable-next-line complexity
export const ContactDetailFields: React.FC<Props> = ({
  edit,
  contact,
  contactDetails,
  onContactDetailsUpdated,
  canEditEmail,
  validation,
}) => {
  const { t } = useTranslation();
  const { email, phone, addressDetails, preferredContactMode } = contactDetails;
  const stateOptions = useUSStateOptions(addressDetails?.state);
  const handleAddressDetailsUpdated = React.useCallback(
    (partial: Partial<AddressVO>) => {
      onContactDetailsUpdated({
        addressDetails: {
          address1: "",
          city: "",
          state: "",
          zip: "",
          ...addressDetails,
          ...partial,
        },
      });
    },
    [addressDetails, onContactDetailsUpdated]
  );

  const contactPerson = contact.relation === "SELF" ? undefined : contact;
  const requiredContactMethod = edit && !contactPerson;

  return (
    <>
      {contactPerson ? (
        <FormFieldInput
          id="contactPerson"
          type="contactPerson"
          edit={false}
          label={t("Contact Person")}
          value={`${contactPerson.name.fullDisplayName}, ${contactPerson.age}`}
        />
      ) : null}
      <FormFieldInput
        id="email"
        type="email"
        label={t("Email Address")}
        value={email}
        error={validation.email.$error}
        edit={canEditEmail && edit}
        required={requiredContactMethod && preferredContactMode === "EMAIL"}
      />
      <FormFieldPhoneInput
        id="phone"
        edit={edit}
        label={t("Phone Number")}
        value={phone}
        error={validation.phone.$error}
        required={
          requiredContactMethod && (preferredContactMode === "CALL" || preferredContactMode === "TEXT")
        }
        onChange={(e) => {
          onContactDetailsUpdated({ phone: parsePhoneNumber(e.target.value) });
        }}
      />
      <div>
        <FormFieldLabel
          content={t("Address")}
          className="font-sansSemiBold text-sm md:text-xs pb-2"
          required={edit && !contactPerson}
          error={
            edit
              ? validation.addressDetails.city.$error ||
                validation.addressDetails.state.$error ||
                validation.addressDetails.zip.$error
              : undefined
          }
          id="ship-address"
        />
        {edit ? (
          <div className="flex flex-col gap-4">
            <GooglePlacesComponent
              fieldValue={addressDetails?.address1}
              onSelectAddress={(address) => {
                if (Object.values(address).some((item) => item.trim().length > 0)) {
                  handleAddressDetailsUpdated({
                    city: address.locality,
                    state: address.state,
                    address1: address.address1,
                    zip: address.postcode,
                  });
                } else {
                  onContactDetailsUpdated({ ...contactDetails, addressDetails: undefined });
                }
              }}
            />
            <FormFieldInput
              id="apartment"
              edit={edit}
              label={t("app.page.labels.apartmentUnitSuitFloor")}
              value={addressDetails?.address2}
              onChange={(e) => {
                handleAddressDetailsUpdated({
                  address2: e.target.value,
                });
              }}
            />
            <FormFieldInput
              id="city"
              edit={edit}
              label={t("City")}
              value={addressDetails?.city}
              error={validation.addressDetails.city.$error}
              onChange={(e) => {
                handleAddressDetailsUpdated({
                  city: e.target.value,
                });
              }}
            />
            <FormFieldSelect
              id="state"
              edit={edit}
              label={t("State")}
              value={addressDetails?.state}
              error={validation.addressDetails.state.$error}
              onChange={(option) => {
                handleAddressDetailsUpdated({
                  state: option?.value,
                });
              }}
              options={stateOptions}
            />
            <FormFieldInput
              id="zip"
              edit={edit}
              label={t("Zipcode")}
              value={addressDetails?.zip}
              error={validation.addressDetails.zip.$error}
              onChange={(e) => {
                handleAddressDetailsUpdated({
                  zip: e.target.value,
                });
              }}
            />
          </div>
        ) : addressDetails ? (
          <ReadOnlyAddress addressDetails={addressDetails} className="text-sm md:text-xs" />
        ) : (
          EMPTY_CELL
        )}
      </div>
    </>
  );
};
