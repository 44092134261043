/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from "react";
import { Options, useDebouncedCallback } from "use-debounce";

/*
  All this does is relay the isPending() status from the debounced callback into react state.

  This is useful when you want to show a loading spinner when a debounced callback is pending. Calling debouncedCallback.isPending()
  doesn't satisfy this case, because it doesn't reset any react state when isPending changes.

  Can use like so:

  const { callback: debounceMyCallback, isPending: isDebouncePending } =
  useDebounceWithPendingStatus(myCallback, DEBOUNCE_TIME_MS, {
    leading: false,
    trailing: true,
  });

  ...
  <AsyncButton isLoading={isDebouncePending} onClick={...}>
    Next
  </AsyncButton>
*/

export const useDebounceWithPendingStatus = <T extends (...args: any) => ReturnType<T>>(
  func: T,
  wait?: number,
  options?: Options
) => {
  const [isPending, setIsPending] = useState(false);
  const debouncedCallback = useCallback(
    (...args: unknown[]): ReturnType<T> => {
      setIsPending(false);

      return func(...args);
    },
    [func]
  );
  const startDebounce = useDebouncedCallback(debouncedCallback, wait, options);

  // We debounce the multi invoice request because user may edit the amount, issuing many requests while typing
  const setStateAndCallDebounce = useCallback(
    (...args: unknown[]) => {
      setIsPending(true);
      startDebounce(...args);
    },
    [startDebounce]
  );

  return {
    isPending,
    callback: setStateAndCallDebounce,
    flush: startDebounce.flush,
    cancel: startDebounce.cancel,
  };
};
