import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { Spinner } from "@libs/components/UI/Spinner";

export const LoadingOverlaySpinner: FC<{
  opaque?: boolean;
  overlayClassName?: string;
  loadingText?: ReactNode;
  loadingSubText?: ReactNode;
  centerVertically?: boolean;
}> = ({ loadingText, loadingSubText, overlayClassName, opaque, centerVertically }) => {
  const hasLoadingMessage = Boolean(loadingText || loadingSubText);

  return (
    <div className={cx("absolute inset-0", overlayClassName, opaque ? "bg-white" : "bg-white/90")}>
      <div
        className={cx(
          `absolute
           left-1/2
           -translate-x-1/2
           -translate-y-1/2
           flex
           flex-col
           gap-y-4
           items-center`,
          centerVertically ? "top-1/2" : "top-1/3"
        )}
      >
        <Spinner animation="border" variant="primary" size="panel" />
        {hasLoadingMessage && (
          <div className="flex flex-col gap-y-1 items-center">
            {loadingText && <div className="text-sm font-sansSemiBold text-center">{loadingText}</div>}
            {loadingSubText && <div className="text-xs text-center">{loadingSubText}</div>}
          </div>
        )}
      </div>
    </div>
  );
};
