/* eslint-disable react/no-unescaped-entities */
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { Button } from "@libs/components/UI/Button";
import { Banner } from "components/UI/Banner";

export const FinixScriptLoadErrorBanner: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Banner theme="error" className={cx("text-xs", className)}>
      <div>
        {t("processPayment.error.scriptFailed")}
        <div>
          <Button theme="link" onClick={() => location.reload()}>
            {t("Reload Page")}
          </Button>
        </div>
      </div>
    </Banner>
  );
};
