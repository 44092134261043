/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable no-bitwise */
import { SerializedElementNode, SerializedLexicalNode, Spread, TextModeType } from "lexical";
import { Style } from "@react-pdf/types";
import React, { useRef } from "react";
import { captureMessage as sentryCaptureMessage } from "@sentry/react";
import { Text } from "components/PatientForms/FormPDFElements/Text";

type SerializedLinebreakNode = Spread<
  {
    type: "linebreak";
  },
  SerializedElementNode
>;
export type SerializedTextNode = Spread<
  {
    detail: number;
    format: number | string;
    mode: TextModeType;
    style: string;
    text: string;
    type: "text";
  },
  SerializedLexicalNode
>;
export type SerializedHeadingNode = Spread<
  {
    tag: "h1" | "h2";
    type: "heading";
    children: SerializedTextNode[];
  },
  SerializedElementNode
>;

export type SerializedListItem = Spread<
  {
    type: "listitem";
    children: SerializedTextNode[];
  },
  SerializedElementNode
>;

export type SerializedList = Spread<
  {
    tag: "ol" | "li";
    type: "list";
    start: number;
    listType: "number" | "bullet";
    children: SerializedListItem[];
  },
  SerializedElementNode
>;
export type SerializedParagraphNode = Spread<
  {
    type: "paragraph";
    children: SerializedTextNode[] | SerializedList[];
  },
  SerializedTextNode
>;

export type SerializedTabNode = Spread<
  {
    type: "tab";
  },
  SerializedTextNode
>;

export type SerializedRootNode = Spread<
  {
    type: "root";
    children: ArchyLexicalNode[];
  },
  SerializedTextNode
>;
export type ArchyLexicalNode =
  | SerializedParagraphNode
  | SerializedLinebreakNode
  | SerializedTextNode
  | SerializedHeadingNode
  | SerializedList;

const getTextDecoration = (
  node: SerializedHeadingNode | SerializedTextNode | SerializedParagraphNode
): Style | undefined => {
  switch (node.format) {
    case 1: {
      // bold
      return {
        fontWeight: 600,
      };
    }
    case 1 << 1: {
      // italic
      return {
        fontStyle: "italic",
      };
    }
    case (1 << 1) + 1: {
      return {
        fontStyle: "italic",
        fontWeight: "bold",
      };
    }
    case 1 << 3: {
      // underline
      return {
        textDecoration: "underline",
      };
    }
    case (1 << 3) + 1: {
      // bold underline
      return {
        textDecoration: "underline",
        fontWeight: 600,
      };
    }
    case (1 << 3) + 2: {
      // underline italic
      return {
        textDecoration: "underline",
        fontStyle: "italic",
      };
    }
    case (1 << 3) + 3: {
      // underline bold italic
      return {
        textDecoration: "underline",
        fontStyle: "italic",
        fontWeight: 600,
      };
    }

    default: {
      return undefined;
    }
  }
  // For more styles: https://github.com/facebook/lexical/issues/2020#issuecomment-1129083803
};
const getTextStyles = (node: SerializedHeadingNode | SerializedTextNode | SerializedParagraphNode): Style => {
  return {
    ...getTextDecoration(node),
    textAlign:
      node.format === "right"
        ? "right"
        : node.format === "left" || node.format === "start"
          ? "left"
          : node.format === "center"
            ? "center"
            : undefined,
    paddingBottom: node.type === "heading" ? 5 : undefined,
  };
};
const LexicalHeadingNode: React.FC<{ node: SerializedHeadingNode }> = ({ node }) => {
  return (
    <Text bold style={getTextStyles(node)}>
      {node.children.map((textNode, i) => (
        <LexicalTextNode key={i} node={textNode} size={node.tag === "h1" ? "xl" : "lg"} />
      ))}
    </Text>
  );
};

const ParagraphNodeContent: React.FC<{ node: SerializedParagraphNode }> = ({ node }) => {
  // Paragraph or list
  const contents = node.children.map((currentNode, i) => (
    <PatientFormLexicalNode key={i} node={currentNode} />
  ));
  const firstChild = node.children.length === 1 ? node.children[0] : undefined;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {firstChild?.type === "list" && node.format === "" ? (
        contents
      ) : (
        <Text style={getTextStyles(node)}>
          {contents}
          {"\n"}
        </Text>
      )}
    </>
  );
};

const LexicalTextNode: React.FC<{
  node: SerializedTextNode | SerializedParagraphNode | SerializedLinebreakNode | SerializedTabNode;
  size?: "xl" | "lg" | "base";
}> = ({ node, size = "base" }) => {
  let content: React.ReactNode | string = null;
  const hasLoggedError = useRef(false);

  switch (node.type) {
    case "linebreak": {
      content = "\n";
      break;
    }
    case "text": {
      content = (
        <Text size={size} style={getTextStyles(node)}>
          {node.text}
        </Text>
      );
      break;
    }
    case "tab": {
      content = <Text size={size}>{node.text}</Text>;
      break;
    }
    default: {
      if (!["list", "paragraph"].includes(node.type) && !hasLoggedError.current) {
        hasLoggedError.current = true;

        const unknownNode = node as { type: string };

        sentryCaptureMessage(`Unknown node type: ${unknownNode.type}`);
      }

      return <ParagraphNodeContent node={node} />;
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{content}</>;
};

const LexicalListNode: React.FC<{ node: SerializedList }> = ({ node }) => {
  return (
    <Text style={{ paddingBottom: 10, paddingLeft: 5 }}>
      {node.children.map((listItem, i) => {
        return (
          <Text key={i}>
            {node.listType === "number" ? `${i + 1}. ` : "• "}
            {listItem.children.map((itemContent, j) => (
              <LexicalTextNode key={j} node={itemContent} />
            ))}
            {"\n"}
          </Text>
        );
      })}
    </Text>
  );
};

export const PatientFormLexicalNode: React.FC<{
  node: ArchyLexicalNode;
}> = ({ node }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {node.type === "heading" ? (
        <LexicalHeadingNode node={node} />
      ) : node.type === "list" ? (
        <LexicalListNode node={node} />
      ) : (
        <LexicalTextNode node={node} />
      )}
    </>
  );
};
