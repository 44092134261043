import React, { FormEvent, useMemo } from "react";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { useValidation } from "@libs/hooks/useValidation";
import { useObjectState } from "@libs/hooks/useObjectState";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { CognitoErrorCode } from "@libs/utils/cognito";
import { isOneOf } from "@libs/utils/isOneOf";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useNavigate } from "react-router-dom";
import { Form } from "@libs/components/UI/Form";
import {
  SignInState,
  StepType,
  getEmailValidationSchema,
  getPasswordValidationSchema,
} from "components/SignIn/validationUtil";

import { normal12, normal14 } from "assets/styles/textSize";
import { FormFieldPassword } from "components/UI/FormFieldPassword";
import { InternalLink } from "components/UI/Link";
import { SignInWrapper } from "components/SignIn/SignInWrapper";
import { useAuthErrorHandler } from "hooks/useAuthErrorHandler";
import { OneTimeCodeForm } from "components/ForgotPassword/OneTimeCodeForm";
import { ConfirmEmailToSignIn } from "components/SignIn/ConfirmEmailToSignIn";
import { ThirdPartySignInButton } from "components/UI/ThirdPartySignInButton";
import { DividerWithText } from "components/SignIn/DividerWithText";
import { postAuthChannelMessage, useAuthChannelListeners } from "hooks/useAuthChannelListeners";
import { usePatientActivityStorage } from "storage/activity";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "router/paths";
import { SignOutReason } from "router/types";

export const ErrorMessage: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={cx("text-red text-center", normal12)}>{children}</div>
);

const accountErrorMessage = "Sorry, there was an error accessing your account.";

const SignInErrorMessages: Record<SignOutReason | "INVALID_CREDENTIALS", string> = {
  INVALID_CREDENTIALS: "Invalid username or password.",
  UNAUTHORIZED: accountErrorMessage,
  SESSION_EXPIRED: "Session expired. Please sign in again.",
  IDENTITY_EMAIL_NOT_FOUND: accountErrorMessage,
  NO_CURRENT_USER: "",
  GET_USER_ACCOUNTS_ERROR: accountErrorMessage,
  ISSUE_TOKEN_ERROR: accountErrorMessage,
};

const useSubmitHandler = ({
  returnUrl,
  lastUserId,
  errorMessage,
  email,
}: {
  errorMessage: string | undefined;
  returnUrl: string;
  lastUserId?: number;
  email: string | undefined;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const activityStorage = usePatientActivityStorage();

  const [signInState, setSignInState] = useObjectState<SignInState>({
    email: email ?? "",
    password: "",
    isLoading: false,
    step: StepType.SIGN_IN,
    errorMessage,
  });
  const signInSchema = React.useMemo(() => {
    return {
      email: getEmailValidationSchema(signInState.email),
      password: getPasswordValidationSchema({ password: signInState.password }),
    };
  }, [signInState.email, signInState.password]);

  const signInForm = useValidation(
    { email: signInState.email, password: signInState.password },
    signInSchema
  );
  const updateErrorData = React.useCallback(
    (newErrorMessage: string, cognitoErrorCode: CognitoErrorCode) => {
      setSignInState({ errorMessage: newErrorMessage, cognitoErrorCode });
    },
    [setSignInState]
  );
  const { handleAuthError } = useAuthErrorHandler(updateErrorData);

  const handleSignIn = React.useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const validate = signInForm.validate();

      if (!validate.$isValid) {
        return;
      }

      setSignInState({ isLoading: true });

      if (signInState.email && signInState.password) {
        try {
          await Auth.signIn(signInState.email, signInState.password);
        } catch (error) {
          if (error instanceof Error) {
            setSignInState({
              isLoading: false,
            });
            handleAuthError(error);
          }

          return;
        }

        activityStorage.setRecentlyActive();
        postAuthChannelMessage({ type: "signIn" });
        navigate(paths.selectAccount({ returnUrl, lastUserId }));
      } else {
        setSignInState({
          errorMessage: t("Invalid username or password"),
          isLoading: false,
        });
      }
    },
    [
      handleAuthError,
      signInForm,
      setSignInState,
      signInState.email,
      signInState.password,
      returnUrl,
      lastUserId,
      navigate,
      activityStorage,
      t,
    ]
  );

  const handleEmailConfirmed = async () => {
    try {
      await Auth.signIn(signInState.email, signInState.password);
      activityStorage.setRecentlyActive();
      postAuthChannelMessage({ type: "signIn" });
      navigate(paths.selectAccount({ returnUrl, lastUserId }));
    } catch (err) {
      handleAuthError(err);
    }
  };

  return {
    handleSignIn,
    signInState,
    setSignInState,
    handleEmailConfirmed,
    returnUrl,
    validation: signInForm.result,
  };
};

const userRequiresEmailConfirmation = (code?: CognitoErrorCode) => {
  return code && isOneOf(code, ["PasswordResetRequiredException", "UserNotConfirmedException"]);
};

export const MainSignIn: React.FC = () => {
  const navigate = useNavigate();
  const { query } = useQueryParams("signIn");
  const returnUrl = query.returnUrl ?? paths.home();
  const { handleSignIn, signInState, setSignInState, validation, handleEmailConfirmed } = useSubmitHandler({
    returnUrl,
    lastUserId: query.lastUserId,
    errorMessage: query.signOutReason ? SignInErrorMessages[query.signOutReason] : undefined,
    email: query.email,
  });
  const { t } = useTranslation();
  const requiresConfirmation = userRequiresEmailConfirmation(signInState.cognitoErrorCode);

  const authChannelEvents = useMemo(
    () => ({
      onSignIn: () => {
        navigate(paths.selectAccount({ returnUrl: query.returnUrl, lastUserId: query.lastUserId }));
      },
    }),
    [navigate, query.returnUrl, query.lastUserId]
  );

  useAuthChannelListeners(authChannelEvents);

  return (
    <SignInWrapper title={t("app.page.labels.signin")}>
      {signInState.step === StepType.SIGN_IN ? (
        requiresConfirmation ? (
          <ConfirmEmailToSignIn
            text={t("signIn.confirmEmailToSignIn")}
            onClickCancel={() => {
              setSignInState({
                errorMessage: undefined,
                cognitoErrorCode: undefined,
              });
            }}
            onClickReConfirm={async () => {
              setSignInState({ isLoading: true });
              await Auth.resendSignUp(signInState.email);
              setSignInState({
                step: StepType.ONE_TIME_CODE,
                isLoading: false,
              });
            }}
            cognitoErrorCode={signInState.cognitoErrorCode}
            errorMessage={signInState.errorMessage}
          />
        ) : (
          <div className="flex flex-col gap-6">
            <ThirdPartySignInButton
              returnUrl={returnUrl}
              lastUserId={query.lastUserId}
              className="w-full"
              provider="Google"
            >
              {t("signIn.signInWith", { thirdParty: "Google" })}
            </ThirdPartySignInButton>

            <DividerWithText className="text-sm text-greyMedium uppercase">{t("or")}</DividerWithText>
            <Form
              id="sign-up-page"
              fieldLayout="labelOut"
              className="flex flex-col space-y-5"
              onSubmit={handleSignIn}
            >
              <FormFieldInput
                type="email"
                label="Email"
                placeholder="example@gmail.com"
                autoComplete="username"
                value={signInState.email}
                error={validation.email.$error}
                onChange={(e) => {
                  setSignInState({
                    email: e.target.value,
                    errorMessage: undefined,
                  });
                }}
                id="signin-email"
              />
              <div className="flex flex-col items-end space-y-1">
                <FormFieldPassword
                  label="Password"
                  id="signin-password"
                  layout="labelOut"
                  autoComplete="current-password"
                  placeholder="Enter Password"
                  className="w-full"
                  value={signInState.password}
                  error={validation.password.$error}
                  onChange={(e) => {
                    setSignInState({
                      password: e.target.value,
                      errorMessage: undefined,
                    });
                  }}
                />
                <InternalLink className="text-sm" to={paths.forgotPasswordSignedOut()}>
                  {t("app.page.labels.forgotPassword")}
                </InternalLink>
              </div>
              {signInState.errorMessage && <ErrorMessage>{signInState.errorMessage}</ErrorMessage>}
              <div className="flex flex-col justify-center items-center gap-3">
                <AsyncButton isLoading={signInState.isLoading} className="w-full" type="submit" size="large">
                  {t("app.page.labels.signin")}
                </AsyncButton>
                <div className={cx("text-center", normal14)}>
                  {t("app.page.labels.wantToCreateNewProfileText")}{" "}
                  <InternalLink to={paths.signUp()}>{t("app.page.labels.signupHere")}</InternalLink>
                </div>
              </div>
            </Form>
          </div>
        )
      ) : (
        <OneTimeCodeForm email={signInState.email} onEmailConfirmed={handleEmailConfirmed} />
      )}
    </SignInWrapper>
  );
};
