import { isDefined } from "@libs/utils/types";

const isExpired = (ms: number) => Date.now() > ms;

export interface StorageItem<T> {
  value: T;
  version: string;
  expires: number;
}

interface StorageOptions {
  expires: number;
  version: string;
}

/**
 * Wraps the native Storage object with additional functionality.
 *
 * @template T The type of the stored value.
 * @param {StorageNamespaces} namespace - The namespace under which the value is stored.
 * @param {Storage} storage - The native Storage object (localStorage, sessionStorage, or memory).
 * @param {Object} wrapStorageOptions An optional configuration object
 * @param {number} wrapStorageOptions.practiceId This should be passed it the data is relevant per practice vs per machine.
 * @returns {Object} Returns an object with `get`, `set`, `clear`, and `ensure` methods.
 */
export const wrapStorage = <T, S extends string>(
  namespace: S,
  storage: Storage,
  wrapStorageOptions?: { practiceId?: number }
) => {
  const getKey = (key: string) => {
    return isDefined(wrapStorageOptions?.practiceId)
      ? `${namespace}_${wrapStorageOptions.practiceId}_${key}`
      : `${namespace}_${key}`;
  };

  function set(key: string, value: T, options: StorageOptions) {
    const storageKey = getKey(key);

    storage.setItem(
      storageKey,
      JSON.stringify({
        value,
        version: options.version,
        expires: options.expires + Date.now(),
      })
    );

    return value;
  }

  function clear(key: string) {
    const storageKey = getKey(key);

    storage.removeItem(storageKey);
  }

  function parse(key: string) {
    const storageKey = getKey(key);
    const json = storage.getItem(storageKey);

    if (typeof json === "string") {
      return JSON.parse(json) as StorageItem<T>;
    }

    return json;
  }

  function get(key: string, version: string) {
    const item = parse(key);

    if (!item) {
      return null;
    }

    if (isExpired(item.expires)) {
      clear(key);

      return null;
    }

    if (version && item.version !== version) {
      clear(key);

      return null;
    }

    return item.value;
  }

  function ensure(key: string, value: T, options: StorageOptions) {
    return get(key, options.version) ?? set(key, value, options);
  }

  return {
    get,
    set,
    clear,
    ensure,
  };
};

export type WrapStorage<T, S extends string> = ReturnType<typeof wrapStorage<T, S>>;
