import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { cx } from "@libs/utils/cx";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { formatDataUrl } from "@libs/utils/formatDataUrl";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { isDefined } from "@libs/utils/types";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { PoweredBy } from "components/UI/PoweredBy";
import { semibold20 } from "assets/styles/textSize";
import { AlreadyHaveAnAccount } from "components/SignIn/AlreadyHaveAnAccount";
import { ReactComponent as PlaceholderIcon } from "assets/icons/Placeholder_32x32.svg";
import { cxSignInStyles } from "components/SignIn/cxSignInStyles";
import { paths } from "router/paths";
import { getPracticeInfoByUuid } from "api/user/queries";
import { useQueryParams } from "hooks/useQueryParams";

const CenteredBranding: React.FC<{ practice: PracticeInfoVO | undefined; isLoading: boolean }> = ({
  isLoading,
  practice,
}) => {
  const getThumbnail = (thumbnail?: string, name?: string) => {
    if (thumbnail) {
      return <img src={formatDataUrl(thumbnail)} alt={name} className="h-10 w-10" />;
    }

    return <PlaceholderIcon className="rounded-full w-10 h-10" />;
  };

  if (practice || isLoading) {
    return (
      <div className="flex flex-col space-y-4 items-center justify-center">
        {isLoading || !practice ? (
          <Skeleton className="w-10 h-10" inline />
        ) : (
          getThumbnail(practice.logo?.thumbnail, practice.name)
        )}
        {isLoading || !practice ? (
          <Skeleton className="w-36 mb-1" inline />
        ) : (
          <div className={cx("text-secondaryTheme", semibold20)}>{practice.name}</div>
        )}
        <PoweredBy textClassName="text-greyMedium" className="text-secondaryTheme" />
      </div>
    );
  }

  return <PoweredBy textClassName="text-greyMedium" className="text-secondaryTheme" />;
};

export const WelcomeLanding: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { query } = useQueryParams("welcome");
  const [practiceQuery] = useApiQueries([
    getPracticeInfoByUuid({
      args: {
        practiceUuid: query.uuid ?? "",
      },
      queryOptions: {
        enabled: isDefined(query.uuid),
      },
    }),
  ]);

  const practiceName: string = practiceQuery.data?.name ?? i18n.t("app.labels.aDentalPractice");

  return (
    <QueryResult queries={query.uuid ? [practiceQuery] : []}>
      <>
        {practiceQuery.data ? (
          <CenteredBranding isLoading={practiceQuery.isLoading} practice={practiceQuery.data} />
        ) : null}
        <div className="w-full text-center gap-6 flex flex-col">
          <div>
            {practiceQuery.isLoading ? (
              <Skeleton className="w-full h-4 mb-1" count={4} />
            ) : (
              t("pages.welcome.inviteMessage", { practiceName })
            )}
          </div>
          <ButtonInternalLink
            className={cxSignInStyles.button}
            to={paths.signUp({ email: query.email ?? undefined })}
          >
            {t("Sign Up")}
          </ButtonInternalLink>
          <AlreadyHaveAnAccount />
        </div>
      </>
    </QueryResult>
  );
};
