/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface Error {
  field?: string;
  message?: string;
  errorCode?:
    | "FIELD_VALIDATION_ERROR"
    | "BAD_REQUEST"
    | "DATA_CONFLICT"
    | "RESOURCE_NOT_FOUND"
    | "PRECONDITION_FAILED"
    | "GF_BUS_ERROR_001"
    | "GF_BUS_ERROR_002"
    | "GF_BUS_ERROR_003"
    | "GF_BUS_ERROR_004"
    | "GF_EMP_ERROR_009"
    | "GF_EMP_ERROR_010"
    | "GF_EMP_ERROR_011"
    | "GF_EMP_ERROR_012"
    | "GF_EMP_ERROR_013"
    | "GF_AUTH_ERROR_001"
    | "UNAUTHORIZED"
    | "PAYMENT"
    | "PAYMENT_PROFILE"
    | "MERCHANT_ONBOARDING"
    | "CLAIMS_CONFIG"
    | "DENTAL_XCHANGE"
    | "TREATMENT_PLAN"
    | "PERIO_CHART_EXAM"
    | "PATIENT_TOOTH"
    | "PROCEDURE_HISTORY"
    | "PROVIDER"
    | "PAYROLL"
    | "MESSAGE"
    | "JOURNEY"
    | "BENEFIT_LIMITATION"
    | "FORM"
    | "FORM_STALE_CONTENT"
    | "SYS_ERROR"
    | "FORBIDDEN"
    | "LICENSE_CHECK"
    | "PHONE_EXTENSION"
    | "EXTERNAL_SERVICE"
    | "PIN_REQUIRED"
    | "MFA_REQUIRED"
    | "PATIENT_INSURANCE"
    | "INSURANCE_ELIGIBILITY";
}

export interface ErrorResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  errors?: Error[];
}

export interface PageDetails {
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  numberOfElementsOnCurrentPage?: number;
  /** @format int32 */
  pageNumber?: number;
}

export interface UpdateUserMfaRequest {
  mfaEnabled: boolean;
}

export interface SuccessResponseUserMfaVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: UserMfaVO;
}

export interface UserMfaVO {
  email: string;
  mfaEnabled: boolean;
  /** @format int64 */
  mfaEnforcedAt?: number;
}

export interface UpdateMessageSubscriptionRequest {
  unsubscribeAll: boolean;
  unsubscribeTransactional: boolean;
  unsubscribeMarketing: boolean;
}

export interface MessageSubscriptionVO {
  /** @format uuid */
  uuid: string;
  email?: string;
  phoneNumber?: string;
  unsubscribeAll: boolean;
  unsubscribeTransactional: boolean;
  unsubscribeMarketing: boolean;
}

export interface SuccessResponseMessageSubscriptionVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MessageSubscriptionVO;
}

export interface AddressVO {
  /**
   * @minLength 0
   * @maxLength 100
   */
  address1: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  address2?: string;
  /**
   * @minLength 0
   * @maxLength 45
   */
  city: string;
  /**
   * @minLength 0
   * @maxLength 45
   */
  state: string;
  /**
   * @minLength 0
   * @maxLength 10
   * @pattern ^\d{5}(?:-\d{4})?$
   */
  zip: string;
  /**
   * @minLength 0
   * @maxLength 2
   */
  country?: string;
  addressLabel1?: string;
  addressLabel2?: string;
}

export interface EmployeeBasicVO {
  /** @format int64 */
  id: number;
  name: NameVO;
  workEmail: string;
}

export interface NameVO {
  /** @format int64 */
  id: number;
  shortDisplayName: string;
  fullDisplayName: string;
  firstName: string;
  lastName: string;
}

export interface PracticeContactDetailVO {
  /**
   * @minLength 0
   * @maxLength 10
   * @pattern ^[0-9]{10}$
   */
  phoneNumber: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  email: string;
  /**
   * @minLength 0
   * @maxLength 10
   * @pattern ^[0-9]{10}$
   */
  fax?: string;
  /** @format int64 */
  officeRepresentativeId?: number;
  officeRepresentative?: EmployeeBasicVO;
  /**
   * @minLength 0
   * @maxLength 10
   * @pattern ^[0-9]{10}$
   */
  commsPhone?: string;
}

export interface PracticeVO {
  /** @format int64 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /** @format uuid */
  uuid: string;
  /** @pattern ^(\d{9})$ */
  taxId?: string;
  taxIdLastFour?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  timezoneId: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  website?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  doingBusinessAs?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  ownerName?: string;
  physicalAddress: AddressVO;
  isBillingAddressSame?: boolean;
  billingAddress?: AddressVO;
  contactDetails: PracticeContactDetailVO;
  workingHourItems?: WorkingHourItemVO[];
  /**
   * @minLength 0
   * @maxLength 255
   */
  logoUrl?: string;
  thumbnail?: string;
  businessType?:
    | "LIMITED_LIABILITY_COMPANY"
    | "CORPORATION"
    | "SOLE_PROPRIETORSHIP"
    | "NON_PROFIT"
    | "PARTNERSHIP"
    | "COOPERATIVE"
    | "LIMITED_PARTNERSHIP";
}

export interface WorkingHourItemVO {
  /** @format int64 */
  id?: number;
  dayOfWeek: "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
  startTime: string;
  endTime: string;
  isSelfBookable?: boolean;
}

export interface SuccessResponsePracticeVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PracticeVO;
}

export interface PatientProcedureRequest {
  /** @format int64 */
  dentalProcedureId?: number;
  /** @format int64 */
  id?: number;
}

export interface UpdateTreatmentPlanRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  patientProcedures: PatientProcedureRequest[];
  /**
   * If provided, will copy priorities from the source treatment plan for the delta procedures.
   * @format uuid
   */
  sourceTreatmentPlanUuid?: string;
}

export interface DentalProcedureRequirementsVO {
  required: boolean;
  surface?: SurfaceRequirementsVO;
  position?: "ARCH_UPPER" | "ARCH_LOWER" | "POSTERIOR" | "ANTERIOR" | "PRIMARY" | "PERMANENT";
  options: ("TOOTH" | "TOOTH_RANGE" | "ARCH" | "QUADRANT" | "MOUTH")[];
}

export interface DentalProcedureVO {
  /** @format int64 */
  id: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  simpleName: string;
  /**
   * @minLength 0
   * @maxLength 10
   */
  duration: string;
  /** @format int64 */
  durationInMinutes?: number;
  /**
   * @minLength 0
   * @maxLength 10
   */
  cdtCode: string;
  name: string;
  categoryType: string;
  subCategory?: string;
  /** @format int64 */
  ucrFee?: number;
  areaSelection?: DentalProcedureRequirementsVO;
  visualType?:
    | "EXTRACTION"
    | "IMPLANT"
    | "ABUTMENT"
    | "RCT"
    | "POST"
    | "FILLING_DARK"
    | "FILLING_LIGHT"
    | "CROWN_DARK"
    | "CROWN_LIGHT"
    | "BRIDGE_DARK"
    | "BRIDGE_LIGHT"
    | "DENTURE_DARK"
    | "DENTURE_LIGHT"
    | "SEALANT"
    | "WATCH"
    | "SPACE_MAINTAINER"
    | "SDF"
    | "APICOECTOMY"
    | "BU"
    | "PIN"
    | "MARGIN_TOOTH"
    | "CRACKED_TOOTH"
    | "IMPACTED_TOOTH"
    | "RETAINED_ROOT"
    | "SENSITIVE_DENTIN"
    | "PERAPICAL_ABSCESS"
    | "DIASTEMA_MESIAL"
    | "DIASTEMA_DISTAL"
    | "INCLINATION_DISTAL"
    | "INCLINATION_MESIAL"
    | "GING_RECESSION"
    | "WATCH_SURFACE"
    | "CHIPPED_TOOTH"
    | "VENEER"
    | "APICAL_PERIO"
    | "BUCCAL_ABFRACTION"
    | "CARIOUS_PULP_EXP"
    | "CRACKED_FILLING"
    | "DEFECT"
    | "IMPACTED_DISTAL"
    | "IMPACTED_MESIAL"
    | "LINGUAL_ABFRACTION"
    | "LINGUAL_RETAINER"
    | "NECROTIC"
    | "ROOT_ABRASION"
    | "ROOT_DECAY"
    | "ROOT_TIPS"
    | "SUPRA_ERUPTED"
    | "NO_VISUAL_FOR_CONDITION"
    | "NONE";
  /** @format int32 */
  codeNum?: number;
  isOrtho: boolean;
  isHygiene: boolean;
  endDate?: string;
  startDate?: string;
  overridden?: boolean;
  practiceSpecific: boolean;
  laymanTerm?: string;
  creditToPractice: boolean;
  prosthetic?: boolean;
}

export interface ImplantVO {
  implantDate: string;
  implantInstallType: "NEW" | "REPLACEMENT";
}

export interface LabCasePatientProcedureVO {
  /** @format int64 */
  id: number;
  date?: string;
  displayName: string;
  cdtCode: string;
  description: string;
  provider?: NameVO;
}

export interface LabCaseReturnReasonVO {
  /** @format uuid */
  uuid: string;
  reason: string;
}

export interface LabCaseStatusVO {
  /** @format uuid */
  uuid: string;
  status: "DRAFT" | "SENT" | "RECEIVED" | "RETURNED" | "READY" | "COMPLETE";
  patientProcedures?: LabCasePatientProcedureVO[];
  estimatedReceiveDate?: string;
  returnReason?: LabCaseReturnReasonVO;
}

export interface PatientProcedureVO {
  /** @format int64 */
  id: number;
  /** @format int64 */
  dentalProcedureId: number;
  date?: string;
  time?: string;
  toothName?: string;
  surfaces?: ("M" | "O" | "D" | "I" | "L" | "B" | "F" | "BV" | "FV" | "LV")[];
  toothRange?: string;
  mouthArea?:
    | "WHOLE_MOUTH"
    | "QUAD_UPPER_LEFT"
    | "QUAD_UPPER_RIGHT"
    | "QUAD_LOWER_LEFT"
    | "QUAD_LOWER_RIGHT"
    | "ARCH_UPPER"
    | "ARCH_LOWER";
  procedureArea?: string;
  displayName: string;
  duration: string;
  cdtCode: string;
  description: string;
  provider?: NameVO;
  creditToPractice: boolean;
  /** @format int64 */
  ucrRate: number;
  ucrManualOverride: boolean;
  /** @format int64 */
  negotiatedRate: number;
  /** @format date-time */
  feeLastOverrideAt?: string;
  feeLastOverrideBy?: NameVO;
  /** @format int64 */
  patientAmount: number;
  /** @format int64 */
  insuranceAmount: number;
  /** @format int64 */
  deductibleAmount: number;
  /** @format int64 */
  copayAmount: number;
  /** @format int32 */
  benefitCoverage?: number;
  primaryFeeManualOverride: boolean;
  /** @format int64 */
  secondaryPatientAmount?: number;
  /** @format int64 */
  secondaryInsuranceAmount?: number;
  /** @format int64 */
  secondaryDeductibleAmount?: number;
  secondaryFeeManualOverride: boolean;
  /** @format int64 */
  finalPatientAmount: number;
  /** @format int64 */
  finalInsuranceAmount: number;
  status: "EXISTING_OTHER" | "PLANNED" | "SCHEDULED" | "DONE" | "EXISTING_CURRENT" | "REFERRED" | "REJECTED";
  prognosis?: "EXCELLENT" | "GOOD" | "FAIR" | "QUESTIONABLE" | "POOR" | "HOPELESS";
  /** @format int32 */
  priority?: number;
  preAuthStatus?: "SENT" | "APPROVED" | "DENIED" | "RESENT" | "APPROVED_RESENT" | "DENIED_RESENT";
  preAuthNumber?: string;
  preAuthSubmittedDate?: string;
  preAuthUpdatedDate?: string;
  /** @format int64 */
  primaryPreAuthPatientAmount?: number;
  /** @format int64 */
  primaryPreAuthInsuranceAmount?: number;
  /** @format int64 */
  primaryPreAuthDeductibleAmount?: number;
  implant?: ImplantVO;
  visualType?:
    | "EXTRACTION"
    | "IMPLANT"
    | "ABUTMENT"
    | "RCT"
    | "POST"
    | "FILLING_DARK"
    | "FILLING_LIGHT"
    | "CROWN_DARK"
    | "CROWN_LIGHT"
    | "BRIDGE_DARK"
    | "BRIDGE_LIGHT"
    | "DENTURE_DARK"
    | "DENTURE_LIGHT"
    | "SEALANT"
    | "WATCH"
    | "SPACE_MAINTAINER"
    | "SDF"
    | "APICOECTOMY"
    | "BU"
    | "PIN"
    | "MARGIN_TOOTH"
    | "CRACKED_TOOTH"
    | "IMPACTED_TOOTH"
    | "RETAINED_ROOT"
    | "SENSITIVE_DENTIN"
    | "PERAPICAL_ABSCESS"
    | "DIASTEMA_MESIAL"
    | "DIASTEMA_DISTAL"
    | "INCLINATION_DISTAL"
    | "INCLINATION_MESIAL"
    | "GING_RECESSION"
    | "WATCH_SURFACE"
    | "CHIPPED_TOOTH"
    | "VENEER"
    | "APICAL_PERIO"
    | "BUCCAL_ABFRACTION"
    | "CARIOUS_PULP_EXP"
    | "CRACKED_FILLING"
    | "DEFECT"
    | "IMPACTED_DISTAL"
    | "IMPACTED_MESIAL"
    | "LINGUAL_ABFRACTION"
    | "LINGUAL_RETAINER"
    | "NECROTIC"
    | "ROOT_ABRASION"
    | "ROOT_DECAY"
    | "ROOT_TIPS"
    | "SUPRA_ERUPTED"
    | "NO_VISUAL_FOR_CONDITION"
    | "NONE";
  /** @format int64 */
  downgradeDentalProcedureId?: number;
  /** @format date-time */
  createdAt: string;
  feeCalcResult?: string;
  labCaseStatus?: LabCaseStatusVO;
  dentalProcedure?: DentalProcedureVO;
}

export interface SuccessResponseTreatmentPlanVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: TreatmentPlanVO;
}

export interface SurfaceRequirementsVO {
  required: boolean;
  /** @format int32 */
  min: number;
  /** @format int32 */
  max: number;
}

export interface TreatmentPlanVO {
  /** @format uuid */
  uuid: string;
  name: string;
  patientProcedures: PatientProcedureVO[];
  /** @format int64 */
  createdAt: number;
  state: "INACTIVE" | "ACTIVE" | "ARCHIVED";
}

export interface UpdateTreatmentPlanStateRequest {
  state: "INACTIVE" | "ACTIVE" | "ARCHIVED";
}

export interface SuccessResponseVoid {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: object;
}

export interface GlyphRequest {
  family: "ARCHY" | "EMOJI";
  name: string;
  value: string;
}

export interface UpdateTagRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  glyph: GlyphRequest;
  /**
   * @minLength 0
   * @maxLength 10
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  color: string;
  isArchived: boolean;
}

export interface GlyphVO {
  name: string;
  value: string;
  family: "ARCHY" | "EMOJI";
}

export interface SuccessResponseTagVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: TagVO;
}

export interface TagVO {
  /** @format int64 */
  id: number;
  slug?:
    | "FAMILY_MEMBER_SAME_DAY"
    | "XRAY"
    | "NEW_PATIENT"
    | "PRE_MEDICATION_REQUIRED"
    | "BIRTHDAY"
    | "REPEAT_NO_SHOW"
    | "MEDICAL_CONDITION"
    | "ALLERGY"
    | "PREGNANT"
    | "SELF_SCHEDULED_APPOINTMENT"
    | "CLINICAL"
    | "OVERDUE_INVOICE"
    | "RECALL_OVERDUE";
  name: string;
  glyph: GlyphVO;
  color: string;
  isArchived: boolean;
}

export interface UpdatePracticeSecuritySettingRequest {
  /** @format int64 */
  mfaEnforcedAt?: number;
  /**
   * @format int64
   * @min 2
   * @max 365
   */
  deviceRememberDays?: number;
}

export interface PracticeSecuritySettingVO {
  /** @format int64 */
  mfaEnforcedAt?: number;
  /** @format int32 */
  deviceRememberDays: number;
}

export interface SuccessResponsePracticeSecuritySettingVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PracticeSecuritySettingVO;
}

export interface RecurringScheduleRequest {
  endDate?: string;
  recurrenceType:
    | "DAYS_OF_LAST_WEEKS_OF_MONTH"
    | "DAYS_OF_WEEKS_OF_MONTH"
    | "DAYS_OF_YEAR"
    | "DAYS"
    | "DAYS_OF_WEEKS"
    | "DAYS_OF_MONTHS";
  daysOfWeek?: ("MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY")[];
  daysOfMonth?: number[];
  daysOfYear?: string[];
  ordinals?: number[];
  /** @format int32 */
  every: number;
}

export interface UpdateScheduleBlockRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  title: string;
  date: string;
  startTime: string;
  endTime: string;
  roomIds?: number[];
  providerIds?: number[];
  /**
   * @minLength 0
   * @maxLength 65535
   */
  comments?: string;
  /** @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$ */
  color: string;
  recurrence?: RecurringScheduleRequest;
}

export interface ProviderVO {
  /** @format int64 */
  id: number;
  jobCategory: "DENTIST" | "BACK_OFFICE" | "FRONT_OFFICE" | "HYGIENIST" | "PRACTICE";
  name: NameVO;
  color: string;
  status: "ACTIVE" | "INACTIVE" | "ARCHIVED" | "PENDING" | "FURLOUGHED";
  isAvailable?: boolean;
  openHours?: TimeRangeVO[];
  /** @format int32 */
  bookingLimitInDays?: number;
  inNetwork?: boolean;
}

export interface RecurringScheduleVO {
  endDate?: string;
  daysOfWeek?: ("MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY")[];
  daysOfMonth?: number[];
  daysOfYear?: string[];
  ordinals?: number[];
  /** @format int32 */
  every: number;
  recurrenceType:
    | "DAYS_OF_LAST_WEEKS_OF_MONTH"
    | "DAYS_OF_WEEKS_OF_MONTH"
    | "DAYS_OF_YEAR"
    | "DAYS"
    | "DAYS_OF_WEEKS"
    | "DAYS_OF_MONTHS";
}

export interface RoomVO {
  /** @format int64 */
  id: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  roomName: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  roomDescription?: string;
  /** @format int32 */
  order?: number;
  isAvailable?: boolean;
  status?: "ACTIVE" | "ARCHIVED";
  isSelfBookable: boolean;
  providers?: ProviderVO[];
  openHours?: TimeRangeVO[];
}

export interface ScheduleBlockVO {
  /** @format int64 */
  id: number;
  title: string;
  date: string;
  startTime: string;
  endTime: string;
  color: string;
  rooms: RoomVO[];
  providers: ProviderVO[];
  comments?: string;
  recurrence?: RecurringScheduleVO;
}

export interface SuccessResponseScheduleBlockVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ScheduleBlockVO;
}

export interface TimeRangeVO {
  startTime: string;
  endTime: string;
}

export interface PermissionConditionalContent {
  /** @format int64 */
  numberOfDaysLimit?: number;
}

/** Provide a list of permission actions that we want to disable for this role. */
export interface RolePermissionActionsRequest {
  /** @format uuid */
  permissionActionUuid: string;
  effect: "ALLOW" | "DENY";
  conditions?: PermissionConditionalContent;
}

export interface RoleRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /** Provide a list of permission actions that we want to disable for this role. */
  permissionActions: RolePermissionActionsRequest[];
}

export interface PermissionActionVO {
  /** @format uuid */
  uuid: string;
  action: string;
  domain:
    | "APPOINTMENT_MANAGEMENT"
    | "PATIENT_PROFILE"
    | "COMMUNICATION"
    | "CHAT"
    | "LAB_CASE"
    | "EMPLOYEE_SETTINGS"
    | "CLAIMS"
    | "REPORTING"
    | "PRACTICE_SETTINGS"
    | "BILLING";
}

export interface RolePermissionActionVO {
  permissionAction: PermissionActionVO;
  effect: "ALLOW" | "DENY";
  conditions?: PermissionConditionalContent;
}

export interface RoleVO {
  name: string;
  /** @format int64 */
  roleId: number;
  rolePermissionActions: RolePermissionActionVO[];
  archyOwned: boolean;
  /** @format uuid */
  practiceUuid: string;
  /** @format int64 */
  practiceId: number;
}

export interface SuccessResponseRoleVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: RoleVO;
}

export interface UpdateProcedureShortcutRequest {
  /**
   * @minLength 0
   * @maxLength 40
   */
  name: string;
  procedureIds: number[];
  surfaces?: ("M" | "O" | "D" | "I" | "L" | "B" | "F" | "BV" | "FV" | "LV")[];
}

export interface ProcedureShortcutVO {
  /** @format uuid */
  uuid: string;
  name: string;
  category:
    | "EXAMS"
    | "XRAYS"
    | "POST_COMPOSITE"
    | "ANT_COMPOSITE"
    | "AMALGAM"
    | "PERIO"
    | "APPLIANCES"
    | "CROWNS_OR_BRIDGES"
    | "ENDO"
    | "IMPLANTS"
    | "ORAL_SURGERY"
    | "ADJUNCT";
  procedures: DentalProcedureVO[];
  surfaces?: ("M" | "O" | "D" | "I" | "L" | "B" | "F" | "BV" | "FV" | "LV")[];
  /** @format int32 */
  order: number;
}

export interface SuccessResponseProcedureShortcutVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ProcedureShortcutVO;
}

export interface PerioChartExamSettingsVO {
  showDentitionSummary: boolean;
  showImplantSummary: boolean;
  showProbingDepth: boolean;
  showGingivalMargin: boolean;
  showAutoCAL: boolean;
  showMGJ: boolean;
  showMobility: boolean;
  showFurcation: boolean;
  showPlaque: boolean;
  showCalculus: boolean;
  showBleeding: boolean;
  showSuppuration: boolean;
  /** The maxillary auto-advance setting. Will be renamed in a future iteration. */
  autoAdvance: "RIGHT" | "LEFT";
  autoAdvanceMandibular: "RIGHT" | "LEFT";
  /** Indicates if auto advance should stop at the end of the current sequence type. */
  autoAdvanceStop: boolean;
  /** @format int32 */
  probingDepthThreshold: number;
  /** @format int32 */
  gingivalMarginThreshold: number;
  /** @format int32 */
  autoCALThreshold: number;
  /** @format int32 */
  mgjThreshold: number;
  /** @format int32 */
  mobilityThreshold: number;
  /** @format int32 */
  furcationThreshold: number;
  detailedFurcationScores: boolean;
}

export interface SuccessResponsePerioChartExamSettingsVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PerioChartExamSettingsVO;
}

export interface PaymentDeviceVO {
  /** @format uuid */
  uuid: string;
  name: string;
  serialNumber: string;
  model: "LINK_2500" | "LANE_3000" | "LANE_5000" | "LANE_7000" | "LANE_8000" | "PAX_A920PRO" | "SUNMI_P2";
  state: "CREATED" | "ACTIVATED" | "ACTIVATION_IN_PROGRESS" | "ACTIVATION_FAILED" | "DEACTIVATED";
}

export interface SuccessResponsePaymentDeviceVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PaymentDeviceVO;
}

export interface AdditionalInfoVO {
  preferredLanguage?: string;
  emergencyContact?: EmergencyContactDetailsVO;
  primaryProvider?: NameVO;
  primaryHygienist?: NameVO;
  referredBy?: string;
  referralInformation?: string;
}

export interface ContactDetailsVO {
  /** @pattern ^[0-9]{10}$ */
  phone?: string;
  /** @pattern ^[0-9]{10}$ */
  phone2?: string;
  /** @pattern ^[0-9]{10}$ */
  workPhone?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  email?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  email2?: string;
  preferredContactMode: "CALL" | "TEXT" | "EMAIL";
  contactModes: ContactModeVO;
  addressDetails?: AddressVO;
  /** @format int64 */
  contactPatientId?: number;
  contactRelation?:
    | "SELF"
    | "PARENT"
    | "CHILD"
    | "SPOUSE"
    | "SIBLING"
    | "GRANDPARENT"
    | "GRANDCHILD"
    | "OTHER";
  isDesignatedContact?: boolean;
}

export interface ContactModeVO {
  call: boolean;
  email: boolean;
  text: boolean;
}

export interface EmergencyContactDetailsVO {
  /**
   * @minLength 0
   * @maxLength 100
   */
  name?: string;
  /** @pattern ^[0-9]{10}$ */
  phone?: string;
  relationship?: "SPOUSE" | "PARENT" | "FAMILY_MEMBER" | "FRIEND" | "OTHER";
}

export interface PersonalDetailsVO {
  displayName?: string;
  fullName?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  firstName: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  middleName?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  lastName: string;
  /**
   * @minLength 0
   * @maxLength 45
   */
  preferredName?: string;
  namePronunciation?: string;
  preferredPronouns?: "HE_HIM" | "SHE_HER" | "THEY_THEM";
  gender?: "MALE" | "FEMALE" | "OTHER" | "UNKNOWN";
  /** @format int64 */
  age?: number;
  dob: string;
  status?: "PROSPECT" | "ACTIVE" | "INACTIVE" | "ARCHIVED" | "NONPATIENT" | "DECEASED";
  ssn?: string | null;
  ssnLastFour?: string;
  /** @format int32 */
  completedVisits?: number;
  firstVisit?: string;
  registeredDate?: string;
  thumbnail?: string;
  employer?: string;
  /** @format int64 */
  heightInInches?: number;
  /** @format int64 */
  weightInPounds?: number;
  previousPmsId?: string;
}

export interface UpdatePatientRequest {
  personalDetails: PersonalDetailsVO;
  contactDetails: ContactDetailsVO;
  additionalInformation: AdditionalInfoVO;
}

export interface ContactVO {
  name: NameVO;
  /** @deprecated */
  displayName: string;
  /** @format int64 */
  age: number;
  gender?: "MALE" | "FEMALE" | "OTHER" | "UNKNOWN";
  /** @deprecated */
  phone?: string;
  callPhone?: string;
  textPhone?: string;
  preferredContactMode: "CALL" | "TEXT" | "EMAIL";
  contactModes: ContactModeVO;
  email?: string;
  address?: AddressVO;
  relation?: "SELF" | "PARENT" | "CHILD" | "SPOUSE" | "SIBLING" | "GRANDPARENT" | "GRANDCHILD" | "OTHER";
}

export interface InsuranceDetailsVO {
  type: "PRIMARY_SUBSCRIBER" | "DEPENDENT" | "NONE";
  primarySubscriber?: PrimarySubscriberVO;
}

export interface InsuranceInfoVO {
  primaryInsurance?: InsuranceDetailsVO;
  secondaryInsurance?: InsuranceDetailsVO;
  signature?: SignatureVO;
  ssnLastFour?: string;
}

export interface PatientVO {
  /** @format int64 */
  id: number;
  personalDetails: PersonalDetailsVO;
  contactDetails: ContactDetailsVO;
  contact: ContactVO;
  insuranceDetails: InsuranceInfoVO;
  additionalInformation?: AdditionalInfoVO;
  nextAppointment?: string;
  /** @format int64 */
  balanceAmount: number;
  onboardingState:
    | "GENERAL_INFO"
    | "INSURANCE_INFO"
    | "DENTAL_HISTORY_INFO"
    | "MEDICAL_HISTORY_INFO"
    | "PAYMENT_INFO"
    | "CONSENT"
    | "PDF_SUBMISSION"
    | "COMPLETED";
}

export interface PrimarySubscriberVO {
  /** @format int64 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 100
   */
  firstName?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  lastName?: string;
  dob?: string;
  /** @format int64 */
  patientId?: number;
  relation?: "SELF" | "PARENT" | "CHILD" | "SPOUSE" | "SIBLING" | "GRANDPARENT" | "GRANDCHILD" | "OTHER";
  carrier?: string;
  /** @format int64 */
  carrierId?: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  employer?: string;
  /**
   * @minLength 2
   * @maxLength 80
   */
  subscriberId?: string;
  /** @pattern ^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$ */
  ssn?: string;
  ssnLastFour?: string;
  insuranceOrdinal?: "PRIMARY" | "SECONDARY" | "OTHER";
  startDate?: string;
  expiryDate?: string;
  releaseOfPatientInfo?: boolean;
  assignmentOfBenefits?: boolean;
  notes?: string;
  /** @format uuid */
  insurancePlanUuid?: string;
}

export interface SignatureVO {
  /** @format int64 */
  id?: number;
  type?:
    | "INSURANCE_INFO_AUTHORIZED"
    | "INSURANCE_INFO_ACKNOWLEDGE"
    | "DENTAL_HISTORY"
    | "MEDICAL_HISTORY"
    | "PRIVACY_POLICY"
    | "WORK_TO_BE_DONE"
    | "DRUGS_AND_MEDICATIONS"
    | "CHANGES_IN_TREATMENT_PLAN"
    | "CROWNS_BRIDGES_AND_VENEERS"
    | "DENTURES_COMPLETE_OR_PARTIAL"
    | "PERIODONTAL_LOSS"
    | "CONSENT";
  text: string;
  date?: string;
}

export interface SuccessResponsePatientVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientVO;
}

export interface PerioChartExamEntryRequest {
  perioSequenceType:
    | "BLEEDING"
    | "SUPPURATION"
    | "PLAQUE"
    | "CALCULUS"
    | "PROBING"
    | "MOBILITY"
    | "GINGMARGIN"
    | "FURCATION"
    | "MUCOGINGJUNC";
  /**
   * @format int32
   * @min 1
   * @max 32
   */
  toothNum: number;
  /**
   * For the BLEEDING, SUPPURATION, PLAQUE, and CALCULUS sequence types, the presence of a value indicates an observation was made, null otherwise. Additionally, only Ging Marg can have a negative value.
   * @format int32
   * @min -9
   * @max 19
   */
  mbValue?: number;
  /**
   * @format int32
   * @min 0
   * @max 9
   */
  secondaryBValue?: number;
  /**
   * @format int32
   * @min -9
   * @max 19
   */
  dbValue?: number;
  /**
   * @format int32
   * @min -9
   * @max 19
   */
  mlValue?: number;
  /**
   * @format int32
   * @min 0
   * @max 9
   */
  secondaryMlValue?: number;
  /**
   * @format int32
   * @min -9
   * @max 19
   */
  dlValue?: number;
  /**
   * @format int32
   * @min 0
   * @max 9
   */
  secondaryDlValue?: number;
  /** @format int32 */
  bvalue?: number;
  /** @format int32 */
  lvalue?: number;
}

export interface UpsertPerioChartExamEntriesRequest {
  entries: PerioChartExamEntryRequest[];
}

export interface PerioChartExamEntryVO {
  /** @format int32 */
  toothNum: number;
  perioSequenceType:
    | "BLEEDING"
    | "SUPPURATION"
    | "PLAQUE"
    | "CALCULUS"
    | "PROBING"
    | "MOBILITY"
    | "GINGMARGIN"
    | "FURCATION"
    | "MUCOGINGJUNC";
  /** @format int32 */
  mbValue?: number;
  /** @format int32 */
  secondaryBValue?: number;
  /** @format int32 */
  dbValue?: number;
  /** @format int32 */
  mlValue?: number;
  /** @format int32 */
  secondaryMlValue?: number;
  /** @format int32 */
  dlValue?: number;
  /** @format int32 */
  secondaryDlValue?: number;
  /** @format int32 */
  bValue?: number;
  /** @format int32 */
  lValue?: number;
}

export interface SuccessResponseListPerioChartExamEntryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PerioChartExamEntryVO[];
}

export interface BankAccountVO {
  type: "CHECKING" | "SAVINGS";
  country: string;
  maskedAccountNumber: string;
  routingNumber: string;
}

export interface CardVO {
  cardCurrency?: string;
  cardBrand?: string;
  cardType?: string;
  /** @format int32 */
  cardExpYear?: number;
  /** @format int32 */
  cardExpMonth?: number;
  cardLastFour?: string;
  cardBin?: string;
  cardIsHealthBenefit?: boolean;
}

export interface EntityReferenceVO {
  type:
    | "PATIENT"
    | "EMPLOYEE"
    | "PRACTICE"
    | "INSURANCE_CARRIER"
    | "FORM_SUBMISSION"
    | "INVOICE"
    | "RECALL"
    | "APPOINTMENT";
  /** @format int64 */
  id?: number;
  /** @format uuid */
  uuid?: string;
}

export interface PaymentProfileVO {
  /** @format uuid */
  uuid: string;
  owner: EntityReferenceVO;
  type: "CARD" | "BANK_ACCOUNT";
  state: "PENDING" | "ACTIVE" | "INVALID" | "TRANSIENT" | "DELETED";
  invalidReason?: string;
  billingName?: string;
  billingAddress?: AddressVO;
  card?: CardVO;
  bankAccount?: BankAccountVO;
  isFamily: boolean;
  /** @format uuid */
  paymentDeviceUuid?: string;
  isDefault: boolean;
}

export interface SuccessResponsePaymentProfileVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PaymentProfileVO;
}

export interface BatchUpsertPatientToothRequest {
  teeth: UpsertPatientToothRequest[];
}

export interface PatientToothConditionRequest {
  condition:
    | "CARIES"
    | "CRACKED_TOOTH"
    | "PERIAPICAL_ABSCESS"
    | "DIASTEMA_OF_TEETH_MESIAL"
    | "DIASTEMA_OF_TEETH_DISTAL"
    | "DISTAL_INCLINATION"
    | "MESIAL_INCLINATION"
    | "WATCH_TOOTH"
    | "WATCH_TOOTH_SURFACE"
    | "SENSITIVE_DENTIN"
    | "GINGIVAL_RECESSION"
    | "RETAINED_ROOT"
    | "ASYMPTOMATIC_PULPITIS"
    | "CARIES_OF_CERVICAL"
    | "CHIPPED_TOOTH"
    | "ABFRACTION"
    | "LINGUAL_ABFRACTION"
    | "BUCCAL_ABFRACTION"
    | "IMPACTED_DISTAL"
    | "IMPACTED_MESIAL"
    | "SUPRA_ERUPTED"
    | "LINGUAL_RETAINER"
    | "ROOT_TIPS"
    | "ROOT_ABRASION"
    | "ROOT_DECAY"
    | "DEFECT"
    | "IRREVERS_PULP"
    | "REVERS_PULP"
    | "NECROTIC"
    | "APICAL_PERIO"
    | "CARIOUS_PULP_EXP"
    | "CRACKED_FILLING";
  surfaces?: ("M" | "O" | "D" | "I" | "L" | "B" | "F" | "BV" | "FV" | "LV")[];
}

export interface UpsertPatientToothRequest {
  /**
   * @format int32
   * @min 1
   * @max 32
   */
  toothNum: number;
  state: "MISSING" | "PRIMARY" | "PRIMARY_UNERUPTED" | "PERMANENT" | "PERMANENT_UNERUPTED";
  conditions: PatientToothConditionRequest[];
}

export interface PatientToothConditionVO {
  condition:
    | "CARIES"
    | "CRACKED_TOOTH"
    | "PERIAPICAL_ABSCESS"
    | "DIASTEMA_OF_TEETH_MESIAL"
    | "DIASTEMA_OF_TEETH_DISTAL"
    | "DISTAL_INCLINATION"
    | "MESIAL_INCLINATION"
    | "WATCH_TOOTH"
    | "WATCH_TOOTH_SURFACE"
    | "SENSITIVE_DENTIN"
    | "GINGIVAL_RECESSION"
    | "RETAINED_ROOT"
    | "ASYMPTOMATIC_PULPITIS"
    | "CARIES_OF_CERVICAL"
    | "CHIPPED_TOOTH"
    | "ABFRACTION"
    | "LINGUAL_ABFRACTION"
    | "BUCCAL_ABFRACTION"
    | "IMPACTED_DISTAL"
    | "IMPACTED_MESIAL"
    | "SUPRA_ERUPTED"
    | "LINGUAL_RETAINER"
    | "ROOT_TIPS"
    | "ROOT_ABRASION"
    | "ROOT_DECAY"
    | "DEFECT"
    | "IRREVERS_PULP"
    | "REVERS_PULP"
    | "NECROTIC"
    | "APICAL_PERIO"
    | "CARIOUS_PULP_EXP"
    | "CRACKED_FILLING";
  displayName: string;
  visualType?:
    | "EXTRACTION"
    | "IMPLANT"
    | "ABUTMENT"
    | "RCT"
    | "POST"
    | "FILLING_DARK"
    | "FILLING_LIGHT"
    | "CROWN_DARK"
    | "CROWN_LIGHT"
    | "BRIDGE_DARK"
    | "BRIDGE_LIGHT"
    | "DENTURE_DARK"
    | "DENTURE_LIGHT"
    | "SEALANT"
    | "WATCH"
    | "SPACE_MAINTAINER"
    | "SDF"
    | "APICOECTOMY"
    | "BU"
    | "PIN"
    | "MARGIN_TOOTH"
    | "CRACKED_TOOTH"
    | "IMPACTED_TOOTH"
    | "RETAINED_ROOT"
    | "SENSITIVE_DENTIN"
    | "PERAPICAL_ABSCESS"
    | "DIASTEMA_MESIAL"
    | "DIASTEMA_DISTAL"
    | "INCLINATION_DISTAL"
    | "INCLINATION_MESIAL"
    | "GING_RECESSION"
    | "WATCH_SURFACE"
    | "CHIPPED_TOOTH"
    | "VENEER"
    | "APICAL_PERIO"
    | "BUCCAL_ABFRACTION"
    | "CARIOUS_PULP_EXP"
    | "CRACKED_FILLING"
    | "DEFECT"
    | "IMPACTED_DISTAL"
    | "IMPACTED_MESIAL"
    | "LINGUAL_ABFRACTION"
    | "LINGUAL_RETAINER"
    | "NECROTIC"
    | "ROOT_ABRASION"
    | "ROOT_DECAY"
    | "ROOT_TIPS"
    | "SUPRA_ERUPTED"
    | "NO_VISUAL_FOR_CONDITION"
    | "NONE";
  surfaces?: ("M" | "O" | "D" | "I" | "L" | "B" | "F" | "BV" | "FV" | "LV")[];
  /** @format date-time */
  date: string;
}

export interface PatientToothVO {
  /** @format int32 */
  toothNum: number;
  toothName: string;
  state: "MISSING" | "PRIMARY" | "PRIMARY_UNERUPTED" | "PERMANENT" | "PERMANENT_UNERUPTED";
  /** @format date-time */
  dateOfState?: string;
  position: "ANTERIOR" | "POSTERIOR";
  arch: "ARCH_UPPER" | "ARCH_LOWER";
  quadrant: "QUAD_UPPER_LEFT" | "QUAD_UPPER_RIGHT" | "QUAD_LOWER_LEFT" | "QUAD_LOWER_RIGHT";
  conditions: PatientToothConditionVO[];
}

export interface SuccessResponseListPatientToothVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientToothVO[];
}

export interface ClinicalNoteProviderStatusRequest {
  /** @format int64 */
  providerId: number;
  completed: boolean;
}

export interface UpdatePatientNoteRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 65535
   */
  text: string;
  status?: "INCOMPLETE" | "DRAFT" | "COMPLETED";
  providerStatus?: ClinicalNoteProviderStatusRequest[];
  tagIds?: number[];
  isAlert?: boolean;
}

export interface AppointmentConfirmationVO {
  reminderSentDate?: string;
  confirmedDate?: string;
  confirmedBy?: NameVO;
}

export interface AppointmentPatientProcedureVO {
  /** @format int64 */
  id: number;
  /** @format int64 */
  dentalProcedureId: number;
  displayName: string;
  fullDisplayName: string;
  procedureArea?: string;
  description: string;
  status: "EXISTING_OTHER" | "PLANNED" | "SCHEDULED" | "DONE" | "EXISTING_CURRENT" | "REFERRED" | "REJECTED";
  duration: string;
  /** @format int64 */
  durationInMinutes: number;
  isInsuranceBillable: boolean;
  labCaseStatus?: LabCaseStatusVO;
  dentalProcedure?: DentalProcedureVO;
  provider?: NameVO;
}

export interface AppointmentTagsVO {
  customAppointment: TagVO[];
  autoAppointment: TagVO[];
  patient: TagVO[];
}

export interface AppointmentVO {
  /** @format int64 */
  id: number;
  patient: NameVO;
  patientProcedures: AppointmentPatientProcedureVO[];
  date: string;
  startTime: string;
  endTime: string;
  room: RoomVO;
  state:
    | "UNSCHEDULED"
    | "UNCONFIRMED"
    | "CONFIRMED"
    | "CANCELED"
    | "NO_SHOW"
    | "ARRIVED"
    | "READY"
    | "IN_PROGRESS"
    | "CHECKOUT"
    | "COMPLETED"
    | "_DELETED"
    | "REQUESTED";
  /** @format int64 */
  duration: number;
  provider: ProviderVO;
  dentist: ProviderVO;
  comments?: string;
  sendConfirmationAndReminders: boolean;
  category?: NameVO;
  categoryName?: NameIdVO;
  /** @format int64 */
  migratedAt?: number;
  /** @format int64 */
  patientAmount: number;
  /** @format int64 */
  deductibleAmount: number;
  /** @format int64 */
  negotiatedRate: number;
  /** @format int64 */
  ucrRate: number;
  color?: string;
  asap: boolean;
  preference?: PreferenceVO;
  confirmation?: AppointmentConfirmationVO;
  isSelfBooked: boolean;
  /** @format date-time */
  completedAt?: string;
  audit?: AuditVO;
  tags?: AppointmentTagsVO;
  clinicalNoteStatus?: "INCOMPLETE" | "DRAFT" | "COMPLETED";
}

export interface AuditLineItemVO {
  doneBy?: UserVO;
  /** @format date-time */
  doneAt: string;
  action: "CREATE" | "UPDATE" | "DELETE" | "READ";
  text?: string;
}

export interface AuditVO {
  createdBy?: UserVO;
  /** @format date-time */
  createdAt?: string;
  updatedBy?: UserVO;
  /** @format date-time */
  updatedAt?: string;
  auditHistory?: AuditLineItemVO[];
}

export interface ClinicalNoteProviderStatusVO {
  /** @format int64 */
  providerId: number;
  completed: boolean;
}

export interface NameIdVO {
  /** @format int64 */
  id: number;
  name: string;
}

export interface PatientNoteAuditEntryVO {
  /** @format uuid */
  uuid: string;
  action: "CREATE" | "UPDATE" | "DELETE" | "READ";
  /** @format date-time */
  doneAt: string;
  doneBy?: UserVO;
  changes: string[];
  updatedName?: string;
  updatedText?: string;
  tags?: TagVO[];
  isAlert?: boolean;
  isUnarchiveAction: boolean;
}

export interface PatientNoteVO {
  /** @format uuid */
  uuid: string;
  /** @format int64 */
  patientId: number;
  name: string;
  text: string;
  /** @format int64 */
  createdAt: number;
  type: "CHART" | "GENERAL";
  appointment?: AppointmentVO;
  status?: "INCOMPLETE" | "DRAFT" | "COMPLETED";
  manualOverride: boolean;
  patientProceduresOutOfSync?: boolean;
  providerOutOfSync?: boolean;
  providerStatus?: ClinicalNoteProviderStatusVO[];
  isAlert: boolean;
  tags: TagVO[];
  lastEdit?: PatientNoteAuditEntryVO;
  isArchived: boolean;
}

export interface PreferenceVO {
  providers?: ProviderVO[];
  daysPreferred?: ("MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY")[];
  timeRanges?: TimeRangeVO[];
  comment?: string;
}

export interface SuccessResponsePatientNoteVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientNoteVO;
}

export interface UserVO {
  name: NameVO;
  type: "EMPLOYEE" | "PATIENT" | "SUPPORT_USER" | "SUPER_ADMIN";
}

export interface BatchFormResponseRequest {
  /** @format uuid */
  formPublishedContentUuid: string;
  responses?: Record<
    string,
    | FormBooleanResponseRequest
    | FormConsentResponseRequest
    | FormDateResponseRequest
    | FormNumberResponseRequest
    | FormSelectResponseRequest
    | FormStringResponseRequest
  >;
}

export interface ClinicalNoteFormResponseRequest {
  /** @format uuid */
  formUuid: string;
  formResponse: BatchFormResponseRequest;
}

export type FormBooleanResponseRequest = UtilRequiredKeys<FormResponseRequest, "type"> & {
  response?: boolean;
  type: "BOOLEAN";
};

export type FormConsentResponseRequest = UtilRequiredKeys<FormResponseRequest, "type"> & {
  signature: string;
  type: "CONSENT";
};

export type FormDateResponseRequest = UtilRequiredKeys<FormResponseRequest, "type"> & {
  response?: string;
  type: "DATE";
};

export type FormNumberResponseRequest = UtilRequiredKeys<FormResponseRequest, "type"> & {
  response?: number;
  type: "NUMBER";
};

export interface FormResponseRequest {
  type: "DATE" | "NUMBER" | "STRING" | "BOOLEAN" | "SELECT" | "CONSENT";
}

export type FormSelectResponseRequest = UtilRequiredKeys<FormResponseRequest, "type"> & {
  responses: Record<string, boolean>;
  other?: string;
  type: "SELECT";
};

export type FormStringResponseRequest = UtilRequiredKeys<FormResponseRequest, "type"> & {
  response?: string;
  type: "STRING";
};

export interface UpsertClinicalNoteWithFormsRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  name?: string;
  formResponses: ClinicalNoteFormResponseRequest[];
  status?: "INCOMPLETE" | "DRAFT" | "COMPLETED";
  manualOverride?: boolean;
  providerStatus?: ClinicalNoteProviderStatusRequest[];
  tagIds?: number[];
  isAlert?: boolean;
}

export interface BatchUpdatePatientProcedureRequest {
  /**
   * If provided, the priorities will be applied to the target treatment plan.
   * @format uuid
   */
  targetTreatmentPlanUuid?: string;
  patientProcedureIds: number[];
  date?: string;
  status?: "EXISTING_OTHER" | "PLANNED" | "SCHEDULED" | "DONE" | "EXISTING_CURRENT" | "REFERRED" | "REJECTED";
  /** @format int64 */
  providerId?: number;
  /**
   * @format int32
   * @min 1
   * @max 11
   */
  priority?: number | null;
}

export interface SuccessResponseListPatientProcedureVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientProcedureVO[];
}

export interface PreAuth {
  status?: "SENT" | "APPROVED" | "DENIED" | "RESENT" | "APPROVED_RESENT" | "DENIED_RESENT";
  /**
   * @minLength 0
   * @maxLength 50
   */
  number?: string;
  /** @format int64 */
  patientAmount?: number;
  /** @format int64 */
  insuranceAmount?: number;
  /** @format int64 */
  deductibleAmount?: number;
  /** @format int64 */
  downgradeDentalProcedureId?: number;
}

export interface UpdatePatientProcedureRequest {
  /**
   * If provided, the priority update will be applied to the target treatment plan.
   * @format uuid
   */
  targetTreatmentPlanUuid?: string;
  date?: string;
  status: "EXISTING_OTHER" | "PLANNED" | "SCHEDULED" | "DONE" | "EXISTING_CURRENT" | "REFERRED" | "REJECTED";
  /** @format int64 */
  providerId?: number;
  creditToPractice?: boolean;
  prognosis?: "EXCELLENT" | "GOOD" | "FAIR" | "QUESTIONABLE" | "POOR" | "HOPELESS";
  surfaces?: ("M" | "O" | "D" | "I" | "L" | "B" | "F" | "BV" | "FV" | "LV")[];
  mouthArea?:
    | "WHOLE_MOUTH"
    | "QUAD_UPPER_LEFT"
    | "QUAD_UPPER_RIGHT"
    | "QUAD_LOWER_LEFT"
    | "QUAD_LOWER_RIGHT"
    | "ARCH_UPPER"
    | "ARCH_LOWER";
  toothName?: string;
  toothRange?: string;
  implantDate?: string;
  implantInstallType?: "NEW" | "REPLACEMENT";
  /**
   * @format int32
   * @min 1
   * @max 11
   */
  priority?: number;
  preAuth?: PreAuth;
}

export interface SuccessResponsePatientProcedureVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientProcedureVO;
}

export interface UpdatePatientProcedureFeeRequest {
  preAuth?: PreAuth;
  /**
   * Set to override UCR rate
   * @format int64
   * @min 0
   */
  ucrRate?: number;
  /**
   * Set to override primary patient amount
   * @format int64
   * @min 0
   */
  primaryPatientAmount: number;
  /**
   * Set to override primary insurance amount
   * @format int64
   * @min 0
   */
  primaryInsuranceAmount: number;
  /**
   * Set to override primary deductible amount
   * @format int64
   * @min 0
   */
  primaryDeductibleAmount: number;
  /**
   * Set to override secondary patient amount
   * @format int64
   * @min 0
   */
  secondaryPatientAmount?: number;
  /**
   * Set to override secondary insurance amount
   * @format int64
   * @min 0
   */
  secondaryInsuranceAmount?: number;
  /**
   * Set to override secondary deductible amount
   * @format int64
   * @min 0
   */
  secondaryDeductibleAmount?: number;
  /**
   * Set to override the procedure with downgrade.
   * @format int64
   */
  downgradeDentalProcedureId?: number;
}

export interface DailyHuddleRequest {
  startDate: string;
  endDate: string;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  otherFilters?: Filter[];
  includeTagIds?: number[];
  /** @uniqueItems true */
  clinicalNoteStatuses?: ("INCOMPLETE" | "DRAFT" | "COMPLETED")[];
}

export interface EmailTemplate {
  html: string;
  json: string;
  text?: string;
}

/** Fields to filter on. Supported fields: insuranceCarrierId, hasNextAppointment, patientStatus */
export interface Filter {
  /**
   * For productionAmount and collectedAmount metrics, supported types: isScheduled(boolean type) providerId(long type) patientId(long type) providerJobCategory(string type)
   * For patientUncollectedAmount metric, supported types: providerId(long type) patientId(long type) isInvoiced(boolean type) providerJobCategory(string type) invoiceState(string type)
   * For insuranceUncollectedAmount metric, supported types: providerId(long type) patientId(long type) primarySubscriberPatientId(long type) insuranceCarrierId(long type) patientInsuranceId(long type) isSubmitted(boolean type) providerJobCategory(string type)
   * For paymentAmount metric, supported types: payerType(string type), paymentMethod(string type), paymentType(string type)
   */
  type: string;
  /** Allowed values for this type. If the value is not of string type, please cast it to string. For booleans, use "true" or "false". Values are evaluated with OR operator. null elements are allowed. An array like [null, "1", "2"] with IN operator means finding all entries with null, 1, or 2 as the value.  */
  values: (string | null)[];
  /** Operator to use when evaluating the values. Defaults to IN. */
  op?: "IN" | "NOT_IN";
}

export interface LocalDateRangeFilter {
  min?: string;
  max?: string;
}

export interface LongRangeFilter {
  /** @format int64 */
  min?: number;
  /** @format int64 */
  max?: number;
}

export type MessageCampaignAsapCriteria = UtilRequiredKeys<MessageCampaignFilter, "type"> & {
  practiceAppointmentListCriteria?: PracticeAppointmentListCriteria;
  type: "ASAP_LIST";
};

export interface MessageCampaignFilter {
  deselectedIds?: number[];
  selectedIds?: number[];
  type: "PATIENT_LIST" | "DAILY_HUDDLE" | "ASAP_LIST" | "REPORTING_UNCOLLECTED" | "REPORTING_RECALL";
}

export type MessageCampaignHuddleCriteria = UtilRequiredKeys<MessageCampaignFilter, "type"> & {
  dailyHuddleRequest?: DailyHuddleRequest;
  type: "DAILY_HUDDLE";
};

export type MessageCampaignPatientCriteria = UtilRequiredKeys<MessageCampaignFilter, "type"> & {
  patientListCriteria?: PatientListCriteria;
  type: "PATIENT_LIST";
};

export type MessageCampaignRecallReportCriteria = UtilRequiredKeys<MessageCampaignFilter, "type"> & {
  reportingRecallByPatientRequest?: ReportingRecallByPatientRequest;
  type: "REPORTING_RECALL";
};

export type MessageCampaignUncollectedReportCriteria = UtilRequiredKeys<MessageCampaignFilter, "type"> & {
  patientBalanceAgingRequest?: PatientBalanceAgingRequest;
  type: "REPORTING_UNCOLLECTED";
};

export interface MonthDayRangeFilter {
  min?: string;
  max?: string;
}

export interface PatientAgeGroupVO {
  /** @format int32 */
  min?: number;
  /** @format int32 */
  max?: number;
}

export interface PatientBalanceAgingRequest {
  startDate?: string;
  endDate?: string;
  /** When true, include the grand total for the practice with all filters applied. False otherwise. */
  includeTotal?: boolean;
  /** Fields to sort the result by. Supported fields: patientLastName, nextAppointmentDate totalPatientBalance, paymentRequestedAt, aging30OrLess, aging31To60, aging61To90, aging91To120, aging121OrMore. */
  sortOrders?: SortOrder[];
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** Supported filters: uninvoicedBalance, wasPaymentRequested, hasOutstandingInvoices, aging30OrLess, aging31To60, aging61To90, aging91To120, aging121OrMore */
  filters?: Filter[];
}

export interface PatientCriteriaVO {
  status?: ("PROSPECT" | "ACTIVE" | "INACTIVE" | "ARCHIVED" | "NONPATIENT" | "DECEASED")[];
  gender?: "MALE" | "FEMALE" | "OTHER" | "UNKNOWN";
  ageGroup?: PatientAgeGroupVO;
  primaryInsuranceVerifyStatuses?: ("VERIFIED" | "AUTO_VERIFIED" | "NOT_VERIFIED" | "FAILED")[];
  primaryInsuranceExpired?: boolean;
  primaryInsuranceCarrierIds?: number[];
  /** @uniqueItems true */
  carrierIds?: number[];
  hasNoContact?: boolean;
  /** @format int64 */
  contactPatientId?: number;
  excludePatientIds?: number[];
  /** @format int64 */
  feeScheduleId?: number;
  /** @format uuid */
  insurancePlanUuid?: string;
  birthdayRange?: MonthDayRangeFilter;
  /**
   * @format int32
   * @max 365
   */
  birthdayWithinDays?: number;
  patientBalanceRange?: LongRangeFilter;
  insuranceBalanceRange?: LongRangeFilter;
  remainingBenefitAmountRange?: LongRangeFilter;
  preferredLanguages?: string[];
  hasPreferredLanguage?: boolean;
  genders?: ("MALE" | "FEMALE" | "OTHER" | "UNKNOWN")[];
}

export interface PatientListCriteria {
  /** @format int32 */
  maxPageSize?: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  searchString?: string;
  sortColumn?: string;
  orderBy?: string;
  patientCriteria?: PatientCriteriaVO;
  /** @deprecated */
  appointmentCriteria?:
    | "NO_APPOINTMENT"
    | "APPOINTMENT_WITHIN_7_DAYS"
    | "APPOINTMENT_WITHIN_15_DAYS"
    | "APPOINTMENT_WITHIN_30_DAYS";
  appointmentDateRange?: LocalDateRangeFilter;
  hasNoAppointment?: boolean;
  /**
   * @format int32
   * @min 0
   */
  appointmentWithinDays?: number;
}

export interface PracticeAppointmentListCriteria {
  /** @format int32 */
  maxPageSize?: number;
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  sortColumn?: string;
  sortDirection?: "ASC" | "DESC";
}

export interface ReportingRecallByPatientRequest {
  startDate?: string;
  endDate?: string;
  includeTotal?: boolean;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** Fields to filter on. Supported fields: insuranceCarrierId, hasNextAppointment, patientStatus */
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: patientLastName, dueDate, previousDate, scheduledDate, previousAppointmentDate, insuranceCarrierId, remainingInsuranceBenefitAmount, patientStatus */
  sortOrders?: SortOrder[];
}

/** Fields to sort the result by. Supported fields: patientLastName, dueDate, previousDate, scheduledDate, previousAppointmentDate, insuranceCarrierId, remainingInsuranceBenefitAmount, patientStatus */
export interface SortOrder {
  direction: "ASCENDING" | "DESCENDING";
  field: string;
}

export interface UpdateMessageCampaignRequest {
  /**
   * @minLength 0
   * @maxLength 600
   */
  template: string;
  filter:
    | MessageCampaignAsapCriteria
    | MessageCampaignHuddleCriteria
    | MessageCampaignPatientCriteria
    | MessageCampaignRecallReportCriteria
    | MessageCampaignUncollectedReportCriteria;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name?: string;
  /** @format int64 */
  scheduledAt?: number;
  emailTemplate?: EmailTemplate;
}

export interface MessageCampaignSummaryVO {
  /** @format uuid */
  uuid: string;
  name?: string;
  status: "DRAFT" | "SCHEDULED" | "SENT" | "CANCELED";
  channels: ("PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL")[];
  /** @deprecated */
  channel?: "PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL";
  /** @format int64 */
  scheduledAt?: number;
  /** @format int64 */
  sentAt?: number;
  /** @format int64 */
  requestedMessageCount: number;
  /** @format int64 */
  sentMessageCount: number;
  /** @format int64 */
  openedMessageCount: number;
  /** @format int32 */
  openRate: number;
  /** @format int64 */
  clickedMessageCount: number;
  /** @format int32 */
  clickRate: number;
  /** @format int64 */
  complainedMessageCount: number;
  /** @format int32 */
  complaintRate: number;
}

export interface MessageCampaignVO {
  /** @format uuid */
  uuid: string;
  filter?:
    | MessageCampaignAsapCriteria
    | MessageCampaignHuddleCriteria
    | MessageCampaignPatientCriteria
    | MessageCampaignRecallReportCriteria
    | MessageCampaignUncollectedReportCriteria;
  name?: string;
  status: "DRAFT" | "SCHEDULED" | "SENT" | "CANCELED";
  channel: "PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL";
  template: string;
  emailTemplate?: EmailTemplate;
  /** @format int64 */
  scheduledAt?: number;
  /** @format int64 */
  sentAt?: number;
  /** @format int64 */
  requestedMessageCount: number;
  summary?: MessageCampaignSummaryVO;
}

export interface SuccessResponseMessageCampaignVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MessageCampaignVO;
}

export interface LabOrderFormFileRequest {
  contentType: string;
  encryptedFileKey: string;
}

export interface LabRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /** @pattern ^[0-9]{10}$ */
  phone?: string;
  /** @pattern ^[0-9]{10}$ */
  fax?: string;
  address?: AddressVO;
  /**
   * @minLength 0
   * @maxLength 255
   */
  contactPerson?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  website?: string;
  openDaysOfWeek?: ("MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY")[];
  openTime?: string;
  closeTime?: string;
  shippingMethod?: "FEDEX" | "UPS" | "USPS" | "COURIER" | "OTHER";
  /**
   * @format int32
   * @min 1
   */
  estimatedCaseTimeInDays?: number;
  orderFormFile?: LabOrderFormFileRequest;
  /**
   * @minLength 0
   * @maxLength 400
   */
  notes?: string;
}

export interface LabVO {
  /** @format uuid */
  uuid: string;
  name: string;
  phone?: string;
  fax?: string;
  address?: AddressVO;
  contactPerson?: string;
  website?: string;
  openDaysOfWeek?: ("MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY")[];
  openTime?: string;
  closeTime?: string;
  shippingMethod?: "FEDEX" | "UPS" | "USPS" | "COURIER" | "OTHER";
  /** @format int32 */
  estimatedCaseTimeInDays?: number;
  notes?: string;
  hasOrderFormFile: boolean;
  isArchived: boolean;
}

export interface SuccessResponseLabVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LabVO;
}

export interface LabCaseRequest {
  date: string;
  status: "DRAFT" | "SENT" | "RECEIVED" | "RETURNED" | "READY" | "COMPLETE";
  /** @format int64 */
  patientId: number;
  /** @uniqueItems true */
  patientProcedureIds?: number[];
  /** @format uuid */
  labUuid?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  trackingNumber?: string;
  estimatedReceiveDate?: string;
  /** @format uuid */
  returnReasonUuid?: string;
  /** @format int64 */
  shippingCost?: number;
  /** @format int64 */
  labFee?: number;
  /**
   * @minLength 0
   * @maxLength 400
   */
  notes?: string;
}

export interface LabCaseAppointmentVO {
  /** @format int64 */
  id: number;
  date: string;
  startTime: string;
}

export interface LabCaseVO {
  /** @format uuid */
  uuid: string;
  date: string;
  status: "DRAFT" | "SENT" | "RECEIVED" | "RETURNED" | "READY" | "COMPLETE";
  patient: NameVO;
  appointments: LabCaseAppointmentVO[];
  patientProcedures: LabCasePatientProcedureVO[];
  types: string[];
  lab?: LabVO;
  trackingNumber?: string;
  estimatedReceiveDate?: string;
  returnReason?: LabCaseReturnReasonVO;
  /** @format int64 */
  shippingCost?: number;
  /** @format int64 */
  labFee?: number;
  notes?: string;
  audit?: AuditVO;
  isArchived: boolean;
}

export interface SuccessResponseLabCaseVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LabCaseVO;
}

export interface LabCaseStatusRequest {
  status: "DRAFT" | "SENT" | "RECEIVED" | "RETURNED" | "READY" | "COMPLETE";
  /** @format uuid */
  returnReasonUuid?: string;
}

export interface SuccessResponseLabCaseStatusVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LabCaseStatusVO;
}

export interface LabCaseTypeRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /** @uniqueItems true */
  dentalProcedureIds: number[];
}

export interface LabCaseTypeVO {
  /** @format uuid */
  uuid: string;
  name: string;
  dentalProcedures: DentalProcedureVO[];
}

export interface SuccessResponseLabCaseTypeVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LabCaseTypeVO;
}

export interface LabCaseReturnReasonRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  reason: string;
}

export interface SuccessResponseLabCaseReturnReasonVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LabCaseReturnReasonVO;
}

export interface AutomationActionRequest {
  event:
    | "CONFIRMED_APPOINTMENT"
    | "UNCONFIRMED_APPOINTMENT"
    | "APPOINTMENT_COMPLETED"
    | "APPOINTMENT_CREATED"
    | "APPOINTMENT_REQUESTED"
    | "PATIENT_CREATED"
    | "PROPHY_DUE"
    | "PERIO_DUE";
  eventTime: "BEFORE" | "WHEN" | "AFTER";
  /**
   * @format int32
   * @min 1
   */
  interval?: number;
  intervalType?: "HOUR" | "DAY" | "WEEK";
  /** @uniqueItems true */
  channels: ("PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL")[];
  /**
   * @minLength 0
   * @maxLength 400
   */
  smsTemplate?: string;
  /**
   * @minLength 0
   * @maxLength 5000
   */
  emailTemplate?: string;
  customEmailTemplate?: EmailTemplate;
  subject?: string;
  sendTime?: string;
  dentalProcedures?: AutomationDentalProceduresRequest;
  active: boolean;
}

export interface AutomationDentalProceduresRequest {
  /** @uniqueItems true */
  ids: number[];
  contains: "ANY" | "ALL" | "EXACT";
}

export interface AutomationActionVO {
  /** @format uuid */
  uuid: string;
  event:
    | "CONFIRMED_APPOINTMENT"
    | "UNCONFIRMED_APPOINTMENT"
    | "APPOINTMENT_COMPLETED"
    | "APPOINTMENT_CREATED"
    | "APPOINTMENT_REQUESTED"
    | "PATIENT_CREATED"
    | "PROPHY_DUE"
    | "PERIO_DUE";
  eventTime: "BEFORE" | "WHEN" | "AFTER";
  /** @format int32 */
  interval?: number;
  intervalType?: "HOUR" | "DAY" | "WEEK";
  channels: ("PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL")[];
  smsTemplate?: string;
  emailTemplate?: string;
  customEmailTemplate?: EmailTemplate;
  subject?: string;
  sendTime?: string;
  dentalProcedures?: AutomationDentalProceduresVO;
  active: boolean;
  /** @format int64 */
  sentCount: number;
  /** @format int64 */
  recipientCount: number;
}

export interface AutomationDentalProceduresVO {
  procedures: DentalProcedureVO[];
  contains: "ANY" | "ALL" | "EXACT";
}

export interface SuccessResponseAutomationActionVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AutomationActionVO;
}

export interface UpdateJourneyRequest {
  name: string;
  active: boolean;
}

export interface ActionVO {
  /** @format uuid */
  uuid: string;
  /** @format int32 */
  interval?: number;
  intervalType?: "HOUR" | "DAY" | "WEEK";
  eventTime: "BEFORE" | "WHEN" | "AFTER";
  subject?: string;
  template: string;
  formTemplate?: string;
  event:
    | "CONFIRMED_APPOINTMENT"
    | "UNCONFIRMED_APPOINTMENT"
    | "APPOINTMENT_COMPLETED"
    | "APPOINTMENT_CREATED"
    | "APPOINTMENT_REQUESTED"
    | "PATIENT_CREATED"
    | "PROPHY_DUE"
    | "PERIO_DUE";
  channel: "PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL";
  /** @format int64 */
  sentCount: number;
  confirmWithY: boolean;
  sendTime?: string;
  active: boolean;
  appointmentCategories: AppointmentCategoryVO[];
  confirmationMessage?: string;
}

export interface AppointmentCategorySelfBookingVO {
  bookingType: "REQUEST" | "SCHEDULE";
  patientType: "ALL" | "NEW" | "EXISTING";
  /** @format int32 */
  leadTimeToBookInDays: number;
}

export interface AppointmentCategoryVO {
  /** @format int64 */
  id: number;
  name: string;
  externalName?: string;
  procedures: DentalProcedureVO[];
  /** @format int32 */
  duration: number;
  color: string;
  /** @format int32 */
  order: number;
  isSelfBookable: boolean;
  providers: ProviderVO[];
  selfBookingConfig?: AppointmentCategorySelfBookingVO;
}

export interface JourneyVO {
  /** @format uuid */
  uuid: string;
  name: string;
  type: "APPOINTMENT" | "POST_APPOINTMENT" | "RECALL" | "FORMS";
  actions: ActionVO[];
  active: boolean;
}

export interface SuccessResponseJourneyVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: JourneyVO;
}

export interface UpdateActionRequest {
  /**
   * @format int32
   * @min 1
   */
  interval?: number;
  intervalType?: "HOUR" | "DAY" | "WEEK";
  eventTime: "BEFORE" | "WHEN" | "AFTER";
  subject?: string;
  /**
   * @minLength 0
   * @maxLength 400
   */
  template: string;
  /**
   * @minLength 0
   * @maxLength 400
   */
  formTemplate?: string;
  event:
    | "CONFIRMED_APPOINTMENT"
    | "UNCONFIRMED_APPOINTMENT"
    | "APPOINTMENT_COMPLETED"
    | "APPOINTMENT_CREATED"
    | "APPOINTMENT_REQUESTED"
    | "PATIENT_CREATED"
    | "PROPHY_DUE"
    | "PERIO_DUE";
  channel: "PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL";
  confirmWithY: boolean;
  sendTime?: string;
  active: boolean;
  /** @uniqueItems true */
  appointmentCategoryIds?: number[];
  /**
   * @minLength 0
   * @maxLength 400
   */
  confirmationMessage?: string;
}

export interface SuccessResponseActionVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ActionVO;
}

export interface AccountEntry {
  /** @format int64 */
  amount: number;
  /** @format int64 */
  balance: number;
}

export interface AppointmentAdjustmentVO {
  /** @format uuid */
  uuid: string;
  /** @format int64 */
  appointmentId: number;
  /** @format int64 */
  amount: number;
  /** @format int64 */
  customAdjustmentTypeId: number;
  customAdjustmentType: CustomAdjustmentTypeVO;
  /** @format uuid */
  invoiceUuid?: string;
  procedureAdjustmentItems?: ProcedureAdjustmentItemVO[];
}

export interface AppointmentSnapshot {
  /** @format int64 */
  endAt: number;
  /** @format int64 */
  ucrRate: number;
  /** @format int64 */
  estWriteOffAmount: number;
  /** @format int64 */
  estDeductibleAmount?: number;
  providerName?: string;
}

export interface CheckPayload {
  /**
   * @minLength 0
   * @maxLength 20
   */
  checkNumber: string;
  /**
   * @minLength 0
   * @maxLength 128
   */
  bankName: string;
}

export interface ClaimSnapshot {
  currency: string;
  insuranceOrdinal: "PRIMARY" | "SECONDARY" | "OTHER";
  insuranceCarrier: string;
  insurancePlanType?:
    | "PPO"
    | "TOA_SOA"
    | "EPO"
    | "HMO_DHMO"
    | "MEDICAID_CHIP"
    | "POS"
    | "DISCOUNT"
    | "SELF_FUNDED_ERISA"
    | "DIRECT_REIMBURSEMENT"
    | "FEDERAL"
    | "MEDICARE"
    | "TRICARE";
  /** @format int64 */
  estPaymentAmount: number;
  /** @format int64 */
  estWriteOffAmount: number;
  /** @format int64 */
  deductibleApplied: number;
}

/** The family payment amount if this payment is associated with a family payment. */
export interface CurrencyAmount {
  /** @pattern ^[A-Z]{3}$ */
  currency: string;
  /** @format int64 */
  amount: number;
}

export interface CustomAdjustmentTypeVO {
  /** @format int64 */
  id: number;
  /** @format int64 */
  practiceId: number;
  /** @format uuid */
  practiceUuid: string;
  entryType: "CREDIT" | "DEBIT";
  name: string;
  isArchived: boolean;
  isArchivable: boolean;
}

export interface EFTPayload {
  /**
   * @minLength 0
   * @maxLength 20
   */
  eftNumber: string;
  /**
   * @minLength 0
   * @maxLength 128
   */
  bankName: string;
}

export interface ExternalPosPayload {
  nameOnCard?: string;
  /** @pattern ^[0-9]{4}$ */
  cardLastFour?: string;
}

export interface InvoiceEntryReference {
  type: "APPOINTMENT" | "ADJUSTMENT";
  id: string;
}

export interface InvoiceEntryVO {
  /** @format int64 */
  createdAt: number;
  entryReference: InvoiceEntryReference;
  ledgerOrderUuids: string[];
  appointmentSnapshot?: AppointmentSnapshot;
  adjustmentNote?: string;
  adjustmentName?: string;
  currency: string;
  /** @format int64 */
  subtotalAmount: number;
  /** @format int64 */
  amount: number;
  /** @format int64 */
  taxAmount: number;
  /** @format int64 */
  discountAmount: number;
  /** @format int64 */
  surchargeAmount: number;
  lineItems: InvoiceLineItemVO[];
  appointmentAdjustments?: AppointmentAdjustmentVO[];
}

export interface InvoiceLineItemReference {
  type: "PATIENT_PROCEDURE";
  id: string;
}

export interface InvoiceLineItemVO {
  lineItemReference: InvoiceLineItemReference;
  currency: string;
  /** @format int64 */
  subtotalAmount: number;
  /** @format int64 */
  amount: number;
  /** @format int64 */
  taxAmount: number;
  /** @format int64 */
  discountAmount: number;
  /** @format int64 */
  surchargeAmount: number;
  patientProcedureSnapshot?: PatientProcedureSnapshot;
}

export interface InvoiceSnapshot {
  invoiceNumber: string;
  currency: string;
  /** @format int64 */
  amount: number;
  /** @format int64 */
  taxAmount?: number;
  /** @format int64 */
  discountAmount?: number;
  /** @format int64 */
  subtotalAmount?: number;
}

export interface InvoiceVO {
  /** @format uuid */
  uuid: string;
  currency: string;
  /** @format int64 */
  amount: number;
  /** @format int64 */
  practiceId?: number;
  /** @format uuid */
  practiceUuid?: string;
  payer: EntityReferenceVO;
  state: "FINALIZED" | "VOID" | "PARTIALLY_PAID" | "PAID";
  /** @format int64 */
  amountPaid: number;
  /** @format int64 */
  dueAt?: number;
  /** @format int64 */
  createdAt: number;
  invoiceNumber: string;
  orders: LedgerOrderVO[];
  payments: PaymentVO[];
  entries: InvoiceEntryVO[];
  attributions: PaymentLedgerAttributionVO[];
  note?: string;
  /** @format int64 */
  subtotalAmount?: number;
  /** @format int64 */
  discountAmount?: number;
  /** @format int64 */
  taxAmount?: number;
  /** @format int64 */
  surchargeAmount?: number;
  payerName?: string;
  payerAddress?: AddressVO;
  practiceName?: string;
  practiceAddress?: AddressVO;
  practiceThumbnail?: string;
  practiceTimezoneId?: string;
  /** @format int64 */
  invoiceDocumentId?: number;
  /** @format int64 */
  voidedAt?: number;
  /** @format int64 */
  paidAt?: number;
}

export interface LedgerLineItemVO {
  /** @format uuid */
  uuid: string;
  classification: string;
  currency: string;
  /** @format int64 */
  entityAmount: number;
  /** @format int64 */
  insuranceAmount: number;
  /** @format int64 */
  practiceAmount: number;
  /** @format int64 */
  patientProcedureId?: number;
  patientProcedureSnapshot?: PatientProcedureSnapshot;
}

export interface LedgerOrderVO {
  /** @format uuid */
  uuid: string;
  type:
    | "APPOINTMENT"
    | "APPOINTMENT_ADJUSTMENT"
    | "APPOINTMENT_CLAIM_ADJUSTMENT"
    | "CLAIM_PAYMENT"
    | "CLAIM_SUBMISSION"
    | "ADJUSTMENT"
    | "PAYMENT"
    | "INVOICE"
    | "INVOICE_APPOINTMENT_ADJUSTMENT";
  /** @format int64 */
  ledgerSequenceNumber: number;
  entity: EntityReferenceVO;
  /** @format int64 */
  practiceId: number;
  /** @format uuid */
  practiceUuid: string;
  /** @format int64 */
  orderCreatedAt: number;
  currency: string;
  entityAccount: AccountEntry;
  insuranceAccount: AccountEntry;
  practiceAccount: AccountEntry;
  /** @format uuid */
  invoiceUuid?: string;
  /** @format uuid */
  paymentUuid?: string;
  lineItems: LedgerLineItemVO[];
  /** @format uuid */
  adjustmentUuid?: string;
  /** @format uuid */
  appointmentAdjustmentUuid?: string;
  adjustmentName?: string;
  adjustmentNote?: string;
  /** @format uuid */
  claimUuid?: string;
  invoiceSnapshot?: InvoiceSnapshot;
  paymentSnapshot?: PaymentSnapshot;
  claimSnapshot?: ClaimSnapshot;
  appointmentSnapshot?: AppointmentSnapshot;
  /** @format uuid */
  reversedBy?: string;
  /** @format uuid */
  reversalFor?: string;
}

export interface PatientProcedureSnapshot {
  toothName?: string;
  surface?: string;
  cdtCode: string;
  name: string;
  laymanName?: string;
}

export interface PaymentLedgerAttributionVO {
  /** @format uuid */
  paymentUuid: string;
  /** @format uuid */
  ledgerOrderUuid: string;
  /** @format uuid */
  ledgerLineItemUuid: string;
  /** @format int64 */
  patientProcedureId?: number;
  /** @format int64 */
  amount: number;
}

export interface PaymentReference {
  type: "INVOICE" | "CLAIM_PAYMENT" | "WALLET_PAYMENT" | "BATCH_PAYMENT" | "OFFSITE_PAYMENT";
  /** @format uuid */
  uuid: string;
}

export interface PaymentSnapshot {
  type: "CHARGE" | "REFUND";
  method:
    | "STORED_PROFILE"
    | "CASH"
    | "CHECK"
    | "STORED_POS"
    | "EXTERNAL_POS"
    | "EFT"
    | "WALLET"
    | "CARE_CREDIT"
    | "THIRD_PARTY_FINANCING"
    | "BALANCE_TRANSFER";
  state:
    | "CREATED"
    | "IN_PROGRESS"
    | "AWAITING_RESULT"
    | "PENDING"
    | "SETTLED"
    | "DENIED"
    | "CHARGEBACK"
    | "VOID";
  paymentProfileType?: "CARD" | "BANK_ACCOUNT";
  cardType?: string;
  cardBrand?: string;
  cardLastFour?: string;
  invoiceNumber?: string;
}

export interface PaymentVO {
  /** @format uuid */
  uuid: string;
  state:
    | "CREATED"
    | "IN_PROGRESS"
    | "AWAITING_RESULT"
    | "PENDING"
    | "SETTLED"
    | "DENIED"
    | "CHARGEBACK"
    | "VOID";
  type: "CHARGE" | "REFUND";
  method:
    | "STORED_PROFILE"
    | "CASH"
    | "CHECK"
    | "STORED_POS"
    | "EXTERNAL_POS"
    | "EFT"
    | "WALLET"
    | "CARE_CREDIT"
    | "THIRD_PARTY_FINANCING"
    | "BALANCE_TRANSFER";
  /** The family payment amount if this payment is associated with a family payment. */
  currencyAmount: CurrencyAmount;
  /** @format int64 */
  paymentCreatedAt: number;
  paymentReference: PaymentReference;
  note?: string;
  /** @format uuid */
  refundedPaymentUuid?: string;
  /** @format uuid */
  batchPaymentUuid?: string;
  balanceTransferType?: "INTRA_INVOICE" | "INTER_INVOICE";
  /** @format uuid */
  balanceTransferFromUuid?: string;
  checkPayload?: CheckPayload;
  externalPosPayload?: ExternalPosPayload;
  eftPayload?: EFTPayload;
  paymentProfile?: PaymentProfileVO;
  /** @format int64 */
  disputeRespondBy?: number;
  disputeReason?: string;
  /** @format int64 */
  receiptDocumentId?: number;
  receiptNumber: string;
  /** @format uuid */
  walletUuid?: string;
  /** @format uuid */
  paymentDeviceUuid?: string;
  walletNote?: string;
  /** @deprecated */
  invoiceNumber?: string;
  invoiceNumbers: string[];
  balanceTransferFromInvoiceNumber?: string;
  /** The family payment amount if this payment is associated with a family payment. */
  refundableCurrencyAmount?: CurrencyAmount;
  payer: EntityReferenceVO;
  /**
   * Indicates the set of permitted actions that can be performed on this payment.
   * @uniqueItems true
   */
  permittedActions: ("EDIT" | "DELETE")[];
  /** The family payment amount if this payment is associated with a family payment. */
  familyPaymentCurrencyAmount?: CurrencyAmount;
  gatewayErrorMessage?: string;
  /** @format int64 */
  surchargeAmount: number;
  /**
   * The total payment amount inclusive of surcharge amount.
   * @format int64
   */
  totalPaymentAmount: number;
}

export interface ProcedureAdjustmentItemVO {
  /** @format int64 */
  procedureId: number;
  /** @format int64 */
  amount: number;
}

export interface SuccessResponseInvoiceVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InvoiceVO;
}

export interface InsuranceCarrierRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  website?: string;
  /**
   * @minLength 0
   * @maxLength 10
   */
  payerId: string;
  /** @pattern ^[0-9]{10}$ */
  phone?: string;
  /** @pattern ^[0-9]{10}$ */
  fax?: string;
  address: AddressVO;
  acceptsAutomatedEligibilities?: boolean;
  acceptsElectronicAttachments?: boolean;
  acceptsToothRange?: boolean;
  comments?: string;
}

export interface InsuranceCarrierDetailsVO {
  /** @format int64 */
  id: number;
  name: string;
  payerId: string;
  phone?: string;
  type: "GLOBAL" | "CUSTOM";
  acceptsElectronicAttachments?: boolean;
  inNetwork?: boolean;
  fax?: string;
  website?: string;
  acceptsAutomatedEligibilities?: boolean;
  acceptsToothRange: boolean;
  address?: AddressVO;
  audit?: AuditVO;
  comments?: string;
}

export interface SuccessResponseInsuranceCarrierDetailsVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InsuranceCarrierDetailsVO;
}

export interface UpdateInsurancePlanRequest {
  /** @format int64 */
  feeScheduleId?: number;
  groupNumber?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  employerName: string;
  groupName?: string;
  planType:
    | "PPO"
    | "TOA_SOA"
    | "EPO"
    | "HMO_DHMO"
    | "MEDICAID_CHIP"
    | "POS"
    | "DISCOUNT"
    | "SELF_FUNDED_ERISA"
    | "DIRECT_REIMBURSEMENT"
    | "FEDERAL"
    | "MEDICARE"
    | "TRICARE";
  /**
   * @minLength 0
   * @maxLength 65535
   */
  planNotes?: string;
  isMedicalInsurance?: boolean;
  eligibilityCoverageLevel?: "INDIVIDUAL" | "FAMILY";
  renewalMonth?:
    | "JANUARY"
    | "FEBRUARY"
    | "MARCH"
    | "APRIL"
    | "MAY"
    | "JUNE"
    | "JULY"
    | "AUGUST"
    | "SEPTEMBER"
    | "OCTOBER"
    | "NOVEMBER"
    | "DECEMBER";
  outOfNetworkBenefits?: boolean;
  coordinationOfBenefits?: "STANDARD" | "NON_DUPLICTION" | "OTHER";
  /**
   * @format int32
   * @min 0
   */
  waitingPeriodInMonths?: number;
  /** @format int64 */
  dependentMaxAge?: number;
  missingToothClause?: boolean;
  crownBridgesPaidOn?: "SEAT_DATE" | "PREP_DATE";
  insuranceRepresentative?: string;
  carrierContacted?: boolean;
  /** @format int64 */
  carrierContactedById?: number;
  /** @format int64 */
  patientInsuranceId?: number;
  /**
   * @format int64
   * @min 0
   */
  orthoLifetimeMaximum?: number;
  /** @format int32 */
  orthoMaxAge?: number;
  /**
   * @format int64
   * @min 0
   */
  individualAnnualDeductible?: number;
  /**
   * @format int64
   * @min 0
   */
  individualLifetimeDeductible?: number;
  /**
   * @format int64
   * @min 0
   */
  familyAnnualDeductible?: number;
  /**
   * @format int64
   * @min 0
   */
  individualAnnualMaximum?: number;
  /**
   * @format int64
   * @min 0
   */
  individualLifetimeMaximum?: number;
  /**
   * @format int64
   * @min 0
   */
  familyAnnualMaximum?: number;
  /** @format int64 */
  carrierId?: number;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
}

export interface InsuranceCarrierVO {
  /** @format int64 */
  id: number;
  name: string;
  payerId: string;
  phone?: string;
  type: "GLOBAL" | "CUSTOM";
  inNetwork?: boolean;
}

export interface InsurancePlanVO {
  /** @format uuid */
  uuid: string;
  carrier: InsuranceCarrierVO;
  feeSchedule?: NameIdVO;
  groupNumber?: string;
  employerName?: string;
  groupName?: string;
  planType:
    | "PPO"
    | "TOA_SOA"
    | "EPO"
    | "HMO_DHMO"
    | "MEDICAID_CHIP"
    | "POS"
    | "DISCOUNT"
    | "SELF_FUNDED_ERISA"
    | "DIRECT_REIMBURSEMENT"
    | "FEDERAL"
    | "MEDICARE"
    | "TRICARE";
  planNotes?: string;
  isMedicalInsurance: boolean;
  eligibilityCoverageLevel: "INDIVIDUAL" | "FAMILY";
  renewalMonth:
    | "JANUARY"
    | "FEBRUARY"
    | "MARCH"
    | "APRIL"
    | "MAY"
    | "JUNE"
    | "JULY"
    | "AUGUST"
    | "SEPTEMBER"
    | "OCTOBER"
    | "NOVEMBER"
    | "DECEMBER";
  outOfNetworkBenefits: boolean;
  coordinationOfBenefits: "STANDARD" | "NON_DUPLICTION" | "OTHER";
  /** @format int32 */
  waitingPeriodInMonths: number;
  /** @format int64 */
  dependentMaxAge?: number;
  missingToothClause: boolean;
  crownBridgesPaidOn?: "SEAT_DATE" | "PREP_DATE";
  insuranceRepresentative?: string;
  carrierContacted: boolean;
  contactedBy?: NameVO;
  /** @format int64 */
  individualAnnualDeductible: number;
  /** @format int64 */
  individualLifetimeDeductible?: number;
  /** @format int64 */
  familyAnnualDeductible?: number;
  /** @format int64 */
  individualAnnualMaximum?: number;
  /** @format int64 */
  individualLifetimeMaximum?: number;
  /** @format int64 */
  familyAnnualMaximum?: number;
  benefitLastVerifiedAt?: string;
  benefitLastVerifiedBy?: NameVO;
  benefitVerifiedStatus?: "VERIFIED" | "AUTO_VERIFIED" | "NOT_VERIFIED" | "FAILED";
  /** @format int64 */
  orthoLifetimeMaximum?: number;
  /** @format int32 */
  orthoMaxAge?: number;
  description?: string;
  autoEligibility: boolean;
}

export interface SuccessResponseInsurancePlanVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InsurancePlanVO;
}

export interface UpdateInsurancePlanFeeScheduleRequest {
  /** @format int64 */
  feeScheduleId: number;
}

export interface ClinicalNoteFormRequest {
  /** @uniqueItems true */
  providerIds?: number[];
  /** @uniqueItems true */
  appointmentCategoryIds?: number[];
}

export type FormAppointmentDetailsElementRequest = UtilRequiredKeys<FormElementRequest, "title" | "type"> & {
  type: "APPOINTMENT_DETAILS";
};

export type FormBooleanInputElementRequest = UtilRequiredKeys<FormElementRequest, "title" | "type"> & {
  conditionalElement?: FormBooleanConditionalElementRequest;
  /** @uniqueItems true */
  settings: ("REQUIRED" | "REQUEST_ADDITIONAL_INFORMATION")[];
  type: "BOOLEAN_INPUT";
};

export type FormConsentElementRequest = UtilRequiredKeys<FormElementRequest, "title" | "type"> & {
  content: string;
  statement: string;
  /** Defaults to false if not provided. */
  acceptsInitials?: boolean;
  type: "CONSENT";
};

export type FormDateInputElementRequest = UtilRequiredKeys<FormElementRequest, "title" | "type"> & {
  /** @uniqueItems true */
  settings: ("REQUIRED" | "FUTURE" | "PAST")[];
  type: "DATE_INPUT";
};

export type FormEditableTextBlockElementRequest = UtilRequiredKeys<FormElementRequest, "title" | "type"> & {
  content: string;
  type: "EDITABLE_TEXT_BLOCK";
};

export interface FormElementRequest {
  /** @format uuid */
  uuid?: string;
  tag?:
    | "PREGNANCY"
    | "ALLERGIES"
    | "DENTAL_CONDITIONS"
    | "MEDICAL_CONDITIONS"
    | "PREMED"
    | "PROCEDURE_TABLE"
    | "APPOINTMENT_DETAILS"
    | "ESTIMATE_DISCLAIMER";
  title: string;
  description?: string;
  type:
    | "SECTION"
    | "BOOLEAN_INPUT"
    | "TEXT_INPUT"
    | "NUMBER_INPUT"
    | "DATE_INPUT"
    | "SELECT_INPUT"
    | "TEXT_BLOCK"
    | "EDITABLE_TEXT_BLOCK"
    | "CONSENT"
    | "PROCEDURE_TABLE"
    | "APPOINTMENT_DETAILS";
}

export type FormNumberInputElementRequest = UtilRequiredKeys<FormElementRequest, "title" | "type"> & {
  /** @uniqueItems true */
  settings: "REQUIRED"[];
  /** @format int32 */
  min?: number;
  /** @format int32 */
  max?: number;
  type: "NUMBER_INPUT";
};

export interface FormPageRequest {
  /** @format uuid */
  uuid?: string;
  content: (
    | FormAppointmentDetailsElementRequest
    | FormBooleanInputElementRequest
    | FormConsentElementRequest
    | FormDateInputElementRequest
    | FormEditableTextBlockElementRequest
    | FormNumberInputElementRequest
    | FormProcedureTableElementRequest
    | FormSectionRequest
    | FormSelectInputElementRequest
    | FormTextBlockElementRequest
    | FormTextInputElementRequest
  )[];
}

export type FormProcedureTableElementRequest = UtilRequiredKeys<FormElementRequest, "title" | "type"> & {
  /** @uniqueItems true */
  settings: "DISPLAY_UCR_RATE"[];
  type: "PROCEDURE_TABLE";
};

export interface FormRequest {
  /**
   * @minLength 0
   * @maxLength 50
   */
  title: string;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
  /**
   * @maxItems 1
   * @minItems 0
   */
  content: FormPageRequest[];
  /** @uniqueItems true */
  dentalProcedureIds?: number[];
  inPersonRequired?: boolean;
  /** @format int64 */
  submissionExpirationSeconds?: number;
  type?: "INTAKE" | "CLINICAL_NOTE";
  clinicalNoteData?: ClinicalNoteFormRequest;
}

export interface FormSectionConditionsRequest {
  /** @format int32 */
  maxAgeYears?: number;
  /** @format int32 */
  minAgeYears?: number;
  genders: ("MALE" | "FEMALE" | "OTHER" | "UNKNOWN")[];
}

export type FormSectionRequest = UtilRequiredKeys<FormElementRequest, "title" | "type"> & {
  content: FormSectionElementRequest[];
  conditions?: FormSectionConditionsRequest;
  type: "SECTION";
};

export type FormSelectInputElementRequest = UtilRequiredKeys<FormElementRequest, "title" | "type"> & {
  options: string[];
  /** @uniqueItems true */
  settings: (
    | "REQUIRED"
    | "ALLOW_ADDITIONAL_OPTION"
    | "ALLOW_MULTIPLE_SELECTIONS"
    | "ENFORCE_EXPLICIT_CONSENT"
  )[];
  type: "SELECT_INPUT";
};

export type FormTextBlockElementRequest = UtilRequiredKeys<FormElementRequest, "title" | "type"> & {
  content: string;
  type: "TEXT_BLOCK";
};

export type FormTextInputElementRequest = UtilRequiredKeys<FormElementRequest, "title" | "type"> & {
  /** @uniqueItems true */
  settings: ("REQUIRED" | "MULTILINE")[];
  type: "TEXT_INPUT";
};

export interface ClinicalNoteFormDataVO {
  providers: ProviderVO[];
  appointmentCategories: NameIdVO[];
}

export type FormAppointmentDetailsElementVO = UtilRequiredKeys<FormElementVO, "uuid" | "title" | "type"> & {
  formAppointmentVO: FormAppointmentVO;
  type: "APPOINTMENT_DETAILS";
};

export interface FormAppointmentVO {
  /** @format int64 */
  id: number;
  date: string;
  startTime: string;
  provider: ProviderVO;
  dentist: ProviderVO;
  categoryName?: NameIdVO;
}

export type FormBooleanInputElementVO = UtilRequiredKeys<FormElementVO, "uuid" | "title" | "type"> & {
  /** @uniqueItems true */
  settings: ("REQUIRED" | "REQUEST_ADDITIONAL_INFORMATION")[];
  conditionalElement?: FormBooleanConditionalElementVO;
  type: "BOOLEAN_INPUT";
};

export type FormConsentElementVO = UtilRequiredKeys<FormElementVO, "uuid" | "title" | "type"> & {
  content: string;
  statement: string;
  acceptsInitials: boolean;
  type: "CONSENT";
};

export type FormDateInputElementVO = UtilRequiredKeys<FormElementVO, "uuid" | "title" | "type"> & {
  /** @uniqueItems true */
  settings: ("REQUIRED" | "FUTURE" | "PAST")[];
  type: "DATE_INPUT";
};

export type FormEditableTextBlockElementVO = UtilRequiredKeys<FormElementVO, "uuid" | "title" | "type"> & {
  content: string;
  type: "EDITABLE_TEXT_BLOCK";
};

export interface FormElementVO {
  /** @format uuid */
  uuid: string;
  tag?:
    | "PREGNANCY"
    | "ALLERGIES"
    | "DENTAL_CONDITIONS"
    | "MEDICAL_CONDITIONS"
    | "PREMED"
    | "PROCEDURE_TABLE"
    | "APPOINTMENT_DETAILS"
    | "ESTIMATE_DISCLAIMER";
  title: string;
  description?: string;
  type:
    | "SECTION"
    | "BOOLEAN_INPUT"
    | "TEXT_INPUT"
    | "NUMBER_INPUT"
    | "DATE_INPUT"
    | "SELECT_INPUT"
    | "TEXT_BLOCK"
    | "EDITABLE_TEXT_BLOCK"
    | "CONSENT"
    | "PROCEDURE_TABLE"
    | "APPOINTMENT_DETAILS";
}

export type FormNumberInputElementVO = UtilRequiredKeys<FormElementVO, "uuid" | "title" | "type"> & {
  /** @uniqueItems true */
  settings: "REQUIRED"[];
  /** @format int32 */
  min?: number;
  /** @format int32 */
  max?: number;
  type: "NUMBER_INPUT";
};

export interface FormPageVO {
  /** @format uuid */
  uuid: string;
  content: (
    | FormAppointmentDetailsElementVO
    | FormBooleanInputElementVO
    | FormConsentElementVO
    | FormDateInputElementVO
    | FormEditableTextBlockElementVO
    | FormNumberInputElementVO
    | FormProcedureTableElementVO
    | FormSectionVO
    | FormSelectInputElementVO
    | FormTextBlockElementVO
    | FormTextInputElementVO
  )[];
}

export interface FormPatientProcedureVO {
  /** @format int64 */
  id: number;
  /** @format int32 */
  priority?: number;
  procedureArea?: string;
  laymanTerm?: string;
  cdtCode: string;
  description: string;
  /** @format int64 */
  ucrRate: number;
  /** @format int64 */
  negotiatedRate: number;
  /** @format int64 */
  patientAmount: number;
  /** @format int64 */
  insuranceAmount: number;
  /** @format int64 */
  deductibleAmount: number;
  /** @format int64 */
  primaryInsuranceAmount?: number;
  /** @format int64 */
  secondaryInsuranceAmount?: number;
}

export type FormProcedureTableElementVO = UtilRequiredKeys<FormElementVO, "uuid" | "title" | "type"> & {
  /** @uniqueItems true */
  settings: "DISPLAY_UCR_RATE"[];
  procedures: FormPatientProcedureVO[];
  treatmentPlanName?: string;
  type: "PROCEDURE_TABLE";
};

export interface FormSectionConditionsVO {
  /** @format int32 */
  maxAgeYears?: number;
  /** @format int32 */
  minAgeYears?: number;
  genders: ("MALE" | "FEMALE" | "OTHER" | "UNKNOWN")[];
}

export type FormSectionVO = UtilRequiredKeys<FormElementVO, "uuid" | "title" | "type"> & {
  content: FormSectionElementVO[];
  conditions?: FormSectionConditionsVO;
  type: "SECTION";
};

export type FormSelectInputElementVO = UtilRequiredKeys<FormElementVO, "uuid" | "title" | "type"> & {
  options: string[];
  /** @uniqueItems true */
  settings: (
    | "REQUIRED"
    | "ALLOW_ADDITIONAL_OPTION"
    | "ALLOW_MULTIPLE_SELECTIONS"
    | "ENFORCE_EXPLICIT_CONSENT"
  )[];
  type: "SELECT_INPUT";
};

export type FormTextBlockElementVO = UtilRequiredKeys<FormElementVO, "uuid" | "title" | "type"> & {
  content: string;
  type: "TEXT_BLOCK";
};

export type FormTextInputElementVO = UtilRequiredKeys<FormElementVO, "uuid" | "title" | "type"> & {
  /** @uniqueItems true */
  settings: ("REQUIRED" | "MULTILINE")[];
  type: "TEXT_INPUT";
};

export interface FormVO {
  /** @format uuid */
  uuid: string;
  /**
   * The UUID of the published content associated with this form. Only populated when fetching the published version of the form.
   * @format uuid
   */
  publishedContentUuid?: string;
  slug?: "DENTAL_HISTORY" | "MEDICAL_HISTORY" | "CONSENT" | "TREATMENT_PLAN_FORM" | "APPOINTMENT_FORM";
  title: string;
  description?: string;
  state: "DRAFT" | "PUBLISHED" | "UNPUBLISHED_CHANGES" | "ARCHIVED";
  content: FormPageVO[];
  inPersonRequired: boolean;
  dentalProcedures: DentalProcedureVO[];
  /** @format int64 */
  submissionExpirationSeconds?: number;
  type: "INTAKE" | "CLINICAL_NOTE";
  clinicalNoteData?: ClinicalNoteFormDataVO;
}

export interface SuccessResponseFormVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FormVO;
}

export interface OnboardingOptionsRequest {
  referralOptions: string[];
}

export interface OnboardingOptionsVO {
  referralOptions: string[];
}

export interface SuccessResponseOnboardingOptionsVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: OnboardingOptionsVO;
}

export interface DentalProcedureFeeVO {
  /** @format int64 */
  procedureId: number;
  /** @format int64 */
  ucrFee?: number;
  /** @format int64 */
  fee?: number;
}

export interface UpdateFeeScheduleRequest {
  /**
   * @minLength 0
   * @maxLength 80
   */
  name: string;
  /** @uniqueItems true */
  carrierIds?: number[];
  /** @uniqueItems true */
  providerIds?: number[];
  startDate: string;
  endDate?: string;
  /** @uniqueItems true */
  dentalProcedureFees?: DentalProcedureFeeVO[];
  /** @uniqueItems true */
  dentalProcedureFeeIdsToDelete?: number[];
  inNetwork?: boolean;
  type?: "PRACTICE_UCR" | "PROVIDER_UCR" | "CARRIER" | "BLUE_BOOK";
}

export interface FeeScheduleVO {
  /** @format int64 */
  id: number;
  name: string;
  dentalProcedureFees?: DentalProcedureFeeVO[];
  carrierIds?: number[];
  providerIds?: number[];
  startDate: string;
  endDate?: string;
  /** @deprecated */
  isPracticeUcr?: boolean;
  /** @deprecated */
  isProviderUcr?: boolean;
  type: "PRACTICE_UCR" | "PROVIDER_UCR" | "CARRIER" | "BLUE_BOOK";
  /** @format int64 */
  insurancePlans: number;
  /** @format int64 */
  patients: number;
  inNetwork: boolean;
  state: "ACTIVE" | "ARCHIVED";
  providers?: NameVO[];
}

export interface SuccessResponseFeeScheduleVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FeeScheduleVO;
}

export interface CompensationDetailsVO {
  compensationType:
    | "FLAT_RATE"
    | "PERCENT_COLLECTION"
    | "PERCENT_PRODUCTION"
    | "FLAT_RATE_PLUS_PERCENT_COLLECTION"
    | "FLAT_RATE_PLUS_PERCENT_PRODUCTION";
  amount?: number;
  period?: "PER_HOUR" | "PER_DIEM" | "PER_MONTH" | "PER_YEAR";
  /** @format double */
  production?: number;
  /** @format double */
  collection?: number;
}

export interface ContactDetails {
  /** @pattern ^[0-9]{10}$ */
  phone?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  personalEmail?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  workEmail: string;
  addressDetails?: AddressVO;
  emergencyContact?: EmergencyContactDetailsVO;
}

export interface EmployeePersonalDetailsVO {
  displayName?: string;
  fullName?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  firstName: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  middleName?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  lastName: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  preferredName?: string;
  preferredPronouns?: "HE_HIM" | "SHE_HER" | "THEY_THEM";
  gender?: "MALE" | "FEMALE" | "OTHER" | "UNKNOWN";
  /** @format int64 */
  age?: number;
  dob?: string;
  /** @pattern ^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$ */
  ssn?: string;
  ssnLastFour?: string;
  thumbnail?: string;
}

export interface EmployeeSettingsVO {
  excludedTagIds?: number[];
}

export interface EmploymentDetailsVO {
  accessDate?: string;
  startDate?: string;
  endDate?: string;
  employeeCategory?: "W2_EMPLOYEE" | "_1099_INDEPENDENT";
  employmentType?: "FULL_TIME" | "PART_TIME" | "TEMPORARY";
  flsaStatus?: "EXEMPT" | "NON_EXEMPT";
  excludeFromPayroll?: boolean;
  status?: "ACTIVE" | "INACTIVE" | "ARCHIVED" | "PENDING" | "FURLOUGHED";
  jobTitle?: string;
  jobTitleKey?:
    | "GENERAL_DENTIST"
    | "PEDIATRIC_DENTIST"
    | "DENTAL_ASSISTANT"
    | "DENTURIST"
    | "ENDODONTIST"
    | "DENTAL_HYGIENIST"
    | "REGISTERED_DENTAL_HYGIENIST_INDEPENDENT_PRACTICE"
    | "ORTHODONTIST"
    | "ORAL_SURGEON"
    | "PERIODONTIST"
    | "PROSTHODONTIST"
    | "UNKNOWN";
  manager?: NameVO;
  compensationDetails?: CompensationDetailsVO;
  /**
   * @minLength 0
   * @maxLength 64
   */
  stateLicenseNumber?: string;
  /**
   * @minLength 0
   * @maxLength 32
   */
  stateRxNumber?: string;
  /**
   * @minLength 0
   * @maxLength 10
   */
  npiNumber?: string;
  /**
   * @minLength 0
   * @maxLength 10
   */
  taxonomyCode?: string;
  /**
   * @minLength 0
   * @maxLength 9
   */
  deaNumber?: string;
}

export interface UpdateEmployeeRequest {
  personalDetails?: EmployeePersonalDetailsVO;
  contactDetails?: ContactDetails;
  employmentDetails?: EmploymentDetailsVO;
  /** @format int64 */
  roleV2Id?: number;
  employeeSettings?: EmployeeSettingsVO;
}

export interface EmployeeVO {
  /** @format int64 */
  id: number;
  /** @format int64 */
  entityVersion: number;
  personalDetails: EmployeePersonalDetailsVO;
  contactDetails: ContactDetails;
  employmentDetails: EmploymentDetailsVO;
  worktimeDetails?: LastWorktimeVO;
  /** @format int64 */
  roleV2Id: number;
  roleV2?: RoleVO;
  isOwner?: boolean;
  employeeSettings?: EmployeeSettingsVO;
}

export interface LastWorktimeVO {
  time: string;
  lastAction?: string;
}

export interface SuccessResponseEmployeeVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: EmployeeVO;
}

export interface AdditionalEarningRequest {
  type: "BONUS" | "COMMISSION" | "REIMBURSEMENT" | "CORRECTION_PAYMENT";
  /**
   * @format int64
   * @min 1
   */
  amount: number;
}

export interface PaidTimeOffRequest {
  type: "SICK_LEAVE" | "VACATION" | "HOLIDAY";
  /**
   * @min 0.1
   * @exclusiveMin false
   */
  hours: number;
}

export interface PostTaxDeductionRequest {
  name: string;
  /**
   * @format int64
   * @min 1
   */
  amount: number;
  /** Indicates if the deduction is court-ordered. A null or undefined value implies false. */
  courtOrdered?: boolean;
}

export interface SubLineItemsRequest {
  additionalEarnings: AdditionalEarningRequest[];
  postTaxDeductions: PostTaxDeductionRequest[];
  paidTimeOff: PaidTimeOffRequest[];
}

export interface UpdatePayrollLineItemRequest {
  subLineItems: SubLineItemsRequest;
  skip: boolean;
}

export interface AdditionalEarningVO {
  type: "BONUS" | "COMMISSION" | "REIMBURSEMENT" | "CORRECTION_PAYMENT";
  /** @format int64 */
  amount: number;
}

export interface PaidTimeOffVO {
  type: "SICK_LEAVE" | "VACATION" | "HOLIDAY";
  hours: number;
}

/** Breakdown of the payroll after calculation. Only available for payrolls with a status of REVIEW or SUBMITTED. */
export interface PayrollEmployeeBreakdownVO {
  /**
   * Gross pay in cents.
   * @format int64
   */
  grossPay: number;
  /**
   * The employee's net amount, does not include reimbursements.
   * @format int64
   */
  netPay: number;
  /**
   * The employee's check amount. Is a sum of net pay and reimbursements.
   * @format int64
   */
  checkAmount: number;
  /**
   * Total amount of deductions in cents.
   * @format int64
   */
  deductions: number;
  /**
   * Total amount of reimbursements in cents.
   * @format int64
   */
  reimbursements: number;
  /**
   * Total amount of company taxes in cents.
   * @format int64
   */
  companyTaxes: number;
  /**
   * Total amount of company benefits in cents.
   * @format int64
   */
  companyBenefits: number;
  /**
   * Total employee taxes in cents.
   * @format int64
   */
  employeeTaxes: number;
  /**
   * Total employee benefits in cents.
   * @format int64
   */
  employeeBenefits: number;
  /**
   * The amount, in cents, that the practice will pay for this employee.
   * @format int64
   */
  companySubtotal: number;
}

export interface PayrollLineItemVO {
  employee: NameVO;
  compensationType:
    | "FLAT_RATE"
    | "PERCENT_COLLECTION"
    | "PERCENT_PRODUCTION"
    | "FLAT_RATE_PLUS_PERCENT_COLLECTION"
    | "FLAT_RATE_PLUS_PERCENT_PRODUCTION";
  /**
   * Compensation amount in cents.
   * @format int64
   */
  compensationAmount: number;
  compensationPeriod: "PER_HOUR" | "PER_DIEM" | "PER_MONTH" | "PER_YEAR";
  /** @format double */
  compensationPercentProduction?: number;
  /** @format double */
  compensationPercentCollection?: number;
  flsaStatus?: "EXEMPT" | "NON_EXEMPT";
  regularHours: number;
  overtimeHours: number;
  doubleOvertimeHours: number;
  paidTimeOffHours: number;
  /** @format int32 */
  daysWorked: number;
  /** This is the sum of regular, overtime, double overtime, and paid time off hours. */
  totalHours: number;
  status: "PENDING" | "APPROVED";
  isSkipped: boolean;
  subLineItems: SubLineItemsVO;
  /**
   * Estimated gross pay in cents. Note that this amount is calculated internally.
   * @format int64
   */
  estimatedGrossPay: number;
  /** Breakdown of the payroll after calculation. Only available for payrolls with a status of REVIEW or SUBMITTED. */
  breakdown?: PayrollEmployeeBreakdownVO;
  /**
   * Document ID of the employee pay-stub. Only available if the payroll is SUBMITTED and on or after the employee pay date.
   * @format int64
   */
  payStubDocumentId?: number;
  isOnboardedWithGusto: boolean;
}

export interface PostTaxDeductionErrorVO {
  code: "CREATE" | "UPDATE" | "DELETE";
  message: string;
}

export interface PostTaxDeductionVO {
  name: string;
  /** @format int64 */
  amount: number;
  courtOrdered: boolean;
  errors: PostTaxDeductionErrorVO[];
}

export interface SubLineItemsVO {
  additionalEarnings: AdditionalEarningVO[];
  postTaxDeductions: PostTaxDeductionVO[];
  paidTimeOff: PaidTimeOffVO[];
}

export interface SuccessResponsePayrollLineItemVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PayrollLineItemVO;
}

export interface OnboardEmployeeRequest {
  personalDetails: EmployeePersonalDetailsVO;
  contactDetails: ContactDetails;
}

export interface UpdateEmailConfigRequest {
  /** @pattern ^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]?\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$ */
  domain?: string;
  replyToEmail?: string;
  fromEmail?: string;
}

export interface EmailConfigVO {
  domain?: string;
  replyToEmail?: string;
  fromEmail?: string;
  /** @format int64 */
  verifyAttemptAt?: number;
  domainVerified: boolean;
  domainDnsConfig?: EmailDnsRecord[];
}

export interface EmailDnsRecord {
  type: string;
  name: string;
  value: string;
}

export interface SuccessResponseEmailConfigVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: EmailConfigVO;
}

export interface UpdateCustomProcedureRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 22
   */
  simpleName: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  laymanTerm?: string;
  /** @pattern ^[\/X]{0,30}$ */
  duration: string;
  endDate?: string;
  isHygiene?: boolean;
  creditToPractice?: boolean;
}

export interface SuccessResponseDentalProcedureVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: DentalProcedureVO;
}

export interface ClaimAttachmentEntry {
  type:
    | "UNKNOWN"
    | "NOTE"
    | "XRAY"
    | "PHOTO"
    | "CHART"
    | "EOB"
    | "DIAGNOSTIC"
    | "MODEL"
    | "RADIOLOGY"
    | "REFERRAL"
    | "OTHER"
    | "NONE";
  /** @format int64 */
  sourceId?: number;
  imageOrientation?: "LEFT" | "RIGHT";
  /**
   * @minLength 0
   * @maxLength 2000
   */
  note?: string;
}

export interface ClaimAttachmentRequest {
  attachments: ClaimAttachmentEntry[];
}

export interface ClaimAttachmentVO {
  /** @format uuid */
  uuid: string;
  type:
    | "UNKNOWN"
    | "NOTE"
    | "XRAY"
    | "PHOTO"
    | "CHART"
    | "EOB"
    | "DIAGNOSTIC"
    | "MODEL"
    | "RADIOLOGY"
    | "REFERRAL"
    | "OTHER"
    | "NONE";
  data: string;
  /** @format int64 */
  sourceId?: number;
  /** @format uuid */
  sourceUuid?: string;
  /** @format int64 */
  sourceCreatedAt: number;
  isUploaded: boolean;
}

export interface ClaimEobFileVO {
  /** @format uuid */
  uuid: string;
  name: string;
  createdAt: string;
}

export interface ClaimLineItemGroupVO {
  /** @format int32 */
  version: number;
  versionType: "PAYMENT" | "ADDITIONAL" | "ADJUSTMENT";
  processedBy?: NameVO;
  /** @format date-time */
  processedAt?: string;
  lineItems: ClaimLineItemVO[];
}

export interface ClaimLineItemVO {
  /** @format int64 */
  patientProcedureId: number;
  providerName?: NameVO;
  /** @format int64 */
  ucrRate: number;
  /** @format int64 */
  negotiatedRate: number;
  /** @format int64 */
  insuranceEstimatedAmount: number;
  /** @format int64 */
  patientEstimatedAmount: number;
  /** @format int64 */
  deductibleEstimatedAmount: number;
  /** @format int64 */
  patientAmount: number;
  /** @format int64 */
  deductibleAmount: number;
  /** @format int64 */
  writeoffAmount: number;
  /** @format int32 */
  insuranceCoveragePercent?: number;
  /** @format int64 */
  insuranceAmount: number;
  procedureCode: string;
  procedureName: string;
  procedureDescription: string;
  procedureArea?: string;
  remarks?: string;
  isIncluded: boolean;
  downgradedCdtCode?: string;
  preAuthStatus?: "SENT" | "APPROVED" | "DENIED" | "RESENT" | "APPROVED_RESENT" | "DENIED_RESENT";
  requiredAttachmentTypes: (
    | "UNKNOWN"
    | "NOTE"
    | "XRAY"
    | "PHOTO"
    | "CHART"
    | "EOB"
    | "DIAGNOSTIC"
    | "MODEL"
    | "RADIOLOGY"
    | "REFERRAL"
    | "OTHER"
    | "NONE"
  )[];
}

export interface ClaimOrthoVO {
  orthoDate?: string;
  /** @format int32 */
  orthoMonthsTotal?: number;
  /** @format int32 */
  orthoMonthsRemaining?: number;
}

export interface ClaimPaymentVO {
  /** @format int64 */
  versionReference: number;
  /** @format int64 */
  insuranceAmount: number;
  payment: PaymentVO;
}

export interface ClaimVO {
  /** @format uuid */
  uuid: string;
  serviceDate: string;
  patientName: NameVO;
  /** @format int64 */
  patientInsuranceId: number;
  /** @format int64 */
  billingProviderId: number;
  /** @format int64 */
  treatingProviderId: number;
  /** @format int64 */
  insuranceCarrierId: number;
  insuranceCarrier: string;
  insuranceAcceptsElectronicAttachments: boolean;
  insurancePlanType?:
    | "PPO"
    | "TOA_SOA"
    | "EPO"
    | "HMO_DHMO"
    | "MEDICAID_CHIP"
    | "POS"
    | "DISCOUNT"
    | "SELF_FUNDED_ERISA"
    | "DIRECT_REIMBURSEMENT"
    | "FEDERAL"
    | "MEDICARE"
    | "TRICARE";
  insuranceMemberId?: string;
  insurancePaymentStatus:
    | "UNPAID"
    | "NOT_SUBMITTED"
    | "PAID_AS_EXPECTED"
    | "OVERPAID"
    | "UNDERPAID"
    | "DENIED";
  /** @format int64 */
  claimNumber: number;
  /** @format int64 */
  appointmentId: number;
  /** @format int64 */
  ucrAmount: number;
  /** @format int64 */
  patientEstimatedAmount: number;
  /** @format int64 */
  patientAmount: number;
  /** @format int64 */
  unpaidPatientAmount: number;
  /** @format int64 */
  insuranceEstimatedAmount: number;
  /** @format int64 */
  insuranceAmount: number;
  /** @format int64 */
  unpaidInsuranceAmount: number;
  /** @format int64 */
  negotiatedAmount: number;
  /** @format int64 */
  deductibleEstimatedAmount: number;
  /** @format int64 */
  deductibleAmount: number;
  /** @format int64 */
  orthoAmount: number;
  /** @format int64 */
  writeoffAmount: number;
  insuranceOrdinal: "PRIMARY" | "SECONDARY" | "OTHER";
  state: "PENDING" | "SUBMITTING" | "SUBMITTED" | "FAILED" | "COMPLETED" | "VOIDED";
  isValidating: boolean;
  notes?: string;
  lineItemGroups: ClaimLineItemGroupVO[];
  /** @format byte */
  activeVersion: string;
  eobFiles: ClaimEobFileVO[];
  payments: ClaimPaymentVO[];
  attachments: ClaimAttachmentVO[];
  ortho?: ClaimOrthoVO;
  implant?: ImplantVO;
  needsAttachment?: boolean;
  submissionError?: string;
  validationError?: string;
  eobVerified: boolean;
  /** @format uuid */
  draftEobPaymentUuid?: string;
  isPreAuth: boolean;
  /** @format date-time */
  submittedAt?: string;
  /** @format uuid */
  replacementClaimUuid?: string;
  /** @format uuid */
  replacedClaimUuid?: string;
  /** @format uuid */
  primaryClaimUuid?: string;
}

export interface SuccessResponseClaimVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ClaimVO;
}

export interface ClaimsConfigRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  dentalXChangeUsername?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  dentalXChangePassword?: string;
  /** @format int32 */
  accountNumber?: number;
  billingProviderType?: "INDIVIDUAL" | "BUSINESS" | "TREATING";
  /** @format int64 */
  billingProviderId?: number;
  /**
   * @minLength 0
   * @maxLength 10
   */
  billingProviderNPI?: string;
  /**
   * @minLength 0
   * @maxLength 10
   */
  taxonomyCode?: string;
  releaseOfPatientInfo?: boolean;
  assignmentOfBenefits?: boolean;
  zeroDollarClaims?: boolean;
  autoCreatePrimary?: boolean;
  autoCreateSecondary?: boolean;
}

export interface ClaimsConfigVO {
  dentalXChangeUsername?: string;
  hasDentalXChangePassword: boolean;
  /** @format int32 */
  accountNumber?: number;
  billingProviderType?: "INDIVIDUAL" | "BUSINESS" | "TREATING";
  /** @format int64 */
  billingProviderId?: number;
  billingProviderNPI?: string;
  taxonomyCode?: string;
  releaseOfPatientInfo: boolean;
  assignmentOfBenefits: boolean;
  zeroDollarClaims: boolean;
  autoCreatePrimary: boolean;
  autoCreateSecondary: boolean;
}

export interface SuccessResponseClaimsConfigVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ClaimsConfigVO;
}

export interface AppointmentRequest {
  /** @format int64 */
  patientId: number;
  newProcedures: CreatePatientProcedureRequest[];
  /** @uniqueItems true */
  editedProcedureIds: number[];
  date: string;
  startTime: string;
  /** @format int64 */
  roomId: number;
  /** @format int64 */
  providerId: number;
  /** @format int64 */
  dentistId?: number;
  sendConfirmationAndReminders?: boolean;
  state?:
    | "UNSCHEDULED"
    | "UNCONFIRMED"
    | "CONFIRMED"
    | "CANCELED"
    | "NO_SHOW"
    | "ARRIVED"
    | "READY"
    | "IN_PROGRESS"
    | "CHECKOUT"
    | "COMPLETED"
    | "_DELETED"
    | "REQUESTED";
  /**
   * @minLength 0
   * @maxLength 65535
   */
  comments?: string;
  /** @format int64 */
  duration: number;
  isMigration?: boolean;
  /** @format int64 */
  appointmentCategoryId?: number;
  /**
   * @minLength 0
   * @maxLength 10
   */
  color?: string;
  asap?: boolean;
  preference?: Preference;
  ignoreFeeCalcError?: boolean;
  /** @format uuid */
  messageCampaignUuid?: string;
  tags?: AppointmentTagsRequest;
}

export interface AppointmentTagsRequest {
  customIds: number[];
}

export interface CreatePatientProcedureRequest {
  /** @format int64 */
  dentalProcedureId: number;
  toothName?: string;
  surfaces?: ("M" | "O" | "D" | "I" | "L" | "B" | "F" | "BV" | "FV" | "LV")[];
  date?: string;
  status: "EXISTING_OTHER" | "PLANNED" | "SCHEDULED" | "DONE" | "EXISTING_CURRENT" | "REFERRED" | "REJECTED";
  /** @format int64 */
  providerId?: number;
  prognosis?: "EXCELLENT" | "GOOD" | "FAIR" | "QUESTIONABLE" | "POOR" | "HOPELESS";
  mouthArea?:
    | "WHOLE_MOUTH"
    | "QUAD_UPPER_LEFT"
    | "QUAD_UPPER_RIGHT"
    | "QUAD_LOWER_LEFT"
    | "QUAD_LOWER_RIGHT"
    | "ARCH_UPPER"
    | "ARCH_LOWER";
  toothRange?: string;
  /**
   * @format int32
   * @min 1
   * @max 11
   */
  priority?: number;
  implantDate?: string;
  implantInstallType?: "NEW" | "REPLACEMENT";
  /**
   * Only relevant when switching dental procedure for an existing patient procedure. If provided, the priority applies to the target treatment plan.
   * @format uuid
   */
  targetTreatmentPlanUuid?: string;
}

export type Preference = {
  providerIds?: number[];
  daysPreferred?: ("MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY")[];
  timeRanges?: TimeRangeVO[];
  /**
   * @minLength 0
   * @maxLength 750
   */
  comment?: string;
} | null;

export interface SuccessResponseAppointmentVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AppointmentVO;
}

export interface AppointmentCardConfigVO {
  indicators: ("CATEGORY" | "PROVIDER")[];
  background?: "CATEGORY" | "PROVIDER";
  showDentistColor?: boolean;
  fontSize: "STANDARD" | "SMALL";
  hidePreferredName: boolean;
  showPatientAge: boolean;
}

export interface PatientFacingCarrierNameRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  inNetwork: boolean;
}

export interface SchedulingConfigRequest {
  appointmentCardConfig?: AppointmentCardConfigVO;
  selfBookingConfig?: SelfBookingConfigRequest;
}

export interface SelfBookingConfigRequest {
  enabled: boolean;
  insuranceIntakeEnabled?: boolean;
  requireInsurance: boolean;
  allowInNetworkOnly?: boolean;
  allowAsap: boolean;
  useCustomCarriers?: boolean;
  patientFacingCarrierNames?: PatientFacingCarrierNameRequest[];
}

export interface PatientFacingCarrierName {
  name: string;
  inNetwork: boolean;
}

export interface SchedulingConfigVO {
  appointmentCardConfig: AppointmentCardConfigVO;
  selfBookingConfig: SelfBookingConfigVO;
}

export interface SelfBookingConfigVO {
  enabled: boolean;
  insuranceIntakeEnabled: boolean;
  requireInsurance: boolean;
  allowInNetworkOnly?: boolean;
  appointmentCategories: AppointmentCategoryVO[];
  allowAsap: boolean;
  useCustomCarriers: boolean;
  patientFacingCarrierNames?: PatientFacingCarrierName[];
}

export interface SuccessResponseSchedulingConfigVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: SchedulingConfigVO;
}

export interface UpdateAlertSettingsRequest {
  appearance: "SHOW_ALERT" | "SHOW_ICON" | "NONE";
  /** @uniqueItems true */
  enabledAppointmentAutoTagIds: number[];
  /** @uniqueItems true */
  enabledPatientAutoTagIds: number[];
}

export interface AlertSettingsTagVO {
  isEnabled: boolean;
  tag: TagVO;
}

export interface AlertSettingsVO {
  appearance: "SHOW_ALERT" | "SHOW_ICON" | "NONE";
  appointmentAutoTags: AlertSettingsTagVO[];
  patientAutoTags: AlertSettingsTagVO[];
  isPatientAutoAlertNoteJobInProgress: boolean;
}

export interface SuccessResponseAlertSettingsVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AlertSettingsVO;
}

export interface PrintFeeCalcRequest {
  date?: string;
  forceInsuranceEstimate?: boolean;
}

export interface PrintPatientProceduresWithFormTaskRequest {
  /** @format uuid */
  treatmentPlanUuid?: string;
  /** @uniqueItems true */
  procedureIds?: number[];
  printFeeCalcRequest?: PrintFeeCalcRequest;
}

export interface SuccessResponseUUID {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  /** @format uuid */
  data: string;
}

export interface IssueTokenRequest {
  /** @format int64 */
  userId: number;
  pin?: string;
  rememberMe: boolean;
}

export interface AccountTokenVO {
  /** @format int64 */
  userId: number;
  token: string;
  /** @format int64 */
  expiresAt: number;
}

export interface SuccessResponseAccountTokenVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AccountTokenVO;
}

export interface IssueSupportTokenRequest {
  /** @format int64 */
  practiceId: number;
}

export interface FamilyMemberAccountVO {
  /** @format int64 */
  id: number;
  name: NameVO;
  avatar?: ProfileImageVO;
}

export interface PracticeInfoVO {
  /** @format int64 */
  id: number;
  /** @format uuid */
  uuid: string;
  name: string;
  city?: string;
  website?: string;
  phoneNumber?: string;
  commsPhoneNumber?: string;
  email: string;
  addressDetails?: AddressVO;
  logo?: ProfileImageVO;
  timezoneId: string;
  practicePreferences: PracticePreferencesVO;
  onboardedWithPaymentProvider: boolean;
  ownerName?: string;
}

export interface PracticePreferencesVO {
  showPreferredPronouns: boolean;
}

export interface ProfileImageVO {
  /** @format url */
  url?: string;
  thumbnail?: string;
}

export interface SuccessResponseSupportTokenResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: SupportTokenResponse;
}

export interface SupportTokenResponse {
  token: AccountTokenVO;
  account: UserAccountVO;
}

export interface UserAccountVO {
  /** @format int64 */
  id: number;
  name: NameVO;
  type: "EMPLOYEE" | "PATIENT" | "SUPPORT_USER" | "SUPER_ADMIN";
  avatar?: ProfileImageVO;
  practice: PracticeInfoVO;
  /** Indicates if the current ip is blocked by the user account. */
  ipBlocked: boolean;
  /** Indicates if a pin (ssn last four) is required for switching to the user account. */
  pinRequired: boolean;
  familyAccounts?: FamilyMemberAccountVO[];
}

export interface CalculateSurchargeRequest {
  /** @format uuid */
  paymentProfileUuid: string;
  /** @format int64 */
  subtotal: number;
}

export interface SuccessResponseSurchargeAppliedAmountVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: SurchargeAppliedAmountVO;
}

export interface SurchargeAppliedAmountVO {
  /** @format int64 */
  subtotal: number;
  /** @format int64 */
  total: number;
  /** @format int64 */
  surchargeAmount: number;
}

export interface GuestCheckoutSummaryRequest {
  patientDob: string;
  /** @format uuid */
  paymentProfileUuid?: string;
}

export interface GuestCheckoutSummaryVO {
  /** @format int64 */
  totalInvoiceBalance: number;
  /** @format int64 */
  outstandingBalance: number;
  /** @format int64 */
  totalChargeAmount: number;
  patientName: NameVO;
  /** @format int64 */
  surchargeAmount: number;
  passCreditCardFees: boolean;
}

export interface SuccessResponseGuestCheckoutSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: GuestCheckoutSummaryVO;
}

export interface GuestCheckoutPaymentRequest {
  paymentToken: string;
  patientToken: string;
  email: string;
  /** The family payment amount if this payment is associated with a family payment. */
  currencyAmount: CurrencyAmount;
}

export interface SuccessResponsePaymentVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PaymentVO;
}

export interface GuestCheckoutPaymentV2Request {
  /** @format uuid */
  paymentProfileUuid: string;
  patientToken: string;
  email: string;
  /** The family payment amount if this payment is associated with a family payment. */
  currencyAmount: CurrencyAmount;
  /** @format uuid */
  idempotencyUuid: string;
}

export interface GuestCheckoutPaymentProfileRequest {
  paymentToken: string;
  patientToken: string;
}

export interface GuestCheckoutPaymentProfileVoidRequest {
  /** @format uuid */
  paymentProfileUuid: string;
  patientToken: string;
}

export interface PatientSubmitFormTaskRequest {
  formResponse: BatchFormResponseRequest;
  encryptedFileKey: string;
}

export interface PatientFormTaskVO {
  /** @format uuid */
  uuid: string;
  /** @format int64 */
  patientId: number;
  state: "PENDING" | "COMPLETED" | "ARCHIVED" | "PRINT_ONLY";
  form: FormVO;
  fileUpload?: PrepareFileUploadResponse;
}

export interface PrepareFileUploadResponse {
  /** @format url */
  url: string;
  encryptedFileKey: string;
  /** @format int64 */
  expiresAt: number;
}

export interface SuccessResponsePatientFormTaskVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientFormTaskVO;
}

export interface PreparePatientFormSubmissionRequest {
  dob: string;
  formTaskUuids?: string[];
  kioskCode?: string;
}

export interface PreparePatientFormSubmissionResponse {
  token: string;
  tasks: PatientFormTaskVO[];
  patient: NameVO;
  dob: string;
  gender?: "MALE" | "FEMALE" | "OTHER" | "UNKNOWN";
}

export interface SuccessResponsePreparePatientFormSubmissionResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PreparePatientFormSubmissionResponse;
}

export interface SelfBookingAppointmentRequest {
  date: string;
  startTime: string;
  /** @format int64 */
  providerId: number;
  /** @format int64 */
  appointmentCategoryId: number;
  patient: SelfBookingPatientRequest;
  /** @format int64 */
  carrierId?: number;
  carrierName?: string;
  asap?: boolean;
  /**
   * @minLength 0
   * @maxLength 255
   */
  comments?: string;
  /** @format uuid */
  messageCampaignUuid?: string;
}

export interface SelfBookingPatientRequest {
  type: "EXISTING" | "NEW";
  /**
   * @minLength 0
   * @maxLength 100
   */
  firstName: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  lastName: string;
  dob: string;
  /** @pattern ^[0-9]{10}$ */
  phone: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  email: string;
}

export interface SelfBookingOpenSlotsRequest {
  startDate?: string;
  /** @uniqueItems true */
  providerIds?: number[];
  /** @format int64 */
  appointmentCategoryId: number;
}

export interface SelfBookingOpenSlotVO {
  date: string;
  startTime: string;
  endTime: string;
}

export interface SelfBookingOpenSlotsResponse {
  providerSlots?: Record<string, SelfBookingOpenSlotVO[]>;
}

export interface SuccessResponseSelfBookingOpenSlotsResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: SelfBookingOpenSlotsResponse;
}

export interface AppointmentConfirmationRequest {
  payload: string;
}

export interface AppointmentDetailsForConfirmationVO {
  date: string;
  startTime: string;
  endTime: string;
  dentist: string;
}

export interface SuccessResponseAppointmentDetailsForConfirmationVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AppointmentDetailsForConfirmationVO;
}

export interface SuccessEndpointResponseMessageVO {
  message?: string;
}

export interface SuccessResponseSuccessEndpointResponseMessageVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: SuccessEndpointResponseMessageVO;
}

export interface CreateFolderRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  name?: string;
  createDuplicateIfExists?: boolean;
}

export interface FolderVO {
  /** @format int64 */
  id: number;
  name: string;
  /** @format int64 */
  fileCount: number;
  isDefaultFolder: boolean;
  /** metadata of the folder. It is always returned if this entity is returned in an update context. Otherwise, it's conditionally populated depending on request param "includeMetadata". */
  metadata?: MetadataVO;
}

/** metadata of the folder. It is always returned if this entity is returned in an update context. Otherwise, it's conditionally populated depending on request param "includeMetadata". */
export interface MetadataVO {
  user: NameVO;
  createdBy?: NameVO;
  updatedBy?: NameVO;
  /** @format int64 */
  createdAt: number;
  /** @format int64 */
  updatedAt?: number;
}

export interface SuccessResponseFolderVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FolderVO;
}

export interface DocumentVO {
  /** @format int64 */
  id: number;
  name: string;
  /** @format int64 */
  folderId: number;
  folderName: string;
  /** @format date-time */
  creationDate: string;
  type?: string;
  contentType?: string;
  /** metadata of the folder. It is always returned if this entity is returned in an update context. Otherwise, it's conditionally populated depending on request param "includeMetadata". */
  metadata?: MetadataVO;
}

export interface SuccessResponseDocumentVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: DocumentVO;
}

export interface UploadDocumentRequest {
  documentName: string;
  contentType: string;
}

export interface SuccessResponseURL {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  /** @format url */
  data: string;
}

export interface CreateTreatmentPlanRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  patientProcedures: PatientProcedureRequest[];
  /** @format int64 */
  patientId: number;
  /**
   * If provided, the priority of the patient procedures selected will be copied. Defaults to utilizing the master treatment plan patient procedure priorities.
   * @format uuid
   */
  sourceTreatmentPlanUuid?: string;
}

export interface TreatmentPlanAddProceduresRequest {
  /**
   * If provided, will copy the patient procedure priorities on the source treatment plan.
   * @format uuid
   */
  sourceTreatmentPlanUuid?: string;
  /** @uniqueItems true */
  patientProcedureIds: number[];
}

export interface CreateTreatmentPlanFormTaskRequest {
  printOnly?: boolean;
  /** @format uuid */
  treatmentPlanUuid?: string;
  /** @uniqueItems true */
  procedureIds?: number[];
  printFeeCalcRequest?: PrintFeeCalcRequest;
}

export interface FormTaskVO {
  /** @format uuid */
  uuid: string;
  /** @format int64 */
  patientId: number;
  /** @format int64 */
  appointmentId?: number;
  appointmentDate?: string;
  appointmentStartTime?: string;
  state: "PENDING" | "COMPLETED" | "ARCHIVED" | "PRINT_ONLY";
  form: FormVO;
  /** @format int64 */
  documentId?: number;
  /** @format int64 */
  submittedAt?: number;
  /** @format int64 */
  expiresAt?: number;
  /** @format int64 */
  sentAt?: number;
  createdManually: boolean;
  treatmentPlanName?: string;
  /** @format uuid */
  treatmentPlanUuid?: string;
}

export interface SuccessResponseFormTaskVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FormTaskVO;
}

export interface TransferMountRequest {
  /** @format int64 */
  sourcePatientId: number;
  /** @format int64 */
  targetPatientId: number;
  /** @format int64 */
  mountId: number;
}

export interface ImageFilterDetails {
  /**
   * @format float
   * @min 0
   * @max 1
   */
  sharpness?: number;
  /**
   * @format float
   * @min -1
   * @max 1
   */
  contrast?: number;
  /**
   * @format float
   * @min -1
   * @max 1
   */
  brightness?: number;
  negative?: boolean;
}

export interface ImageTransformDetails {
  /**
   * @format int32
   * @min -270
   * @max 270
   */
  rotationDegrees: number;
  mirror: string[];
}

export interface MedicalImageVO {
  /** @format int64 */
  id?: number;
  /** @format url */
  url?: string;
  /** @format url */
  dicomUrl?: string;
  transforms?: ImageTransformDetails;
  filters?: ImageFilterDetails;
  /**
   * @minLength 0
   * @maxLength 65535
   */
  annotation?: string;
  layersHidden?: ("ANNOTATIONS" | "FILTERS")[];
  teeth?: string[];
  /** @format int64 */
  createdAt?: number;
  createdDate?: string;
  assignedDate?: string;
  isArchived?: boolean;
  type?: "X_RAY" | "PHOTO" | "EXTERNAL";
  sensor?: string;
  /** High level analysis of the image by pearl. Only populated for images of type XRAY. */
  pearlAnalysis?: PearlAnalysisVO;
  /** @format int32 */
  i?: number;
  /** @format int64 */
  x?: number;
  /** @format int64 */
  y?: number;
  /** @format double */
  h?: number;
  /** @format double */
  w?: number;
}

export interface MountVO {
  /** @format int64 */
  id: number;
  name: string;
  layout: string;
  date: string;
  images?: MedicalImageVO[];
  /** @uniqueItems true */
  types?: string[];
}

/** High level analysis of the image by pearl. Only populated for images of type XRAY. */
export interface PearlAnalysisVO {
  status: "NOT_SUBMITTED" | "SUBMITTED" | "DONE" | "REJECTED";
  conditions: PearlImageSummaryVO[];
  errorMessage?: string;
  /**
   * Rotation degrees of the image at the time it was submitted to pearl.
   * @format int32
   */
  rotationDegrees: number;
  /** The mirror of the image at the time it was submitted to pearl. */
  mirror: string[];
}

export interface PearlImageSummaryVO {
  /** @format int32 */
  count: number;
  condition?:
    | "conditions.pathologies.ca.eo"
    | "conditions.pathologies.ca.id"
    | "conditions.pathologies.ca.aoip"
    | "conditions.pathologies.pr"
    | "conditions.restoration_e.cm"
    | "conditions.restoration_e.cnm"
    | "conditions.restoration_e.fm"
    | "conditions.restoration_e.fnm"
    | "conditions.restoration_e.rc"
    | "conditions.restoration_e.im"
    | "conditions.restoration_e.br"
    | "conditions.restoration_f.om"
    | "conditions.restoration_f.sm"
    | "conditions.pathologies.calculus.low"
    | "conditions.pathologies.calculus.high"
    | "conditions.restoration_f.oh"
    | "conditions.anatomy.tooth"
    | "conditions.anatomy.biowidth"
    | "conditions.anatomy.occlusal"
    | "conditions.pathologies.ca.rd"
    | "conditions.anatomy.overlap";
  category?:
    | "crown"
    | "periapical.radiolucency"
    | "filling"
    | "anatomy"
    | "caries"
    | "margin.discrepancy"
    | "implant"
    | "root.canal"
    | "bridge"
    | "calculus";
}

export interface SuccessResponseMountVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MountVO;
}

export interface TransferImagesRequest {
  /** @format int64 */
  sourcePatientId: number;
  /** @format int64 */
  targetPatientId: number;
  /** @format int64 */
  sourceMountId: number;
  /** @format int64 */
  targetMountId?: number;
  imageIds: number[];
}

export interface CreateTagRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  glyph: GlyphRequest;
  /**
   * @minLength 0
   * @maxLength 10
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  color: string;
}

export interface CreateStatementRequest {
  startDate: string;
  endDate: string;
  /** If not explicitly provided, the starting balance on the statement will be the sum of all entries prior to the startDate. */
  rolloverBalance?: boolean;
}

export interface SendMessageCampaignRequest {
  /** @format uuid */
  messageCampaignUuid: string;
  /** @uniqueItems true */
  channels: ("PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL")[];
  /**
   * @minLength 0
   * @maxLength 400
   */
  smsTemplate?: string;
  /**
   * @minLength 0
   * @maxLength 5000
   */
  emailTemplate?: string;
  customEmailTemplate?: EmailTemplate;
  subject?: string;
  createStatementRequest?: CreateStatementRequest;
  filter:
    | MessageCampaignAsapCriteria
    | MessageCampaignHuddleCriteria
    | MessageCampaignPatientCriteria
    | MessageCampaignRecallReportCriteria
    | MessageCampaignUncollectedReportCriteria;
}

export interface CreateIPFilterRequest {
  /**
   * @minLength 0
   * @maxLength 2000
   */
  address: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  description: string;
  /** @uniqueItems true */
  userIds?: number[];
  appliesToAll?: boolean;
}

export interface IPFilterVO {
  /** @format uuid */
  uuid: string;
  address: string;
  description: string;
  /** @uniqueItems true */
  userIds: number[];
  appliesToAll: boolean;
}

export interface SuccessResponseIPFilterVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: IPFilterVO;
}

export interface CreateScheduleBlockRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  title: string;
  date: string;
  startTime: string;
  endTime: string;
  roomIds?: number[];
  providerIds?: number[];
  /**
   * @minLength 0
   * @maxLength 65535
   */
  comments?: string;
  /** @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$ */
  color: string;
  recurrence?: RecurringScheduleRequest;
}

export interface CreateSavedFilterRequest {
  /** @format uuid */
  uuid: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  filter:
    | MessageCampaignAsapCriteria
    | MessageCampaignHuddleCriteria
    | MessageCampaignPatientCriteria
    | MessageCampaignRecallReportCriteria
    | MessageCampaignUncollectedReportCriteria;
}

export interface SavedFilterVO {
  /** @format uuid */
  uuid: string;
  name: string;
  filter:
    | MessageCampaignAsapCriteria
    | MessageCampaignHuddleCriteria
    | MessageCampaignPatientCriteria
    | MessageCampaignRecallReportCriteria
    | MessageCampaignUncollectedReportCriteria;
}

export interface SuccessResponseSavedFilterVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: SavedFilterVO;
}

export interface RoomRequest {
  /** @format int64 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  roomName: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  roomDescription?: string;
  /** @format int32 */
  order: number;
  status?: "ACTIVE" | "ARCHIVED";
  isSelfBookable?: boolean;
  providerIds?: number[];
}

export interface RoomsRequest {
  rooms: RoomRequest[];
}

export interface SuccessResponseListRoomVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: RoomVO[];
}

export interface WorkingHourRequest {
  startDate?: string;
  endDate?: string;
  includeTotal?: boolean;
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: providerLastName, secondsWorked */
  sortOrders?: SortOrder[];
}

export interface SuccessResponseWorkingHourResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: WorkingHourResponse;
}

export interface WorkingHourResponse {
  entries: WorkingHourVO[];
  subtotals: object[];
  total?: WorkingHourTotalVO;
}

export interface WorkingHourTotalVO {
  /** @format int64 */
  totalWorkingSeconds: number;
}

export interface WorkingHourVO {
  employee: NameVO;
  /** @format int64 */
  totalSecondsWorked: number;
  worktimeStatus: "PENDING" | "APPROVED";
}

export interface UnscheduledProcedureRequest {
  startDate?: string;
  endDate?: string;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  includeTotal?: boolean;
  /** Fields to filter on. Supported fields: hasNextAppointment, patientStatus, dentalProcedureId, isHygiene, treatmentPlanState */
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: patientLastName, previousAppointmentDate, priority, createdDate, isMasterTreatmentPlan, treatmentPlanState, dentalProcedureCdtCode, providerLastName */
  sortOrders?: SortOrder[];
}

export interface SuccessResponseUnscheduledProcedureResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: UnscheduledProcedureResponse;
}

export interface UnscheduledProcedureEntryVO {
  patient: NameVO;
  patientStatus: "PROSPECT" | "ACTIVE" | "INACTIVE" | "ARCHIVED" | "NONPATIENT" | "DECEASED";
  patientPhone?: string;
  provider: NameVO;
  carrier?: InsuranceCarrierVO;
  /** @format int64 */
  patientInsuranceId?: number;
  previousAppointmentDate?: string;
  /** @format int64 */
  previousAppointmentId?: number;
  nextAppointmentDate?: string;
  /** @format int64 */
  nextAppointmentId?: number;
  dentalProcedure: DentalProcedureVO;
  treatmentPlanName?: string;
  /** @format uuid */
  treatmentPlanUuid?: string;
  treatmentPlanState?: "INACTIVE" | "ACTIVE" | "ARCHIVED";
  /** @format int64 */
  negotiatedRate: number;
  toothName?: string;
  surfaces?: ("M" | "O" | "D" | "I" | "L" | "B" | "F" | "BV" | "FV" | "LV")[];
  toothRange?: string;
  procedureArea?: string;
  mouthArea?:
    | "WHOLE_MOUTH"
    | "QUAD_UPPER_LEFT"
    | "QUAD_UPPER_RIGHT"
    | "QUAD_LOWER_LEFT"
    | "QUAD_LOWER_RIGHT"
    | "ARCH_UPPER"
    | "ARCH_LOWER";
  /** @format int32 */
  priority?: number;
  createdDate?: string;
}

export interface UnscheduledProcedureResponse {
  entries: UnscheduledProcedureEntryVO[];
  subtotals: object[];
  total?: UnscheduledProcedureTotalVO;
}

export interface UnscheduledProcedureTotalVO {
  /** @format int64 */
  totalDistinctUnscheduledPatients: number;
  /** @format int64 */
  totalDistinctUnscheduledProcedures: number;
  /** @format int64 */
  totalUnscheduledProcedures: number;
}

export interface UncollectedByServiceRequest {
  startDate?: string;
  endDate?: string;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** Fields to sort the result by. The result list is always sorted by patient id first to support subtotal. It cannot be changed. Other supported fields: patientLastName, productionDate, invoiceDate, patientUncollectedAmount. When sorting by invoiceDate, regardless of the direction, those without invoice will always appear last. */
  sortOrders?: SortOrder[];
  /** When true, include the grand total for the practice with all filters applied. False otherwise. */
  includeTotal?: boolean;
  filters?: Filter[];
}

export interface SuccessResponseUncollectedByServiceResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: UncollectedByServiceResponse;
}

export interface UncollectedByServiceEntryVO {
  entryType: "APPOINTMENT" | "ADJUSTMENT";
  serviceDate: string;
  patient: NameVO;
  /** @format uuid */
  adjustmentUuid?: string;
  /** @format int64 */
  appointmentId?: number;
  provider?: NameVO;
  /** @format int64 */
  totalPatientBalance: number;
  /** @format int64 */
  outstandingBalance: number;
  /** @format int64 */
  patientBalance: number;
  /** @format int64 */
  insuranceBalance: number;
  /** @format int64 */
  uninvoicedBalance: number;
  /** @format int64 */
  invoicedBalance: number;
}

export interface UncollectedByServiceResponse {
  entries: UncollectedByServiceEntryVO[];
  subtotals: object[];
  total?: UncollectedByServiceTotalVO;
}

export interface UncollectedByServiceTotalVO {
  /** @format int64 */
  distinctPatientCount: number;
  /** @format int64 */
  totalOutstandingBalance: number;
  /** @format int64 */
  totalPatientBalance: number;
  /** @format int64 */
  totalInsuranceBalance: number;
  /** @format int64 */
  totalUninvoicedBalance: number;
  /** @format int64 */
  totalInvoicedBalance: number;
}

export interface UncollectedByPatientRequest {
  startDate?: string;
  endDate?: string;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** Fields to sort the result by. The result list is always sorted by patient id first to support subtotal. It cannot be changed. Other supported fields: patientLastName, productionDate, invoiceDate, patientUncollectedAmount. When sorting by invoiceDate, regardless of the direction, those without invoice will always appear last. */
  sortOrders?: SortOrder[];
  /** When true, include the grand total for the practice with all filters applied. False otherwise. */
  includeTotal?: boolean;
  filters?: Filter[];
}

export interface SuccessResponseUncollectedByPatientResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: UncollectedByPatientResponse;
}

export interface UncollectedByPatientEntryVO {
  productionDate?: string;
  /** @format int64 */
  patientId: number;
  invoiceDate?: string;
  agingDate: string;
  /** @format int64 */
  invoiceAge?: number;
  /** @format uuid */
  invoiceUuid?: string;
  invoiceNumber?: string;
  invoiceState?: "FINALIZED" | "VOID" | "PARTIALLY_PAID" | "PAID";
  isInvoiced: boolean;
  /** @format int64 */
  patientUncollectedAmount: number;
}

export interface UncollectedByPatientResponse {
  entries: UncollectedByPatientEntryVO[];
  subtotals: UncollectedByPatientSubtotalVO[];
  total?: UncollectedByPatientTotalVO;
}

export interface UncollectedByPatientSubtotalVO {
  patient: NameVO;
  /**
   * Current patient balance. It reflects the current patient balance and will not change with the date filters.
   * @format int64
   */
  currentPatientBalance: number;
  /**
   * Current family balance. It reflects the current family balance and will not change with the date filters.
   * @format int64
   */
  currentFamilyBalance: number;
  /**
   * Uncollected amount with all filters applied.
   * @format int64
   */
  totalPatientUncollectedAmount: number;
}

export interface UncollectedByPatientTotalVO {
  /** @format int64 */
  totalPatientUncollectedAmount: number;
}

export interface UncollectedByCarrierRequest {
  startDate?: string;
  endDate?: string;
  /** When true, include the grand total for the practice with all filters applied. False otherwise. */
  includeTotal?: boolean;
  /** Fields to sort the result by. The result list is always sorted by carrier id first. It cannot be changed. Other supported fields: patientLastName, primarySubscriberPatientLastName, patientDob, insuranceOrdinal, claimAmount, productionDate, insuranceUncollectedAmount, claimSubmissionDate. When sorting by claimSubmissionDate regardless of the direction, those without a submitted claim will always appear last. */
  sortOrders?: SortOrder[];
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  filters?: Filter[];
}

export interface SuccessResponseUncollectedByCarrierResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: UncollectedByCarrierResponse;
}

export interface UncollectedByCarrierEntryVO {
  patient: NameVO;
  patientDob?: string;
  primarySubscriberPatient: NameVO;
  /** @format int64 */
  patientInsuranceId: number;
  insuranceOrdinal: "PRIMARY" | "SECONDARY" | "OTHER";
  /** @format int64 */
  insuranceCarrierId: number;
  productionDate: string;
  claimSubmissionDate?: string;
  /** @format int64 */
  claimAmount: number;
  /** @format int64 */
  insuranceUncollectedAmount: number;
  /** @format int64 */
  claimAge?: number;
  /** @format uuid */
  claimUuid: string;
  claimState: "PENDING" | "SUBMITTING" | "SUBMITTED" | "FAILED" | "COMPLETED" | "VOIDED";
}

export interface UncollectedByCarrierResponse {
  entries: UncollectedByCarrierEntryVO[];
  subtotals: UncollectedByCarrierSubtotalVO[];
  total?: UncollectedByCarrierTotalVO;
}

export interface UncollectedByCarrierSubtotalVO {
  carrier: InsuranceCarrierVO;
  /** @format int64 */
  totalClaimAmount: number;
  /** @format int64 */
  totalInsuranceUncollectedAmount: number;
}

export interface UncollectedByCarrierTotalVO {
  /** @format int64 */
  totalClaimAmount: number;
  /** @format int64 */
  totalInsuranceUncollectedAmount: number;
}

export interface TimeSeriesRequest {
  startDate?: string;
  endDate?: string;
  /** Granularity which the date will be grouped by on. When ALL is supplied, each time series will have at most one datapoint, where the startDate is the min date of all the data points in the date range. */
  resolution: "DAY" | "WEEK" | "MONTH" | "YEAR" | "ALL" | "AGING" | "LONG_AGING" | "QUARTER";
  /** Supported metrics: productionAmount collectedAmount patientUncollectedAmount insuranceUncollectedAmount */
  metric: string;
  /** Filters to narrow down the scope of the time series. Filters are evaluated with AND operator. */
  filters: Filter[];
  /**
   * For productionAmount and collectedAmount, supported groupBys: isScheduled providerId patientId, providerJobCategory
   * For patientUncollectedAmount, supported groupBys: providerId, patientId, isInvoiced, invoiceState, providerJobCategory
   * For insuranceUncollectedAmount, supported groupBys: providerId, patientId, primarySubscriberPatientId, insuranceCarrierId, patientInsuranceId, isSubmitted, claimState, providerJobCategory
   * For paymentAmount, supported groupBys: payerType, paymentMethod, paymentType
   */
  groupBy?: string;
  /** When true, fill all time series with 0 on intervals/data-points without data. */
  shouldZeroFill?: boolean;
}

export interface SuccessResponseTimeSeriesResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: TimeSeriesResponse;
}

export interface TimeSeries {
  dimension?: string;
  dataPoints: TimeSeriesDataPoint[];
}

export interface TimeSeriesDataPoint {
  startDate: string;
  /** @format int64 */
  value: number;
}

export interface TimeSeriesResponse {
  timeSeries: TimeSeries[];
}

export interface ReportingRecallRequest {
  startDate?: string;
  endDate?: string;
  includeTotal?: boolean;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** Fields to filter on. Supported fields: insuranceCarrierId, recallType, hasNextAppointment, patientStatus */
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: patientLastName, dueDate, previousDate, scheduledDate, recallType, previousAppointmentDate, insuranceCarrierId, remainingInsuranceBenefitAmount, patientStatus */
  sortOrders?: SortOrder[];
}

export interface ReportingRecallEntryVO {
  /** @format uuid */
  uuid: string;
  patient: NameVO;
  patientStatus: "PROSPECT" | "ACTIVE" | "INACTIVE" | "ARCHIVED" | "NONPATIENT" | "DECEASED";
  patientPhone?: string;
  /** @format int64 */
  practiceId: number;
  /** @format int64 */
  previousAppointmentId?: number;
  /** @format int64 */
  scheduledAppointmentId?: number;
  previousDate?: string;
  scheduledDate?: string;
  previousAppointmentDate?: string;
  dueDate: string;
  /** @format int64 */
  dueAge: number;
  recallType: "EXAM" | "PROPHY" | "FOUR_BW" | "PANO" | "FLUORIDE" | "PERIO" | "FMX";
  /** @format int64 */
  patientInsuranceId?: number;
  carrier?: InsuranceCarrierVO;
  /** @format int64 */
  remainingInsuranceBenefitAmount?: number;
}

export interface ReportingRecallResponse {
  entries: ReportingRecallEntryVO[];
  subtotals: object[];
  total?: ReportingRecallTotalVO;
}

export interface ReportingRecallTotalVO {
  /** @format int64 */
  distinctCount: number;
}

export interface SuccessResponseReportingRecallResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ReportingRecallResponse;
}

export interface ReportingRecallByPatientEntryVO {
  patient: NameVO;
  patientPhone?: string;
  patientStatus: "PROSPECT" | "ACTIVE" | "INACTIVE" | "ARCHIVED" | "NONPATIENT" | "DECEASED";
  /** @format int64 */
  practiceId: number;
  /** @format int64 */
  previousAppointmentId?: number;
  /** @format int64 */
  scheduledAppointmentId?: number;
  previousDate?: string;
  scheduledDate?: string;
  previousAppointmentDate?: string;
  dueDate: string;
  /** @format int64 */
  dueAge: number;
  /** @format int64 */
  patientInsuranceId?: number;
  carrier?: InsuranceCarrierVO;
  /** @format int64 */
  remainingInsuranceBenefitAmount?: number;
}

export interface ReportingRecallByPatientResponse {
  entries: ReportingRecallByPatientEntryVO[];
  subtotals: object[];
  total?: ReportingRecallTotalVO;
}

export interface SuccessResponseReportingRecallByPatientResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ReportingRecallByPatientResponse;
}

export interface ProviderRollupRequest {
  /** Please see supported metrics TimeSeriesRequest.metric */
  metric: string;
  startDate?: string;
  endDate?: string;
  filters?: Filter[];
}

export interface ProviderRollupEntryVO {
  provider: NameVO;
  jobCategory: "DENTIST" | "BACK_OFFICE" | "FRONT_OFFICE" | "HYGIENIST" | "PRACTICE";
  /** @format int64 */
  value: number;
}

export interface ProviderRollupResponse {
  entries: ProviderRollupEntryVO[];
  subtotals: ProviderRollupSubtotalVO[];
  total: ProviderRollupTotalVO;
}

export interface ProviderRollupSubtotalVO {
  jobCategory: "DENTIST" | "BACK_OFFICE" | "FRONT_OFFICE" | "HYGIENIST" | "PRACTICE";
  /** @format int64 */
  value: number;
}

export interface ProviderRollupTotalVO {
  /** @format int64 */
  value: number;
}

export interface SuccessResponseProviderRollupResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ProviderRollupResponse;
}

export interface ProductionByProviderRequest {
  startDate?: string;
  endDate?: string;
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: providerLastName, productionAmount, collectedAmount, patientCount, productionByPatient. */
  sortOrders?: SortOrder[];
}

export interface ProductionByProviderResponse {
  total: ProductionTotalVO;
  byProviders: ProductionByProviderVO[];
}

export interface ProductionByProviderVO {
  provider: NameVO;
  /** @format int64 */
  totalProductionAmount: number;
  /** @format int64 */
  patientCount: number;
  /** @format int64 */
  productionPerPatient: number;
  /** @format int64 */
  totalCollectedAmount: number;
  /** @format int64 */
  totalProducedAmount: number;
  /** @format int64 */
  totalScheduledAmount: number;
  /** @format int64 */
  totalGrossProductionAmount: number;
  /** @format int64 */
  totalGrossProducedAmount: number;
  /** @format int64 */
  totalGrossScheduledAmount: number;
  /** @format int64 */
  grossProductionPerPatient: number;
}

export interface ProductionTotalVO {
  /** @format int64 */
  totalProductionAmount: number;
  /** @format int64 */
  totalCollectedAmount: number;
  /** @format int64 */
  totalProducedAmount: number;
  /** @format int64 */
  totalScheduledAmount: number;
  /** @format int64 */
  distinctCount: number;
  /** @format int64 */
  distinctAppointmentCount: number;
  /** @format int64 */
  totalGrossProductionAmount: number;
  /** @format int64 */
  totalGrossProducedAmount: number;
  /** @format int64 */
  totalGrossScheduledAmount: number;
}

export interface SuccessResponseProductionByProviderResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ProductionByProviderResponse;
}

export interface ProductionByProviderComboResponse {
  total: ProductionTotalVO;
  byProviders: ProductionByProviderComboVO[];
}

export interface ProductionByProviderComboVO {
  provider: ProviderVO;
  dentist: ProviderVO;
  providerName: NameVO;
  dentistName: NameVO;
  /** @format int64 */
  totalProductionAmount: number;
  /** @format int64 */
  patientCount: number;
  /** @format int64 */
  productionPerPatient: number;
  /** @format int64 */
  totalCollectedAmount: number;
  /** @format int64 */
  totalProducedAmount: number;
  /** @format int64 */
  totalScheduledAmount: number;
  /** @format int64 */
  totalGrossProductionAmount: number;
  /** @format int64 */
  totalGrossProducedAmount: number;
  /** @format int64 */
  totalGrossScheduledAmount: number;
  /** @format int64 */
  grossProductionPerPatient: number;
}

export interface SuccessResponseProductionByProviderComboResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ProductionByProviderComboResponse;
}

export interface ProductionByProcedureRequest {
  startDate?: string;
  endDate?: string;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** When true, include the grand total for the practice with all filters applied. False otherwise. */
  includeTotal: boolean;
  /** Supported filters: isScheduled(boolean type) providerId(long type) patientId(long type) providerJobCategory(string type) dentalProcedureId(long type) */
  filters: Filter[];
  /** Supported sort fields: patientLastName, providerLastName, dentalProcedureCdtCode, dentalProcedureSimpleName, productionAmount, productionDate */
  sortOrders: SortOrder[];
}

export interface ProductionByProcedureResponse {
  entries: ProductionByProcedureVO[];
  subtotals: object[];
  total?: ProductionTotalVO;
}

export interface ProductionByProcedureVO {
  patient: NameVO;
  provider: NameVO;
  productionDate: string;
  isScheduled: boolean;
  /** @format int64 */
  patientProcedureId: number;
  /** @format int64 */
  dentalProcedureId: number;
  dentalProcedureSimpleName: string;
  dentalProcedureCdtCode: string;
  /** @format int64 */
  totalProductionAmount: number;
  /** @format int64 */
  totalProducedAmount: number;
  /** @format int64 */
  totalScheduledAmount: number;
  /** @format int64 */
  totalGrossProductionAmount: number;
  /** @format int64 */
  totalGrossProducedAmount: number;
  /** @format int64 */
  totalGrossScheduledAmount: number;
  /** @format int64 */
  appointmentId: number;
}

export interface SuccessResponseProductionByProcedureResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ProductionByProcedureResponse;
}

export interface ProductionByAppointmentRequest {
  startDate?: string;
  endDate?: string;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** When true, include the grand total for the practice with all filters applied. False otherwise. */
  includeTotal?: boolean;
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: patientLastName, providerLastName, productionAmount, collectedAmount, productionDate. */
  sortOrders?: SortOrder[];
}

export interface ProductionByAppointmentResponse {
  entries: ProductionByAppointmentVO[];
  subtotals: object[];
  total?: ProductionTotalVO;
  /** @deprecated */
  byPatients: ProductionByAppointmentVO[];
}

export interface ProductionByAppointmentVO {
  patient: NameVO;
  provider: NameVO;
  dentist: NameVO;
  productionDate: string;
  isScheduled: boolean;
  patientProcedureDisplay: string;
  /** @format int64 */
  totalProductionAmount: number;
  /** @format int64 */
  totalProducedAmount: number;
  /** @format int64 */
  totalScheduledAmount: number;
  /** @format int64 */
  totalGrossProductionAmount: number;
  /** @format int64 */
  totalGrossProducedAmount: number;
  /** @format int64 */
  totalGrossScheduledAmount: number;
  /** @format int64 */
  appointmentId: number;
}

export interface SuccessResponseProductionByAppointmentResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ProductionByAppointmentResponse;
}

export interface PatientWalletRequest {
  startDate?: string;
  endDate?: string;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** When true, include the grand total for the practice with all filters applied. False otherwise. */
  includeTotal?: boolean;
  /** Fields to filter on. */
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: patientLastName, patientBalance, walletBalance, lastActivityDate */
  sortOrders?: SortOrder[];
}

export interface PatientWalletResponse {
  entries: PatientWalletVO[];
  subtotals: object[];
  total?: PatientWalletTotalVO;
}

export interface PatientWalletTotalVO {
  /** @format int64 */
  totalWalletBalance: number;
  /** @format int64 */
  totalPatientCount: number;
}

export interface PatientWalletVO {
  patient: NameVO;
  /** @format int64 */
  walletBalance: number;
  /** @format int64 */
  patientBalance: number;
  lastActivityDate: string;
}

export interface SuccessResponsePatientWalletResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientWalletResponse;
}

export interface InvoiceStatusVO {
  /** @format uuid */
  uuid: string;
  state: "FINALIZED" | "VOID" | "PARTIALLY_PAID" | "PAID";
  /** @format int64 */
  createdAt: number;
}

export interface LastStatementVO {
  /** @format uuid */
  uuid: string;
  date: string;
}

export interface MessageStatusVO {
  /** @format int64 */
  id: number;
  type: "CHAT" | "EMAIL" | "CALL" | "SMS" | "MAIL";
  /** @format int64 */
  timestamp: number;
  state: "SCHEDULED" | "PENDING" | "ACCEPTED" | "SENT" | "DELIVERED" | "FAILED";
  errorMessage?: string;
}

export interface PatientBalanceAgingEntryVO {
  patient: NameVO;
  nextAppointmentDate?: string;
  /** @format int64 */
  nextAppointmentId?: number;
  patientPhone?: string;
  /** @format int64 */
  totalPatientBalance: number;
  /** @format int64 */
  uninvoicedBalance: number;
  lastStatement?: LastStatementVO;
  outstandingInvoices?: InvoiceStatusVO[];
  /** @format int64 */
  paymentRequestedAt?: number;
  paymentRequestedMessages?: MessageStatusVO[];
  /** @format int64 */
  aging30OrLess: number;
  /** @format int64 */
  aging31To60: number;
  /** @format int64 */
  aging61To90: number;
  /** @format int64 */
  aging91To120: number;
  /** @format int64 */
  aging121OrMore: number;
}

export interface PatientBalanceAgingResponse {
  entries: PatientBalanceAgingEntryVO[];
  subtotals: object[];
  total?: PatientBalanceAgingTotalVO;
}

export interface PatientBalanceAgingTotalVO {
  /** @format int64 */
  distinctPatientCount: number;
  /** @format int64 */
  totalPatientBalance: number;
  /** @format int64 */
  totalUninvoicedBalance: number;
  /** @format int64 */
  totalUninvoicedAbsBalance: number;
  /** @format int64 */
  totalAging30OrLess: number;
  /** @format int64 */
  totalAging31To60: number;
  /** @format int64 */
  totalAging61To90: number;
  /** @format int64 */
  totalAging91To120: number;
  /** @format int64 */
  totalAging121OrMore: number;
}

export interface SuccessResponsePatientBalanceAgingResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientBalanceAgingResponse;
}

export interface PatientAppointmentStatsRequest {
  startDate?: string;
  endDate?: string;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** When true, include the grand total for the practice with all filters applied. False otherwise. */
  includeTotal?: boolean;
  /** Fields to filter on. Supported fields: patientId, patientType, primaryInsuranceCarrierId */
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: patientLastName, patientDob, patientType, primaryInsuranceCarrierName, numCompletedAppointments */
  sortOrders?: SortOrder[];
}

export interface PatientAppointmentStatsResponse {
  entries: PatientAppointmentStatsVO[];
  subtotals: object[];
  total?: PatientAppointmentStatsTotalVO;
}

export interface PatientAppointmentStatsTotalVO {
  /** @format int64 */
  totalPatientCount: number;
  /** @format int64 */
  totalCompletedAppointments: number;
}

export interface PatientAppointmentStatsVO {
  patient: NameVO;
  patientDob: string;
  patientType: "NEW" | "EXISTING";
  primaryInsuranceCarrier?: InsuranceCarrierVO;
  /** @format int64 */
  completedAppointments: number;
}

export interface SuccessResponsePatientAppointmentStatsResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientAppointmentStatsResponse;
}

export interface JournalEventRequest {
  startDate?: string;
  endDate?: string;
  includeTotal?: boolean;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** Fields to filter on. Supported fields: eventType, providerId */
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: patientLastName, eventDate, eventType, providerLastName */
  sortOrders?: SortOrder[];
}

export interface JournalEventResponse {
  entries: JournalEventVO[];
  subtotals: object[];
  total?: JournalEventTotalVO;
}

export interface JournalEventTotalVO {
  /** @format int64 */
  totalGrossProductionAmount: number;
  /** @format int64 */
  totalPatientPaymentAmount: number;
  /** @format int64 */
  totalInsurancePaymentAmount: number;
  /** @format int64 */
  totalCollectionAdjustmentAmount: number;
  /** @format int64 */
  totalInsuranceAdjustmentAmount: number;
}

export interface JournalEventVO {
  patient: NameVO;
  eventDate: string;
  eventType:
    | "PRODUCTION"
    | "COLLECTION_ADJUSTMENT"
    | "INSURANCE_ADJUSTMENT"
    | "PATIENT_PAYMENT"
    | "INSURANCE_PAYMENT";
  provider?: NameVO;
  paymentMethod?:
    | "ACH"
    | "CASH"
    | "CARD"
    | "EXTERNAL_CARD"
    | "CHECK"
    | "CARE_CREDIT"
    | "THIRD_PARTY_FINANCING"
    | "WALLET"
    | "BALANCE_TRANSFER";
  adjustmentName?: string;
  adjustmentNote?: string;
  carrier?: InsuranceCarrierVO;
  dentalProcedure?: DentalProcedureVO;
  /** @format int64 */
  amount: number;
}

export interface SuccessResponseJournalEventResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: JournalEventResponse;
}

export interface InsuranceAdjustmentRequest {
  startDate?: string;
  endDate?: string;
  includeTotal?: boolean;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** Fields to filter on. Supported fields: adjustmentState, carrier */
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: patientLastName, insuranceAdjustmentAmount, insuranceOrdinal, serviceDate */
  sortOrders?: SortOrder[];
}

export interface InsuranceAdjustmentEntryVO {
  patient: NameVO;
  /** @format uuid */
  claimUuid?: string;
  serviceDate: string;
  primarySubscriberPatient?: NameVO;
  /** @format int64 */
  insuranceCarrierId: number;
  /** @format int64 */
  patientInsuranceId?: number;
  insuranceOrdinal?: "PRIMARY" | "SECONDARY" | "OTHER";
  insuranceAdjustmentState: "ESTIMATED" | "COLLECTED";
  /** @format int64 */
  insuranceAdjustmentAmount: number;
  /** @format int64 */
  ucrFee: number;
  /** @format int64 */
  netProduction: number;
}

export interface InsuranceAdjustmentResponse {
  entries: InsuranceAdjustmentEntryVO[];
  subtotals: InsuranceAdjustmentSubtotalVO[];
  total?: InsuranceAdjustmentTotalVO;
}

export interface InsuranceAdjustmentSubtotalVO {
  carrier: InsuranceCarrierVO;
  /** @format int64 */
  totalAdjustmentAmount: number;
  /** @format int64 */
  totalUcrFee: number;
  /** @format int64 */
  totalNetProduction: number;
}

export interface InsuranceAdjustmentTotalVO {
  /** @format int64 */
  totalAdjustmentAmount: number;
  /** @format int64 */
  totalUcrFeeAmount: number;
  /** @format int64 */
  totalNetProductionAmount: number;
}

export interface SuccessResponseInsuranceAdjustmentResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InsuranceAdjustmentResponse;
}

export interface InboundReferralRequest {
  startDate?: string;
  endDate?: string;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** Fields to filter on. Supported fields: insuranceCarrierId, referredBy, hasFirstAppointment */
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: patientLastName, insuranceCarrierId, referredBy, firstAppointmentDate */
  sortOrders?: SortOrder[];
}

export interface InboundReferralResponse {
  entries: InboundReferralVO[];
  subtotals: object[];
  total?: object;
}

export interface InboundReferralVO {
  patient: NameVO;
  insuranceCarrier: InsuranceCarrierVO;
  firstAppointmentDate?: string;
  referredBy: string;
  referralInformation?: string;
  /** @format int64 */
  netProduction: number;
}

export interface SuccessResponseInboundReferralResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InboundReferralResponse;
}

export interface CollectionAdjustmentRequest {
  startDate?: string;
  endDate?: string;
  includeTotal?: boolean;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** Fields to filter on. Supported fields: customAdjustmentTypeId */
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: patientLastName, adjustmentDate, customAdjustmentTypeId, adjustmentAmount */
  sortOrders?: SortOrder[];
}

export interface CollectionAdjustmentResponse {
  entries: CollectionAdjustmentVO[];
  subtotals: object[];
  total?: CollectionAdjustmentTotalVO;
}

export interface CollectionAdjustmentTotalVO {
  /** @format int64 */
  totalCollectionAdjustmentAmount: number;
}

export interface CollectionAdjustmentVO {
  patient: NameVO;
  adjustmentDate: string;
  adjustmentName: string;
  /** @format int64 */
  collectionAdjustmentAmount: number;
  adjustmentNote?: string;
  /** @format int64 */
  customAdjustmentTypeId: number;
}

export interface SuccessResponseCollectionAdjustmentResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: CollectionAdjustmentResponse;
}

export interface CollectedPaymentRequest {
  startDate: string;
  endDate: string;
  includeTotal?: boolean;
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: patientLastName, payerType, paymentType, paymentMethod, paymentAmount */
  sortOrders?: SortOrder[];
  patientSearchString?: string;
}

export interface CollectedPaymentByTypeEntryVO {
  /** Type of the payer. Currently, we support PATIENT and INSURANCE_CARRIER. */
  payerType:
    | "PATIENT"
    | "EMPLOYEE"
    | "PRACTICE"
    | "INSURANCE_CARRIER"
    | "FORM_SUBMISSION"
    | "INVOICE"
    | "RECALL"
    | "APPOINTMENT";
  /** Method of the payment, e.g. CASH, CHECK, ACH, CARD can be treated as CARD payments. */
  paymentMethod:
    | "ACH"
    | "CASH"
    | "CARD"
    | "EXTERNAL_CARD"
    | "CHECK"
    | "CARE_CREDIT"
    | "THIRD_PARTY_FINANCING"
    | "WALLET"
    | "BALANCE_TRANSFER";
  /**
   * Total payment amount.
   * @format int64
   */
  totalPaymentAmount: number;
}

export interface CollectedPaymentEntryVO {
  patient: NameVO;
  paymentType: "CHARGE" | "REFUND";
  payerType:
    | "PATIENT"
    | "EMPLOYEE"
    | "PRACTICE"
    | "INSURANCE_CARRIER"
    | "FORM_SUBMISSION"
    | "INVOICE"
    | "RECALL"
    | "APPOINTMENT";
  paymentMethod:
    | "ACH"
    | "CASH"
    | "CARD"
    | "EXTERNAL_CARD"
    | "CHECK"
    | "CARE_CREDIT"
    | "THIRD_PARTY_FINANCING"
    | "WALLET"
    | "BALANCE_TRANSFER";
  paymentDate: string;
  /** @format uuid */
  paymentUuid: string;
  /** @format uuid */
  claimUuid: string;
  /** @format uuid */
  invoiceUuid: string;
  /** @format int64 */
  paymentAmount: number;
  paymentReference: PaymentReference;
  paymentChannel: "PRACTICE" | "ONLINE";
  checkPayload?: CheckPayload;
  externalPosPayload?: ExternalPosPayload;
  eftPayload?: EFTPayload;
}

export interface CollectedPaymentResponse {
  entries: CollectedPaymentEntryVO[];
  subtotals: CollectedPaymentByTypeEntryVO[];
  total?: CollectedPaymentTotalVO;
}

export interface CollectedPaymentTotalVO {
  /** @format int64 */
  totalPaymentAmount: number;
  /** @format int64 */
  totalPatientPaymentAmount: number;
  /** @format int64 */
  totalInsurancePaymentAmount: number;
}

export interface SuccessResponseCollectedPaymentResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: CollectedPaymentResponse;
}

export interface CollectedPaymentByTypeRequest {
  startDate: string;
  endDate: string;
}

export interface CollectedPaymentByTypeResponse {
  entries: CollectedPaymentByTypeEntryVO[];
  total: CollectedPaymentTotalVO;
}

export interface SuccessResponseCollectedPaymentByTypeResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: CollectedPaymentByTypeResponse;
}

export interface CollectedPaymentByProviderRequest {
  startDate: string;
  endDate: string;
  includeTotal?: boolean;
  /** Fields to filter on. Supported fields: providerId, payerType, paymentType, treatingProviderId */
  filters?: Filter[];
  /** Fields to sort the result by. Supported fields: patientLastName, providerLastName, payerType, paymentType, paymentMethod, paymentAmount, paymentDate, treatingProviderLastName */
  sortOrders?: SortOrder[];
  patientSearchString?: string;
}

export interface CollectedPaymentByProviderEntryVO {
  patient: NameVO;
  provider?: NameVO;
  treatingProvider?: NameVO;
  associatedProvider?: NameVO;
  paymentType: "CHARGE" | "REFUND";
  payerType:
    | "PATIENT"
    | "EMPLOYEE"
    | "PRACTICE"
    | "INSURANCE_CARRIER"
    | "FORM_SUBMISSION"
    | "INVOICE"
    | "RECALL"
    | "APPOINTMENT";
  paymentMethod:
    | "ACH"
    | "CASH"
    | "CARD"
    | "EXTERNAL_CARD"
    | "CHECK"
    | "CARE_CREDIT"
    | "THIRD_PARTY_FINANCING"
    | "WALLET"
    | "BALANCE_TRANSFER";
  paymentDate: string;
  /** @format uuid */
  paymentUuid: string;
  /** @format uuid */
  claimUuid?: string;
  /** @format uuid */
  invoiceUuid?: string;
  /** @format int64 */
  paymentAmount: number;
  paymentReference: PaymentReference;
  checkPayload?: CheckPayload;
  externalPosPayload?: ExternalPosPayload;
  eftPayload?: EFTPayload;
}

export interface CollectedPaymentByProviderResponse {
  entries: CollectedPaymentByProviderEntryVO[];
  subtotals: object[];
  total?: CollectedPaymentTotalVO;
}

export interface SuccessResponseCollectedPaymentByProviderResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: CollectedPaymentByProviderResponse;
}

export interface CollectedPaymentByProviderComboEntryVO {
  provider?: NameVO;
  treatingProvider?: NameVO;
  associatedProvider?: NameVO;
  /** @format int64 */
  totalPaymentAmount: number;
  /** @format int64 */
  totalPatientPaymentAmount: number;
  /** @format int64 */
  totalInsurancePaymentAmount: number;
  /** @format int64 */
  patientCount: number;
  /** @format int64 */
  paymentAmountPerPatient: number;
}

export interface CollectedPaymentByProviderComboResponse {
  entries: CollectedPaymentByProviderComboEntryVO[];
  subtotals: object[];
  total?: CollectedPaymentTotalVO;
}

export interface SuccessResponseCollectedPaymentByProviderComboResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: CollectedPaymentByProviderComboResponse;
}

export interface AppointmentBookingRequest {
  startDate?: string;
  endDate?: string;
  /** Optional patient search string as used in other patient search boxes. */
  patientSearchString?: string;
  /** When true, include the grand total for the practice with all filters applied. False otherwise. */
  includeTotal?: boolean;
  /** Fields to filter on. Supported fields: providerId, appointmentState, patientType, dentalProcedureId, providerJobCategory, hasNextAppointment. When hasNextAppointment is specified in conjunction with includeNextAppointmentDentalProcedureIds, the next appointment dental procedure ids will be considered. */
  filters?: Filter[];
  /** Specify the dental procedure ids that the next scheduled appointment should be for. It is not a filter because no matter what values are in this list, the number of items returned will not be reduced. It only adjusts what is being included as the nextScheduledAppointmentId. */
  includeNextAppointmentDentalProcedureIds?: number[];
  /** Fields to sort the result by. Supported fields: patientLastName, providerLastName, appointmentDate, appointmentState, appointmentDuration, patientType, patientProcedureDisplay */
  sortOrders?: SortOrder[];
}

export interface AppointmentBookingResponse {
  entries: AppointmentBookingVO[];
  subtotals: object[];
  total?: AppointmentBookingTotalVO;
}

export interface AppointmentBookingTotalVO {
  /** @format int64 */
  totalDuration: number;
  /** @format int64 */
  distinctCount: number;
}

export interface AppointmentBookingVO {
  /** @format int64 */
  appointmentId: number;
  patient: NameVO;
  provider: NameVO;
  patientType: "NEW" | "EXISTING";
  appointmentState: "SCHEDULED" | "CANCELED" | "COMPLETED" | "NO_SHOW";
  /** @format int64 */
  appointmentDuration: number;
  providerJobCategory: "DENTIST" | "BACK_OFFICE" | "FRONT_OFFICE" | "HYGIENIST" | "PRACTICE";
  appointmentDate: string;
  patientProcedureDisplay: string;
  nextScheduledAppointmentDate?: string;
  /** @format int64 */
  nextScheduledAppointmentId?: number;
}

export interface SuccessResponseAppointmentBookingResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AppointmentBookingResponse;
}

export interface PracticeRemoteSettingsRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  serverUsername?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  serverPassword?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  pmsUsername?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  pmsPassword?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  anydeskAddress?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  anydeskAddress2?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  anydeskAddress3?: string;
}

export interface PracticeConnectedRemoteSettingsVO {
  hasServerUsername: boolean;
  hasServerPassword: boolean;
  hasPmsUsername: boolean;
  hasPmsPassword: boolean;
  anydeskAddress?: string;
  anydeskAddress2?: string;
  anydeskAddress3?: string;
}

export interface SuccessResponsePracticeConnectedRemoteSettingsVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PracticeConnectedRemoteSettingsVO;
}

export interface PublicHolidayVO {
  /** @format int64 */
  id?: number;
  date: string;
  timeRanges?: TimeRangeVO[];
  /**
   * @minLength 0
   * @maxLength 100
   */
  description?: string;
  isClosed: boolean;
}

export interface SuccessResponseListPublicHolidayVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PublicHolidayVO[];
}

export interface ProviderSchedulingRequest {
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  bookingLimitInDays?: number;
  /** @format int64 */
  providerId: number;
}

export interface SuccessResponseProviderVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ProviderVO;
}

export interface ProviderColorRequest {
  /** @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$ */
  color: string;
  /** @format int64 */
  providerId: number;
}

export interface CreateProcedureShortcutRequest {
  /**
   * @minLength 0
   * @maxLength 40
   */
  name: string;
  category:
    | "EXAMS"
    | "XRAYS"
    | "POST_COMPOSITE"
    | "ANT_COMPOSITE"
    | "AMALGAM"
    | "PERIO"
    | "APPLIANCES"
    | "CROWNS_OR_BRIDGES"
    | "ENDO"
    | "IMPLANTS"
    | "ORAL_SURGERY"
    | "ADJUNCT";
  surfaces?: ("M" | "O" | "D" | "I" | "L" | "B" | "F" | "BV" | "FV" | "LV")[];
  procedureIds: number[];
}

export interface ReorderProcedureShortcutsRequest {
  category:
    | "EXAMS"
    | "XRAYS"
    | "POST_COMPOSITE"
    | "ANT_COMPOSITE"
    | "AMALGAM"
    | "PERIO"
    | "APPLIANCES"
    | "CROWNS_OR_BRIDGES"
    | "ENDO"
    | "IMPLANTS"
    | "ORAL_SURGERY"
    | "ADJUNCT";
  shortcutUuids: string[];
}

export interface SuccessResponseListProcedureShortcutVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ProcedureShortcutVO[];
}

export interface DosespotSingleSignOnVO {
  dosespotClinicId: string;
  dosespotClinicianId: string;
  dosespotPatientId: string;
  dosespotEncodedSingleSignOnCode: string;
  dosespotEncodedSingleSignOnUserVerify: string;
  dosespotSingleSignOnUri: string;
}

export interface SuccessResponseDosespotSingleSignOnVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: DosespotSingleSignOnVO;
}

export interface PracticeFeatureStateVO {
  practiceFeature: PracticeFeatureVO;
  /** @format int64 */
  practiceId: number;
  /** @format uuid */
  practiceUuid: string;
  isEnabled: boolean;
}

export interface PracticeFeatureVO {
  /** @format int64 */
  id: number;
  type: "PEARL_AI" | "AUTO_ELIGIBILITY";
  friendlyName: string;
}

export interface SuccessResponsePracticeFeatureStateVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PracticeFeatureStateVO;
}

export interface BatchUpdatePracticeFeatureStateRequest {
  updatePracticeFeatureStates: UpdatePracticeFeatureState[];
}

export interface UpdatePracticeFeatureState {
  /** @format int64 */
  practiceFeatureId: number;
  isEnabled: boolean;
}

export interface SuccessResponseListPracticeFeatureStateVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PracticeFeatureStateVO[];
}

export interface EmployeeConsolidationRequest {
  active: MergeEmployeeRequest[];
  archived: MergeEmployeeRequest[];
}

export interface MergeEmployeeRequest {
  firstName: string;
  lastName: string;
  middleName?: string;
  jobTitle: string;
  /** @format int64 */
  archyId?: number;
  /** @format uuid */
  gustoId?: string;
}

export interface GustoPracticeFlowRequest {
  flowTypes: (
    | "EOY_REVIEW"
    | "RUN_PAYROLL"
    | "PAYROLL_RECEIPT"
    | "COMPANY_ONBOARDING"
    | "ADD_ADDRESSES"
    | "FEDERAL_TAX_SETUP"
    | "SELECT_INDUSTRY"
    | "ADD_BANK_INFO"
    | "VERIFY_BANK_INFO"
    | "ADD_EMPLOYEES"
    | "ADD_CONTRACTORS"
    | "STATE_SETUP"
    | "PAYROLL_SCHEDULE"
    | "SIGN_ALL_FORMS"
    | "EXTERNAL_PAYROLL"
    | "CONTRACTOR_PAYMENTS"
  )[];
}

export interface GustoFlowVO {
  url: string;
}

export interface SuccessResponseGustoFlowVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: GustoFlowVO;
}

export interface BankAccountOptions {
  type: "CHECKING" | "SAVINGS";
  /** @pattern ^[A-Z]{3}$ */
  country: string;
  accountNumber: string;
  /**
   * @minLength 9
   * @maxLength 9
   */
  routingNumber: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  name: string;
}

export interface CardOptions {
  healthBenefit?: boolean;
}

export interface CreatePaymentProfileByTokenRequest {
  owner: EntityReferenceVO;
  type: "CARD" | "BANK_ACCOUNT";
  token?: string;
  gateway: string;
  markAsDefault?: boolean;
  cardOptions?: CardOptions;
  bankAccountOptions?: BankAccountOptions;
}

export interface CreatePaymentDeviceRequest {
  /**
   * Nickname of the device. If you have multiple devices, please name them so that they are easily identifiable.
   * @minLength 0
   * @maxLength 50
   */
  name: string;
  model: "LINK_2500" | "LANE_3000" | "LANE_5000" | "LANE_7000" | "LANE_8000" | "PAX_A920PRO" | "SUNMI_P2";
  /**
   * Unique device manufacture identifier typically shown at the back of the device.
   * @minLength 0
   * @maxLength 100
   */
  serialNumber: string;
}

export interface CreatePatientRequest {
  personalDetails: PersonalDetailsVO;
  contactDetails: ContactDetailsVO;
  insuranceDetails: InsuranceDetailsVO;
  additionalInformation?: AdditionalInfoVO;
}

export interface SuccessResponseListPatientVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientVO[];
}

export interface WalletCreationRequest {
  /** @format uuid */
  idempotencyUuid: string;
  /**
   * @minLength 0
   * @maxLength 25
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  note?: string;
  /** @pattern ^[A-Z]{3}$ */
  currency: string;
  type: "MEMBERSHIP_PLAN" | "PROCEDURE" | "DEFAULT";
}

export interface SuccessResponseWalletVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: WalletVO;
}

export interface WalletVO {
  /** @format uuid */
  uuid: string;
  name: string;
  note?: string;
  /** @format int64 */
  patientId: number;
  /** @format int64 */
  practiceId: number;
  /** @format uuid */
  practiceUuid: string;
  currency: string;
  /** @format int64 */
  balance: number;
  type: "MEMBERSHIP_PLAN" | "PROCEDURE" | "DEFAULT";
  isFamily: boolean;
}

export interface PaymentCreationRequest {
  type: "CHARGE" | "REFUND";
  method:
    | "STORED_PROFILE"
    | "CASH"
    | "CHECK"
    | "STORED_POS"
    | "EXTERNAL_POS"
    | "EFT"
    | "WALLET"
    | "CARE_CREDIT"
    | "THIRD_PARTY_FINANCING"
    | "BALANCE_TRANSFER";
  /** The family payment amount if this payment is associated with a family payment. */
  currencyAmount: CurrencyAmount;
  /** @format uuid */
  idempotencyUuid: string;
  note?: string;
  /** @format int64 */
  paymentCreatedAt?: number;
  /** @format uuid */
  paymentProfileUuid?: string;
  /** @format uuid */
  paymentDeviceUuid?: string;
  /** @format uuid */
  chargePaymentUuid?: string;
  /** @format uuid */
  walletUuid?: string;
  checkPayload?: CheckPayload;
  externalPosPayload?: ExternalPosPayload;
  eftPayload?: EFTPayload;
  confirmationEmail?: string;
}

export interface WalletRefundRequest {
  paymentCreationRequest: PaymentCreationRequest;
  /**
   * @minLength 0
   * @maxLength 100
   */
  note: string;
}

export interface SuccessResponseWalletActivityVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: WalletActivityVO;
}

export interface WalletActivityVO {
  /** @format int64 */
  id: number;
  /** The family payment amount if this payment is associated with a family payment. */
  currencyAmount: CurrencyAmount;
  /** @format uuid */
  walletUuid: string;
  walletActivityType: "WITHDRAW" | "TOP_UP" | "CREDIT" | "REFUND";
  note?: string;
  walletActivityState: "PENDING" | "VOID" | "FINALIZED" | "FAILED";
  /** @format int64 */
  patientId: number;
  /** @format int64 */
  practiceId: number;
  /** @format uuid */
  practiceUuid: string;
  /** @format int64 */
  activityCreatedAt: number;
  payment?: PaymentVO;
}

export interface WalletTopupRequest {
  walletCreationRequest?: WalletCreationRequest;
  /** @format uuid */
  walletUuid?: string;
  paymentCreationRequest: PaymentCreationRequest;
  /**
   * @minLength 0
   * @maxLength 100
   */
  note: string;
}

export interface StatementVO {
  /** @format uuid */
  uuid: string;
  asOfDate: string;
  startDate: string;
  endDate: string;
  /** @format int64 */
  documentId?: number;
}

export interface SuccessResponseStatementVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: StatementVO;
}

export interface CreateRecallRequest {
  /**
   * Note
   * @minLength 0
   * @maxLength 500
   */
  note?: string;
  /**
   * Interval in days
   * @format int32
   */
  intervalDays: number;
  /**
   * Interval in months
   * @format int32
   */
  intervalMonths: number;
  dueDate?: string;
  /** Type of recall exam */
  type: "EXAM" | "PROPHY" | "FOUR_BW" | "PANO" | "FLUORIDE" | "PERIO" | "FMX";
}

export interface RecallVO {
  type: "EXAM" | "PROPHY" | "FOUR_BW" | "PANO" | "FLUORIDE" | "PERIO" | "FMX";
  /** @format uuid */
  uuid?: string;
  /** @format int32 */
  intervalDays?: number;
  /** @format int32 */
  intervalMonths?: number;
  dueDate?: string;
  isDue?: boolean;
  dueSoon?: boolean;
  previousDate?: string;
  scheduledDate?: string;
  /** @format int64 */
  previousAppointmentId?: number;
  /** @format int64 */
  scheduledAppointmentId?: number;
  note?: string;
  dueDateOverridden: boolean;
}

export interface SuccessResponseRecallVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: RecallVO;
}

export interface CreatePerioChartExamRequest {
  /**
   * The respective Dentist or Hygienist provider conducting the exam.
   * @format int64
   */
  providerId: number;
  date?: string;
  /**
   * @minLength 0
   * @maxLength 5000
   */
  note?: string;
}

export interface PerioChartExamVO {
  /** @format uuid */
  uuid: string;
  date: string;
  note?: string;
  provider: NameVO;
  /**
   * Teeth to show as missing on the perio chart. Built from the state of the teeth at the time of the exam.
   * @deprecated
   * @uniqueItems true
   */
  missingTeeth: number[];
  upperToothConfig: PerioChartToothConfigVO[];
  lowerToothConfig: PerioChartToothConfigVO[];
  /** If no entries are available, then an empty array is returned. */
  entries: PerioChartExamEntryVO[];
}

export interface PerioChartToothConfigVO {
  /** @format int32 */
  toothNum: number;
  label: string;
  missing: boolean;
  implant: boolean;
}

export interface SuccessResponsePerioChartExamVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PerioChartExamVO;
}

export interface PearlAnalysisRequest {
  /** @uniqueItems true */
  medicalImageIds?: number[];
  /** @format int64 */
  mountId?: number;
}

export interface PearlAnalysisMedicalImageRequestVO {
  /** @format int64 */
  medicalImageId: number;
  status: "NOT_SUBMITTED" | "SUBMITTED" | "DONE" | "REJECTED";
  /** Error message if pearl rejected the analysis request. If the request was successful, this will be null. */
  errorMessage?: string;
}

export interface PearlAnalysisRequestStatusVO {
  /**
   * Will be populated if a mount was provided in the request.
   * @format int64
   */
  mountId?: number;
  medicalImageRequestStatuses: PearlAnalysisMedicalImageRequestVO[];
}

export interface SuccessResponsePearlAnalysisRequestStatusVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PearlAnalysisRequestStatusVO;
}

export interface BatchCreatePatientProcedureRequest {
  patientProcedures: CreatePatientProcedureRequest[];
}

export interface CreatePatientNoteRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 65535
   */
  text: string;
  type?: "CHART" | "GENERAL";
  status?: "INCOMPLETE" | "DRAFT" | "COMPLETED";
  tagIds?: number[];
  isAlert?: boolean;
}

export interface BatchConfirmPatientOnboardingFileRequest {
  requests: ConfirmPatientOnboardingFileRequest[];
}

export interface ConfirmPatientOnboardingFileRequest {
  formSlug: "DENTAL_HISTORY" | "MEDICAL_HISTORY" | "CONSENT" | "TREATMENT_PLAN_FORM" | "APPOINTMENT_FORM";
  encryptedFileKey: string;
}

export interface ConsentInfoVO {
  consentData?: Record<string, string>;
  signatures?: SignatureVO[];
}

export interface GeneralInfoVO {
  personalDetails: PersonalDetailsVO;
  contactDetails: ContactDetailsVO;
  referredBy?: string;
}

export interface PatientMedicalHistoryVO {
  medicalConditions?: number[];
  dentalConditions?: number[];
  smileAnalysis?: QuestionVO[];
  questions: QuestionVO[];
  signature?: SignatureVO;
  /** @format date-time */
  date?: string;
}

export interface QuestionVO {
  /** @format int64 */
  id: number;
  additionalInfo?: string;
}

export interface UpdateOnboardingInfoRequest {
  generalInfo?: GeneralInfoVO;
  insuranceInfo?: InsuranceInfoVO;
  dentalHistoryInfo?: PatientMedicalHistoryVO;
  medicalHistoryInfo?: PatientMedicalHistoryVO;
  consentInfo?: ConsentInfoVO;
  paymentProfileInfo?: CreatePaymentProfileByTokenRequest;
  batchConfirmFile?: BatchConfirmPatientOnboardingFileRequest;
}

export interface MedicalConditionsVO {
  /** @format int64 */
  id: number;
  name?: string;
  shortName?: string;
  additionalInfo?: string;
  isSevere?: boolean;
}

export interface MedicalHistoryResponseVO {
  medicalConditions?: MedicalConditionsVO[];
  dentalConditions?: MedicalConditionsVO[];
  smileAnalysis?: MedicalConditionsVO[];
  questions?: MedicalConditionsVO[];
}

export interface OnboardingInfoVO {
  generalInfo?: GeneralInfoVO;
  insuranceInfo?: InsuranceInfoVO;
  paymentProfileInfo?: PaymentProfileVO;
  medicalHistoryInfo?: MedicalHistoryResponseVO;
  dentalHistoryInfo?: MedicalHistoryResponseVO;
  consentInfo?: ConsentInfoVO;
  nextOnboardingState:
    | "GENERAL_INFO"
    | "INSURANCE_INFO"
    | "DENTAL_HISTORY_INFO"
    | "MEDICAL_HISTORY_INFO"
    | "PAYMENT_INFO"
    | "CONSENT"
    | "PDF_SUBMISSION"
    | "COMPLETED";
}

export interface SuccessResponseOnboardingInfoVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: OnboardingInfoVO;
}

export interface MultiInvoicePaymentRequest {
  payment?: PaymentCreationRequest;
  /**
   * If not provided, all FINALIZED and PARTIALLY_PAID invoices will be considered in the calculation.
   * Note that if a payment is provided, this list MUST be populated.
   */
  invoiceUuids?: string[];
  /**
   * Provide false to run a calculation without committing the payment. Provide true to commit the payment.
   * Note that if providing true, invoiceUuids must be provided.
   */
  commit: boolean;
  /**
   * Used to support the when the only field in the request is: { "commit": false }.
   * If true, all open invoices for the patient and their family will be returned. If false, only the invoices
   * associated with the patient will be returned.
   */
  includeFamily?: boolean;
}

/**
 * The allocations of the payment and creditable invoices.
 * Note that this is only populated for display purposes when performing calculations.
 */
export interface MultiInvoicePaymentAllocationVO {
  /** @format uuid */
  invoiceUuid: string;
  /**
   * The total amount of credits applied to this invoice.
   * @format int64
   */
  totalCreditAmount: number;
  /**
   * The total amount due on the invoice after the credits are applied.
   * @format int64
   */
  totalAmountDue: number;
  /**
   * The amount allocated from the payment to the invoice.
   * @format int64
   */
  paymentAllocationAmount: number;
  /**
   * The amount remaining on the invoice after the payment allocation and credits are applied.
   * @format int64
   */
  totalAmountRemaining: number;
}

export interface MultiInvoicePaymentVO {
  payment: PaymentVO;
  /** The invoices the payment applies to. */
  invoices: InvoiceVO[];
  /**
   * The maximum amount of the payment that can be collected.
   * @format int64
   */
  maxPaymentAmount?: number;
  /**
   * The allocations of the payment and creditable invoices.
   * Note that this is only populated for display purposes when performing calculations.
   */
  allocations?: MultiInvoicePaymentAllocationVO[];
}

export interface SuccessResponseMultiInvoicePaymentVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MultiInvoicePaymentVO;
}

export interface EditMultiInvoicePaymentRequest {
  /** If commit is true, then payment must be provided. */
  payment?: PaymentCorrectionRequest;
  /** Provide false to run a calculation without committing the payment correction. Provide true to commit the payment. */
  commit: boolean;
}

/** If commit is true, then payment must be provided. */
export interface PaymentCorrectionRequest {
  /**
   * @deprecated
   * @format uuid
   */
  paymentUuid?: string;
  /** The family payment amount if this payment is associated with a family payment. */
  currencyAmount?: CurrencyAmount;
  note: string;
  /** @format int64 */
  paymentCreatedAt?: number;
  externalPosPayload?: ExternalPosPayload;
  checkPayload?: CheckPayload;
  eftPayload?: EFTPayload;
}

export interface GenerateMountRequest {
  name: string;
  layout: string;
}

export interface SuccessResponseMedicalImageVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MedicalImageVO;
}

export interface UploadMedicalImageV2Request {
  /** @format int32 */
  positionI: number;
  /** @format int64 */
  positionX: number;
  /** @format int64 */
  positionY: number;
  /** @format double */
  height: number;
  /** @format double */
  width: number;
  type: "X_RAY" | "PHOTO" | "EXTERNAL";
  encryptedFileKey: string;
  contentType: string;
  fileName: string;
  sensor?: string;
  teeth?: string[];
}

export interface MessageRequest {
  /**
   * @minLength 0
   * @maxLength 600
   */
  content: string;
  type: "CHAT" | "EMAIL" | "CALL" | "SMS" | "MAIL";
  /** @format int64 */
  appointmentId?: number;
  subject?: string;
  /** @format uuid */
  paymentUuid?: string;
  /** @format uuid */
  messageCampaignUuid?: string;
  emailFallbackText?: string;
  /** @format uuid */
  gustoNotificationUuid?: string;
}

export interface MessageReadRequest {
  isRead?: boolean;
  type?: "CHAT" | "EMAIL" | "CALL" | "SMS" | "MAIL";
}

export interface PatientInsuranceVO {
  /** @format int64 */
  id: number;
  /** @format int64 */
  patientId: number;
  type: "PRIMARY_SUBSCRIBER" | "DEPENDENT" | "NONE";
  insuranceOrdinal: "PRIMARY" | "SECONDARY" | "OTHER";
  startDate?: string;
  expiryDate?: string;
  insuranceState: "ACTIVE" | "INACTIVE" | "ARCHIVED";
  relationshipWithSubscriber?:
    | "SELF"
    | "PARENT"
    | "CHILD"
    | "SPOUSE"
    | "SIBLING"
    | "GRANDPARENT"
    | "GRANDCHILD"
    | "OTHER";
  eligibilityLastVerifiedAt?: string;
  eligibilityLastVerifiedBy?: NameVO;
  releaseOfPatientInfo: boolean;
  eligibilityVerifiedStatus?: "VERIFIED" | "AUTO_VERIFIED" | "NOT_VERIFIED" | "FAILED";
  assignmentOfBenefits: boolean;
  /** @format int64 */
  annualDeductibleRemaining?: number;
  /** @format int64 */
  annualMaximumRemaining?: number;
  /** @format int64 */
  lifetimeDeductibleRemaining?: number;
  /** @format int64 */
  lifetimeMaximumRemaining?: number;
  /** @format int64 */
  orthoMaximumRemaining?: number;
  insuranceNetworkType: "IN_NETWORK" | "OUT_OF_NETWORK";
  subscriber: SubscriberVO;
  autoEligibilityLastErrorAt?: string;
  autoEligibilityErrorMessage?: string;
}

export interface SubscriberVO {
  /** @format int64 */
  id: number;
  firstName?: string;
  lastName?: string;
  dob?: string;
  /** @format int64 */
  patientId: number;
  carrier: string;
  /** @format int64 */
  carrierId: number;
  employer?: string;
  externalMemberId?: string;
  ssnLastFour?: string;
  notes?: string;
  /** @format uuid */
  insurancePlanUuid?: string;
  /** @format int64 */
  annualDeductibleRemaining?: number;
  /** @format int64 */
  annualMaximumRemaining?: number;
}

export interface SuccessResponsePatientInsuranceVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientInsuranceVO;
}

export interface InsuranceVerifyRequest {
  status: "VERIFIED" | "AUTO_VERIFIED" | "NOT_VERIFIED" | "FAILED";
}

export interface UnlinkInsurancePlanRequest {
  /** @format uuid */
  insurancePlanUuid: string;
}

export interface BenefitCoverageVO {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  insurancePlanUuid: string;
  /** @format int32 */
  startCdtCodeRange: number;
  /** @format int32 */
  endCdtCodeRange: number;
  benefitCategory: string;
  /** @format int32 */
  percentCoverage: number;
  applyDeductible: boolean;
  /** @format int64 */
  copayAmount: number;
  preauthRequired: boolean;
  /** @format int32 */
  waitingPeriodInMonths: number;
  autoEligibility: boolean;
  isDefault?: boolean;
}

export interface BenefitLimitationVO {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  insurancePlanUuid: string;
  /** @format int32 */
  startCdtCodeRange?: number;
  /** @format int32 */
  endCdtCodeRange?: number;
  frequencyLimitationCategory?: FrequencyLimitationCategoryVO;
  serviceName: string;
  /** @format int32 */
  waitingPeriodInMonths?: number;
  downgradeProcedure?: DentalProcedureVO;
  /** @format int32 */
  ageMax?: number;
  /** @format int32 */
  ageMin?: number;
  sameDay?: boolean;
  notes?: string;
  frequency?: FrequencyLimitVO;
  autoEligibility: boolean;
}

export interface FrequencyLimitVO {
  /** @format int32 */
  frequencyQuantity: number;
  /** @format int32 */
  durationQuantity: number;
  durationType: "MONTH" | "QUARTER" | "YEAR" | "LIFETIME";
  limitationType: "SERVICE_DATE" | "BENEFIT_YEAR" | "CALENDAR_YEAR";
  mouthConstraint?: "QUADRANT" | "ARCH" | "TOOTH" | "SURFACE";
}

export interface FrequencyLimitationCategoryVO {
  /** @format int64 */
  id: number;
  name: string;
  group: "DIAGNOSTIC" | "PREVENTIVE" | "RESTORATIVE" | "PERIODONTAL" | "PROSTHODONTICS" | "IMPLANTS" | "NONE";
  defaultFrequencyLimit?:
    | "X_VISITS_PER_BENEFIT_YEAR"
    | "ONE_VISIT_EVERY_X_YEARS"
    | "ONE_VISIT_EVERY_X_MONTHS"
    | "ONE_VISIT_EVERY_X_YEARS_PER_TOOTH"
    | "ONE_VISIT_EVERY_X_MONTHS_PER_QUADRANT"
    | "ONE_VISIT_EVERY_X_YEARS_PER_ARCH";
  dentalProcedures: DentalProcedureVO[];
  isDefault: boolean;
  isHidden: boolean;
}

export interface PatientInsuranceResponse {
  patientInsurance: PatientInsuranceVO;
  insurancePlan?: InsurancePlanVO;
  benefitCoverages?: BenefitCoverageVO[];
  benefitLimitations?: BenefitLimitationVO[];
  procedureHistory: RecentProcedureHistoryItemVO[];
  shortcutBenefitLimitations: ShortcutBenefitLimitationVO[];
}

export interface RecentProcedureHistoryItemVO {
  /** @format int64 */
  id?: number;
  group:
    | "BITEWINGS"
    | "FMX_PANO"
    | "EXAM"
    | "PROPHY"
    | "SRP_UR"
    | "SRP_UL"
    | "SRP_LR"
    | "SRP_LL"
    | "PERIO_MAINTENANCE"
    | "FULL_DEBRIDEMENT";
  /** Display friendly group name. */
  groupName: string;
  /** @format int64 */
  patientProcedureId?: number;
  dentalProcedure?: DentalProcedureVO;
  date?: string;
  autoEligibility: boolean;
}

export interface ShortcutBenefitLimitationVO {
  /** @format uuid */
  uuid?: string;
  serviceName: string;
  frequencyLimitationCategory: FrequencyLimitationCategoryVO;
  frequency?: FrequencyLimitVO;
  /** @format int32 */
  ageMin?: number;
  /** @format int32 */
  ageMax?: number;
  autoEligibility: boolean;
}

export interface SuccessResponsePatientInsuranceResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientInsuranceResponse;
}

export interface BatchUpdateRecentProcedureHistoryRequest {
  requests: UpdateRecentProcedureHistoryItemRequest[];
}

export interface UpdateRecentProcedureHistoryItemRequest {
  /**
   * Must be provided if the target group has an identifiable recent entry.
   * @format int64
   */
  id?: number;
  group:
    | "BITEWINGS"
    | "FMX_PANO"
    | "EXAM"
    | "PROPHY"
    | "SRP_UR"
    | "SRP_UL"
    | "SRP_LR"
    | "SRP_LL"
    | "PERIO_MAINTENANCE"
    | "FULL_DEBRIDEMENT";
  date?: string;
  autoEligibility?: boolean;
}

export interface SuccessResponseListRecentProcedureHistoryItemVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: RecentProcedureHistoryItemVO[];
}

export interface LinkInsurancePlanRequest {
  /** @format uuid */
  insurancePlanUuid: string;
}

export interface AutoVerifyEligibilityRequest {
  eligibilityOnly?: boolean;
  groupNumber?: string;
}

export interface InsuranceBySubscriberIdRequest {
  /** @format int64 */
  patientInsuranceSubscriberId: number;
  relationToSubscriber?:
    | "SELF"
    | "PARENT"
    | "CHILD"
    | "SPOUSE"
    | "SIBLING"
    | "GRANDPARENT"
    | "GRANDCHILD"
    | "OTHER";
}

export type FormBooleanResponseVO = UtilRequiredKeys<FormResponseVO, "type"> & {
  response?: boolean;
  type: "BOOLEAN";
};

export type FormConsentResponseVO = UtilRequiredKeys<FormResponseVO, "type"> & {
  signature: string;
  /** @format date-time */
  consentedAt: string;
  type: "CONSENT";
};

export type FormDateResponseVO = UtilRequiredKeys<FormResponseVO, "type"> & {
  response?: string;
  type: "DATE";
};

export type FormNumberResponseVO = UtilRequiredKeys<FormResponseVO, "type"> & {
  response?: number;
  type: "NUMBER";
};

export interface FormResponseVO {
  tag?:
    | "PREGNANCY"
    | "ALLERGIES"
    | "DENTAL_CONDITIONS"
    | "MEDICAL_CONDITIONS"
    | "PREMED"
    | "PROCEDURE_TABLE"
    | "APPOINTMENT_DETAILS"
    | "ESTIMATE_DISCLAIMER";
  type: "DATE" | "NUMBER" | "STRING" | "BOOLEAN" | "SELECT" | "CONSENT";
}

export type FormSelectResponseVO = UtilRequiredKeys<FormResponseVO, "type"> & {
  responses: Record<string, boolean>;
  other?: string;
  type: "SELECT";
};

export type FormStringResponseVO = UtilRequiredKeys<FormResponseVO, "type"> & {
  response?: string;
  type: "STRING";
};

export interface FormSubmissionVO {
  /** @format uuid */
  formPublishedContentUuid: string;
  dateUpdatedByPractice?: string;
  dateUpdatedByPatient?: string;
  form: FormVO;
  responses: Record<
    string,
    | FormBooleanResponseVO
    | FormConsentResponseVO
    | FormDateResponseVO
    | FormNumberResponseVO
    | FormSelectResponseVO
    | FormStringResponseVO
  >;
}

export interface SuccessResponseFormSubmissionVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FormSubmissionVO;
}

export interface CreateFormTaskRequest {
  /** @format uuid */
  uuid: string;
  /**
   * @deprecated
   * @format uuid
   */
  formUuid?: string;
  formUuidOrSlug?: string;
  /** @format int64 */
  appointmentId?: number;
  /** @format uuid */
  treatmentPlanUuid?: string;
  patientProcedureIds?: number[];
}

export interface AddFamilyMemberRequest {
  type?: string;
  relation: "SELF" | "PARENT" | "CHILD" | "SPOUSE" | "SIBLING" | "GRANDPARENT" | "GRANDCHILD" | "OTHER";
  existingPatient?: ExistingPatient;
  newPatient?: CreatePatientRequest;
  permissions?: Permissions;
}

export interface ExistingPatient {
  /** @format int64 */
  id: number;
}

export interface Permission {
  hasPhiAccess?: boolean;
  isGuardian?: boolean;
}

export interface Permissions {
  member?: Permission;
  patient?: Permission;
}

export interface FamilyRelationVO {
  /** @format int64 */
  id: number;
  /** @format int64 */
  familyMemberPatientId: number;
  /** Determines if the family member has phi access on the patient. */
  hasFamilyMemberPhiAccess?: boolean;
  /** Determines if the family member is a guardian to the patient. */
  isFamilyMemberGuardian?: boolean;
  relation: "SELF" | "PARENT" | "CHILD" | "SPOUSE" | "SIBLING" | "GRANDPARENT" | "GRANDCHILD" | "OTHER";
  displayName: string;
  /** @format int64 */
  age?: number;
  gender?: "MALE" | "FEMALE" | "OTHER" | "UNKNOWN";
  insuranceType?: "PRIMARY_SUBSCRIBER" | "DEPENDENT" | "NONE";
  thumbnail?: string;
  /** @format int64 */
  patientBalance?: number;
  firstName: string;
  lastName: string;
}

export interface SuccessResponseFamilyRelationVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FamilyRelationVO;
}

export interface CallRequest {
  extension: string;
}

export interface MessageVO {
  /** @format int64 */
  id: number;
  content: string;
  type: "CHAT" | "EMAIL" | "CALL" | "SMS" | "MAIL";
  /** @format int64 */
  timestamp: number;
  isOutgoing: boolean;
  isRead: boolean;
  state: "SCHEDULED" | "PENDING" | "ACCEPTED" | "SENT" | "DELIVERED" | "FAILED";
  subject?: string;
  errorMessage?: string;
  /** @format int64 */
  attachmentFolderId?: number;
  attachmentDocumentIds?: number[];
  sender: SenderVO;
  fromExtension?: string;
}

export interface SenderVO {
  /** @format uuid */
  actionUuid?: string;
  /** @format uuid */
  messageCampaignUuid?: string;
  /** @format int64 */
  employeeId?: number;
  displayName: string;
}

export interface SuccessResponseMessageVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MessageVO;
}

export interface CreateAdjustmentRequest {
  /** @format uuid */
  idempotencyUuid: string;
  /** The family payment amount if this payment is associated with a family payment. */
  currencyAmount: CurrencyAmount;
  /** @format int64 */
  orderCreatedAt?: number;
  /** @format int64 */
  employeeId?: number;
  /**
   * @minLength 0
   * @maxLength 500
   */
  note: string;
  /** @format int64 */
  customAdjustmentTypeId: number;
}

export interface AdjustmentVO {
  /** @format uuid */
  uuid: string;
  /** @format int64 */
  adjustmentCreatedAt: number;
  /** @format int64 */
  patientId: number;
  /** @format int64 */
  practiceId: number;
  /** @format uuid */
  practiceUuid: string;
  currency: string;
  /** @format int64 */
  employeeId?: number;
  /** @format int64 */
  amount: number;
  note?: string;
  description: string;
  customAdjustmentType: CustomAdjustmentTypeVO;
}

export interface SuccessResponseAdjustmentVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AdjustmentVO;
}

export interface PatientLookupRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  firstName?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  lastName?: string;
  dob: string;
  subscriberId?: string;
  /** @pattern ^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$ */
  ssn?: string;
  /** @format int64 */
  carrierId?: number;
}

export interface PatientSummaryVO {
  /** @format int64 */
  id: number;
  name: NameVO;
  namePronunciation?: string;
  dob: string;
  status: "PROSPECT" | "ACTIVE" | "INACTIVE" | "ARCHIVED" | "NONPATIENT" | "DECEASED";
  ssnLastFour?: string;
  nextAppointment?: string;
  /** @format int64 */
  completedVisits: number;
  /** @format int64 */
  balanceAmount: number;
  /** @format int64 */
  primaryInsuranceId?: number;
  primaryInsuranceCarrier?: string;
  /** @format int64 */
  primaryInsuranceCarrierId?: number;
  primaryInsuranceEligibilityVerifiedStatus?: "VERIFIED" | "AUTO_VERIFIED" | "NOT_VERIFIED" | "FAILED";
  primaryInsuranceExpiryDate?: string;
  /** @format int64 */
  primaryProviderId?: number;
  gender?: "MALE" | "FEMALE" | "OTHER" | "UNKNOWN";
  thumbnail?: string;
  contact: ContactVO;
}

export interface SuccessResponsePatientSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientSummaryVO;
}

export interface PatientListRequest {
  searchString?: string;
  sortColumn?: string;
  orderBy?: string;
  patientCriteria?: PatientCriteriaVO;
  appointmentDateRange?: LocalDateRangeFilter;
  hasNoAppointment?: boolean;
  /**
   * @format int32
   * @min 0
   */
  appointmentWithinDays?: number;
}

export interface SuccessResponseListPatientSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientSummaryVO[];
}

export interface BatchAutoVerifyEligibilityRequest {
  /** @uniqueItems true */
  patientInsuranceIds?: number[];
  dailyHuddleRequest?: DailyHuddleRequest;
  eligibilityOnly?: boolean;
}

export interface PearlOrganizationProcessAgreementsUrlsRequired {
  msa_url?: string;
  dpa_url?: string;
  privacy_policy_url?: string;
  baa_url?: string;
}

export interface CreateMigrationRunRequest {
  sourceName: string;
  sourceVersion: string;
}

export interface MigrationRunVO {
  /** @format int64 */
  id: number;
  /** @format int64 */
  practiceId: number;
  /** @format uuid */
  practiceUuid: string;
  sourceName: string;
  sourceVersion: string;
  checkpoint?: string;
  /** @format int64 */
  lastCheckpointAt?: number;
  state: "CREATED" | "FINISHED";
}

export interface SuccessResponseMigrationRunVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MigrationRunVO;
}

export interface CreateMigrationSourceTargetLinkRequest {
  sourceSchema: string;
  sourceId: string;
  targetSchema: string;
  targetId: string;
}

export interface CreateMigrationSourceEntryRequest {
  sourceId: string;
  sourceSchema: string;
  sourceEntity: string;
  shouldEncrypt: boolean;
}

export interface SeekMigrationRunRequest {
  stepName: string;
  /** @format int64 */
  sourceEntryId: number;
}

export interface ExecuteMigrationRunRequest {
  /** @format int32 */
  allowances: number;
  /** @format int32 */
  parallelism?: number;
  notificationHandles?: string[];
  sendNotifications?: boolean;
  steps?: string[];
}

export interface BatchCreateMigrationEntriesRequest {
  entryRequests: CreateMigrationSourceEntryRequest[];
}

export interface MessageRetryRequest {
  /** @format int64 */
  timestamp: number;
}

export interface MessageCampaignRequest {
  /**
   * @minLength 0
   * @maxLength 600
   */
  template: string;
  filter:
    | MessageCampaignAsapCriteria
    | MessageCampaignHuddleCriteria
    | MessageCampaignPatientCriteria
    | MessageCampaignRecallReportCriteria
    | MessageCampaignUncollectedReportCriteria;
  /** @format uuid */
  messageCampaignUuid: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name?: string;
  /** @format int64 */
  scheduledAt?: number;
  channel?: "PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL";
  emailTemplate?: EmailTemplate;
}

export interface PreviewMessageCampaignRequest {
  email?: string;
}

export interface TwilioPhoneNumberPurchaseRequest {
  /** @pattern ^\+1\d{10}$ */
  phoneNumberToPurchase: string;
}

export interface MangoAuthorizationRequest {
  key: string;
}

export interface BatchCreateLicenseRequest {
  licenseRequests: LicenseRequest[];
}

export interface LicenseRequest {
  feature:
    | "CUSTOM_APPOINTMENT_REMINDER"
    | "CUSTOM_FORMS"
    | "SELF_SCHEDULING"
    | "TWO_WAY_TEXTING"
    | "MMS_TEXTING"
    | "MASS_TEXTING"
    | "ERX"
    | "REPORTING"
    | "TIME_TRACKING"
    | "PAYROLL";
  expiryDate?: string;
}

export interface LicenseVO {
  /** @format uuid */
  uuid: string;
  feature:
    | "CUSTOM_APPOINTMENT_REMINDER"
    | "CUSTOM_FORMS"
    | "SELF_SCHEDULING"
    | "TWO_WAY_TEXTING"
    | "MMS_TEXTING"
    | "MASS_TEXTING"
    | "ERX"
    | "REPORTING"
    | "TIME_TRACKING"
    | "PAYROLL";
  expiryDate?: string;
}

export interface SuccessResponseListLicenseVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LicenseVO[];
}

export interface PreviewSmsAutomationRequest {
  /**
   * @minLength 0
   * @maxLength 400
   * @pattern ^[0-9]{10}$
   */
  phone: string;
  smsTemplate: string;
}

export interface PreviewEmailAutomationRequest {
  email: string;
  subject?: string;
  /**
   * @minLength 0
   * @maxLength 5000
   */
  emailTemplate?: string;
  customEmailTemplate?: EmailTemplate;
}

export interface CreateActionRequest {
  /**
   * @format int32
   * @min 1
   */
  interval?: number;
  intervalType?: "HOUR" | "DAY" | "WEEK";
  eventTime: "BEFORE" | "WHEN" | "AFTER";
  subject?: string;
  /**
   * @minLength 0
   * @maxLength 400
   */
  template: string;
  /**
   * @minLength 0
   * @maxLength 400
   */
  formTemplate?: string;
  event:
    | "CONFIRMED_APPOINTMENT"
    | "UNCONFIRMED_APPOINTMENT"
    | "APPOINTMENT_COMPLETED"
    | "APPOINTMENT_CREATED"
    | "APPOINTMENT_REQUESTED"
    | "PATIENT_CREATED"
    | "PROPHY_DUE"
    | "PERIO_DUE";
  channel: "PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL";
  confirmWithY: boolean;
  sendTime?: string;
  active: boolean;
  /** @uniqueItems true */
  appointmentCategoryIds?: number[];
  /**
   * @minLength 0
   * @maxLength 400
   */
  confirmationMessage?: string;
}

export interface AmountAdjustment {
  /**
   * @format int64
   * @min 1
   */
  discountAmount?: number;
  discountPercentage?: string;
  taxPercentage?: string;
}

export interface CreateAppointmentAdjustmentRequest {
  /** @format int64 */
  appointmentId: number;
  /** @format int64 */
  adjustmentAmount?: number;
  adjustmentPercentage?: string;
  /**
   * @minLength 0
   * @maxLength 500
   */
  note?: string;
  /** @format int64 */
  customAdjustmentTypeId: number;
  accountingType: "COLLECTION" | "PRODUCTION";
  procedureAdjustments?: CreateProcedureAdjustmentRequest[];
}

export interface CreateInvoiceRequest {
  payer: EntityReferenceVO;
  /** @format int64 */
  dueAt?: number;
  entryProposals: EntryProposal[];
  appointmentAdjustmentRequests?: CreateAppointmentAdjustmentRequest[];
  commit: boolean;
  note?: string;
}

export interface CreateProcedureAdjustmentRequest {
  /** @format int64 */
  procedureId: number;
  /** @format int64 */
  adjustmentAmount?: number;
  adjustmentPercentage?: string;
}

export interface EntryProposal {
  entryReference: InvoiceEntryReference;
  ledgerOrderUuids: string[];
  itemProposals?: LineItemProposal[];
  amountAdjustment?: AmountAdjustment;
}

export interface LineItemProposal {
  lineItemReference: InvoiceLineItemReference;
  amountAdjustment?: AmountAdjustment;
}

export interface AddAdjustmentToInvoiceRequest {
  adjustmentUuids: string[];
  commitChanges: boolean;
}

export interface IssueChatTokenRequest {
  /**
   * Seconds until the token expires
   * @format int32
   * @min 1
   * @example 3600
   */
  validDurationSeconds?: number;
}

export interface ChatTokenResponse {
  userId: string;
  appId: string;
  token: string;
  /**
   * Epoch time in seconds when the token expires
   * @format int64
   * @example 1610000000
   */
  expiresAt: number;
}

export interface SuccessResponseChatTokenResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ChatTokenResponse;
}

export interface BatchUpsertFrequencyLimitationCategoriesRequest {
  requests: UpsertFrequencyLimitationCategoryRequest[];
}

export interface UpsertFrequencyLimitationCategoryRequest {
  /** @format int64 */
  id?: number;
  name: string;
  group?:
    | "DIAGNOSTIC"
    | "PREVENTIVE"
    | "RESTORATIVE"
    | "PERIODONTAL"
    | "PROSTHODONTICS"
    | "IMPLANTS"
    | "NONE";
  defaultFrequencyLimit?:
    | "X_VISITS_PER_BENEFIT_YEAR"
    | "ONE_VISIT_EVERY_X_YEARS"
    | "ONE_VISIT_EVERY_X_MONTHS"
    | "ONE_VISIT_EVERY_X_YEARS_PER_TOOTH"
    | "ONE_VISIT_EVERY_X_MONTHS_PER_QUADRANT"
    | "ONE_VISIT_EVERY_X_YEARS_PER_ARCH";
  dentalProcedureIds: number[];
  /** @format uuid */
  insurancePlanUuid?: string;
}

export interface SuccessResponseListFrequencyLimitationCategoryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FrequencyLimitationCategoryVO[];
}

export interface HideUnhideFrequencyLimitationCategoryRequest {
  hide: boolean;
}

export interface CreateCustomCarrierRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  website?: string;
  /**
   * @minLength 0
   * @maxLength 10
   */
  payerId: string;
  /** @pattern ^[0-9]{10}$ */
  phone?: string;
  /** @pattern ^[0-9]{10}$ */
  fax?: string;
  address: AddressVO;
  acceptsAutomatedEligibilities?: boolean;
  acceptsElectronicAttachments?: boolean;
  acceptsToothRange?: boolean;
  comments?: string;
}

export interface InsuranceCarrierSyncVO {
  /** @uniqueItems true */
  missingCarriers: InsuranceCarrierVO[];
  /** @uniqueItems true */
  attachmentDataOutOfSync: InsuranceCarrierVO[];
  /** @format int32 */
  archyCarriers?: number;
  /** @format int32 */
  dxcCarriers?: number;
}

export interface SuccessResponseInsuranceCarrierSyncVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InsuranceCarrierSyncVO;
}

export interface CreateInsurancePlanRequest {
  /** @format int64 */
  carrierId: number;
  /** @format int64 */
  feeScheduleId?: number;
  groupNumber?: string;
  employerName?: string;
  groupName?: string;
  planType:
    | "PPO"
    | "TOA_SOA"
    | "EPO"
    | "HMO_DHMO"
    | "MEDICAID_CHIP"
    | "POS"
    | "DISCOUNT"
    | "SELF_FUNDED_ERISA"
    | "DIRECT_REIMBURSEMENT"
    | "FEDERAL"
    | "MEDICARE"
    | "TRICARE";
  /**
   * @minLength 0
   * @maxLength 65535
   */
  planNotes?: string;
  isMedicalInsurance?: boolean;
  eligibilityCoverageLevel?: "INDIVIDUAL" | "FAMILY";
  renewalMonth?:
    | "JANUARY"
    | "FEBRUARY"
    | "MARCH"
    | "APRIL"
    | "MAY"
    | "JUNE"
    | "JULY"
    | "AUGUST"
    | "SEPTEMBER"
    | "OCTOBER"
    | "NOVEMBER"
    | "DECEMBER";
  outOfNetworkBenefits?: boolean;
  coordinationOfBenefits?: "STANDARD" | "NON_DUPLICTION" | "OTHER";
  /**
   * @format int32
   * @min 0
   */
  waitingPeriodInMonths?: number;
  /** @format int64 */
  dependentMaxAge?: number;
  missingToothClause?: boolean;
  crownBridgesPaidOn?: "SEAT_DATE" | "PREP_DATE";
  insuranceRepresentative?: string;
  carrierContacted?: boolean;
  /** @format int64 */
  carrierContactedById?: number;
  /** @format int64 */
  patientInsuranceId?: number;
  applyDowngrade?: boolean;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
}

export interface BatchUpsertBenefitLimitationRequest {
  benefits: BenefitLimitationRequest[];
  autoEligibility?: boolean;
}

export interface BenefitLimitationRequest {
  /** @format uuid */
  uuid?: string;
  /**
   * @format int32
   * @min 100
   * @max 9999
   */
  startCdtCodeRange?: number;
  /**
   * @format int32
   * @min 100
   * @max 9999
   */
  endCdtCodeRange?: number;
  /** @format int64 */
  frequencyLimitationCategoryId?: number;
  serviceName: string;
  /**
   * @format int32
   * @min 0
   */
  waitingPeriodInMonths?: number;
  /** @format int64 */
  downgradeProcedureId?: number;
  /** @format int32 */
  ageMax?: number;
  /** @format int32 */
  ageMin?: number;
  sameDay?: boolean;
  notes?: string;
  frequency?: FrequencyLimitRequest;
  autoEligibility?: boolean;
}

export interface FrequencyLimitRequest {
  /**
   * @format int32
   * @min 1
   */
  frequencyQuantity: number;
  /**
   * @format int32
   * @min 1
   */
  durationQuantity: number;
  durationType: "MONTH" | "QUARTER" | "YEAR" | "LIFETIME";
  limitationType?: "SERVICE_DATE" | "BENEFIT_YEAR" | "CALENDAR_YEAR";
  mouthConstraint?: "QUADRANT" | "ARCH" | "TOOTH" | "SURFACE";
}

export interface SuccessResponseListBenefitLimitationVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: BenefitLimitationVO[];
}

export interface BatchUpsertBenefitCoverageRequest {
  benefits: BenefitCoverageRequest[];
  autoEligibility?: boolean;
}

export interface BenefitCoverageRequest {
  /** @format uuid */
  uuid?: string;
  /**
   * @format int32
   * @min 100
   * @max 9999
   */
  startCdtCodeRange: number;
  /**
   * @format int32
   * @min 100
   * @max 9999
   */
  endCdtCodeRange: number;
  benefitCategory: string;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  percentCoverage?: number;
  applyDeductible?: boolean;
  /**
   * @format int64
   * @min 0
   */
  copayAmount?: number;
  preauthRequired?: boolean;
  /**
   * @format int32
   * @min 0
   */
  waitingPeriodInMonths?: number;
  autoEligibility?: boolean;
}

export interface SuccessResponseListBenefitCoverageVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: BenefitCoverageVO[];
}

export interface InsurancePlanMergeRequest {
  /** @uniqueItems true */
  sourceInsurancePlanUuids: string[];
  /** @format uuid */
  targetInsurancePlanUuid: string;
}

export interface DailyHuddleAppointmentVO {
  patient: NameVO;
  isPatientOnboarded: boolean;
  /** @format int64 */
  practiceId: number;
  carrier?: InsuranceCarrierVO;
  appointmentDate: string;
  appointmentStartTime: string;
  /** @format int64 */
  appointmentId: number;
  appointmentState:
    | "UNSCHEDULED"
    | "UNCONFIRMED"
    | "CONFIRMED"
    | "CANCELED"
    | "NO_SHOW"
    | "ARRIVED"
    | "READY"
    | "IN_PROGRESS"
    | "CHECKOUT"
    | "COMPLETED"
    | "_DELETED"
    | "REQUESTED";
  /** @format int64 */
  appointmentDuration: number;
  provider: NameVO;
  dentist: NameVO;
  room: RoomVO;
  /** @format int64 */
  balanceAmount: number;
  gender?: "MALE" | "FEMALE" | "OTHER" | "UNKNOWN";
  dob?: string;
  email?: string;
  clinicalNoteStatus?: "INCOMPLETE" | "DRAFT" | "COMPLETED";
  dailyHuddleInsuranceInfo?: DailyHuddleInsuranceInfoVO;
  labCaseStatuses: LabCaseStatusVO[];
  tags: TagVO[];
}

export interface DailyHuddleInsuranceInfoVO {
  /** @format int64 */
  patientInsuranceId: number;
  eligibilityLastVerifiedAt?: string;
  eligibilityLastVerifiedBy?: NameVO;
  eligibilityVerifiedStatus?: "VERIFIED" | "AUTO_VERIFIED" | "NOT_VERIFIED" | "FAILED";
  insuranceType?: "PRIMARY_SUBSCRIBER" | "DEPENDENT" | "NONE";
  benefitLastVerifiedAt?: string;
  benefitLastVerifiedBy?: NameVO;
  benefitVerifiedStatus?: "VERIFIED" | "AUTO_VERIFIED" | "NOT_VERIFIED" | "FAILED";
}

export interface SuccessResponseListDailyHuddleAppointmentVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: DailyHuddleAppointmentVO[];
}

export interface DailyHuddleOverviewRequest {
  includeTagIds?: number[];
  /** @uniqueItems true */
  clinicalNoteStatuses?: ("INCOMPLETE" | "DRAFT" | "COMPLETED")[];
}

export interface DailyHuddleOverview {
  /** @format int64 */
  yesterdayCount: number;
  /** @format int64 */
  todayCount: number;
  /** @format int64 */
  tomorrowCount: number;
  /** @format int64 */
  thisWeekCount: number;
  /** @format int64 */
  nextWeekCount: number;
}

export interface SuccessResponseDailyHuddleOverview {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: DailyHuddleOverview;
}

export interface GustoLinkPracticeRequest {
  authorizationCode: string;
}

export interface ClinicalNoteFormPreviewRequest {
  responses?: Record<
    string,
    | FormBooleanResponseRequest
    | FormConsentResponseRequest
    | FormDateResponseRequest
    | FormNumberResponseRequest
    | FormSelectResponseRequest
    | FormStringResponseRequest
  >;
}

export interface ClinicalNotePreviewVO {
  text: string;
}

export interface SuccessResponseClinicalNotePreviewVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ClinicalNotePreviewVO;
}

export interface SubmitFormTaskRequest {
  /** @format int64 */
  documentId: number;
  /**
   * The epoch time in seconds when the form was submitted as an override. If not provided, the form will be considered submitted at the time of the request.
   * @format int64
   */
  submittedAt?: number;
}

export interface SendFormTaskRequest {
  formTaskUuids: string[];
  message: string;
  /** @pattern ^[0-9]{10}$ */
  phoneOverride?: string;
}

export interface GenerateFormTaskKioskCodeRequest {
  formTaskUuids: string[];
}

export interface SuccessResponseString {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: string;
}

export interface PrepareFileUploadRequest {
  contentType: string;
}

export interface SuccessResponsePrepareFileUploadResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PrepareFileUploadResponse;
}

export interface BatchPrepareFileUploadRequest {
  requests: PrepareFileUploadRequest[];
}

export interface BatchPrepareFileUploadResponse {
  responses?: PrepareFileUploadResponse[];
}

export interface SuccessResponseBatchPrepareFileUploadResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: BatchPrepareFileUploadResponse;
}

export interface AddFeeScheduleRequest {
  /**
   * @minLength 0
   * @maxLength 80
   */
  name: string;
  dentalProcedureFees: DentalProcedureFeeVO[];
  /** @uniqueItems true */
  carrierIds: number[];
  /** @uniqueItems true */
  providerIds: number[];
  startDate: string;
  endDate?: string;
  /** @deprecated */
  isProviderUcr?: boolean;
  type?: "PRACTICE_UCR" | "PROVIDER_UCR" | "CARRIER" | "BLUE_BOOK";
  inNetwork?: boolean;
}

export interface ArchiveFeeScheduleRequest {
  /** @format int64 */
  replacementFeeScheduleId?: number;
}

export interface FeeCalcRequest {
  /** @format int64 */
  patientId: number;
  date?: string;
  applyRemainingAmount?: boolean;
  forceInsuranceEstimate?: boolean;
}

export interface CreateEmployeeRequest {
  personalDetails: EmployeePersonalDetailsVO;
  contactDetails: ContactDetails;
  employmentDetails: EmploymentDetailsVO;
  /** @format int64 */
  roleV2Id: number;
}

export interface WorktimeActionRequest {
  date?: string;
  action: "CLOCK_IN" | "CLOCK_OUT";
  time: string;
  submittedTime?: string;
  notes?: string;
}

export interface CreateClinicianRequest {
  createAsProxyPrescriber: boolean;
}

export interface GustoEmployeeFlowRequest {
  flowType:
    | "EMPLOYEE_FORM_SIGNING"
    | "EMPLOYEE_SELF_MANAGEMENT"
    | "CONTRACTOR_SELF_MANAGEMENT"
    | "CONTRACTOR_DOCUMENTS";
}

export interface CustomHolidayVO {
  /** @format int64 */
  id?: number;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  description?: string;
}

export interface SuccessResponseListCustomHolidayVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: CustomHolidayVO[];
}

export interface VerifyDomainRequest {
  /** Whether the request was simply made to check verification. When false, BE will reset the verification timestamp. */
  checkingVerification: boolean;
}

export interface AdaProcedureOverrideRequest {
  /**
   * @minLength 0
   * @maxLength 22
   */
  simpleName: string;
  /** @pattern ^[\/X]{0,30}$ */
  duration: string;
  isHygiene: boolean;
  /**
   * @minLength 0
   * @maxLength 255
   */
  laymanTerm?: string;
  creditToPractice?: boolean;
}

export interface CreateCustomProcedureRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 22
   */
  simpleName: string;
  /**
   * @minLength 0
   * @maxLength 15
   */
  cdtCode: string;
  /** @pattern ^[\/X]{0,30}$ */
  duration: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  laymanTerm?: string;
  isHygiene?: boolean;
  creditToPractice?: boolean;
}

export interface CreateCustomPatientFolderRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
}

export interface CustomPatientFolderVO {
  /** @format int64 */
  id: number;
  name: string;
}

export interface SuccessResponseCustomPatientFolderVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: CustomPatientFolderVO;
}

export interface ClaimCreateRequest {
  /** @format int64 */
  appointmentId: number;
  /** @format int64 */
  billingProviderId: number;
  insuranceOrdinal: "PRIMARY" | "SECONDARY" | "OTHER";
  /** @format int64 */
  patientInsuranceId: number;
  /** @format uuid */
  primaryClaimUuid?: string;
}

export interface SuccessResponseClaimEobFileVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ClaimEobFileVO;
}

export interface ClaimAdjustmentLineItemData {
  /** @format int64 */
  patientProcedureId: number;
  /** @format int64 */
  deductibleAmount: number;
  /** @format int64 */
  patientAmount: number;
  /** @format int64 */
  insuranceAmount: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  remarks?: string;
  /**
   * @minLength 0
   * @maxLength 10
   */
  downgradedCdtCode?: string;
}

export interface ClaimAdjustmentRequest {
  lineItems: ClaimAdjustmentLineItemData[];
}

export interface ClaimUploadAttachmentsRequest {
  attachments: UploadAttachmentEntry[];
}

export interface UploadAttachmentEntry {
  type:
    | "UNKNOWN"
    | "NOTE"
    | "XRAY"
    | "PHOTO"
    | "CHART"
    | "EOB"
    | "DIAGNOSTIC"
    | "MODEL"
    | "RADIOLOGY"
    | "REFERRAL"
    | "OTHER"
    | "NONE";
  /** @format int64 */
  createdAt: number;
  /** @format int64 */
  sourceId?: number;
  /** @format uuid */
  sourceUuid?: string;
}

export interface CreatePreAuthClaimRequest {
  /** @format int64 */
  patientId: number;
  /** @uniqueItems true */
  patientProcedureIds: number[];
  /** @format int64 */
  treatingProviderId?: number;
}

export interface CreditCardPayload {
  paymentToken: string;
}

export interface OffsiteClaimPaymentRequest {
  /** @format uuid */
  idempotencyUuid: string;
  creditCardPayload: CreditCardPayload;
  /** @format int64 */
  insuranceCarrierId: number;
  /** @format int64 */
  amount: number;
  note?: string;
}

export interface CreateDraftEobPaymentRequest {
  /** @uniqueItems true */
  claimUuids: string[];
  /** @format int64 */
  insuranceCarrierId: number;
}

export interface ClaimPaymentSummaryVO {
  /** @format uuid */
  uuid: string;
  serviceDate: string;
  patientName: NameVO;
  /** @format int64 */
  claimNumber: number;
  insuranceMemberId?: string;
  /** @format int64 */
  patientAmount: number;
  /** @format int64 */
  unpaidPatientAmount: number;
  /** @format int64 */
  insuranceAmount: number;
  /** @format int64 */
  unpaidInsuranceAmount: number;
  insuranceOrdinal: "PRIMARY" | "SECONDARY" | "OTHER";
  eobVerified: boolean;
  latestVersionType: "PAYMENT" | "ADDITIONAL" | "ADJUSTMENT";
}

export interface DraftEobPaymentVO {
  /** @format uuid */
  uuid: string;
  createDate: string;
  state: "DRAFT" | "PAYMENT_PENDING";
  paymentErrorMessage?: string;
  /** @format int64 */
  insuranceCarrierId: number;
  insuranceCarrier: string;
  /** @format int64 */
  unpaidPatientAmount: number;
  /** @format int64 */
  unpaidInsuranceAmount: number;
  claimPaymentSummaries: ClaimPaymentSummaryVO[];
  eobFiles: ClaimEobFileVO[];
}

export interface SuccessResponseDraftEobPaymentVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: DraftEobPaymentVO;
}

export interface ClaimCreateAccountRequest {
  accountType: "DXC";
}

export interface CreateBulkStatementRequest {
  createStatementRequest: CreateStatementRequest;
  filter:
    | MessageCampaignAsapCriteria
    | MessageCampaignHuddleCriteria
    | MessageCampaignPatientCriteria
    | MessageCampaignRecallReportCriteria
    | MessageCampaignUncollectedReportCriteria;
}

export interface AppointmentCategoryRequest {
  /** @format int64 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  externalName?: string;
  procedures: DentalProcedureVO[];
  /** @format int32 */
  duration: number;
  /**
   * @minLength 0
   * @maxLength 10
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  color?: string;
  /** @format int32 */
  order?: number;
  isSelfBookable?: boolean;
  providerIds?: number[];
  selfBookingConfig?: AppointmentCategorySelfBookingRequest;
}

export interface AppointmentCategorySelfBookingRequest {
  bookingType: "REQUEST" | "SCHEDULE";
  patientType: "ALL" | "NEW" | "EXISTING";
  /**
   * @format int32
   * @max 365
   */
  leadTimeToBookInDays: number;
}

export interface SuccessResponseListAppointmentCategoryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AppointmentCategoryVO[];
}

export interface BatchUpdateAsapRequest {
  /** @uniqueItems true */
  appointmentIds: number[];
  asap: boolean;
}

export interface CreateCustomAdjustmentTypeRequest {
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
  entryType: "CREDIT" | "DEBIT";
  archived?: boolean;
}

export interface SuccessResponseCustomAdjustmentTypeVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: CustomAdjustmentTypeVO;
}

export interface BenefitRenewalRequest {
  renewalDate: string;
}

export interface CreateInsuranceCarrierRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  website?: string;
  /**
   * @minLength 0
   * @maxLength 10
   */
  payerId: string;
  /** @pattern ^[0-9]{10}$ */
  phone?: string;
  /** @pattern ^[0-9]{10}$ */
  fax?: string;
  address?: AddressVO;
  acceptsAutomatedEligibilities?: boolean;
  acceptsElectronicAttachments?: boolean;
  acceptsToothRange?: boolean;
}

export interface UpdateFolderRequest {
  /**
   * @minLength 1
   * @maxLength 100
   */
  name?: string;
}

export interface UpdateDocumentRequest {
  /**
   * @minLength 1
   * @maxLength 100
   */
  name?: string;
  /** @format int64 */
  folderId?: number;
}

export interface MoveDocumentRequest {
  /** @format int64 */
  updatedFolderId: number;
}

export interface UpdatePracticeTaxIdRequest {
  /** @pattern ^(\d{9})$ */
  taxId: string;
}

export interface UpdateIPFilterRequest {
  /**
   * @minLength 0
   * @maxLength 2000
   */
  address?: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  description?: string;
  /** @uniqueItems true */
  userIds?: number[];
  appliesToAll?: boolean;
}

export interface UpdateSavedFilterRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
}

export interface UpdatePaymentDeviceRequest {
  name?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  serialNumber?: string;
  model?: "LINK_2500" | "LANE_3000" | "LANE_5000" | "LANE_7000" | "LANE_8000" | "PAX_A920PRO" | "SUNMI_P2";
}

export interface UpdateWalletRequest {
  isFamily?: boolean;
}

export interface WalletEditRequest {
  /** If commit is true, then payment must be provided. */
  paymentCorrectionRequest?: PaymentCorrectionRequest;
  /**
   * @minLength 0
   * @maxLength 100
   */
  note?: string;
}

export interface UpdateRecallRequest {
  /**
   * Note
   * @minLength 0
   * @maxLength 500
   */
  note?: string;
  /**
   * Interval in days
   * @format int32
   * @max 31
   */
  intervalDays?: number;
  /**
   * Interval in months
   * @format int32
   */
  intervalMonths?: number;
  dueDate?: string;
}

export interface UpdatePerioChartExamRequest {
  /**
   * Since this is a PATCH endpoint, null values are not considered. To reset the note, provide an empty string.
   * @minLength 0
   * @maxLength 5000
   */
  note?: string;
  /**
   * The dentist or hygienist performing the exam. If null or undefined is provided, it is ignored.
   * @format int64
   */
  providerId?: number;
  date?: string;
}

export interface UpdatePaymentProfileRequest {
  isDefault?: boolean;
  isFamily?: boolean;
}

export interface UpdateMountRequest {
  name?: string;
  layout?: string;
  images?: MedicalImageVO[];
}

export interface UpdatePatientInsuranceRequest {
  relation?: "SELF" | "PARENT" | "CHILD" | "SPOUSE" | "SIBLING" | "GRANDPARENT" | "GRANDCHILD" | "OTHER";
  /** @format int64 */
  carrierId?: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  employer?: string;
  /**
   * @minLength 2
   * @maxLength 80
   */
  subscriberId?: string;
  /** @pattern ^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$ */
  ssn?: string;
  ssnLastFour?: string;
  releaseOfPatientInfo?: boolean;
  assignmentOfBenefits?: boolean;
  notes?: string;
  startDate?: string;
  expiryDate?: string;
  /**
   * @format int64
   * @min 0
   */
  annualDeductibleRemaining?: number | null;
  /**
   * @format int64
   * @min 0
   */
  annualMaximumRemaining?: number | null;
  /**
   * @format int64
   * @min 0
   */
  familyAnnualDeductibleRemaining?: number | null;
  /**
   * @format int64
   * @min 0
   */
  familyAnnualMaximumRemaining?: number | null;
  /**
   * @format int64
   * @min 0
   */
  lifetimeDeductibleRemaining?: number | null;
  /**
   * @format int64
   * @min 0
   */
  lifetimeMaximumRemaining?: number | null;
  /**
   * @format int64
   * @min 0
   */
  orthoMaximumRemaining?: number | null;
}

export interface UpdatePatientInsuranceStateRequest {
  state: "ACTIVE" | "INACTIVE" | "ARCHIVED";
  expiryDate?: string;
  cascade?: boolean;
}

export interface UpdatePatientInsuranceOrdinalRequest {
  ordinal: "PRIMARY" | "SECONDARY" | "OTHER";
}

export interface UpdateFamilyMemberRequest {
  /** @format int64 */
  id: number;
  relation?: "SELF" | "PARENT" | "CHILD" | "SPOUSE" | "SIBLING" | "GRANDPARENT" | "GRANDCHILD" | "OTHER";
  /** Determines if the family member is a guardian to the patient. */
  isFamilyMemberGuardian?: boolean;
  /** Determines if the family member has phi access on the patient. */
  hasFamilyMemberPhiAccess?: boolean;
}

export interface SuccessResponseListFamilyRelationVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FamilyRelationVO[];
}

export interface UpdateGuardianOrPhiAccessRequest {
  /** @format int64 */
  memberPatientId: number;
  guardian?: boolean;
  phiAccess?: boolean;
}

export interface EditAdjustmentRequest {
  /** The family payment amount if this payment is associated with a family payment. */
  currencyAmount?: CurrencyAmount;
  /** @format int64 */
  orderCreatedAt?: number;
  /** @format int64 */
  employeeId?: number;
  /**
   * @minLength 0
   * @maxLength 500
   */
  note?: string;
  /** @format int64 */
  customAdjustmentTypeId?: number;
}

export interface UpdateEmployeeChatPreferenceRequest {
  showPopover?: boolean;
  showPreview?: boolean;
  useSound?: boolean;
}

export interface EmployeeChatPreferenceVO {
  showPopover: boolean;
  showPreview: boolean;
  useSound: boolean;
}

export interface SuccessResponseEmployeeChatPreferenceVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: EmployeeChatPreferenceVO;
}

export interface UpdateWorktimeRequest {
  submittedClockIn?: string;
  submittedClockOut?: string;
  notes?: string;
  approvedClockIn?: string;
  approvedClockOut?: string;
  approvalNotes?: string;
}

export interface BatchApproveWorktimeRequest {
  worktimeIds: number[];
}

export interface UpdateCustomPatientFolderRequest {
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
}

export interface ClaimUpdateRequest {
  /** @uniqueItems true */
  patientProcedureIds?: number[];
  /** @format int64 */
  patientInsuranceId?: number;
  /** @format int64 */
  billingProviderId?: number;
  /** @format int64 */
  treatingProviderId?: number;
  orthoDate?: string;
  /**
   * @format int32
   * @min 0
   * @max 1200
   */
  orthoMonthsTotal?: number;
  /**
   * @format int32
   * @min 0
   * @max 1200
   */
  orthoMonthsRemaining?: number;
  /**
   * @minLength 0
   * @maxLength 400
   */
  notes?: string | null;
  insuranceOrdinal?: "PRIMARY" | "SECONDARY" | "OTHER";
}

export interface ClaimLineItemChangeRequest {
  lineItems: ClaimLineItemData[];
  remarksOrDowngradeCodeOnly?: boolean;
}

export interface ClaimLineItemData {
  /** @format int64 */
  patientProcedureId: number;
  /** @format int64 */
  version: number;
  /** @format int64 */
  deductibleAmount?: number;
  /** @format int64 */
  patientAmount?: number;
  /** @format int64 */
  insuranceAmount?: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  remarks?: string | null;
  /**
   * @minLength 0
   * @maxLength 10
   */
  downgradedCdtCode?: string | null;
}

export interface ClaimUpdateAttachmentRequest {
  attachments: UpdateAttachmentEntry[];
}

export interface UpdateAttachmentEntry {
  /** @format uuid */
  uuid: string;
  /** @format int64 */
  createdAt?: number;
  type?:
    | "UNKNOWN"
    | "NOTE"
    | "XRAY"
    | "PHOTO"
    | "CHART"
    | "EOB"
    | "DIAGNOSTIC"
    | "MODEL"
    | "RADIOLOGY"
    | "REFERRAL"
    | "OTHER"
    | "NONE";
}

export interface UpdateDraftEobPaymentRequest {
  /** @uniqueItems true */
  claimUuids: string[];
}

export interface ClaimPaymentRequest {
  /** @format uuid */
  idempotencyUuid: string;
  paymentDate: string;
  creditCardPayload?: CreditCardPayload;
  checkPayload?: CheckPayload;
  eftPayload?: EFTPayload;
  externalPosPayload?: ExternalPosPayload;
}

export interface ClaimBatchSubmitRequest {
  /** @uniqueItems true */
  uuids: string[];
  /**
   * If true, the claim will be submitted regardless of its needsAttachment status. Note that this is only
   * evaluated if a single claim UUID is provided. If multiple claim UUIDs are provided, this will be ignored.
   */
  overrideNeedsAttachment?: boolean;
}

export interface UpdatePracticeBillingSettingRequest {
  collectPaymentInfoOnPatientPortal?: boolean;
  /** @format int32 */
  paymentModificationAllowedDays?: number;
}

export interface PracticeBillingSettingVO {
  collectPaymentInfoOnPatientPortal: boolean;
  /** @format int32 */
  paymentModificationAllowedDays: number;
  passCreditCardFees: boolean;
}

export interface SuccessResponsePracticeBillingSettingVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PracticeBillingSettingVO;
}

export interface FamilyPaymentAllocationVO {
  name: NameVO;
  /** @format int64 */
  amountAllocated: number;
  /** @format int64 */
  creditAmountGiven: number;
  /** @format int64 */
  creditAmountReceived: number;
}

export interface InvoiceNumberVO {
  /** @format uuid */
  uuid: string;
  /** @format int64 */
  patientId: number;
  number: string;
}

export interface PaymentSummaryVO {
  /** @format uuid */
  uuid: string;
  type: "CHARGE" | "REFUND";
  paidBy: NameVO;
  /** @format int64 */
  createdAt: number;
  method:
    | "STORED_PROFILE"
    | "CASH"
    | "CHECK"
    | "STORED_POS"
    | "EXTERNAL_POS"
    | "EFT"
    | "WALLET"
    | "CARE_CREDIT"
    | "THIRD_PARTY_FINANCING"
    | "BALANCE_TRANSFER";
  paymentProfile?: PaymentProfileVO;
  externalPosPayload?: ExternalPosPayload;
  /** The family payment amount if this payment is associated with a family payment. */
  currencyAmount: CurrencyAmount;
  /**
   * The amount of the payment allocated to this patient.
   * @format int64
   */
  amountAllocated: number;
  /**
   * The amount of credits given away from refund invoices associated with this patient.
   * @format int64
   */
  creditAmountGiven: number;
  /**
   * The amount of credits received from refund invoices associated with this patient or family-members.
   * @format int64
   */
  creditAmountReceived: number;
  /** @format int64 */
  receiptDocumentId: number;
  invoiceNumbers: InvoiceNumberVO[];
  familyPaymentAllocations: FamilyPaymentAllocationVO[];
  /** @uniqueItems true */
  permittedActions: ("EDIT" | "DELETE")[];
}

export interface SuccessResponseListPaymentSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PaymentSummaryVO[];
}

export interface SuccessResponseListPatientNoteVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientNoteVO[];
}

export interface PatientChartEntryVO {
  /** @format int32 */
  toothNum: number;
  toothName: string;
  state: "MISSING" | "PRIMARY" | "PRIMARY_UNERUPTED" | "PERMANENT" | "PERMANENT_UNERUPTED";
  /** Indicates if the tooth has been replaced by an implant. */
  isReplaced: boolean;
  /** @format date-time */
  dateOfState?: string;
  conditions: PatientToothConditionVO[];
  position: "ANTERIOR" | "POSTERIOR";
  arch: "ARCH_UPPER" | "ARCH_LOWER";
  quadrant: "QUAD_UPPER_LEFT" | "QUAD_UPPER_RIGHT" | "QUAD_LOWER_LEFT" | "QUAD_LOWER_RIGHT";
  visuals: (PatientChartVisualCondition | PatientChartVisualProcedure)[];
}

export interface PatientChartVisual {
  visualType:
    | "EXTRACTION"
    | "IMPLANT"
    | "ABUTMENT"
    | "RCT"
    | "POST"
    | "FILLING_DARK"
    | "FILLING_LIGHT"
    | "CROWN_DARK"
    | "CROWN_LIGHT"
    | "BRIDGE_DARK"
    | "BRIDGE_LIGHT"
    | "DENTURE_DARK"
    | "DENTURE_LIGHT"
    | "SEALANT"
    | "WATCH"
    | "SPACE_MAINTAINER"
    | "SDF"
    | "APICOECTOMY"
    | "BU"
    | "PIN"
    | "MARGIN_TOOTH"
    | "CRACKED_TOOTH"
    | "IMPACTED_TOOTH"
    | "RETAINED_ROOT"
    | "SENSITIVE_DENTIN"
    | "PERAPICAL_ABSCESS"
    | "DIASTEMA_MESIAL"
    | "DIASTEMA_DISTAL"
    | "INCLINATION_DISTAL"
    | "INCLINATION_MESIAL"
    | "GING_RECESSION"
    | "WATCH_SURFACE"
    | "CHIPPED_TOOTH"
    | "VENEER"
    | "APICAL_PERIO"
    | "BUCCAL_ABFRACTION"
    | "CARIOUS_PULP_EXP"
    | "CRACKED_FILLING"
    | "DEFECT"
    | "IMPACTED_DISTAL"
    | "IMPACTED_MESIAL"
    | "LINGUAL_ABFRACTION"
    | "LINGUAL_RETAINER"
    | "NECROTIC"
    | "ROOT_ABRASION"
    | "ROOT_DECAY"
    | "ROOT_TIPS"
    | "SUPRA_ERUPTED"
    | "NO_VISUAL_FOR_CONDITION"
    | "NONE";
  displayName: string;
  surfaces?: ("M" | "O" | "D" | "I" | "L" | "B" | "F" | "BV" | "FV" | "LV")[];
  /** @format date-time */
  date: string;
  type: "CONDITION" | "PROCEDURE";
}

export type PatientChartVisualCondition = UtilRequiredKeys<
  PatientChartVisual,
  "visualType" | "displayName" | "date" | "type"
> & {
  condition:
    | "CARIES"
    | "CRACKED_TOOTH"
    | "PERIAPICAL_ABSCESS"
    | "DIASTEMA_OF_TEETH_MESIAL"
    | "DIASTEMA_OF_TEETH_DISTAL"
    | "DISTAL_INCLINATION"
    | "MESIAL_INCLINATION"
    | "WATCH_TOOTH"
    | "WATCH_TOOTH_SURFACE"
    | "SENSITIVE_DENTIN"
    | "GINGIVAL_RECESSION"
    | "RETAINED_ROOT"
    | "ASYMPTOMATIC_PULPITIS"
    | "CARIES_OF_CERVICAL"
    | "CHIPPED_TOOTH"
    | "ABFRACTION"
    | "LINGUAL_ABFRACTION"
    | "BUCCAL_ABFRACTION"
    | "IMPACTED_DISTAL"
    | "IMPACTED_MESIAL"
    | "SUPRA_ERUPTED"
    | "LINGUAL_RETAINER"
    | "ROOT_TIPS"
    | "ROOT_ABRASION"
    | "ROOT_DECAY"
    | "DEFECT"
    | "IRREVERS_PULP"
    | "REVERS_PULP"
    | "NECROTIC"
    | "APICAL_PERIO"
    | "CARIOUS_PULP_EXP"
    | "CRACKED_FILLING";
  type: "CONDITION";
};

export type PatientChartVisualProcedure = UtilRequiredKeys<
  PatientChartVisual,
  "visualType" | "displayName" | "date" | "type"
> & {
  cdtCode: string;
  status: "EXISTING_OTHER" | "PLANNED" | "SCHEDULED" | "DONE" | "EXISTING_CURRENT" | "REFERRED" | "REJECTED";
  type: "PROCEDURE";
};

export interface SuccessResponseListPatientChartEntryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientChartEntryVO[];
}

export interface FamilyMemberResponse {
  linkedFamilyMembers: FamilyMemberVO[];
  unlinkedFamilyMembers: FamilyMemberVO[];
}

export interface FamilyMemberVO {
  /** @deprecated */
  displayName: string;
  name: NameVO;
  /** @format int64 */
  age: number;
  gender?: "MALE" | "FEMALE" | "OTHER" | "UNKNOWN";
  relation?: "SELF" | "PARENT" | "CHILD" | "SPOUSE" | "SIBLING" | "GRANDPARENT" | "GRANDCHILD" | "OTHER";
  thumbnail?: string;
  /** @uniqueItems true */
  linkedFamilyMembers: LinkedFamilyMemberVO[];
  /** @format int64 */
  memberPatientId: number;
  /** @format int64 */
  relationId?: number;
  insurances: PatientInsuranceSummaryVO[];
  /** @format int64 */
  balanceAmount: number;
  contact: ContactVO;
}

export interface LinkedFamilyMemberVO {
  name: NameVO;
  relation: "SELF" | "PARENT" | "CHILD" | "SPOUSE" | "SIBLING" | "GRANDPARENT" | "GRANDCHILD" | "OTHER";
  guardian?: boolean;
  phiAccess?: boolean;
}

export interface PatientInsuranceSummaryVO {
  /** @format int64 */
  id: number;
  /** @format int64 */
  patientInsuranceSubscriberId: number;
  carrier: InsuranceCarrierVO;
  type: "PRIMARY_SUBSCRIBER" | "DEPENDENT" | "NONE";
  subscriber: NameVO;
  employer?: string;
}

export interface SuccessResponseFamilyMemberResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FamilyMemberResponse;
}

export interface FeeScheduleListVO {
  /** @format int64 */
  id: number;
  name: string;
  providers?: NameVO[];
  startDate: string;
  endDate?: string;
  /** @format int64 */
  insurancePlans: number;
  /** @format int64 */
  patients: number;
  inNetwork: boolean;
  state: "ACTIVE" | "ARCHIVED";
  type: "PRACTICE_UCR" | "PROVIDER_UCR" | "CARRIER" | "BLUE_BOOK";
}

export interface SuccessResponseListFeeScheduleListVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FeeScheduleListVO[];
}

export interface SuccessResponseUserSupportStatusVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: UserSupportStatusVO;
}

export interface UserSupportStatusVO {
  isArchySupportIdentity: boolean;
  isAdminPortalSuperuser: boolean;
}

export interface SuccessResponseListPracticeInfoVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PracticeInfoVO[];
}

export interface SuccessResponseListUserInfoVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: UserInfoVO[];
}

export interface UserInfoVO {
  /** @format int64 */
  id: number;
  /** @format int64 */
  practiceId: number;
  /** @format uuid */
  practiceUuid: string;
  status: "ACTIVE" | "INACTIVE" | "ARCHIVED" | "PENDING" | "FURLOUGHED";
  fullName?: string;
  fullDisplayName: string;
  thumbnail?: string;
  onboardingState?:
    | "GENERAL_INFO"
    | "INSURANCE_INFO"
    | "DENTAL_HISTORY_INFO"
    | "MEDICAL_HISTORY_INFO"
    | "PAYMENT_INFO"
    | "CONSENT"
    | "PDF_SUBMISSION"
    | "COMPLETED";
  startDate?: string;
  practiceTimezoneId: string;
}

export interface SuccessResponseListUserAccountVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: UserAccountVO[];
}

export interface SuccessResponseUserAccountVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: UserAccountVO;
}

export interface SuccessResponseListToothConditionVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ToothConditionVO[];
}

export interface ToothConditionVO {
  /** @format int64 */
  id: number;
  condition:
    | "CARIES"
    | "CRACKED_TOOTH"
    | "PERIAPICAL_ABSCESS"
    | "DIASTEMA_OF_TEETH_MESIAL"
    | "DIASTEMA_OF_TEETH_DISTAL"
    | "DISTAL_INCLINATION"
    | "MESIAL_INCLINATION"
    | "WATCH_TOOTH"
    | "WATCH_TOOTH_SURFACE"
    | "SENSITIVE_DENTIN"
    | "GINGIVAL_RECESSION"
    | "RETAINED_ROOT"
    | "ASYMPTOMATIC_PULPITIS"
    | "CARIES_OF_CERVICAL"
    | "CHIPPED_TOOTH"
    | "ABFRACTION"
    | "LINGUAL_ABFRACTION"
    | "BUCCAL_ABFRACTION"
    | "IMPACTED_DISTAL"
    | "IMPACTED_MESIAL"
    | "SUPRA_ERUPTED"
    | "LINGUAL_RETAINER"
    | "ROOT_TIPS"
    | "ROOT_ABRASION"
    | "ROOT_DECAY"
    | "DEFECT"
    | "IRREVERS_PULP"
    | "REVERS_PULP"
    | "NECROTIC"
    | "APICAL_PERIO"
    | "CARIOUS_PULP_EXP"
    | "CRACKED_FILLING";
  displayName: string;
  visualType:
    | "EXTRACTION"
    | "IMPLANT"
    | "ABUTMENT"
    | "RCT"
    | "POST"
    | "FILLING_DARK"
    | "FILLING_LIGHT"
    | "CROWN_DARK"
    | "CROWN_LIGHT"
    | "BRIDGE_DARK"
    | "BRIDGE_LIGHT"
    | "DENTURE_DARK"
    | "DENTURE_LIGHT"
    | "SEALANT"
    | "WATCH"
    | "SPACE_MAINTAINER"
    | "SDF"
    | "APICOECTOMY"
    | "BU"
    | "PIN"
    | "MARGIN_TOOTH"
    | "CRACKED_TOOTH"
    | "IMPACTED_TOOTH"
    | "RETAINED_ROOT"
    | "SENSITIVE_DENTIN"
    | "PERAPICAL_ABSCESS"
    | "DIASTEMA_MESIAL"
    | "DIASTEMA_DISTAL"
    | "INCLINATION_DISTAL"
    | "INCLINATION_MESIAL"
    | "GING_RECESSION"
    | "WATCH_SURFACE"
    | "CHIPPED_TOOTH"
    | "VENEER"
    | "APICAL_PERIO"
    | "BUCCAL_ABFRACTION"
    | "CARIOUS_PULP_EXP"
    | "CRACKED_FILLING"
    | "DEFECT"
    | "IMPACTED_DISTAL"
    | "IMPACTED_MESIAL"
    | "LINGUAL_ABFRACTION"
    | "LINGUAL_RETAINER"
    | "NECROTIC"
    | "ROOT_ABRASION"
    | "ROOT_DECAY"
    | "ROOT_TIPS"
    | "SUPRA_ERUPTED"
    | "NO_VISUAL_FOR_CONDITION"
    | "NONE";
  surfaceRequired: boolean;
}

export interface SuccessResponseListTaxonomyCodeVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: TaxonomyCodeVO[];
}

export interface TaxonomyCodeVO {
  code: string;
  description: string;
}

export interface SuccessResponseSelfBookingConfigVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: SelfBookingConfigVO;
}

export interface SuccessResponseListProviderVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ProviderVO[];
}

export interface SuccessResponseCollectionInsuranceCarrierVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InsuranceCarrierVO[];
}

export interface SuccessResponsePracticeInfoVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PracticeInfoVO;
}

export interface SuccessResponsePaymentState {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data:
    | "CREATED"
    | "IN_PROGRESS"
    | "AWAITING_RESULT"
    | "PENDING"
    | "SETTLED"
    | "DENIED"
    | "CHARGEBACK"
    | "VOID";
}

export interface ProcedureShortcutCategoryVO {
  category:
    | "EXAMS"
    | "XRAYS"
    | "POST_COMPOSITE"
    | "ANT_COMPOSITE"
    | "AMALGAM"
    | "PERIO"
    | "APPLIANCES"
    | "CROWNS_OR_BRIDGES"
    | "ENDO"
    | "IMPLANTS"
    | "ORAL_SURGERY"
    | "ADJUNCT";
  displayName: string;
}

export interface SuccessResponseListProcedureShortcutCategoryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ProcedureShortcutCategoryVO[];
}

export interface SuccessResponseListWorkingHourItemVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: WorkingHourItemVO[];
}

export interface SsnResponse {
  /** @pattern ^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$ */
  ssn: string;
}

export interface SuccessResponseSsnResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: SsnResponse;
}

export interface SuccessResponseProfileImageVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ProfileImageVO;
}

export interface SuccessResponseListFolderVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FolderVO[];
}

export interface SuccessResponseListDocumentVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: DocumentVO[];
}

export interface SuccessResponseListRoleVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: RoleVO[];
}

export interface SuccessResponseUnlayerTokenVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: UnlayerTokenVO;
}

export interface UnlayerTokenVO {
  userId: string;
  userSignature: string;
}

export interface SuccessResponseListTemplateVariableGroupVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: TemplateVariableGroupVO[];
}

export interface TemplateVariableGroupVO {
  type:
    | "PRACTICE"
    | "PATIENT"
    | "APPOINTMENT"
    | "PROVIDER"
    | "FORMS"
    | "RECALL"
    | "PROCEDURES"
    | "TREATMENT_PLAN"
    | "LINKS";
  variables: TemplateVariableVO[];
}

export interface TemplateVariableVO {
  key:
    | "PRACTICE_NAME"
    | "PRACTICE_ADDRESS"
    | "PRACTICE_PHONE_NUMBER"
    | "PATIENT_PREFERRED_NAME"
    | "PATIENT_FIRST_NAME"
    | "PATIENT_LAST_NAME"
    | "APPOINTMENT_DATE"
    | "APPOINTMENT_TIME"
    | "PROVIDER_FIRST_NAME"
    | "PROVIDER_LAST_NAME"
    | "PROVIDER_FULL_NAME"
    | "RECALL_DUE_DATE"
    | "PROCEDURES_LIST"
    | "FORMS_LINK"
    | "PAYMENT_LINK"
    | "NEXT_APPOINTMENT"
    | "TREATMENT_PLAN_NAME"
    | "UNSUBSCRIBE_LINK"
    | "SELF_BOOKING_LINK"
    | "CONFIRM_APPOINTMENT_LINK";
  exampleValue: string;
}

export interface SuccessResponseListTagVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: TagVO[];
}

export interface SuccessResponseSupportUserVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: SupportUserVO;
}

export interface SupportUserVO {
  /** @format int64 */
  id: number;
  name: NameVO;
  roleV2: RoleVO;
}

export interface SuccessResponseZendeskSsoResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ZendeskSsoResponse;
}

export interface ZendeskSsoResponse {
  token: string;
}

export interface LearnuponSsoResponse {
  url: string;
}

export interface SuccessResponseLearnuponSsoResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LearnuponSsoResponse;
}

export interface SuccessResponseListIPFilterVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: IPFilterVO[];
}

export interface SuccessResponseListScheduleBlockVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ScheduleBlockVO[];
}

export interface SuccessResponseListSavedFilterVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: SavedFilterVO[];
}

export interface SuccessResponseCollectionRoomVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: RoomVO[];
}

export interface EmployeeRolesVO {
  role: RoleVO;
  employees: NameVO[];
}

export interface SuccessResponseListEmployeeRolesVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: EmployeeRolesVO[];
}

export interface SuccessResponseListNameVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: NameVO[];
}

export interface SuccessResponseListPermissionActionVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PermissionActionVO[];
}

export interface PracticeRemoteSettingsVO {
  /** @format int64 */
  practiceId: number;
  /** @format uuid */
  practiceUuid: string;
  serverUsername?: string;
  serverPassword?: string;
  pmsUsername?: string;
  pmsPassword?: string;
  anydeskAddress?: string;
  anydeskAddress2?: string;
  anydeskAddress3?: string;
}

export interface SuccessResponsePracticeRemoteSettingsVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PracticeRemoteSettingsVO;
}

export interface DosespotEntityVO {
  /** @format int64 */
  id: number;
  type: "PRACTICE" | "PATIENT" | "EMPLOYEE";
  name: string;
  isProxy: boolean;
  isConfirmed: boolean;
}

export interface SuccessResponseListDosespotEntityVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: DosespotEntityVO[];
}

export interface PhoneExtensionVO {
  extension: string;
  name: string;
}

export interface SuccessResponseListPhoneExtensionVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PhoneExtensionVO[];
}

export interface PayrollSummaryVO {
  /** @format uuid */
  uuid: string;
  status: "DRAFT" | "CALCULATING" | "REVIEW" | "SUBMITTED" | "CANCELED";
  type: "REGULAR" | "OFF_CYCLE" | "DISMISSAL";
  periodStartDate: string;
  periodEndDate: string;
  payrollDeadline: string;
  submittedDate?: string;
  employeePayDate: string;
  /** Indicates if the payroll can be canceled. Only applies for payrolls with the SUBMITTED status. */
  isCancelable: boolean;
  /**
   * The total amount of the payroll in cents. Will be null until the payroll has been processed.
   * @format int64
   */
  totalAmount?: number;
}

export interface SuccessResponseListPayrollSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PayrollSummaryVO[];
}

/** Breakdown of the payroll after calculation. Only available for payrolls with a status of REVIEW or SUBMITTED. */
export interface PayrollBreakdownVO {
  /**
   * The total amount of the payroll in cents. Sum of gross employee pay and employer taxes.
   * @format int64
   */
  totalAmount: number;
  /**
   * The total amount debited from the practice in cents.
   * @format int64
   */
  debitAmount: number;
  /**
   * The total amount of taxes generated by employees in cents.
   * @format int64
   */
  totalTaxesByEmployees: number;
  /**
   * The total amount of taxes generated by the company in cents.
   * @format int64
   */
  totalTaxesByCompany: number;
  taxes: PayrollTaxVO[];
  /** Breakdown of amounts debited from the practice for the payroll. */
  debits: PayrollDebitVO;
}

/** Breakdown of amounts debited from the practice for the payroll. */
export interface PayrollDebitVO {
  /** @format int64 */
  directDeposits: number;
  /** @format int64 */
  reimbursements: number;
  /** @format int64 */
  childSupportGarnishments: number;
  /** @format int64 */
  taxesByEmployeesAndEmployers: number;
}

export interface PayrollErrorVO {
  code: "UPDATE";
  message: string;
}

export interface PayrollTaxVO {
  name: string;
  /** @format int64 */
  amountByEmployee: number;
  /** @format int64 */
  amountByCompany: number;
}

export interface PayrollVO {
  /** @format uuid */
  uuid: string;
  status: "DRAFT" | "CALCULATING" | "REVIEW" | "SUBMITTED" | "CANCELED";
  type: "REGULAR" | "OFF_CYCLE" | "DISMISSAL";
  periodStartDate: string;
  periodEndDate: string;
  payrollDeadline: string;
  submittedDate?: string;
  employeePayDate: string;
  /** Indicates if the payroll can be canceled. Only applies for payrolls with the SUBMITTED status. */
  isCancelable: boolean;
  lineItems: PayrollLineItemVO[];
  /** Breakdown of the payroll after calculation. Only available for payrolls with a status of REVIEW or SUBMITTED. */
  breakdown?: PayrollBreakdownVO;
  errors: PayrollErrorVO[];
}

export interface SuccessResponsePayrollVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PayrollVO;
}

export interface PayPeriodVO {
  /** @format uuid */
  payrollUuid: string;
  startDate: string;
  endDate: string;
}

export interface SuccessResponseListPayPeriodVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PayPeriodVO[];
}

export interface EmployeeConsolidationVO {
  active: MergeEmployeeVO[];
  archived: MergeEmployeeVO[];
}

export interface MergeEmployeeVO {
  firstName: string;
  lastName: string;
  middleName?: string;
  jobTitle: string;
  /** @format int64 */
  archyId?: number;
  /** @format uuid */
  gustoId?: string;
}

export interface SuccessResponseEmployeeConsolidationVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: EmployeeConsolidationVO;
}

export interface SuccessResponseListPaymentDeviceVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PaymentDeviceVO[];
}

export interface SuccessResponseListWalletVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: WalletVO[];
}

export interface SuccessResponseListWalletActivityVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: WalletActivityVO[];
}

export interface SuccessResponseListTreatmentPlanVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: TreatmentPlanVO[];
}

export interface SuccessResponseListStatementVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: StatementVO[];
}

export interface StatementItemCountPerDateVO {
  /** @format int32 */
  count: number;
  date: string;
}

export interface SuccessResponseListStatementItemCountPerDateVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: StatementItemCountPerDateVO[];
}

export interface StatementDateRangeVO {
  startDate?: string;
  endDate?: string;
}

export interface SuccessResponseStatementDateRangeVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: StatementDateRangeVO;
}

export interface RefundablePaymentVO {
  payment: PaymentVO;
  /** @format int64 */
  refundableAmount: number;
}

export interface SuccessResponseListRefundablePaymentVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: RefundablePaymentVO[];
}

export interface SuccessResponseListRecallVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: RecallVO[];
}

export interface SuccessResponseListPerioChartExamVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PerioChartExamVO[];
}

export interface SuccessResponseListPaymentProfileVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PaymentProfileVO[];
}

export interface ClinicalNoteFormsResponse {
  forms: FormVO[];
  submissions: Record<string, FormSubmissionVO>;
}

export interface SuccessResponseClinicalNoteFormsResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ClinicalNoteFormsResponse;
}

export interface PatientOutstandingBalanceSummaryVO {
  /** @format int64 */
  totalPendingInvoiceAmount: number;
  /** @format int64 */
  outstandingBalance: number;
  /** @format int32 */
  pendingInvoiceCount: number;
}

export interface SuccessResponsePatientOutstandingBalanceSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientOutstandingBalanceSummaryVO;
}

export interface SuccessResponseOptionalAppointmentVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AppointmentVO;
}

export interface SuccessResponseListMountVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MountVO[];
}

export interface MigratedTransactionVO {
  /** @format int64 */
  id: number;
  responsiblePatient: NameVO;
  date: string;
  transactionId: string;
  employee?: NameVO;
  type: "ADJUSTMENT" | "PAYMENT" | "PROCEDURE" | "OTHER";
  code?: string;
  description: string;
  /** @format int64 */
  amount: number;
  note?: string;
}

export interface SuccessResponseListMigratedTransactionVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MigratedTransactionVO[];
}

export interface SuccessResponseListMessageVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MessageVO[];
}

export interface MessageOverviewVO {
  /** @format int64 */
  all: number;
  /** @format int64 */
  chat: number;
  /** @format int64 */
  sms: number;
  /** @format int64 */
  email: number;
  /** @format int64 */
  call: number;
}

export interface SuccessResponseMessageOverviewVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MessageOverviewVO;
}

export interface SuccessResponseListMedicalImageVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MedicalImageVO[];
}

export interface PearlAnnotationsInner {
  enml_annotation_id?: string;
  category_id?: "1" | "3" | "4" | "5" | "6" | "7" | "8" | "10" | "11" | "14";
  category?:
    | "crown"
    | "periapical.radiolucency"
    | "filling"
    | "anatomy"
    | "caries"
    | "margin.discrepancy"
    | "implant"
    | "root.canal"
    | "bridge"
    | "calculus";
  condition_id?:
    | "1"
    | "2"
    | "3"
    | "4"
    | "5"
    | "6"
    | "7"
    | "8"
    | "9"
    | "10"
    | "11"
    | "12"
    | "13"
    | "14"
    | "15"
    | "20"
    | "21"
    | "55"
    | "56"
    | "57"
    | "64"
    | "77"
    | "86"
    | "87"
    | "88";
  condition?:
    | "conditions.pathologies.ca.eo"
    | "conditions.pathologies.ca.id"
    | "conditions.pathologies.ca.aoip"
    | "conditions.pathologies.pr"
    | "conditions.restoration_e.cm"
    | "conditions.restoration_e.cnm"
    | "conditions.restoration_e.fm"
    | "conditions.restoration_e.fnm"
    | "conditions.restoration_e.rc"
    | "conditions.restoration_e.im"
    | "conditions.restoration_e.br"
    | "conditions.restoration_f.om"
    | "conditions.restoration_f.sm"
    | "conditions.pathologies.calculus.low"
    | "conditions.pathologies.calculus.high"
    | "conditions.restoration_f.oh"
    | "conditions.anatomy.tooth"
    | "conditions.anatomy.biowidth"
    | "conditions.anatomy.occlusal"
    | "conditions.pathologies.ca.rd"
    | "conditions.anatomy.overlap";
  contour_box?: PearlAnnotationsInnerContourBox;
  level?: "l" | "m" | "h";
  confidence?: number;
  is_deleted?: boolean;
  is_dismissed?: boolean;
  is_accepted?: boolean;
  polygon?: PearlAnnotationsInnerPolygonInner[];
  relationships?: PearlAnnotationsInnerRelationshipsInner[];
  line_segment?: PearlAnnotationsInnerLineSegment;
  text?: PearlAnnotationsInnerText;
  stroke_color?: string;
  /** @format int32 */
  stroke_width?: number;
  fill_color?: string;
  hover_fill_color?: string;
  text_color?: string;
  hover_stroke_color?: string;
  label_colors?: PearlAnnotationsInnerLabelColors;
}

export interface PearlAnnotationsInnerContourBox {
  /** @format int32 */
  x?: number;
  /** @format int32 */
  y?: number;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
}

export interface PearlAnnotationsInnerLabelColors {
  fill_color?: string;
  text_color?: string;
}

export interface PearlAnnotationsInnerLineSegment {
  x1?: number;
  y1?: number;
  x2?: number;
  y2?: number;
  size?: number;
}

export interface PearlAnnotationsInnerPolygonInner {
  x?: number;
  y?: number;
}

export interface PearlAnnotationsInnerRelationshipsInner {
  /** @format int64 */
  id?: number;
  relation?: string;
  prop_value?:
    | "background"
    | "conditions.anatomy.bone"
    | "conditions.anatomy.cementum"
    | "conditions.anatomy.dentin"
    | "conditions.anatomy.enamel"
    | "conditions.anatomy.pulp"
    | "conditions.restoration_e"
    | "conditions.anatomy.occlusal";
  metric_value?: number;
  annotation_id?: string;
}

export interface PearlAnnotationsInnerText {
  text?: string;
  x?: number;
  y?: number;
}

export interface PearlImageResponseGetEndpoints {
  result?: PearlImageResponseGetEndpointsResult;
}

export interface PearlImageResponseGetEndpointsResult {
  request_id?: string;
  is_completed?: boolean;
  is_rejected?: boolean;
  phash?: string;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
  enml_image_id?: string;
  is_deleted?: boolean;
  image_ingestion_id?: number;
  patient_id?: string;
  pearl_patient_id?: string;
  study_date?: string;
  organization_id?: string;
  office_id?: string;
  result_id?: string;
  annotations?: PearlAnnotationsInner[];
  toothParts?: PearlToothPartsInner[];
}

export interface PearlToothPartsInner {
  id?: string;
  confidence?: number;
  contour?: PearlToothPartsInnerContour;
  condition?:
    | "conditions.anatomy.bone"
    | "conditions.anatomy.enamel"
    | "conditions.anatomy.dentin"
    | "conditions.anatomy.pulp"
    | "conditions.anatomy.cementum"
    | "conditions.restoration_e"
    | "conditions.anatomy.ian"
    | "conditions.anatomy.si"
    | "conditions.anatomy.nc"
    | "conditions.anatomy.bo";
  condition_id?: "90" | "91" | "92" | "93" | "94" | "95" | "16" | "17" | "18" | "19";
  polygon?: PearlToothPartsInnerPolygonInner[];
  category?: "Tooth Parts";
  category_id?: "23";
  color?: PearlToothPartsInnerColor;
  region_representation?: object;
}

export interface PearlToothPartsInnerColor {
  legend_color?: string;
  stroke_color?: string;
  fill_color?: string;
  /** @format int32 */
  stroke_width?: number;
}

export interface PearlToothPartsInnerContour {
  /** @format int32 */
  x?: number;
  /** @format int32 */
  y?: number;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
}

export interface PearlToothPartsInnerPolygonInner {
  x?: number;
  y?: number;
}

export interface SuccessResponsePearlImageResponseGetEndpoints {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PearlImageResponseGetEndpoints;
}

export interface SuccessResponseMedicalHistoryResponseVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MedicalHistoryResponseVO;
}

export interface MedicalHistoryFormItemVO {
  /** @format int64 */
  id: number;
  name: string;
  shortName?: string;
  description?: string;
  acceptedValues: string[];
  acceptedFormat: "BOOLEAN" | "TEXT" | "MULTILINE_TEXT" | "ENUM" | "DATE" | "OPTIONAL_DATE";
  isOptional: boolean;
  gender?: "MALE" | "FEMALE" | "OTHER" | "UNKNOWN";
  dateSupported?: "FUTURE" | "PAST";
  type: "MEDICAL_CONDITION" | "DENTAL_CONDITION" | "MEDICAL_QUESTION" | "DENTAL_QUESTION" | "SMILE_ANALYSIS";
  toggleType?: "SWITCH" | "CHECKBOX";
  toggleText?: string;
  toggleValue?: string;
}

export interface MedicalHistoryFormVO {
  dentalFormItems: MedicalHistoryFormItemVO[];
  medicalFormItems: MedicalHistoryFormItemVO[];
  smileAnalysisFormItems: MedicalHistoryFormItemVO[];
}

export interface SuccessResponseMedicalHistoryFormVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MedicalHistoryFormVO;
}

export interface SuccessResponseListLedgerOrderVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LedgerOrderVO[];
}

export interface LedgerBalanceSummaryVO {
  currency: string;
  /** @format int64 */
  entityBalance: number;
  /** @format int64 */
  insuranceBalance: number;
  /** @format int64 */
  totalDiscountAmount: number;
}

export interface SuccessResponseLedgerBalanceSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LedgerBalanceSummaryVO;
}

export type LedgerV2AdjustmentEntryVO = UtilRequiredKeys<
  LedgerV2EntryVO,
  | "subEntries"
  | "entity"
  | "timestamp"
  | "collectionAmount"
  | "runningBalance"
  | "patientRunningBalance"
  | "insuranceRunningBalance"
  | "type"
> & {
  /** @format uuid */
  adjustmentUuid: string;
  customAdjustmentType: CustomAdjustmentTypeVO;
  employee?: NameVO;
  note?: string;
  /** @format uuid */
  invoiceUuid?: string;
  type: "ADJUSTMENT";
};

export type LedgerV2AppointmentEditSubEntryVO = UtilRequiredKeys<
  LedgerV2SubEntryVO,
  "collectionAmount" | "timestamp" | "type"
> & {
  /** @format int64 */
  appointmentId: number;
  procedures: LedgerV2ProcedureItemVO[];
  /** @format int64 */
  previousPatientAmount: number;
  /** @format int64 */
  patientAmount: number;
  /** @format int64 */
  previousInsuranceAmount: number;
  /** @format int64 */
  insuranceAmount: number;
  type: "APPOINTMENT_EDIT";
};

export type LedgerV2AppointmentEntryVO = UtilRequiredKeys<
  LedgerV2EntryVO,
  | "subEntries"
  | "entity"
  | "timestamp"
  | "collectionAmount"
  | "runningBalance"
  | "patientRunningBalance"
  | "insuranceRunningBalance"
  | "type"
> & {
  /** @format int64 */
  appointmentId: number;
  providers: NameVO[];
  /** @format int64 */
  ucrRate: number;
  /** @format int64 */
  negotiatedRate: number;
  /** @format int64 */
  writeOffAmount: number;
  /** @format int64 */
  deductibleAmount: number;
  type: "APPOINTMENT";
};

export type LedgerV2AppointmentSubEntryVO = UtilRequiredKeys<
  LedgerV2SubEntryVO,
  "collectionAmount" | "timestamp" | "type"
> & {
  /** @format int64 */
  appointmentId: number;
  procedureItems: LedgerV2ProcedureItemVO[];
  adjustments: AppointmentAdjustmentVO[];
  /** @format int64 */
  patientAmount: number;
  /** @format int64 */
  insuranceAmount: number;
  type: "APPOINTMENT";
};

export type LedgerV2ClaimBalanceTransferSubEntryVO = UtilRequiredKeys<
  LedgerV2SubEntryVO,
  "collectionAmount" | "timestamp" | "type"
> & {
  /** @format int64 */
  previousPatientAmount: number;
  /** @format int64 */
  claimNumber: number;
  /** @format uuid */
  claimUuid: string;
  insuranceCarrier: string;
  insuranceOrdinal: "PRIMARY" | "SECONDARY" | "OTHER";
  procedures: LedgerV2ProcedureItemVO[];
  /** @format int64 */
  patientAmount: number;
  type: "CLAIM_BALANCE_TRANSFER";
};

export type LedgerV2ClaimSubEntryVO = UtilRequiredKeys<
  LedgerV2SubEntryVO,
  "collectionAmount" | "timestamp" | "type"
> & {
  /** @format uuid */
  claimUuid: string;
  claimState: "PENDING" | "SUBMITTING" | "SUBMITTED" | "FAILED" | "COMPLETED" | "VOIDED";
  insuranceOrdinal: "PRIMARY" | "SECONDARY" | "OTHER";
  /** @format int64 */
  claimNumber: number;
  insuranceCarrier: string;
  paymentStatus: "UNPAID" | "NOT_SUBMITTED" | "PAID_AS_EXPECTED" | "OVERPAID" | "UNDERPAID" | "DENIED";
  /** @format int64 */
  patientAmount: number;
  /** @format int64 */
  patientEstimatedAmount: number;
  /** @format int64 */
  insuranceAmount: number;
  /** @format int64 */
  insuranceEstimatedAmount: number;
  procedures: LedgerV2ProcedureItemVO[];
  payments: ClaimPaymentVO[];
  lineItemGroups: ClaimLineItemGroupVO[];
  /** @format uuid */
  draftEobPaymentUuid?: string;
  type: "CLAIM";
};

export interface LedgerV2EntryPaymentVO {
  paymentMethod:
    | "STORED_PROFILE"
    | "CASH"
    | "CHECK"
    | "STORED_POS"
    | "EXTERNAL_POS"
    | "EFT"
    | "WALLET"
    | "CARE_CREDIT"
    | "THIRD_PARTY_FINANCING"
    | "BALANCE_TRANSFER";
  /** @format int64 */
  paymentCreatedAt: number;
  /** @format uuid */
  paymentUuid: string;
  checkPayload?: CheckPayload;
  externalPosPayload?: ExternalPosPayload;
  eftPayload?: EFTPayload;
  paymentType?: "CHARGE" | "REFUND";
  paymentProfile?: PaymentProfileVO;
  balanceTransferType?: "INTRA_INVOICE" | "INTER_INVOICE";
  /** @format uuid */
  balanceTransferFromUuid?: string;
  balanceTransferFromInvoiceNumber?: string;
  /** @format int64 */
  amount: number;
  /** @format int64 */
  totalAmount: number;
  note?: string;
  /**
   * Indicates the set of permitted actions that can be performed on this payment.
   * @uniqueItems true
   */
  permittedActions: ("EDIT" | "DELETE")[];
  /** @format uuid */
  batchPaymentUuid?: string;
  /**
   * The family payment amount if this payment is associated with a family payment.
   * @format int64
   */
  familyPaymentAmount?: number;
}

export interface LedgerV2EntryVO {
  /** Invoice sub-entries will always be fetched regardless of the includeSubentry value. */
  subEntries: (
    | LedgerV2AppointmentEditSubEntryVO
    | LedgerV2AppointmentSubEntryVO
    | LedgerV2ClaimBalanceTransferSubEntryVO
    | LedgerV2ClaimSubEntryVO
    | LedgerV2InvoiceSubEntryVO
  )[];
  entity: EntityReferenceVO;
  /** @format date-time */
  timestamp: string;
  /** @format int64 */
  collectionAmount: number;
  /** @format int64 */
  runningBalance: number;
  /** @format int64 */
  patientRunningBalance: number;
  /** @format int64 */
  insuranceRunningBalance: number;
  type: "APPOINTMENT" | "ADJUSTMENT";
}

export type LedgerV2InvoiceSubEntryVO = UtilRequiredKeys<
  LedgerV2SubEntryVO,
  "collectionAmount" | "timestamp" | "type"
> & {
  entryReference: InvoiceEntryReference;
  /** @format uuid */
  invoiceUuid: string;
  invoiceNumber: string;
  invoiceState: "FINALIZED" | "VOID" | "PARTIALLY_PAID" | "PAID";
  procedureItems: LedgerV2ProcedureItemVO[];
  appointmentAdjustments: AppointmentAdjustmentVO[];
  /** @format int64 */
  patientAmount: number;
  paymentLedgerLineItemReferences: PaymentLedgerAttributionVO[];
  paymentsForLedgerEntry: LedgerV2EntryPaymentVO[];
  /** @format int64 */
  invoiceAmount: number;
  type: "INVOICE";
};

export interface LedgerV2ProcedureItemVO {
  /** @format int64 */
  patientProcedureId: number;
  provider?: NameVO;
  procedureName: string;
  laymanProcedureName?: string;
  toothName?: string;
  surface?: string;
  cdtCode: string;
  procedureArea?: string;
  /** @format int64 */
  patientEstimatedAmount: number;
  /** @format int64 */
  patientAmount: number;
  /** @format int64 */
  patientSubtotalAmount: number;
  /** @format int64 */
  previousPatientAmount: number;
  /** @format int64 */
  insuranceEstimatedAmount: number;
  /** @format int64 */
  insuranceAmount: number;
  /** @format int64 */
  previousInsuranceAmount: number;
}

/** Invoice sub-entries will always be fetched regardless of the includeSubentry value. */
export interface LedgerV2SubEntryVO {
  /** @format int64 */
  collectionAmount: number;
  /** @format date-time */
  timestamp: string;
  type: "APPOINTMENT" | "INVOICE" | "CLAIM" | "APPOINTMENT_EDIT" | "CLAIM_BALANCE_TRANSFER";
}

export interface SuccessResponseListLedgerV2EntryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: (LedgerV2AdjustmentEntryVO | LedgerV2AppointmentEntryVO)[];
}

export interface SuccessResponseLedgerV2EntryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LedgerV2AdjustmentEntryVO | LedgerV2AppointmentEntryVO;
}

export interface SuccessResponseListInvoiceVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InvoiceVO[];
}

export interface SuccessResponseListInvoiceEntryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InvoiceEntryVO[];
}

export interface SuccessResponseListPatientInsuranceResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientInsuranceResponse[];
}

export interface AutoEligibilityStatusVO {
  enabled: boolean;
}

export interface SuccessResponseAutoEligibilityStatusVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AutoEligibilityStatusVO;
}

export interface FormSubmissionSummaryVO {
  /** @format uuid */
  formPublishedContentUuid: string;
  dateUpdatedByPractice?: string;
  dateUpdatedByPatient?: string;
}

export interface SuccessResponseListFormSubmissionSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FormSubmissionSummaryVO[];
}

export interface SuccessResponseListFormTaskVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FormTaskVO[];
}

export interface SuccessResponseListLong {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: number[];
}

export interface PatientCallCardVO {
  summary: PatientCallSummaryVO;
  contact?: PatientCallSummaryVO;
  nextAppointment?: AppointmentVO;
  familyMembers: LinkedFamilyMemberVO[];
  multiplePatientsWithSamePhone: boolean;
}

export interface PatientCallSummaryVO {
  firstName: string;
  lastName: string;
  dob: string;
  /** @format int64 */
  age: number;
  gender?: "MALE" | "FEMALE" | "OTHER" | "UNKNOWN";
  thumbnail?: string;
}

export interface SuccessResponsePatientCallCardVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientCallCardVO;
}

export interface MedicalConditionsAuditVO {
  added?: string;
  removed?: string;
}

export interface MedicalHistoryAuditVO {
  /** @format int64 */
  id: number;
  date?: string;
  time?: string;
  editorName?: string;
  isEmployee?: boolean;
  medicalConditions?: MedicalConditionsAuditVO;
  dentalConditions?: MedicalConditionsAuditVO;
  medicalQuestions?: MedicalConditionsAuditVO;
  dentalQuestions?: MedicalConditionsAuditVO;
}

export interface SuccessResponseListMedicalHistoryAuditVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MedicalHistoryAuditVO[];
}

export interface SuccessResponseListAppointmentVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AppointmentVO[];
}

export interface PatientOverviewVO {
  /** @format int64 */
  all?: number;
  /** @format int64 */
  totalActive?: number;
  /** @format int64 */
  appointmentsToday?: number;
  /** @format int64 */
  expiredInsurance?: number;
  /** @format int64 */
  unverifiedInsurance?: number;
}

export interface SuccessResponsePatientOverviewVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientOverviewVO;
}

export interface PatientProcedureInsuranceStatusVO {
  primaryClaimSubmitted: boolean;
  secondaryClaimSubmitted: boolean;
  secondaryInsuranceExists: boolean;
}

export interface SuccessResponsePatientProcedureInsuranceStatusVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientProcedureInsuranceStatusVO;
}

export interface SuccessResponseListPatientNoteAuditEntryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PatientNoteAuditEntryVO[];
}

export interface SuccessResponseListTimeRangeVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: TimeRangeVO[];
}

export interface MigrationSourceEntryCountVO {
  sourceSchema: string;
  /** @format int64 */
  count: number;
}

export interface SuccessResponseListMigrationSourceEntryCountVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MigrationSourceEntryCountVO[];
}

export interface MigrationSourceEntryVO {
  /** @format int64 */
  id: number;
  /** @format int64 */
  migrationRunId: number;
  sourceSchema: string;
  sourceId: string;
  sourceEntity: string;
  sourceIsEncrypted: boolean;
}

export interface SuccessResponseMigrationSourceEntryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MigrationSourceEntryVO;
}

export interface RankedPatientMessageVO {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  patientId: number;
  content?: string;
  /** @format int64 */
  timestamp?: number;
  isRead?: boolean;
  isOutgoing?: boolean;
  patientName: string;
  patientThumbnail?: string;
}

export interface SuccessResponseListRankedPatientMessageVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: RankedPatientMessageVO[];
}

export interface SuccessResponseListMessageCampaignSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MessageCampaignSummaryVO[];
}

export interface SuccessResponseTwilioOnboardingStatusVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: TwilioOnboardingStatusVO;
}

export interface TwilioOnboardingStatusVO {
  status: "PENDING" | "IN_PROGRESS" | "FAILURE" | "COMPLETE";
  practiceCommsNumber?: string;
}

export interface AvailableTwilioPhoneNumberVO {
  phoneNumber: string;
  phoneNumberFriendlyName: string;
  country: string;
  locality?: string;
  region: string;
}

export interface SuccessResponseListAvailableTwilioPhoneNumberVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AvailableTwilioPhoneNumberVO[];
}

export interface MassTextStatus {
  isEnabled: boolean;
}

export interface SuccessResponseMassTextStatus {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: MassTextStatus;
}

export interface SuccessResponseListLabVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LabVO[];
}

export interface SuccessResponseListLabCaseVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LabCaseVO[];
}

export interface SuccessResponseListLabCaseTypeVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LabCaseTypeVO[];
}

export interface SuccessResponseListLabCaseReturnReasonVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LabCaseReturnReasonVO[];
}

export interface AutomationJourneyVO {
  /** @format uuid */
  uuid: string;
  type: "APPOINTMENT" | "POST_APPOINTMENT" | "RECALL" | "FORMS";
  actions: AutomationActionVO[];
}

export interface SuccessResponseAutomationJourneyVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AutomationJourneyVO;
}

export interface SuccessResponseListJourneyVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: JourneyVO[];
}

export interface ChatUnreadMessage {
  /** @format int64 */
  unreadMessageCount: number;
}

export interface SuccessResponseChatUnreadMessage {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ChatUnreadMessage;
}

export interface SuccessResponseListInsuranceCarrierVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InsuranceCarrierVO[];
}

export interface InsuranceCarrierListEntryVO {
  /** @format int64 */
  id: number;
  name: string;
  payerId: string;
  type: "GLOBAL" | "CUSTOM";
  /** @format int64 */
  patientCount: number;
  /** @format int64 */
  planCount: number;
  inNetwork: boolean;
  feeSchedules: NameIdVO[];
}

export interface SuccessResponseListInsuranceCarrierListEntryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InsuranceCarrierListEntryVO[];
}

export interface InsurancePlanSummaryVO {
  /** @format uuid */
  uuid: string;
  groupNumber?: string;
  groupName?: string;
  type:
    | "PPO"
    | "TOA_SOA"
    | "EPO"
    | "HMO_DHMO"
    | "MEDICAID_CHIP"
    | "POS"
    | "DISCOUNT"
    | "SELF_FUNDED_ERISA"
    | "DIRECT_REIMBURSEMENT"
    | "FEDERAL"
    | "MEDICARE"
    | "TRICARE";
  employerName?: string;
  /** @format int64 */
  patientCount: number;
  feeSchedule?: NameIdVO;
  carrier: NameIdVO;
  description?: string;
  autoEligibility: boolean;
}

export interface SuccessResponseListInsurancePlanSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InsurancePlanSummaryVO[];
}

export interface InsurancePlanOverviewVO {
  /** @format int64 */
  patients: number;
}

export interface SuccessResponseInsurancePlanOverviewVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: InsurancePlanOverviewVO;
}

export interface GroupedBenefitLimitationsVO {
  /** @deprecated */
  shortcutBenefitLimitations: ShortcutBenefitLimitationsGroupVO[];
  shortcutLimitations?: ShortcutBenefitLimitationVO[];
  completeBenefitLimitations: BenefitLimitationVO[];
}

export interface ShortcutBenefitLimitationsGroupVO {
  group: "DIAGNOSTIC" | "PREVENTIVE" | "RESTORATIVE" | "PERIODONTAL" | "PROSTHODONTICS" | "IMPLANTS" | "NONE";
  benefitLimitations: ShortcutBenefitLimitationVO[];
}

export interface SuccessResponseGroupedBenefitLimitationsVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: GroupedBenefitLimitationsVO;
}

export interface PracticeImagingSettingsVO {
  dateOnboardedWithPearl?: string;
  /** @uniqueItems true */
  features: ("PEARL" | "ARCHY_IMAGING_SERVICE")[];
}

export interface SuccessResponsePracticeImagingSettingsVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PracticeImagingSettingsVO;
}

export interface CompanyOnboardingStatusVO {
  status:
    | "NOT_SETUP"
    | "NEEDS_AUTHENTICATION"
    | "NEEDS_ONBOARDING"
    | "NEEDS_MIGRATION"
    | "NOT_APPROVED"
    | "ONBOARDED";
}

export interface SuccessResponseCompanyOnboardingStatusVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: CompanyOnboardingStatusVO;
}

export interface GustoErrorVO {
  message: string;
  /** @format int64 */
  employeeId?: number;
}

export interface GustoOnboardingErrorsVO {
  archyErrors: GustoErrorVO[];
  gustoErrorsFromOnboardingSteps: GustoErrorVO[];
}

export interface SuccessResponseGustoOnboardingErrorsVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: GustoOnboardingErrorsVO;
}

export interface FormSummaryVO {
  /** @format uuid */
  uuid: string;
  slug?: "DENTAL_HISTORY" | "MEDICAL_HISTORY" | "CONSENT" | "TREATMENT_PLAN_FORM" | "APPOINTMENT_FORM";
  title: string;
  description?: string;
  state: "DRAFT" | "PUBLISHED" | "UNPUBLISHED_CHANGES" | "ARCHIVED";
  dateCreated: string;
  /** @format date-time */
  lastPublishedAt?: string;
  /** @format uuid */
  lastPublishedContentUuid?: string;
  type: "INTAKE" | "CLINICAL_NOTE";
  dentalProcedures: DentalProcedureVO[];
  clinicalNoteData?: ClinicalNoteFormDataVO;
}

export interface SuccessResponseListFormSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FormSummaryVO[];
}

export interface CardVolumeDistribution {
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  card_present_percentage: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  mail_order_telephone_order_percentage: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  ecommerce_percentage: number;
}

export interface FinixAddress {
  city?: string;
  country?: string;
  region?: string;
  line1?: string;
  line2?: string;
  postal_code?: string;
}

export interface FinixDate {
  /** @format int32 */
  year: number;
  /** @format int32 */
  day: number;
  /** @format int32 */
  month: number;
}

export interface FinixMerchantBusiness {
  first_name: string;
  last_name: string;
  title: string;
  dob: FinixDate;
  /** @format int32 */
  principal_percentage_ownership: number;
  /** @pattern ^[0-9]{10}$ */
  phone: string;
  email: string;
  personal_address: FinixAddress;
  /** @format int64 */
  max_transaction_amount: number;
  /** @format int64 */
  annual_card_volume: number;
  has_accepted_credit_cards_previously?: boolean;
  /**
   * @minLength 1
   * @maxLength 20
   */
  default_statement_descriptor: string;
  incorporation_date: FinixDate;
  business_address: FinixAddress;
  ownership_type: "PUBLIC" | "PRIVATE";
  /** @pattern ^(\d{9})$ */
  business_tax_id: string;
  doing_business_as: string;
  mcc?: string;
  business_name: string;
  /** @pattern ^(\d{9})$ */
  tax_id: string;
  business_type:
    | "INDIVIDUAL_SOLE_PROPRIETORSHIP"
    | "CORPORATION"
    | "LIMITED_LIABILITY_COMPANY"
    | "PARTNERSHIP"
    | "ASSOCIATION_ESTATE_TRUST"
    | "TAX_EXEMPT_ORGANIZATION"
    | "INTERNATIONAL_ORGANIZATION"
    | "GOVERNMENT_AGENCY";
  /** @pattern ^[0-9]{10}$ */
  business_phone: string;
  url: string;
}

export interface FinixMerchantIdentityVO {
  /** @format int64 */
  practiceId: number;
  /** @format uuid */
  practiceUuid?: string;
  merchantIdentity?: string;
  business?: FinixMerchantBusiness;
  entities?: FinixMerchantOwner[];
  underwriting?: FinixMerchantUnderwriting;
  onboardingState?: "PROVISIONING" | "APPROVED" | "REJECTED" | "FORM_COMPLETED";
  /** @format uuid */
  merchantPaymentIdentityUuid?: string;
  /** @format uuid */
  merchantPaymentProfileUuid?: string;
  formStatus?: "IN_PROGRESS" | "COMPLETED";
}

export interface FinixMerchantOwner {
  first_name: string;
  last_name: string;
  title: string;
  dob: FinixDate;
  /** @format int32 */
  principal_percentage_ownership: number;
  /** @pattern ^[0-9]{10}$ */
  phone: string;
  email: string;
  personal_address: FinixAddress;
  /** @pattern ^(\d{9})$ */
  tax_id: string;
}

export interface FinixMerchantUnderwriting {
  merchant_agreement_accepted?: boolean;
  merchant_agreement_ip_address?: string;
  volume_distribution_by_business_type: VolumeDistributionByBusinessType;
  /** @format int64 */
  average_ach_transfer_amount: number;
  /** @format int64 */
  annual_ach_volume: number;
  refund_policy?: "NO_REFUNDS" | "MERCHANDISE_EXCHANGE_ONLY" | "WITHIN_30_DAYS" | "OTHER";
  merchant_agreement_timestamp?: string;
  business_description: string;
  /** @format int64 */
  average_card_transfer_amount: number;
  merchant_agreement_user_agent?: string;
  card_volume_distribution: CardVolumeDistribution;
}

export interface SuccessResponseFinixMerchantIdentityVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FinixMerchantIdentityVO;
}

export interface VolumeDistributionByBusinessType {
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  other_volume_percentage: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  consumer_to_consumer_volume_percentage: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  business_to_consumer_volume_percentage: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  business_to_business_volume_percentage: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  person_to_person_volume_percentage: number;
}

export interface FinixOnboardingFormVO {
  formUrl: string;
}

export interface SuccessResponseFinixOnboardingFormVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FinixOnboardingFormVO;
}

export interface SuccessResponseListFeeScheduleVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FeeScheduleVO[];
}

export interface SuccessResponseListEmployeeVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: EmployeeVO[];
}

export interface DateWorktimeDetails {
  /** @format int64 */
  id: number;
  clockIn: WorktimeVO;
  clockOut: WorktimeVO;
  /** Will be null if no clock-out time is present for the worktime entry. */
  hours?: number;
  status: "PENDING" | "APPROVED";
  isEditedByEmployee: boolean;
}

export interface DateWorktimeVO {
  date: string;
  regularHours: number;
  overtime: number;
  doubleOvertime: number;
  worktimeDetails: DateWorktimeDetails[];
  worktimeDate: string;
}

export interface SuccessResponseListDateWorktimeVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: DateWorktimeVO[];
}

export interface WorktimeVO {
  time: string;
  isEditedByEmployee: boolean;
  orignalTime: string;
  submittedTime?: string;
  notes?: string;
  approvedTime?: string;
  approvalNotes?: string;
}

export interface DateWorktimeOverviewVO {
  date: string;
  regularHours: number;
  overtime: number;
}

export interface SuccessResponseWorktimeOverviewVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: WorktimeOverviewVO;
}

export interface WorktimeOverviewVO {
  overview: DateWorktimeOverviewVO[];
  totalWorkingHours: number;
  totalOvertimeHours: number;
}

export interface LastWorktimeVOV2 {
  /** @format date-time */
  time?: string;
  lastAction: "CLOCK_IN" | "CLOCK_OUT";
}

export interface SuccessResponseLastWorktimeVOV2 {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: LastWorktimeVOV2;
}

export interface PrescriptionStatus {
  isEnabled: boolean;
}

export interface SuccessResponsePrescriptionStatus {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PrescriptionStatus;
}

export interface ProviderDosespotNotificationCount {
  /** @format int64 */
  refillRequestsCount: number;
  /** @format int64 */
  transactionErrorsCount: number;
  /** @format int64 */
  pendingPrescriptionsCount: number;
  /** @format int64 */
  pendingRxChangeCount: number;
  /** @format int64 */
  totalNotificationCount: number;
}

export interface SuccessResponseProviderDosespotNotificationCount {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ProviderDosespotNotificationCount;
}

export interface EmployeeOnboardingStatusVO {
  status: "ONBOARDED" | "NOT_ONBOARDED" | "NEEDS_AUTHENTICATION";
}

export interface SuccessResponseEmployeeOnboardingStatusVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: EmployeeOnboardingStatusVO;
}

export interface EmployeeOverviewVO {
  /** @format int64 */
  total?: number;
  /** @format int64 */
  dentist?: number;
  /** @format int64 */
  backOffice?: number;
  /** @format int64 */
  frontOffice?: number;
  /** @format int64 */
  hygenist?: number;
  /** @format int64 */
  all?: number;
  /** @format int64 */
  active?: number;
  /** @format int64 */
  pending?: number;
  /** @format int64 */
  inactive?: number;
}

export interface SuccessResponseEmployeeOverviewVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: EmployeeOverviewVO;
}

export interface SuccessResponseListDentalProcedureVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: DentalProcedureVO[];
}

export interface FetchCustomPatientFolderResponse {
  customPatientFolders: CustomPatientFolderVO[];
  defaultPatientFolders: string[];
}

export interface SuccessResponseFetchCustomPatientFolderResponse {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: FetchCustomPatientFolderResponse;
}

export interface PracticeCountersVO {
  /** @format int64 */
  unreadMessages: number;
}

export interface SuccessResponsePracticeCountersVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: PracticeCountersVO;
}

export interface ClaimSummaryVO {
  /** @format uuid */
  uuid: string;
  serviceDate: string;
  patientName: NameVO;
  billingProviderName: NameVO;
  treatingProviderName: NameVO;
  /** @format int64 */
  insuranceCarrierId: number;
  insuranceCarrier: string;
  insuranceMemberId?: string;
  insurancePlanType?:
    | "PPO"
    | "TOA_SOA"
    | "EPO"
    | "HMO_DHMO"
    | "MEDICAID_CHIP"
    | "POS"
    | "DISCOUNT"
    | "SELF_FUNDED_ERISA"
    | "DIRECT_REIMBURSEMENT"
    | "FEDERAL"
    | "MEDICARE"
    | "TRICARE";
  insurancePaymentStatus:
    | "UNPAID"
    | "NOT_SUBMITTED"
    | "PAID_AS_EXPECTED"
    | "OVERPAID"
    | "UNDERPAID"
    | "DENIED";
  insuranceOrdinal: "PRIMARY" | "SECONDARY" | "OTHER";
  /** @format int64 */
  ucrAmount: number;
  /** @format int64 */
  patientAmount: number;
  /** @format int64 */
  insuranceAmount: number;
  state: "PENDING" | "SUBMITTING" | "SUBMITTED" | "FAILED" | "COMPLETED" | "VOIDED";
  isValidating: boolean;
  needsAttachment?: boolean;
  submissionError?: string;
  validationError?: string;
  /** @format date-time */
  submittedAt?: string;
  hasNotes: boolean;
  /** @format uuid */
  replacementClaimUuid?: string;
  isPreAuth: boolean;
  /** @format uuid */
  draftEobPaymentUuid?: string;
}

export interface SuccessResponseListClaimSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ClaimSummaryVO[];
}

export interface SuccessResponseListClaimEobFileVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ClaimEobFileVO[];
}

export interface ClaimsOverviewVO {
  /** @format int64 */
  draftEobs?: number;
  /** @format int64 */
  claimCount?: number;
  /** @format int64 */
  preAuthCount?: number;
}

export interface SuccessResponseClaimsOverviewVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ClaimsOverviewVO;
}

export interface ClaimInsuranceCarrierVO {
  /** @format int64 */
  insuranceCarrierId: number;
  name: string;
}

export interface SuccessResponseListClaimInsuranceCarrierVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ClaimInsuranceCarrierVO[];
}

export interface DraftEobPaymentSummaryVO {
  /** @format uuid */
  uuid: string;
  createDate: string;
  state: "DRAFT" | "PAYMENT_PENDING";
  paymentErrorMessage?: string;
  /** @format int64 */
  insuranceCarrierId: number;
  insuranceCarrier: string;
  /** @format int64 */
  claimCount: number;
  /** @format int64 */
  unpaidPatientAmount: number;
  /** @format int64 */
  unpaidInsuranceAmount: number;
}

export interface SuccessResponseListDraftEobPaymentSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: DraftEobPaymentSummaryVO[];
}

export interface ClaimReceiverUrlVO {
  loginUrl: string;
}

export interface SuccessResponseClaimReceiverUrlVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ClaimReceiverUrlVO;
}

export interface AppointmentClaimSummaryVO {
  /** @format int64 */
  appointmentId: number;
  /** @format uuid */
  claimUuid: string;
  insuranceCarrier: string;
  /** @format int64 */
  insuranceCarrierId: number;
  insuranceOrdinal: "PRIMARY" | "SECONDARY" | "OTHER";
  insuranceMemberId?: string;
  insurancePaymentStatus:
    | "UNPAID"
    | "NOT_SUBMITTED"
    | "PAID_AS_EXPECTED"
    | "OVERPAID"
    | "UNDERPAID"
    | "DENIED";
  state: "PENDING" | "SUBMITTING" | "SUBMITTED" | "FAILED" | "COMPLETED" | "VOIDED";
  billingProviderName: NameVO;
  primarySubscriberName: string;
  /** @uniqueItems true */
  procedureIds: number[];
  /** @format uuid */
  draftEobPaymentUuid?: string;
}

export interface SuccessResponseListAppointmentClaimSummaryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AppointmentClaimSummaryVO[];
}

export interface AutoEligibilityCarriersVO {
  /** @uniqueItems true */
  carrierIds: number[];
}

export interface SuccessResponseAutoEligibilityCarriersVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AutoEligibilityCarriersVO;
}

export interface AppointmentSeriesVO {
  date: string;
  openSlots: OpenSlotVO[];
}

export interface OpenSlotVO {
  date: string;
  startTime: string;
  endTime: string;
  room: RoomVO;
  provider: ProviderVO;
  /** @format int64 */
  duration: number;
}

export interface SuccessResponseListAppointmentSeriesVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AppointmentSeriesVO[];
}

export interface SuccessResponseLong {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  /** @format int64 */
  data: number;
}

export interface SuccessResponseCollectionAppointmentCategoryVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: AppointmentCategoryVO[];
}

export interface SuccessResponseListCustomAdjustmentTypeVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: CustomAdjustmentTypeVO[];
}

export interface ActionEventGroupVO {
  eventTime: "BEFORE" | "WHEN" | "AFTER";
  events: ActionEventVO[];
}

export interface ActionEventVO {
  type:
    | "CONFIRMED_APPOINTMENT"
    | "UNCONFIRMED_APPOINTMENT"
    | "APPOINTMENT_COMPLETED"
    | "APPOINTMENT_CREATED"
    | "APPOINTMENT_REQUESTED"
    | "PATIENT_CREATED"
    | "PROPHY_DUE"
    | "PERIO_DUE";
  canConfirmWithY: boolean;
  canSetSendTime: boolean;
  allowAppointmentCategory: boolean;
  canSetFormTemplate: boolean;
}

export interface SuccessResponseListActionEventGroupVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: ActionEventGroupVO[];
}

export interface EmployeeJobTitleV2VO {
  name: string;
  key:
    | "GENERAL_DENTIST"
    | "PEDIATRIC_DENTIST"
    | "DENTAL_ASSISTANT"
    | "DENTURIST"
    | "ENDODONTIST"
    | "DENTAL_HYGIENIST"
    | "REGISTERED_DENTAL_HYGIENIST_INDEPENDENT_PRACTICE"
    | "ORTHODONTIST"
    | "ORAL_SURGEON"
    | "PERIODONTIST"
    | "PROSTHODONTIST"
    | "UNKNOWN";
  category: "DENTIST" | "BACK_OFFICE" | "FRONT_OFFICE" | "HYGIENIST" | "PRACTICE";
  namePrefix?: string;
}

export interface SuccessResponseListEmployeeJobTitleV2VO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: EmployeeJobTitleV2VO[];
}

export interface EmployeeJobTitleVO {
  /** @format int64 */
  id: number;
  name: string;
  key: string;
  category: "DENTIST" | "BACK_OFFICE" | "FRONT_OFFICE" | "HYGIENIST" | "PRACTICE";
  namePrefix?: string;
}

export interface SuccessResponseListEmployeeJobTitleVO {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: EmployeeJobTitleVO[];
}

export interface SuccessResponseCollectionString {
  pageDetails: PageDetails;
  status?: string;
  traceId?: string;
  data: string[];
}

export interface DeleteRoleRequest {
  /** @format int64 */
  replacementRoleId?: number;
}

export interface MultiUpdateVO {
  /** @uniqueItems true */
  ids: number[];
}

export interface FamilyMemberIdVO {
  /** @format int64 */
  familyMemberPatientId: number;
}

export interface BatchDeletePatientProcedureRequest {
  patientProcedureIds: number[];
}

export interface BatchDeleteLicenseRequest {
  features: (
    | "CUSTOM_APPOINTMENT_REMINDER"
    | "CUSTOM_FORMS"
    | "SELF_SCHEDULING"
    | "TWO_WAY_TEXTING"
    | "MMS_TEXTING"
    | "MASS_TEXTING"
    | "ERX"
    | "REPORTING"
    | "TIME_TRACKING"
    | "PAYROLL"
  )[];
}

export interface ClaimAttachmentDeleteRequest {
  attachmentUuids: string[];
}

export interface DosespotNotificationEvent {
  /** @format int64 */
  employeeId: number;
}

export interface RoleUpdatedEvent {
  /** @format int64 */
  roleId: number;
}

export interface AppointmentPushEvent {
  /** @format int64 */
  id: number;
  /** @format int64 */
  patientId: number;
  origDate?: string;
  date: string;
  origState?:
    | "UNSCHEDULED"
    | "UNCONFIRMED"
    | "CONFIRMED"
    | "CANCELED"
    | "NO_SHOW"
    | "ARRIVED"
    | "READY"
    | "IN_PROGRESS"
    | "CHECKOUT"
    | "COMPLETED"
    | "_DELETED"
    | "REQUESTED";
  state:
    | "UNSCHEDULED"
    | "UNCONFIRMED"
    | "CONFIRMED"
    | "CANCELED"
    | "NO_SHOW"
    | "ARRIVED"
    | "READY"
    | "IN_PROGRESS"
    | "CHECKOUT"
    | "COMPLETED"
    | "_DELETED"
    | "REQUESTED";
  origAsap?: boolean;
  asap: boolean;
}

export interface SelfBookingEvent {
  /** @format int64 */
  appointmentId: number;
}

export interface UnReadMessageEvent {
  /** @format int64 */
  patientId: number;
}

export interface PhoneCallEvent {
  callMainId: string;
  extensionNumber: string;
  /** @format int64 */
  patientId?: number;
  phoneNumber: string;
  callerId?: string;
  outbound?: boolean;
}

export interface EmailDomainVerifiedEvent {
  /** @format int64 */
  practiceId: number;
}

export interface PongEvent {
  found: boolean;
}

export interface PushMessage {
  /** @format int64 */
  initiatedById?: number;
  type:
    | "UNREAD_MESSAGE"
    | "UPDATED_ERX_NOTIFICATION_COUNT"
    | "PAYROLL_REVIEW"
    | "GUSTO_MIGRATION_FINISHED"
    | "GUSTO_ONBOARDING_FINISHED"
    | "CLAIM_SUBMISSION"
    | "CLAIM_VALIDATION"
    | "DRAFT_EOB_PAYMENT_SUCCESS"
    | "DRAFT_EOB_PAYMENT_FAILED"
    | "PAYMENT_UPDATED"
    | "PATIENT_LEDGER_UPDATED"
    | "PATIENT_WALLET_BALANCE_UPDATED"
    | "WALLET_ACTIVITY_UPDATED"
    | "INTRA_OFFICE_CHAT_UNREAD_MESSAGE"
    | "INTRA_OFFICE_CHAT_UNREAD_MESSAGE_CONTENT"
    | "EMPLOYEE_CHAT_PREFERENCE_UPDATED"
    | "INVOICE_UPDATED"
    | "STATEMENT_UPDATED"
    | "PHONE_CALL_RINGING"
    | "PHONE_CALL_ANSWERED"
    | "PHONE_CALL_HANGUP"
    | "FORM_TASK"
    | "CLINICAL_NOTE_CREATED"
    | "SCHEDULE_UPDATED_V2"
    | "AUTO_ELIGIBILITY_VERIFIED"
    | "ROLE_UPDATED"
    | "EMPLOYEE_ROLE_UPDATED"
    | "PEARL_ANALYSIS_UPDATED"
    | "APPOINTMENT_UPDATED"
    | "SCHEDULE_BLOCKS_UPDATED"
    | "PONG"
    | "EMAIL_DOMAIN_VERIFIED"
    | "PATIENT_NOTE_UPDATED"
    | "ALERT_SETTINGS_BATCH_JOB_DONE"
    | "APPOINTMENT_ALERT_SETTINGS_UPDATED";
  payload?: object;
}

export interface EmployeeChatPreferenceUpdatedEvent {
  /** @format int64 */
  employeeId: number;
}

export interface ChatUnreadMessageContentEvent {
  channelUrl: string;
  /** The name of the channel. */
  channelName: string;
  /** The custom type of the channel. When the type is DIRECT, it means the channel is a 1:1 messaging channel. */
  channelCustomType?: string;
  senderSendBirdUserId: string;
  senderNickname: string;
  senderProfileUrl?: string;
  /**
   * The time the message was sent, in seconds since the epoch.
   * @format int64
   */
  sentAt: number;
  /** The message that was sent. If the message was a file, this will be the file name. */
  message: string;
  /** The type of the message. Could be MESG or FILE. This is determined by SendBird. */
  messageType: "MESG" | "FILE" | "UNKNOWN_VALUE";
}

export interface ClinicalNoteEvent {
  /** @format int64 */
  patientId: number;
}

export interface PatientNotePushEvent {
  /** @format int64 */
  patientId: number;
  /** @format int64 */
  appointmentId?: number;
  /** @format uuid */
  noteUuid: string;
  isArchived: boolean;
  isAlert: boolean;
  type: "CHART" | "GENERAL";
}

export interface AutoEligibilityEvent {
  /** @format int64 */
  patientId: number;
  /** @format int64 */
  patientInsuranceId: number;
  /** @format uuid */
  insurancePlanUuid?: string;
}

export interface PatientLedgerUpdatedEvent {
  /** @format int64 */
  patientId: number;
}

export interface InvoiceUpdatedEvent {
  /** @format uuid */
  invoiceUuid: string;
  /** @format int64 */
  patientId: number;
}

export interface PaymentUpdatedEvent {
  /** @format uuid */
  paymentUuid: string;
}

export interface WalletActivityUpdatedEvent {
  /** @format int64 */
  walletActivityId: number;
  /** @format int64 */
  patientId: number;
  /** @format uuid */
  walletUuid: string;
}

export interface WalletBalanceUpdatedEvent {
  /** @format int64 */
  patientId: number;
}

export interface StatementUpdatedEvent {
  /** @format uuid */
  statementUuid: string;
  /** @format int64 */
  patientId: number;
}

export interface EmployeeRoleUpdatedEvent {
  /** @format int64 */
  employeeId: number;
}

export interface PayrollMigrationEvent {
  /** @format int32 */
  onboardedToArchy: number;
  /** @format int32 */
  onboardedToGusto: number;
}

export interface PayrollEvent {
  /** @format uuid */
  payrollUuid: string;
}

export interface MedicalImageEvent {
  /** @format int64 */
  imageId: number;
  /** @format int64 */
  patientId: number;
  /** @format int64 */
  mountId: number;
}

export interface ClaimPushEvent {
  /** @format uuid */
  claimUuid: string;
  state: "PENDING" | "SUBMITTING" | "SUBMITTED" | "FAILED" | "COMPLETED" | "VOIDED";
  isPreAuth: boolean;
}

export interface DraftEOBPaymentPushEvent {
  /** @format uuid */
  uuid: string;
}

export interface FormTaskEvent {
  /** @format int64 */
  patientId: number;
  /** @format int64 */
  appointmentId?: number;
}

export type FormSectionElementRequest =
  | FormBooleanInputElementRequest
  | FormDateInputElementRequest
  | FormNumberInputElementRequest
  | FormSelectInputElementRequest
  | FormTextInputElementRequest
  | FormTextBlockElementRequest
  | FormConsentElementRequest;

export type FormSectionElementVO =
  | FormBooleanInputElementVO
  | FormDateInputElementVO
  | FormNumberInputElementVO
  | FormTextInputElementVO
  | FormSelectInputElementVO
  | FormConsentElementVO
  | FormTextBlockElementVO;

export type FormBooleanConditionalElementRequest =
  | FormNumberInputElementRequest
  | FormTextInputElementRequest
  | FormDateInputElementRequest
  | FormSelectInputElementRequest;

export type FormBooleanConditionalElementVO =
  | FormNumberInputElementVO
  | FormTextInputElementVO
  | FormDateInputElementVO
  | FormSelectInputElementVO;

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://portal.archy.com/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) =>
        Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== "string" ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
        body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl https://portal.archy.com/api
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  user = {
    /**
     * No description
     *
     * @tags user-mfa-controller
     * @name GetUserMfa
     * @request GET:/user/mfa
     */
    getUserMfa: (params: RequestParams = {}) =>
      this.request<SuccessResponseUserMfaVO, ErrorResponse>({
        path: `/user/mfa`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-mfa-controller
     * @name UpdateUserMfa
     * @request PUT:/user/mfa
     */
    updateUserMfa: (data: UpdateUserMfaRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseUserMfaVO, ErrorResponse>({
        path: `/user/mfa`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name IssueAccountToken
     * @request POST:/user/token
     */
    issueAccountToken: (data: IssueTokenRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseAccountTokenVO, ErrorResponse>({
        path: `/user/token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name IssueSupportToken
     * @request POST:/user/support-token
     */
    issueSupportToken: (data: IssueSupportTokenRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseSupportTokenResponse, ErrorResponse>({
        path: `/user/support-token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetUserSupportStatus
     * @request GET:/user/support-status
     */
    getUserSupportStatus: (params: RequestParams = {}) =>
      this.request<SuccessResponseUserSupportStatusVO, ErrorResponse>({
        path: `/user/support-status`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name ListPractices
     * @request GET:/user/practices
     */
    listPractices: (
      query: {
        searchString: string;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPracticeInfoVO, ErrorResponse>({
        path: `/user/practices`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetUserLoginInfo
     * @request GET:/user/info
     */
    getUserLoginInfo: (params: RequestParams = {}) =>
      this.request<SuccessResponseListUserInfoVO, ErrorResponse>({
        path: `/user/info`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetUserAccounts
     * @request GET:/user/accounts
     */
    getUserAccounts: (
      query?: {
        /** @uniqueItems true */
        userTypes?: ("EMPLOYEE" | "PATIENT" | "SUPPORT_USER" | "SUPER_ADMIN")[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListUserAccountVO, ErrorResponse>({
        path: `/user/accounts`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetUserAccount
     * @request GET:/user/account
     */
    getUserAccount: (params: RequestParams = {}) =>
      this.request<SuccessResponseUserAccountVO, ErrorResponse>({
        path: `/user/account`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  public = {
    /**
     * No description
     *
     * @tags message-subscription-controller
     * @name GetMessageSubscription
     * @request GET:/public/practices/{practiceUuid}/message-subscriptions/{messageSubscriptionUuid}
     */
    getMessageSubscription: (
      practiceUuid: string,
      messageSubscriptionUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMessageSubscriptionVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/message-subscriptions/${messageSubscriptionUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-subscription-controller
     * @name UpdateMessageSubscription
     * @request PUT:/public/practices/{practiceUuid}/message-subscriptions/{messageSubscriptionUuid}
     */
    updateMessageSubscription: (
      practiceUuid: string,
      messageSubscriptionUuid: string,
      data: UpdateMessageSubscriptionRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMessageSubscriptionVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/message-subscriptions/${messageSubscriptionUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name CalculateSurchargeOnPayment
     * @request POST:/public/practices/{practiceUuid}/payments/calc-surcharge
     */
    calculateSurchargeOnPayment: (
      practiceUuid: string,
      data: CalculateSurchargeRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSurchargeAppliedAmountVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/payments/calc-surcharge`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name FetchGuestCheckoutSummary
     * @request POST:/public/practices/{practiceUuid}/guest-checkout/summary
     */
    fetchGuestCheckoutSummary: (
      practiceUuid: string,
      query: {
        patientToken: string;
      },
      data: GuestCheckoutSummaryRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseGuestCheckoutSummaryVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/guest-checkout/summary`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name CreateGuestCheckoutPayment
     * @request POST:/public/practices/{practiceUuid}/guest-checkout/payments
     */
    createGuestCheckoutPayment: (
      practiceUuid: string,
      data: GuestCheckoutPaymentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/guest-checkout/payments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name CreateGuestCheckoutPaymentV2
     * @request POST:/public/practices/{practiceUuid}/guest-checkout/payments/v2
     */
    createGuestCheckoutPaymentV2: (
      practiceUuid: string,
      data: GuestCheckoutPaymentV2Request,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/guest-checkout/payments/v2`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-profile-controller
     * @name CreateGuestCheckoutPaymentProfile
     * @request POST:/public/practices/{practiceUuid}/guest-checkout/payment-profiles
     */
    createGuestCheckoutPaymentProfile: (
      practiceUuid: string,
      data: GuestCheckoutPaymentProfileRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentProfileVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/guest-checkout/payment-profiles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-profile-controller
     * @name UpdateGuestCheckoutPaymentProfileAsTransient
     * @request POST:/public/practices/{practiceUuid}/guest-checkout/payment-profiles/void
     */
    updateGuestCheckoutPaymentProfileAsTransient: (
      practiceUuid: string,
      data: GuestCheckoutPaymentProfileVoidRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/guest-checkout/payment-profiles/void`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-task-controller
     * @name PatientSubmitFormTask
     * @request POST:/public/practices/{practiceUuid}/form-tasks/{formTaskUuid}/submit
     */
    patientSubmitFormTask: (
      practiceUuid: string,
      formTaskUuid: string,
      data: PatientSubmitFormTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientFormTaskVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/form-tasks/${formTaskUuid}/submit`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-task-controller
     * @name PreparePatientFormTasks
     * @request POST:/public/practices/{practiceUuid}/form-tasks/prepare-submit
     */
    preparePatientFormTasks: (
      practiceUuid: string,
      data: PreparePatientFormSubmissionRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePreparePatientFormSubmissionResponse, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/form-tasks/prepare-submit`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags self-booking-appointment-controller
     * @name BookAppointment
     * @request POST:/public/practices/{practiceUuid}/appointments
     */
    bookAppointment: (
      practiceUuid: string,
      data: SelfBookingAppointmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/appointments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags self-booking-appointment-controller
     * @name GetSelfBookableOpenSlots
     * @request POST:/public/practices/{practiceUuid}/appointments/open-slots
     */
    getSelfBookableOpenSlots: (
      practiceUuid: string,
      data: SelfBookingOpenSlotsRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSelfBookingOpenSlotsResponse, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/appointments/open-slots`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name ViewAppointmentConfirmationDetails
     * @request POST:/public/practices/{practiceUuid}/appointments/confirm-details
     */
    viewAppointmentConfirmationDetails: (
      practiceUuid: string,
      data: AppointmentConfirmationRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAppointmentDetailsForConfirmationVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/appointments/confirm-details`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name ConfirmAppointment
     * @request POST:/public/appointments/confirmation
     */
    confirmAppointment: (data: AppointmentConfirmationRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/public/appointments/confirmation`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags self-booking-appointment-controller
     * @name GetSelfBookingConfig
     * @request GET:/public/practices/{practiceUuid}/self-booking-config
     */
    getSelfBookingConfig: (practiceUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseSelfBookingConfigVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/self-booking-config`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags self-booking-appointment-controller
     * @name GetSelfBookableProviders
     * @request GET:/public/practices/{practiceUuid}/providers
     */
    getSelfBookableProviders: (
      practiceUuid: string,
      query?: {
        /** @format int64 */
        carrierId?: number;
        /** @format int64 */
        appointmentCategoryId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListProviderVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/providers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name GetInsuranceCarrierNames
     * @request GET:/public/practices/{practiceUuid}/insurances/carriers
     */
    getInsuranceCarrierNames: (practiceUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseCollectionInsuranceCarrierVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/insurances/carriers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetPracticeInfoByUuid
     * @request GET:/public/practices/{practiceUuid}/info
     */
    getPracticeInfoByUuid: (practiceUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponsePracticeInfoVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/info`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name GetGuestCheckoutPaymentStatus
     * @request GET:/public/practices/{practiceUuid}/guest-checkout/status
     */
    getGuestCheckoutPaymentStatus: (
      practiceUuid: string,
      query: {
        patientToken: string;
        /** @format uuid */
        paymentUuid: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentState, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/guest-checkout/status`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name GetFormPreview
     * @request GET:/public/practices/{practiceUuid}/forms/{uuid}/form-preview
     */
    getFormPreview: (practiceUuid: string, uuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseFormVO, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/forms/${uuid}/form-preview`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-task-controller
     * @name PrepareFileUploadForFormTask
     * @request GET:/public/practices/{practiceUuid}/form-tasks/{formTaskUuid}/file-upload-url
     */
    prepareFileUploadForFormTask: (practiceUuid: string, formTaskUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponsePrepareFileUploadResponse, ErrorResponse>({
        path: `/public/practices/${practiceUuid}/form-tasks/${formTaskUuid}/file-upload-url`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  practices = {
    /**
     * No description
     *
     * @tags practice-controller
     * @name GetPractice
     * @request GET:/practices/{practiceId}
     */
    getPractice: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePracticeVO, ErrorResponse>({
        path: `/practices/${practiceId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name UpdatePractice
     * @request PUT:/practices/{practiceId}
     */
    updatePractice: (practiceId: number, data: PracticeVO, params: RequestParams = {}) =>
      this.request<SuccessResponsePracticeVO, ErrorResponse>({
        path: `/practices/${practiceId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name GetTreatmentPlan
     * @request GET:/practices/{practiceId}/treatment-plans/{treatmentPlanUuid}
     */
    getTreatmentPlan: (
      practiceId: number,
      treatmentPlanUuid: string,
      query?: {
        includeStatuses?: (
          | "EXISTING_OTHER"
          | "PLANNED"
          | "SCHEDULED"
          | "DONE"
          | "EXISTING_CURRENT"
          | "REFERRED"
          | "REJECTED"
        )[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseTreatmentPlanVO, ErrorResponse>({
        path: `/practices/${practiceId}/treatment-plans/${treatmentPlanUuid}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name UpdateTreatmentPlan
     * @request PUT:/practices/{practiceId}/treatment-plans/{treatmentPlanUuid}
     */
    updateTreatmentPlan: (
      practiceId: number,
      treatmentPlanUuid: string,
      data: UpdateTreatmentPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseTreatmentPlanVO, ErrorResponse>({
        path: `/practices/${practiceId}/treatment-plans/${treatmentPlanUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name DeleteTreatmentPlan
     * @request DELETE:/practices/{practiceId}/treatment-plans/{treatmentPlanUuid}
     */
    deleteTreatmentPlan: (practiceId: number, treatmentPlanUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/treatment-plans/${treatmentPlanUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name UpdateTreatmentPlanState
     * @request PUT:/practices/{practiceId}/treatment-plans/{treatmentPlanUuid}/state
     */
    updateTreatmentPlanState: (
      practiceId: number,
      treatmentPlanUuid: string,
      data: UpdateTreatmentPlanStateRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/treatment-plans/${treatmentPlanUuid}/state`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag-controller
     * @name UpdateTag
     * @request PUT:/practices/{practiceId}/tags/{tagId}
     */
    updateTag: (practiceId: number, tagId: number, data: UpdateTagRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseTagVO, ErrorResponse>({
        path: `/practices/${practiceId}/tags/${tagId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags security-controller
     * @name GetPracticeSecuritySettings
     * @request GET:/practices/{practiceId}/security/settings
     */
    getPracticeSecuritySettings: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePracticeSecuritySettingVO, ErrorResponse>({
        path: `/practices/${practiceId}/security/settings`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags security-controller
     * @name UpdatePracticeSecuritySettings
     * @request PUT:/practices/{practiceId}/security/settings
     */
    updatePracticeSecuritySettings: (
      practiceId: number,
      data: UpdatePracticeSecuritySettingRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePracticeSecuritySettingVO, ErrorResponse>({
        path: `/practices/${practiceId}/security/settings`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags schedule-block-controller
     * @name GetScheduleBlock
     * @request GET:/practices/{practiceId}/schedule-blocks/{scheduleBlockId}
     */
    getScheduleBlock: (practiceId: number, scheduleBlockId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseScheduleBlockVO, ErrorResponse>({
        path: `/practices/${practiceId}/schedule-blocks/${scheduleBlockId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags schedule-block-controller
     * @name UpdateScheduleBlock
     * @request PUT:/practices/{practiceId}/schedule-blocks/{scheduleBlockId}
     */
    updateScheduleBlock: (
      practiceId: number,
      scheduleBlockId: number,
      data: UpdateScheduleBlockRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseScheduleBlockVO, ErrorResponse>({
        path: `/practices/${practiceId}/schedule-blocks/${scheduleBlockId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags schedule-block-controller
     * @name DeleteScheduleBlock
     * @request DELETE:/practices/{practiceId}/schedule-blocks/{scheduleBlockId}
     */
    deleteScheduleBlock: (practiceId: number, scheduleBlockId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/schedule-blocks/${scheduleBlockId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags schedule-block-controller
     * @name UpdateRecurringScheduleBlockInstance
     * @request PUT:/practices/{practiceId}/schedule-blocks/{scheduleBlockId}/update-instance/{instanceDate}
     */
    updateRecurringScheduleBlockInstance: (
      practiceId: number,
      scheduleBlockId: number,
      instanceDate: string,
      data: UpdateScheduleBlockRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseScheduleBlockVO, ErrorResponse>({
        path: `/practices/${practiceId}/schedule-blocks/${scheduleBlockId}/update-instance/${instanceDate}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags schedule-block-controller
     * @name UpdateRecurringScheduleBlockFuture
     * @request PUT:/practices/{practiceId}/schedule-blocks/{scheduleBlockId}/update-future/{instanceDate}
     */
    updateRecurringScheduleBlockFuture: (
      practiceId: number,
      scheduleBlockId: number,
      instanceDate: string,
      data: UpdateScheduleBlockRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseScheduleBlockVO, ErrorResponse>({
        path: `/practices/${practiceId}/schedule-blocks/${scheduleBlockId}/update-future/${instanceDate}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags permission-controller
     * @name GetRoleV2
     * @request GET:/practices/{practiceId}/roles/{roleId}
     */
    getRoleV2: (practiceId: number, roleId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseRoleVO, ErrorResponse>({
        path: `/practices/${practiceId}/roles/${roleId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags permission-controller
     * @name UpdateRoleV2
     * @request PUT:/practices/{practiceId}/roles/{roleId}
     */
    updateRoleV2: (practiceId: number, roleId: number, data: RoleRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseRoleVO, ErrorResponse>({
        path: `/practices/${practiceId}/roles/${roleId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags permission-controller
     * @name DeleteRoleV2
     * @request DELETE:/practices/{practiceId}/roles/{roleId}
     */
    deleteRoleV2: (practiceId: number, roleId: number, data: DeleteRoleRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/roles/${roleId}`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name UpdateProcedureShortcut
     * @request PUT:/practices/{practiceId}/procedure-shortcuts/{shortcutUuid}
     */
    updateProcedureShortcut: (
      practiceId: number,
      shortcutUuid: string,
      data: UpdateProcedureShortcutRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseProcedureShortcutVO, ErrorResponse>({
        path: `/practices/${practiceId}/procedure-shortcuts/${shortcutUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name DeleteProcedureShortcut
     * @request DELETE:/practices/{practiceId}/procedure-shortcuts/{shortcutUuid}
     */
    deleteProcedureShortcut: (practiceId: number, shortcutUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/procedure-shortcuts/${shortcutUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags perio-chart-exam-controller
     * @name GetPerioChartExamSettings
     * @request GET:/practices/{practiceId}/perio-chart-exams/{providerId}/settings
     */
    getPerioChartExamSettings: (practiceId: number, providerId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePerioChartExamSettingsVO, ErrorResponse>({
        path: `/practices/${practiceId}/perio-chart-exams/${providerId}/settings`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags perio-chart-exam-controller
     * @name UpsertPerioChartExamSettings
     * @request PUT:/practices/{practiceId}/perio-chart-exams/{providerId}/settings
     */
    upsertPerioChartExamSettings: (
      practiceId: number,
      providerId: number,
      data: PerioChartExamSettingsVO,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePerioChartExamSettingsVO, ErrorResponse>({
        path: `/practices/${practiceId}/perio-chart-exams/${providerId}/settings`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-device-controller
     * @name DeactivatePaymentDevice
     * @request PUT:/practices/{practiceId}/payment-devices/{paymentDeviceUuid}/deactivate
     */
    deactivatePaymentDevice: (practiceId: number, paymentDeviceUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponsePaymentDeviceVO, ErrorResponse>({
        path: `/practices/${practiceId}/payment-devices/${paymentDeviceUuid}/deactivate`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-device-controller
     * @name ActivatePaymentDevice
     * @request PUT:/practices/{practiceId}/payment-devices/{paymentDeviceUuid}/activate
     */
    activatePaymentDevice: (practiceId: number, paymentDeviceUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponsePaymentDeviceVO, ErrorResponse>({
        path: `/practices/${practiceId}/payment-devices/${paymentDeviceUuid}/activate`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name GetPatient
     * @request GET:/practices/{practiceId}/patients/{patientId}
     */
    getPatient: (
      patientId: number,
      practiceId: number,
      query?: {
        includeContactPatientDetails?: boolean;
        includeInboundReferralDetails?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name UpdatePatient
     * @request PUT:/practices/{practiceId}/patients/{patientId}
     */
    updatePatient: (
      practiceId: number,
      patientId: number,
      data: UpdatePatientRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags perio-chart-exam-controller
     * @name UpdatePerioChartExamEntries
     * @request PUT:/practices/{practiceId}/patients/{patientId}/perio-chart-exams/{perioChartExamUuid}/entries
     */
    updatePerioChartExamEntries: (
      practiceId: number,
      patientId: number,
      perioChartExamUuid: string,
      data: UpsertPerioChartExamEntriesRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPerioChartExamEntryVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/perio-chart-exams/${perioChartExamUuid}/entries`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-profile-controller
     * @name MarkPaymentProfileAsDefault
     * @request PUT:/practices/{practiceId}/patients/{patientId}/payment-profiles/{paymentProfileUuid}/default
     * @deprecated
     */
    markPaymentProfileAsDefault: (
      patientId: number,
      practiceId: number,
      paymentProfileUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentProfileVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/payment-profiles/${paymentProfileUuid}/default`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-tooth-controller
     * @name GetPatientTeeth
     * @request GET:/practices/{practiceId}/patients/{patientId}/patient-teeth
     */
    getPatientTeeth: (practiceId: number, patientId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListPatientToothVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-teeth`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-tooth-controller
     * @name UpsertPatientTeeth
     * @request PUT:/practices/{practiceId}/patients/{patientId}/patient-teeth
     */
    upsertPatientTeeth: (
      practiceId: number,
      patientId: number,
      data: BatchUpsertPatientToothRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPatientToothVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-teeth`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name GetPatientNote
     * @request GET:/practices/{practiceId}/patients/{patientId}/patient-note/{patientNoteUuid}
     */
    getPatientNote: (
      practiceId: number,
      patientId: number,
      patientNoteUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientNoteVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-note/${patientNoteUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name UpdatePatientNote
     * @request PUT:/practices/{practiceId}/patients/{patientId}/patient-note/{patientNoteUuid}
     */
    updatePatientNote: (
      practiceId: number,
      patientId: number,
      patientNoteUuid: string,
      data: UpdatePatientNoteRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientNoteVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-note/${patientNoteUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name DeletePatientNote
     * @request DELETE:/practices/{practiceId}/patients/{patientId}/patient-note/{patientNoteUuid}
     */
    deletePatientNote: (
      practiceId: number,
      patientId: number,
      patientNoteUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-note/${patientNoteUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name UpdateClinicalNoteWithForms
     * @request PUT:/practices/{practiceId}/patients/{patientId}/patient-note/clinical/{patientNoteUuid}
     */
    updateClinicalNoteWithForms: (
      practiceId: number,
      patientId: number,
      patientNoteUuid: string,
      data: UpsertClinicalNoteWithFormsRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientNoteVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-note/clinical/${patientNoteUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name UpdatePatientProcedures
     * @request PUT:/practices/{practiceId}/patient-procedures
     */
    updatePatientProcedures: (
      practiceId: number,
      data: BatchUpdatePatientProcedureRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPatientProcedureVO, ErrorResponse>({
        path: `/practices/${practiceId}/patient-procedures`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name DeletePatientProcedures
     * @request DELETE:/practices/{practiceId}/patient-procedures
     */
    deletePatientProcedures: (
      practiceId: number,
      data: BatchDeletePatientProcedureRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/patient-procedures`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name GetPatientProcedure
     * @request GET:/practices/{practiceId}/patient-procedures/{patientProcedureId}
     */
    getPatientProcedure: (practiceId: number, patientProcedureId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePatientProcedureVO, ErrorResponse>({
        path: `/practices/${practiceId}/patient-procedures/${patientProcedureId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name UpdatePatientProcedure
     * @request PUT:/practices/{practiceId}/patient-procedures/{patientProcedureId}
     */
    updatePatientProcedure: (
      practiceId: number,
      patientProcedureId: number,
      data: UpdatePatientProcedureRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientProcedureVO, ErrorResponse>({
        path: `/practices/${practiceId}/patient-procedures/${patientProcedureId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name UpdatePatientProcedureFee
     * @request PUT:/practices/{practiceId}/patient-procedures/{patientProcedureId}/fee
     */
    updatePatientProcedureFee: (
      practiceId: number,
      patientProcedureId: number,
      data: UpdatePatientProcedureFeeRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientProcedureVO, ErrorResponse>({
        path: `/practices/${practiceId}/patient-procedures/${patientProcedureId}/fee`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-campaign-controller
     * @name GetMessageCampaign
     * @request GET:/practices/{practiceId}/message-campaign/{messageCampaignUuid}
     */
    getMessageCampaign: (
      practiceId: number,
      messageCampaignUuid: string,
      query?: {
        includeSummary?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMessageCampaignVO, ErrorResponse>({
        path: `/practices/${practiceId}/message-campaign/${messageCampaignUuid}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-campaign-controller
     * @name UpdateMessageCampaign
     * @request PUT:/practices/{practiceId}/message-campaign/{messageCampaignUuid}
     */
    updateMessageCampaign: (
      practiceId: number,
      messageCampaignUuid: string,
      data: UpdateMessageCampaignRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMessageCampaignVO, ErrorResponse>({
        path: `/practices/${practiceId}/message-campaign/${messageCampaignUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-campaign-controller
     * @name DeleteDraftMessageCampaign
     * @request DELETE:/practices/{practiceId}/message-campaign/{messageCampaignUuid}
     */
    deleteDraftMessageCampaign: (
      practiceId: number,
      messageCampaignUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/message-campaign/${messageCampaignUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name GetLab
     * @request GET:/practices/{practiceId}/labs/{labUuid}
     */
    getLab: (practiceId: number, labUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseLabVO, ErrorResponse>({
        path: `/practices/${practiceId}/labs/${labUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name UpdateLab
     * @request PUT:/practices/{practiceId}/labs/{labUuid}
     */
    updateLab: (practiceId: number, labUuid: string, data: LabRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseLabVO, ErrorResponse>({
        path: `/practices/${practiceId}/labs/${labUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name GetLabCase
     * @request GET:/practices/{practiceId}/lab-cases/{labCaseUuid}
     */
    getLabCase: (practiceId: number, labCaseUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseLabCaseVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-cases/${labCaseUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name UpdateLabCase
     * @request PUT:/practices/{practiceId}/lab-cases/{labCaseUuid}
     */
    updateLabCase: (
      practiceId: number,
      labCaseUuid: string,
      data: LabCaseRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseLabCaseVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-cases/${labCaseUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name UpdateLabCaseStatus
     * @request PUT:/practices/{practiceId}/lab-cases/{labCaseUuid}/status
     */
    updateLabCaseStatus: (
      practiceId: number,
      labCaseUuid: string,
      data: LabCaseStatusRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseLabCaseStatusVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-cases/${labCaseUuid}/status`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name GetLabCaseType
     * @request GET:/practices/{practiceId}/lab-case-types/{labCaseTypeUuid}
     */
    getLabCaseType: (practiceId: number, labCaseTypeUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseLabCaseTypeVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-case-types/${labCaseTypeUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name UpdateLabCaseType
     * @request PUT:/practices/{practiceId}/lab-case-types/{labCaseTypeUuid}
     */
    updateLabCaseType: (
      practiceId: number,
      labCaseTypeUuid: string,
      data: LabCaseTypeRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseLabCaseTypeVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-case-types/${labCaseTypeUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name DeleteLabCaseType
     * @request DELETE:/practices/{practiceId}/lab-case-types/{labCaseTypeUuid}
     */
    deleteLabCaseType: (practiceId: number, labCaseTypeUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/lab-case-types/${labCaseTypeUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name GetLabCaseReturnReason
     * @request GET:/practices/{practiceId}/lab-case-return-reasons/{labCaseReturnReasonUuid}
     */
    getLabCaseReturnReason: (
      practiceId: number,
      labCaseReturnReasonUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseLabCaseReturnReasonVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-case-return-reasons/${labCaseReturnReasonUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name UpdateLabCaseReturnReason
     * @request PUT:/practices/{practiceId}/lab-case-return-reasons/{labCaseReturnReasonUuid}
     */
    updateLabCaseReturnReason: (
      practiceId: number,
      labCaseReturnReasonUuid: string,
      data: LabCaseReturnReasonRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseLabCaseReturnReasonVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-case-return-reasons/${labCaseReturnReasonUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name DeleteLabCaseReturnReason
     * @request DELETE:/practices/{practiceId}/lab-case-return-reasons/{labCaseReturnReasonUuid}
     */
    deleteLabCaseReturnReason: (
      practiceId: number,
      labCaseReturnReasonUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/lab-case-return-reasons/${labCaseReturnReasonUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags automation-controller
     * @name GetAutomationAction
     * @request GET:/practices/{practiceId}/journeys/{journeyUuidOrType}/actions/{actionUuid}
     */
    getAutomationAction: (
      practiceId: number,
      journeyUuidOrType: string | "APPOINTMENT" | "POST_APPOINTMENT" | "RECALL" | "FORMS",
      actionUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAutomationActionVO, ErrorResponse>({
        path: `/practices/${practiceId}/journeys/${journeyUuidOrType}/actions/${actionUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags automation-controller
     * @name UpdateAutomationAction
     * @request PUT:/practices/{practiceId}/journeys/{journeyUuidOrType}/actions/{actionUuid}
     */
    updateAutomationAction: (
      practiceId: number,
      journeyUuidOrType: string | "APPOINTMENT" | "POST_APPOINTMENT" | "RECALL" | "FORMS",
      actionUuid: string,
      data: AutomationActionRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAutomationActionVO, ErrorResponse>({
        path: `/practices/${practiceId}/journeys/${journeyUuidOrType}/actions/${actionUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags automation-controller
     * @name DeleteAutomationAction
     * @request DELETE:/practices/{practiceId}/journeys/{journeyUuidOrType}/actions/{actionUuid}
     */
    deleteAutomationAction: (
      practiceId: number,
      journeyUuidOrType: string | "APPOINTMENT" | "POST_APPOINTMENT" | "RECALL" | "FORMS",
      actionUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAutomationActionVO, ErrorResponse>({
        path: `/practices/${practiceId}/journeys/${journeyUuidOrType}/actions/${actionUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name UpdatePatientEngagementJourney
     * @request PUT:/practices/{practiceId}/journey/{journeyUuid}
     */
    updatePatientEngagementJourney: (
      practiceId: number,
      journeyUuid: string,
      data: UpdateJourneyRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseJourneyVO, ErrorResponse>({
        path: `/practices/${practiceId}/journey/${journeyUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name GetPatientEngagementAction
     * @request GET:/practices/{practiceId}/journey/{journeyUuid}/action/{actionUuid}
     */
    getPatientEngagementAction: (
      practiceId: number,
      journeyUuid: string,
      actionUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseActionVO, ErrorResponse>({
        path: `/practices/${practiceId}/journey/${journeyUuid}/action/${actionUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name UpdatePatientEngagementAction
     * @request PUT:/practices/{practiceId}/journey/{journeyUuid}/action/{actionUuid}
     */
    updatePatientEngagementAction: (
      practiceId: number,
      journeyUuid: string,
      actionUuid: string,
      data: UpdateActionRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseActionVO, ErrorResponse>({
        path: `/practices/${practiceId}/journey/${journeyUuid}/action/${actionUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name DeletePatientEngagementAction
     * @request DELETE:/practices/{practiceId}/journey/{journeyUuid}/action/{actionUuid}
     */
    deletePatientEngagementAction: (
      practiceId: number,
      journeyUuid: string,
      actionUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/journey/${journeyUuid}/action/${actionUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name VoidInvoice
     * @request PUT:/practices/{practiceId}/invoices/{invoiceUuid}/void
     */
    voidInvoice: (practiceId: number, invoiceUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseInvoiceVO, ErrorResponse>({
        path: `/practices/${practiceId}/invoices/${invoiceUuid}/void`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-carrier-controller
     * @name GetCarrier
     * @request GET:/practices/{practiceId}/insurances/carriers/{carrierId}
     */
    getCarrier: (practiceId: number, carrierId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseInsuranceCarrierDetailsVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurances/carriers/${carrierId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-carrier-controller
     * @name UpdateCustomCarrier
     * @request PUT:/practices/{practiceId}/insurances/carriers/{carrierId}
     */
    updateCustomCarrier: (
      practiceId: number,
      carrierId: number,
      data: InsuranceCarrierRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseInsuranceCarrierDetailsVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurances/carriers/${carrierId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-carrier-controller
     * @name DeleteCustomCarrier
     * @request DELETE:/practices/{practiceId}/insurances/carriers/{carrierId}
     */
    deleteCustomCarrier: (practiceId: number, carrierId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/insurances/carriers/${carrierId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name GetInsurancePlan
     * @request GET:/practices/{practiceId}/insurance-plans/{insurancePlanUuid}
     */
    getInsurancePlan: (practiceId: number, insurancePlanUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseInsurancePlanVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans/${insurancePlanUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name UpdateInsurancePlan
     * @request PUT:/practices/{practiceId}/insurance-plans/{insurancePlanUuid}
     */
    updateInsurancePlan: (
      practiceId: number,
      insurancePlanUuid: string,
      data: UpdateInsurancePlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseInsurancePlanVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans/${insurancePlanUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name UpdateInsurancePlanFeeSchedule
     * @request PUT:/practices/{practiceId}/insurance-plans/{insurancePlanUuid}/fee-schedule
     */
    updateInsurancePlanFeeSchedule: (
      practiceId: number,
      insurancePlanUuid: string,
      data: UpdateInsurancePlanFeeScheduleRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseInsurancePlanVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans/${insurancePlanUuid}/fee-schedule`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name UpdateForm
     * @request PUT:/practices/{practiceId}/forms/{uuidOrSlug}
     */
    updateForm: (
      practiceId: number,
      uuidOrSlug:
        | string
        | "DENTAL_HISTORY"
        | "MEDICAL_HISTORY"
        | "CONSENT"
        | "TREATMENT_PLAN_FORM"
        | "APPOINTMENT_FORM",
      data: FormRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormVO, ErrorResponse>({
        path: `/practices/${practiceId}/forms/${uuidOrSlug}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags onboarding-options-controller
     * @name GetOnboardingOptions
     * @request GET:/practices/{practiceId}/forms/onboarding-options
     */
    getOnboardingOptions: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseOnboardingOptionsVO, ErrorResponse>({
        path: `/practices/${practiceId}/forms/onboarding-options`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags onboarding-options-controller
     * @name UpsertOnboardingOptions
     * @request PUT:/practices/{practiceId}/forms/onboarding-options
     */
    upsertOnboardingOptions: (
      practiceId: number,
      data: OnboardingOptionsRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseOnboardingOptionsVO, ErrorResponse>({
        path: `/practices/${practiceId}/forms/onboarding-options`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name GetFeeScheduleDetails
     * @request GET:/practices/{practiceId}/fee-schedules/{feeScheduleId}
     */
    getFeeScheduleDetails: (practiceId: number, feeScheduleId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseFeeScheduleVO, ErrorResponse>({
        path: `/practices/${practiceId}/fee-schedules/${feeScheduleId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name UpdateFeeSchedule
     * @request PUT:/practices/{practiceId}/fee-schedules/{feeScheduleId}
     */
    updateFeeSchedule: (
      practiceId: number,
      feeScheduleId: number,
      data: UpdateFeeScheduleRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFeeScheduleVO, ErrorResponse>({
        path: `/practices/${practiceId}/fee-schedules/${feeScheduleId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name DeleteFeeSchedule
     * @request DELETE:/practices/{practiceId}/fee-schedules/{feeScheduleId}
     */
    deleteFeeSchedule: (practiceId: number, feeScheduleId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/fee-schedules/${feeScheduleId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name GetEmployee
     * @request GET:/practices/{practiceId}/employees/{employeeId}
     */
    getEmployee: (
      practiceId: number,
      employeeId: number,
      query?: {
        includeCompensationDetails?: boolean;
        includeRole?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseEmployeeVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name UpdateEmployee
     * @request PUT:/practices/{practiceId}/employees/{employeeId}
     */
    updateEmployee: (
      practiceId: number,
      employeeId: number,
      data: UpdateEmployeeRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseEmployeeVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name UpdateLineItem
     * @request PUT:/practices/{practiceId}/employees/{employeeId}/payrolls/{payrollUuid}
     */
    updateLineItem: (
      practiceId: number,
      employeeId: number,
      payrollUuid: string,
      data: UpdatePayrollLineItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePayrollLineItemVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/payrolls/${payrollUuid}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name OnboardEmployee
     * @request PUT:/practices/{practiceId}/employees/{employeeId}/onboard
     */
    onboardEmployee: (
      practiceId: number,
      employeeId: number,
      data: OnboardEmployeeRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseEmployeeVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/onboard`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags email-config-controller
     * @name GetEmailConfig
     * @request GET:/practices/{practiceId}/email-config
     */
    getEmailConfig: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseEmailConfigVO, ErrorResponse>({
        path: `/practices/${practiceId}/email-config`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags email-config-controller
     * @name UpdateEmailConfig
     * @request PUT:/practices/{practiceId}/email-config
     */
    updateEmailConfig: (practiceId: number, data: UpdateEmailConfigRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseEmailConfigVO, ErrorResponse>({
        path: `/practices/${practiceId}/email-config`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dental-procedure-controller
     * @name UpdateCustomProcedure
     * @request PUT:/practices/{practiceId}/dental-procedures/custom/{dentalProcedureId}
     */
    updateCustomProcedure: (
      practiceId: number,
      dentalProcedureId: number,
      data: UpdateCustomProcedureRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDentalProcedureVO, ErrorResponse>({
        path: `/practices/${practiceId}/dental-procedures/custom/${dentalProcedureId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dental-procedure-controller
     * @name DeleteCustomProcedure
     * @request DELETE:/practices/{practiceId}/dental-procedures/custom/{dentalProcedureId}
     */
    deleteCustomProcedure: (practiceId: number, dentalProcedureId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/dental-procedures/custom/${dentalProcedureId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name SetClaimAttachments
     * @request PUT:/practices/{practiceId}/claims/{claimUuid}/attachment
     */
    setClaimAttachments: (
      practiceId: number,
      claimUuid: string,
      data: ClaimAttachmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/attachment`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name UploadClaimAttachments
     * @request POST:/practices/{practiceId}/claims/{claimUuid}/attachment
     */
    uploadClaimAttachments: (
      practiceId: number,
      claimUuid: string,
      data: {
        file: File[];
        metadata: ClaimUploadAttachmentsRequest;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/attachment`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name DeleteClaimAttachment
     * @request DELETE:/practices/{practiceId}/claims/{claimUuid}/attachment
     */
    deleteClaimAttachment: (
      practiceId: number,
      claimUuid: string,
      data: ClaimAttachmentDeleteRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/attachment`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name UpdateClaimAttachments
     * @request PATCH:/practices/{practiceId}/claims/{claimUuid}/attachment
     */
    updateClaimAttachments: (
      practiceId: number,
      claimUuid: string,
      data: ClaimUpdateAttachmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/attachment`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetClaimsConfig
     * @request GET:/practices/{practiceId}/claims/config
     */
    getClaimsConfig: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimsConfigVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/config`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name SaveClaimsConfig
     * @request PUT:/practices/{practiceId}/claims/config
     */
    saveClaimsConfig: (practiceId: number, data: ClaimsConfigRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimsConfigVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/config`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetAppointment
     * @request GET:/practices/{practiceId}/appointments/{appointmentId}
     */
    getAppointment: (
      practiceId: number,
      appointmentId: number,
      query?: {
        fetchIcons?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAppointmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/${appointmentId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name UpdateAppointment
     * @request PUT:/practices/{practiceId}/appointments/{appointmentId}
     */
    updateAppointment: (
      practiceId: number,
      appointmentId: number,
      data: AppointmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAppointmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/${appointmentId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name DeleteAppointment
     * @request DELETE:/practices/{practiceId}/appointments/{appointmentId}
     */
    deleteAppointment: (practiceId: number, appointmentId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/${appointmentId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetSchedulingConfig
     * @request GET:/practices/{practiceId}/appointments/config
     */
    getSchedulingConfig: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseSchedulingConfigVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/config`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name UpdateSchedulingConfig
     * @request PUT:/practices/{practiceId}/appointments/config
     */
    updateSchedulingConfig: (practiceId: number, data: SchedulingConfigRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseSchedulingConfigVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/config`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags alert-settings-controller
     * @name GetAlertSettings
     * @request GET:/practices/{practiceId}/alert-settings
     */
    getAlertSettings: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseAlertSettingsVO, ErrorResponse>({
        path: `/practices/${practiceId}/alert-settings`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags alert-settings-controller
     * @name UpdateAlertSettings
     * @request PUT:/practices/{practiceId}/alert-settings
     */
    updateAlertSettings: (practiceId: number, data: UpdateAlertSettingsRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseAlertSettingsVO, ErrorResponse>({
        path: `/practices/${practiceId}/alert-settings`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name CreatePractice
     * @request POST:/practices
     */
    createPractice: (data: PracticeVO, params: RequestParams = {}) =>
      this.request<SuccessResponsePracticeVO, ErrorResponse>({
        path: `/practices`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetWorkingHours
     * @request GET:/practices/{practiceId}/working-hours
     */
    getWorkingHours: (
      practiceId: number,
      query: {
        /** @format int64 */
        providerId?: number;
        /** @default false */
        isSelfBookable: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListWorkingHourItemVO, ErrorResponse>({
        path: `/practices/${practiceId}/working-hours`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name UpsertWorkingHours
     * @request POST:/practices/{practiceId}/working-hours
     */
    upsertWorkingHours: (practiceId: number, data: WorkingHourItemVO[], params: RequestParams = {}) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/working-hours`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetProfileImage
     * @request GET:/practices/{practiceId}/users/{userId}/profile-image
     */
    getProfileImage: (userId: number, practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseProfileImageVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/profile-image`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UploadProfileImage
     * @request POST:/practices/{practiceId}/users/{userId}/profile-image
     */
    uploadProfileImage: (
      userId: number,
      practiceId: number,
      data: {
        /** @format binary */
        image: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/profile-image`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name DeleteProfileImage
     * @request DELETE:/practices/{practiceId}/users/{userId}/profile-image
     */
    deleteProfileImage: (userId: number, practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/profile-image`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name GetFolders
     * @request GET:/practices/{practiceId}/users/{userId}/folders
     */
    getFolders: (
      practiceId: number,
      userId: number,
      query?: {
        includeMetadata?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListFolderVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name CreateFolder
     * @request POST:/practices/{practiceId}/users/{userId}/folders
     */
    createFolder: (
      practiceId: number,
      userId: number,
      data: CreateFolderRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFolderVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name GetFolderDocuments
     * @request GET:/practices/{practiceId}/users/{userId}/folders/{folderId}/documents
     */
    getFolderDocuments: (
      practiceId: number,
      userId: number,
      folderId: number,
      query?: {
        includeMetadata?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListDocumentVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders/${folderId}/documents`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name UploadDocument
     * @request POST:/practices/{practiceId}/users/{userId}/folders/{folderId}/documents
     */
    uploadDocument: (
      practiceId: number,
      userId: number,
      folderId: number,
      data: {
        /** @format binary */
        document: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDocumentVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders/${folderId}/documents`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name GenerateUploadUrl
     * @request POST:/practices/{practiceId}/users/{userId}/folders/{folderId}/documents/generate-upload-url
     */
    generateUploadUrl: (
      practiceId: number,
      userId: number,
      folderId: number,
      data: UploadDocumentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseURL, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders/${folderId}/documents/generate-upload-url`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name ConfirmUpload
     * @request POST:/practices/{practiceId}/users/{userId}/folders/{folderId}/documents/confirm-upload
     */
    confirmUpload: (
      practiceId: number,
      userId: number,
      folderId: number,
      data: UploadDocumentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDocumentVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders/${folderId}/documents/confirm-upload`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name CreateTreatmentPlan
     * @request POST:/practices/{practiceId}/treatment-plans
     */
    createTreatmentPlan: (practiceId: number, data: CreateTreatmentPlanRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseTreatmentPlanVO, ErrorResponse>({
        path: `/practices/${practiceId}/treatment-plans`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name AddProceduresToTreatmentPlan
     * @request POST:/practices/{practiceId}/treatment-plans/{treatmentPlanUuid}/procedures
     */
    addProceduresToTreatmentPlan: (
      practiceId: number,
      treatmentPlanUuid: string,
      data: TreatmentPlanAddProceduresRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/treatment-plans/${treatmentPlanUuid}/procedures`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name CreateTreatmentPlanFormTask
     * @request POST:/practices/{practiceId}/treatment-plans/form-tasks
     */
    createTreatmentPlanFormTask: (
      practiceId: number,
      data: CreateTreatmentPlanFormTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormTaskVO, ErrorResponse>({
        path: `/practices/${practiceId}/treatment-plans/form-tasks`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name TransferMount
     * @request POST:/practices/{practiceId}/transfer-mount
     */
    transferMount: (practiceId: number, data: TransferMountRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseMountVO, ErrorResponse>({
        path: `/practices/${practiceId}/transfer-mount`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name TransferImages
     * @request POST:/practices/{practiceId}/transfer-images
     */
    transferImages: (practiceId: number, data: TransferImagesRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseMountVO, ErrorResponse>({
        path: `/practices/${practiceId}/transfer-images`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag-controller
     * @name GetTags
     * @request GET:/practices/{practiceId}/tags
     */
    getTags: (
      practiceId: number,
      query?: {
        /** @uniqueItems true */
        typeFilters?: ("ARCHY" | "CUSTOM")[];
        includeArchived?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListTagVO, ErrorResponse>({
        path: `/practices/${practiceId}/tags`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag-controller
     * @name CreateTag
     * @request POST:/practices/{practiceId}/tags
     */
    createTag: (practiceId: number, data: CreateTagRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseTagVO, ErrorResponse>({
        path: `/practices/${practiceId}/tags`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-campaign-controller
     * @name SendMessageCampaign
     * @request POST:/practices/{practiceId}/send-message-campaign
     */
    sendMessageCampaign: (practiceId: number, data: SendMessageCampaignRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/send-message-campaign`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags security-controller
     * @name GetIpFilters
     * @request GET:/practices/{practiceId}/security/ip-filters
     */
    getIpFilters: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListIPFilterVO, ErrorResponse>({
        path: `/practices/${practiceId}/security/ip-filters`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags security-controller
     * @name AddIpFilter
     * @request POST:/practices/{practiceId}/security/ip-filters
     */
    addIpFilter: (practiceId: number, data: CreateIPFilterRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseIPFilterVO, ErrorResponse>({
        path: `/practices/${practiceId}/security/ip-filters`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags schedule-block-controller
     * @name GetScheduleBlocks
     * @request GET:/practices/{practiceId}/schedule-blocks
     */
    getScheduleBlocks: (
      practiceId: number,
      query: {
        filterDate: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListScheduleBlockVO, ErrorResponse>({
        path: `/practices/${practiceId}/schedule-blocks`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags schedule-block-controller
     * @name CreateScheduleBlock
     * @request POST:/practices/{practiceId}/schedule-blocks
     */
    createScheduleBlock: (practiceId: number, data: CreateScheduleBlockRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseScheduleBlockVO, ErrorResponse>({
        path: `/practices/${practiceId}/schedule-blocks`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags schedule-block-controller
     * @name DeleteRecurringScheduleBlockInstance
     * @request POST:/practices/{practiceId}/schedule-blocks/{scheduleBlockId}/delete-instance/{instanceDate}
     */
    deleteRecurringScheduleBlockInstance: (
      practiceId: number,
      scheduleBlockId: number,
      instanceDate: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/schedule-blocks/${scheduleBlockId}/delete-instance/${instanceDate}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags schedule-block-controller
     * @name DeleteRecurringScheduleBlockFuture
     * @request POST:/practices/{practiceId}/schedule-blocks/{scheduleBlockId}/delete-future/{instanceDate}
     */
    deleteRecurringScheduleBlockFuture: (
      practiceId: number,
      scheduleBlockId: number,
      instanceDate: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/schedule-blocks/${scheduleBlockId}/delete-future/${instanceDate}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags saved-filter-controller
     * @name ListSavedFilters
     * @request GET:/practices/{practiceId}/saved-filters
     */
    listSavedFilters: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListSavedFilterVO, ErrorResponse>({
        path: `/practices/${practiceId}/saved-filters`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags saved-filter-controller
     * @name CreateSavedFilter
     * @request POST:/practices/{practiceId}/saved-filters
     */
    createSavedFilter: (practiceId: number, data: CreateSavedFilterRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseSavedFilterVO, ErrorResponse>({
        path: `/practices/${practiceId}/saved-filters`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetRoomsByPracticeId
     * @request GET:/practices/{practiceId}/rooms
     */
    getRoomsByPracticeId: (
      practiceId: number,
      query?: {
        includeArchived?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseCollectionRoomVO, ErrorResponse>({
        path: `/practices/${practiceId}/rooms`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name UpsertRooms
     * @request POST:/practices/{practiceId}/rooms
     */
    upsertRooms: (practiceId: number, data: RoomsRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseListRoomVO, ErrorResponse>({
        path: `/practices/${practiceId}/rooms`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags permission-controller
     * @name GetPracticeRolesV2
     * @request GET:/practices/{practiceId}/roles
     */
    getPracticeRolesV2: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListEmployeeRolesVO, ErrorResponse>({
        path: `/practices/${practiceId}/roles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags permission-controller
     * @name CreateRoleV2
     * @request POST:/practices/{practiceId}/roles
     */
    createRoleV2: (practiceId: number, data: RoleRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseRoleVO, ErrorResponse>({
        path: `/practices/${practiceId}/roles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetEmployeeWorkingHourStats
     * @request POST:/practices/{practiceId}/reporting/working-hours
     */
    getEmployeeWorkingHourStats: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: WorkingHourRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseWorkingHourResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/working-hours`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetUnscheduledProcedures
     * @request POST:/practices/{practiceId}/reporting/unscheduled-procedure
     */
    getUnscheduledProcedures: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: UnscheduledProcedureRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseUnscheduledProcedureResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/unscheduled-procedure`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetUncollectedByService
     * @request POST:/practices/{practiceId}/reporting/uncollected-by-service
     */
    getUncollectedByService: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: UncollectedByServiceRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseUncollectedByServiceResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/uncollected-by-service`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetUncollectedByPatient
     * @request POST:/practices/{practiceId}/reporting/uncollected-by-patient
     */
    getUncollectedByPatient: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: UncollectedByPatientRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseUncollectedByPatientResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/uncollected-by-patient`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetUncollectedByCarrier
     * @request POST:/practices/{practiceId}/reporting/uncollected-by-carrier
     */
    getUncollectedByCarrier: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: UncollectedByCarrierRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseUncollectedByCarrierResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/uncollected-by-carrier`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetTimeSeries
     * @request POST:/practices/{practiceId}/reporting/time-series
     */
    getTimeSeries: (practiceId: number, data: TimeSeriesRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseTimeSeriesResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/time-series`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetRecallStats
     * @request POST:/practices/{practiceId}/reporting/recall
     */
    getRecallStats: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: ReportingRecallRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseReportingRecallResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/recall`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetRecallByPatientStats
     * @request POST:/practices/{practiceId}/reporting/recall-by-patient
     */
    getRecallByPatientStats: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: ReportingRecallByPatientRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseReportingRecallByPatientResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/recall-by-patient`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetProviderRollup
     * @request POST:/practices/{practiceId}/reporting/provider-rollup
     */
    getProviderRollup: (practiceId: number, data: ProviderRollupRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseProviderRollupResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/provider-rollup`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetProductionByProvider
     * @request POST:/practices/{practiceId}/reporting/production-by-provider
     */
    getProductionByProvider: (
      practiceId: number,
      data: ProductionByProviderRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseProductionByProviderResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/production-by-provider`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetProductionByProviderCombo
     * @request POST:/practices/{practiceId}/reporting/production-by-provider-combo
     */
    getProductionByProviderCombo: (
      practiceId: number,
      data: ProductionByProviderRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseProductionByProviderComboResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/production-by-provider-combo`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetProductionByProcedure
     * @request POST:/practices/{practiceId}/reporting/production-by-procedure
     */
    getProductionByProcedure: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: ProductionByProcedureRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseProductionByProcedureResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/production-by-procedure`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetProductionByAppointment
     * @request POST:/practices/{practiceId}/reporting/production-by-appointment
     */
    getProductionByAppointment: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: ProductionByAppointmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseProductionByAppointmentResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/production-by-appointment`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetPatientWalletStats
     * @request POST:/practices/{practiceId}/reporting/patient-wallet
     */
    getPatientWalletStats: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: PatientWalletRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientWalletResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/patient-wallet`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetPatientBalanceAging
     * @request POST:/practices/{practiceId}/reporting/patient-balance-aging
     */
    getPatientBalanceAging: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: PatientBalanceAgingRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientBalanceAgingResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/patient-balance-aging`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetPatientAppointmentStats
     * @request POST:/practices/{practiceId}/reporting/patient-appointment-stats
     */
    getPatientAppointmentStats: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: PatientAppointmentStatsRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientAppointmentStatsResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/patient-appointment-stats`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetJournalEvents
     * @request POST:/practices/{practiceId}/reporting/journal-event
     */
    getJournalEvents: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: JournalEventRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseJournalEventResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/journal-event`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetInsuranceAdjustmentStats
     * @request POST:/practices/{practiceId}/reporting/insurance-adjustment
     */
    getInsuranceAdjustmentStats: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: InsuranceAdjustmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseInsuranceAdjustmentResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/insurance-adjustment`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetInboundReferralStats
     * @request POST:/practices/{practiceId}/reporting/inbound-referral
     */
    getInboundReferralStats: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: InboundReferralRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseInboundReferralResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/inbound-referral`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetCollectionAdjustmentStats
     * @request POST:/practices/{practiceId}/reporting/collection-adjustment
     */
    getCollectionAdjustmentStats: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: CollectionAdjustmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseCollectionAdjustmentResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/collection-adjustment`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetCollectedPayment
     * @request POST:/practices/{practiceId}/reporting/collected-payment
     */
    getCollectedPayment: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: CollectedPaymentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseCollectedPaymentResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/collected-payment`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetCollectedPaymentByType
     * @request POST:/practices/{practiceId}/reporting/collected-payment-by-type
     */
    getCollectedPaymentByType: (
      practiceId: number,
      data: CollectedPaymentByTypeRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseCollectedPaymentByTypeResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/collected-payment-by-type`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetCollectedPaymentByProvider
     * @request POST:/practices/{practiceId}/reporting/collected-payment-by-provider
     */
    getCollectedPaymentByProvider: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: CollectedPaymentByProviderRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseCollectedPaymentByProviderResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/collected-payment-by-provider`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetCollectedPaymentByProviderCombo
     * @request POST:/practices/{practiceId}/reporting/collected-payment-by-provider-combo
     */
    getCollectedPaymentByProviderCombo: (
      practiceId: number,
      data: CollectedPaymentByProviderRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseCollectedPaymentByProviderComboResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/collected-payment-by-provider-combo`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reporting-controller
     * @name GetAppointmentBooking
     * @request POST:/practices/{practiceId}/reporting/appointment-booking
     */
    getAppointmentBooking: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: AppointmentBookingRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAppointmentBookingResponse, ErrorResponse>({
        path: `/practices/${practiceId}/reporting/appointment-booking`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetPracticeRemoteSettings
     * @request GET:/practices/{practiceId}/remote-settings
     */
    getPracticeRemoteSettings: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePracticeRemoteSettingsVO, ErrorResponse>({
        path: `/practices/${practiceId}/remote-settings`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name UpsertPracticeRemoteSettings
     * @request POST:/practices/{practiceId}/remote-settings
     */
    upsertPracticeRemoteSettings: (
      practiceId: number,
      data: PracticeRemoteSettingsRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePracticeConnectedRemoteSettingsVO, ErrorResponse>({
        path: `/practices/${practiceId}/remote-settings`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetPublicHolidaysByYear
     * @request GET:/practices/{practiceId}/public-holidays
     */
    getPublicHolidaysByYear: (
      practiceId: number,
      query: {
        year: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPublicHolidayVO, ErrorResponse>({
        path: `/practices/${practiceId}/public-holidays`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name UpsertPublicHolidays
     * @request POST:/practices/{practiceId}/public-holidays
     */
    upsertPublicHolidays: (practiceId: number, data: PublicHolidayVO[], params: RequestParams = {}) =>
      this.request<SuccessResponseListPublicHolidayVO, ErrorResponse>({
        path: `/practices/${practiceId}/public-holidays`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name UpdateProviderScheduling
     * @request POST:/practices/{practiceId}/providers/scheduling
     */
    updateProviderScheduling: (
      practiceId: number,
      data: ProviderSchedulingRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseProviderVO, ErrorResponse>({
        path: `/practices/${practiceId}/providers/scheduling`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name UpdateProviderColor
     * @request POST:/practices/{practiceId}/providers/colors
     */
    updateProviderColor: (practiceId: number, data: ProviderColorRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseProviderVO, ErrorResponse>({
        path: `/practices/${practiceId}/providers/colors`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetProfileImage1
     * @request GET:/practices/{practiceId}/profile-image
     */
    getProfileImage1: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseProfileImageVO, ErrorResponse>({
        path: `/practices/${practiceId}/profile-image`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name UploadProfileImage1
     * @request POST:/practices/{practiceId}/profile-image
     */
    uploadProfileImage1: (
      practiceId: number,
      data: {
        /** @format binary */
        image: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/profile-image`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name DeleteProfileImage1
     * @request DELETE:/practices/{practiceId}/profile-image
     */
    deleteProfileImage1: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/profile-image`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name GetProcedureShortcutsByCategory
     * @request GET:/practices/{practiceId}/procedure-shortcuts
     */
    getProcedureShortcutsByCategory: (
      practiceId: number,
      query: {
        category:
          | "EXAMS"
          | "XRAYS"
          | "POST_COMPOSITE"
          | "ANT_COMPOSITE"
          | "AMALGAM"
          | "PERIO"
          | "APPLIANCES"
          | "CROWNS_OR_BRIDGES"
          | "ENDO"
          | "IMPLANTS"
          | "ORAL_SURGERY"
          | "ADJUNCT";
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListProcedureShortcutVO, ErrorResponse>({
        path: `/practices/${practiceId}/procedure-shortcuts`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name CreateProcedureShortcut
     * @request POST:/practices/{practiceId}/procedure-shortcuts
     */
    createProcedureShortcut: (
      practiceId: number,
      data: CreateProcedureShortcutRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseProcedureShortcutVO, ErrorResponse>({
        path: `/practices/${practiceId}/procedure-shortcuts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name ReorderProcedureShortcuts
     * @request POST:/practices/{practiceId}/procedure-shortcuts/reorder
     */
    reorderProcedureShortcuts: (
      practiceId: number,
      data: ReorderProcedureShortcutsRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListProcedureShortcutVO, ErrorResponse>({
        path: `/practices/${practiceId}/procedure-shortcuts/reorder`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prescription-controller
     * @name OnboardPracticeToDosespot
     * @request POST:/practices/{practiceId}/prescription/onboard
     */
    onboardPracticeToDosespot: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/prescription/onboard`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prescription-controller
     * @name FetchDosespotSingleSignOnUri
     * @request POST:/practices/{practiceId}/prescription/dosespot
     */
    fetchDosespotSingleSignOnUri: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseDosespotSingleSignOnVO, ErrorResponse>({
        path: `/practices/${practiceId}/prescription/dosespot`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-feature-controller
     * @name UpdatePracticeFeatureState
     * @request POST:/practices/{practiceId}/practice-features/{practiceFeatureId}
     */
    updatePracticeFeatureState: (
      practiceId: number,
      practiceFeatureId: number,
      query: {
        isEnabled: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePracticeFeatureStateVO, ErrorResponse>({
        path: `/practices/${practiceId}/practice-features/${practiceFeatureId}`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-feature-controller
     * @name BatchUpdatePracticeFeatureState
     * @request POST:/practices/{practiceId}/practice-features/batch-update
     */
    batchUpdatePracticeFeatureState: (
      practiceId: number,
      data: BatchUpdatePracticeFeatureStateRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPracticeFeatureStateVO, ErrorResponse>({
        path: `/practices/${practiceId}/practice-features/batch-update`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name SubmitPayroll
     * @request POST:/practices/{practiceId}/payrolls/{payrollUuid}/submit
     */
    submitPayroll: (practiceId: number, payrollUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/payrolls/${payrollUuid}/submit`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name CancelPayroll
     * @request POST:/practices/{practiceId}/payrolls/{payrollUuid}/cancel
     */
    cancelPayroll: (practiceId: number, payrollUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/payrolls/${payrollUuid}/cancel`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name CalculatePayroll
     * @request POST:/practices/{practiceId}/payrolls/{payrollUuid}/calculate
     */
    calculatePayroll: (practiceId: number, payrollUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/payrolls/${payrollUuid}/calculate`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name OnboardNewPractice
     * @request POST:/practices/{practiceId}/payrolls/onboard
     */
    onboardNewPractice: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/payrolls/onboard`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name MigrateCompany
     * @request POST:/practices/{practiceId}/payrolls/migrate
     */
    migrateCompany: (practiceId: number, data: EmployeeConsolidationRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/payrolls/migrate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name CreatePracticeFlow
     * @request POST:/practices/{practiceId}/payrolls/flows
     */
    createPracticeFlow: (practiceId: number, data: GustoPracticeFlowRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseGustoFlowVO, ErrorResponse>({
        path: `/practices/${practiceId}/payrolls/flows`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-profile-controller
     * @name CreatePaymentProfile
     * @request POST:/practices/{practiceId}/payment-profiles
     */
    createPaymentProfile: (
      practiceId: number,
      data: CreatePaymentProfileByTokenRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentProfileVO, ErrorResponse>({
        path: `/practices/${practiceId}/payment-profiles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-device-controller
     * @name ListPaymentDevices
     * @request GET:/practices/{practiceId}/payment-devices
     */
    listPaymentDevices: (
      practiceId: number,
      query?: {
        states?: ("CREATED" | "ACTIVATED" | "ACTIVATION_IN_PROGRESS" | "ACTIVATION_FAILED" | "DEACTIVATED")[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPaymentDeviceVO, ErrorResponse>({
        path: `/practices/${practiceId}/payment-devices`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-device-controller
     * @name CreatePaymentDevice
     * @request POST:/practices/{practiceId}/payment-devices
     */
    createPaymentDevice: (practiceId: number, data: CreatePaymentDeviceRequest, params: RequestParams = {}) =>
      this.request<SuccessResponsePaymentDeviceVO, ErrorResponse>({
        path: `/practices/${practiceId}/payment-devices`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name CreatePatient
     * @request POST:/practices/{practiceId}/patients
     */
    createPatient: (practiceId: number, data: CreatePatientRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseListPatientVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name MultiUpdate
     * @request DELETE:/practices/{practiceId}/patients
     */
    multiUpdate: (
      practiceId: number,
      query: {
        action: "DELETE" | "ARCHIVE";
      },
      data: MultiUpdateVO,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients`,
        method: "DELETE",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet-controller
     * @name GetWallets
     * @request GET:/practices/{practiceId}/patients/{patientId}/wallets
     */
    getWallets: (
      practiceId: number,
      patientId: number,
      query: {
        withEmptyBalances?: boolean;
        includeFamily?: boolean;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListWalletVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/wallets`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet-controller
     * @name CreateWallet
     * @request POST:/practices/{practiceId}/patients/{patientId}/wallets
     */
    createWallet: (
      practiceId: number,
      patientId: number,
      data: WalletCreationRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseWalletVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/wallets`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet-controller
     * @name RefundWallet
     * @request POST:/practices/{practiceId}/patients/{patientId}/wallets/{walletUuid}/refund
     */
    refundWallet: (
      practiceId: number,
      patientId: number,
      walletUuid: string,
      data: WalletRefundRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseWalletActivityVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/wallets/${walletUuid}/refund`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet-controller
     * @name TopupWallet
     * @request POST:/practices/{practiceId}/patients/{patientId}/wallets/top-up
     */
    topupWallet: (
      practiceId: number,
      patientId: number,
      data: WalletTopupRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseWalletActivityVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/wallets/top-up`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags statement-controller
     * @name GetStatements
     * @request GET:/practices/{practiceId}/patients/{patientId}/statements
     */
    getStatements: (
      patientId: number,
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListStatementVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/statements`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags statement-controller
     * @name CreateStatement
     * @request POST:/practices/{practiceId}/patients/{patientId}/statements
     */
    createStatement: (
      practiceId: number,
      patientId: number,
      data: CreateStatementRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseStatementVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/statements`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name ResendPatientInvite
     * @request POST:/practices/{practiceId}/patients/{patientId}/resend-invite
     */
    resendPatientInvite: (practiceId: number, patientId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/resend-invite`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recall-controller
     * @name GetPatientRecalls
     * @request GET:/practices/{practiceId}/patients/{patientId}/recall
     */
    getPatientRecalls: (practiceId: number, patientId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListRecallVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/recall`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recall-controller
     * @name CreateRecall
     * @request POST:/practices/{practiceId}/patients/{patientId}/recall
     */
    createRecall: (
      practiceId: number,
      patientId: number,
      data: CreateRecallRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseRecallVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/recall`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prescription-controller
     * @name FetchPatientDosespotSingleSignOnUri
     * @request POST:/practices/{practiceId}/patients/{patientId}/prescription/dosespot
     */
    fetchPatientDosespotSingleSignOnUri: (
      practiceId: number,
      patientId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDosespotSingleSignOnVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/prescription/dosespot`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags perio-chart-exam-controller
     * @name GetPerioChartExams
     * @request GET:/practices/{practiceId}/patients/{patientId}/perio-chart-exams
     */
    getPerioChartExams: (practiceId: number, patientId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListPerioChartExamVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/perio-chart-exams`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags perio-chart-exam-controller
     * @name CreatePerioChartExam
     * @request POST:/practices/{practiceId}/patients/{patientId}/perio-chart-exams
     */
    createPerioChartExam: (
      practiceId: number,
      patientId: number,
      data: CreatePerioChartExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePerioChartExamVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/perio-chart-exams`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags perio-chart-exam-controller
     * @name ClonePerioChartExam
     * @request POST:/practices/{practiceId}/patients/{patientId}/perio-chart-exams/{perioChartExamUuid}/clone
     */
    clonePerioChartExam: (
      practiceId: number,
      patientId: number,
      perioChartExamUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePerioChartExamVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/perio-chart-exams/${perioChartExamUuid}/clone`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name AnalyzeImagesWithPearl
     * @request POST:/practices/{practiceId}/patients/{patientId}/pearl-imaging-analysis
     */
    analyzeImagesWithPearl: (
      practiceId: number,
      patientId: number,
      data: PearlAnalysisRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePearlAnalysisRequestStatusVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/pearl-imaging-analysis`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-profile-controller
     * @name GetAllPaymentProfilesByPatient
     * @request GET:/practices/{practiceId}/patients/{patientId}/payment-profiles
     */
    getAllPaymentProfilesByPatient: (
      patientId: number,
      practiceId: number,
      query?: {
        includeFamily?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPaymentProfileVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/payment-profiles`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-profile-controller
     * @name CreatePaymentProfileByToken
     * @request POST:/practices/{practiceId}/patients/{patientId}/payment-profiles
     */
    createPaymentProfileByToken: (
      patientId: number,
      practiceId: number,
      data: CreatePaymentProfileByTokenRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentProfileVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/payment-profiles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name GetPatientProcedures
     * @request GET:/practices/{practiceId}/patients/{patientId}/patient-procedures
     */
    getPatientProcedures: (
      patientId: number,
      practiceId: number,
      query: {
        includeStatuses?: (
          | "EXISTING_OTHER"
          | "PLANNED"
          | "SCHEDULED"
          | "DONE"
          | "EXISTING_CURRENT"
          | "REFERRED"
          | "REJECTED"
        )[];
        /** @format uuid */
        treatmentPlanUuid?: string;
        /** @format int64 */
        appointmentId?: number;
        ids?: number[];
        sort?: string[];
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPatientProcedureVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-procedures`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name CreatePatientProcedures
     * @request POST:/practices/{practiceId}/patients/{patientId}/patient-procedures
     */
    createPatientProcedures: (
      patientId: number,
      practiceId: number,
      data: BatchCreatePatientProcedureRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPatientProcedureVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-procedures`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name CreatePatientNote
     * @request POST:/practices/{practiceId}/patients/{patientId}/patient-note
     */
    createPatientNote: (
      practiceId: number,
      patientId: number,
      data: CreatePatientNoteRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientNoteVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-note`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name CreateClinicalNoteWithForms
     * @request POST:/practices/{practiceId}/patients/{patientId}/patient-note/clinical
     */
    createClinicalNoteWithForms: (
      practiceId: number,
      patientId: number,
      data: UpsertClinicalNoteWithFormsRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientNoteVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-note/clinical`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name GetOnboardingInfo
     * @request GET:/practices/{practiceId}/patients/{patientId}/onboarding
     */
    getOnboardingInfo: (
      practiceId: number,
      patientId: number,
      query: {
        onboardingState:
          | "GENERAL_INFO"
          | "INSURANCE_INFO"
          | "DENTAL_HISTORY_INFO"
          | "MEDICAL_HISTORY_INFO"
          | "PAYMENT_INFO"
          | "CONSENT"
          | "PDF_SUBMISSION"
          | "COMPLETED";
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseOnboardingInfoVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/onboarding`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name UpdateOnboardingInfo
     * @request POST:/practices/{practiceId}/patients/{patientId}/onboarding
     */
    updateOnboardingInfo: (
      practiceId: number,
      patientId: number,
      query: {
        onboardingState:
          | "GENERAL_INFO"
          | "INSURANCE_INFO"
          | "DENTAL_HISTORY_INFO"
          | "MEDICAL_HISTORY_INFO"
          | "PAYMENT_INFO"
          | "CONSENT"
          | "PDF_SUBMISSION"
          | "COMPLETED";
      },
      data: UpdateOnboardingInfoRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseOnboardingInfoVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/onboarding`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name CreateMultiInvoicePayment
     * @request POST:/practices/{practiceId}/patients/{patientId}/multi-invoice-payments
     */
    createMultiInvoicePayment: (
      practiceId: number,
      patientId: number,
      data: MultiInvoicePaymentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMultiInvoicePaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/multi-invoice-payments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetMultiInvoicePayment
     * @request GET:/practices/{practiceId}/patients/{patientId}/multi-invoice-payments/{paymentUuid}
     */
    getMultiInvoicePayment: (
      practiceId: number,
      patientId: number,
      paymentUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMultiInvoicePaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/multi-invoice-payments/${paymentUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name EditMultiInvoicePayment
     * @request POST:/practices/{practiceId}/patients/{patientId}/multi-invoice-payments/{paymentUuid}
     */
    editMultiInvoicePayment: (
      practiceId: number,
      patientId: number,
      paymentUuid: string,
      data: EditMultiInvoicePaymentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMultiInvoicePaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/multi-invoice-payments/${paymentUuid}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name DeleteMultiInvoicePayment
     * @request DELETE:/practices/{practiceId}/patients/{patientId}/multi-invoice-payments/{paymentUuid}
     */
    deleteMultiInvoicePayment: (
      practiceId: number,
      patientId: number,
      paymentUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/multi-invoice-payments/${paymentUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name CreatePatientInitiatedMultiInvoicePaymentRecord
     * @request POST:/practices/{practiceId}/patients/{patientId}/multi-invoice-payments/patient
     */
    createPatientInitiatedMultiInvoicePaymentRecord: (
      practiceId: number,
      patientId: number,
      data: MultiInvoicePaymentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMultiInvoicePaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/multi-invoice-payments/patient`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name GetMountList
     * @request GET:/practices/{practiceId}/patients/{patientId}/mounts
     */
    getMountList: (
      practiceId: number,
      patientId: number,
      query: {
        imageType?: string;
        sortColumn?: string;
        orderBy?: string;
        populateImages?: boolean;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListMountVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/mounts`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name GenerateMount
     * @request POST:/practices/{practiceId}/patients/{patientId}/mounts
     */
    generateMount: (
      practiceId: number,
      patientId: number,
      data: GenerateMountRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMountVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/mounts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name UploadMedicalImage
     * @request POST:/practices/{practiceId}/patients/{patientId}/mounts/{mountId}/medical-images
     * @deprecated
     */
    uploadMedicalImage: (
      practiceId: number,
      patientId: number,
      mountId: number,
      query: {
        /** @format int32 */
        position_i?: number;
        /** @format int64 */
        position_x?: number;
        /** @format int64 */
        position_y?: number;
        /** @format double */
        height?: number;
        /** @format double */
        width?: number;
        type: string;
        teeth?: string[];
      },
      data: {
        /** @format binary */
        image: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMedicalImageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/mounts/${mountId}/medical-images`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name UploadMedicalImageV2
     * @request POST:/practices/{practiceId}/patients/{patientId}/mounts/{mountId}/medical-images-v2
     */
    uploadMedicalImageV2: (
      practiceId: number,
      patientId: number,
      mountId: number,
      data: UploadMedicalImageV2Request,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMedicalImageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/mounts/${mountId}/medical-images-v2`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name GetPatientMessages
     * @request GET:/practices/{practiceId}/patients/{patientId}/messages
     */
    getPatientMessages: (
      patientId: number,
      practiceId: number,
      query: {
        searchString?: string;
        type?: "CHAT" | "EMAIL" | "CALL" | "SMS" | "MAIL";
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/messages`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name CreateAndSendMessage
     * @request POST:/practices/{practiceId}/patients/{patientId}/messages
     */
    createAndSendMessage: (
      patientId: number,
      practiceId: number,
      data: MessageRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/messages`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name MarkAsReadOrUnread
     * @request POST:/practices/{practiceId}/patients/{patientId}/messages/read-status
     */
    markAsReadOrUnread: (
      patientId: number,
      practiceId: number,
      data: MessageReadRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/messages/read-status`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name GetMedicalHistory
     * @request GET:/practices/{practiceId}/patients/{patientId}/medical-history
     */
    getMedicalHistory: (
      patientId: number,
      practiceId: number,
      query?: {
        type?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMedicalHistoryResponseVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/medical-history`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name UpdateMedicalHistory
     * @request POST:/practices/{practiceId}/patients/{patientId}/medical-history
     */
    updateMedicalHistory: (
      practiceId: number,
      patientId: number,
      data: PatientMedicalHistoryVO,
      query?: {
        type?: "MEDICAL" | "DENTAL";
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/medical-history`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name CreatePatientInitiatedPaymentRecord
     * @request POST:/practices/{practiceId}/patients/{patientId}/invoices/{invoiceUuid}/payments
     */
    createPatientInitiatedPaymentRecord: (
      practiceId: number,
      patientId: number,
      invoiceUuid: string,
      data: PaymentCreationRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/invoices/${invoiceUuid}/payments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name GetPatientInsurances
     * @request GET:/practices/{practiceId}/patients/{patientId}/insurances
     */
    getPatientInsurances: (
      practiceId: number,
      patientId: number,
      query: {
        /** @default ["ACTIVE"] */
        includeState: ("ACTIVE" | "INACTIVE" | "ARCHIVED")[];
        includeInsurancePlan?: boolean;
        includeBenefitCoverage?: boolean;
        includeProcedureHistory?: boolean;
        includeBenefitLimitation?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPatientInsuranceResponse, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name AddPatientInsurance
     * @request POST:/practices/{practiceId}/patients/{patientId}/insurances
     */
    addPatientInsurance: (
      practiceId: number,
      patientId: number,
      data: InsuranceDetailsVO,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientInsuranceVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name VerifyPatientInsuranceEligibility
     * @request POST:/practices/{practiceId}/patients/{patientId}/insurances/{insuranceId}/verify
     */
    verifyPatientInsuranceEligibility: (
      practiceId: number,
      patientId: number,
      insuranceId: number,
      data: InsuranceVerifyRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientInsuranceVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances/${insuranceId}/verify`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-insurance-controller
     * @name UnlinkPatientInsuranceFromPlan
     * @request POST:/practices/{practiceId}/patients/{patientId}/insurances/{insuranceId}/unlink
     */
    unlinkPatientInsuranceFromPlan: (
      practiceId: number,
      patientId: number,
      insuranceId: number,
      data: UnlinkInsurancePlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientInsuranceResponse, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances/${insuranceId}/unlink`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags procedure-history-controller
     * @name GetRecentProcedureHistoryItems
     * @request GET:/practices/{practiceId}/patients/{patientId}/insurances/{insuranceId}/procedure-history/recent
     */
    getRecentProcedureHistoryItems: (
      practiceId: number,
      patientId: number,
      insuranceId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListRecentProcedureHistoryItemVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances/${insuranceId}/procedure-history/recent`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags procedure-history-controller
     * @name UpdateRecentProcedureHistoryItem
     * @request POST:/practices/{practiceId}/patients/{patientId}/insurances/{insuranceId}/procedure-history/recent
     */
    updateRecentProcedureHistoryItem: (
      practiceId: number,
      patientId: number,
      insuranceId: number,
      data: BatchUpdateRecentProcedureHistoryRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListRecentProcedureHistoryItemVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances/${insuranceId}/procedure-history/recent`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-insurance-controller
     * @name LinkPatientInsuranceToPlan
     * @request POST:/practices/{practiceId}/patients/{patientId}/insurances/{insuranceId}/link
     */
    linkPatientInsuranceToPlan: (
      practiceId: number,
      patientId: number,
      insuranceId: number,
      data: LinkInsurancePlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientInsuranceResponse, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances/${insuranceId}/link`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-insurance-controller
     * @name AutoVerifyEligibility
     * @request POST:/practices/{practiceId}/patients/{patientId}/insurances/{insuranceId}/auto/verify
     */
    autoVerifyEligibility: (
      practiceId: number,
      patientId: number,
      insuranceId: number,
      data: AutoVerifyEligibilityRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientInsuranceVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances/${insuranceId}/auto/verify`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-insurance-controller
     * @name AddDependentPatientInsurance
     * @request POST:/practices/{practiceId}/patients/{patientId}/insurances/dependent
     */
    addDependentPatientInsurance: (
      practiceId: number,
      patientId: number,
      data: InsuranceBySubscriberIdRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientInsuranceVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances/dependent`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name SubmitFormResponses
     * @request POST:/practices/{practiceId}/patients/{patientId}/forms/{uuidOrSlug}/responses
     */
    submitFormResponses: (
      practiceId: number,
      patientId: number,
      uuidOrSlug:
        | string
        | "DENTAL_HISTORY"
        | "MEDICAL_HISTORY"
        | "CONSENT"
        | "TREATMENT_PLAN_FORM"
        | "APPOINTMENT_FORM",
      data: BatchFormResponseRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormSubmissionVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/forms/${uuidOrSlug}/responses`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-task-controller
     * @name ListFormTasks
     * @request GET:/practices/{practiceId}/patients/{patientId}/form-tasks
     */
    listFormTasks: (
      practiceId: number,
      patientId: number,
      query: {
        states?: ("PENDING" | "COMPLETED" | "ARCHIVED" | "PRINT_ONLY")[];
        appointmentIds?: number[];
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListFormTaskVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/form-tasks`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-task-controller
     * @name CreateFormTask
     * @request POST:/practices/{practiceId}/patients/{patientId}/form-tasks
     */
    createFormTask: (
      practiceId: number,
      patientId: number,
      data: CreateFormTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormTaskVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/form-tasks`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags family-controller
     * @name AddFamilyMember
     * @request POST:/practices/{practiceId}/patients/{patientId}/family-members
     */
    addFamilyMember: (
      patientId: number,
      practiceId: number,
      data: AddFamilyMemberRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFamilyRelationVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/family-members`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags family-controller
     * @name RemoveFamilyMember
     * @request DELETE:/practices/{practiceId}/patients/{patientId}/family-members
     */
    removeFamilyMember: (
      patientId: number,
      practiceId: number,
      data: FamilyMemberIdVO,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/family-members`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags family-controller
     * @name UpdateFamilyMember
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/family-members
     */
    updateFamilyMember: (
      patientId: number,
      practiceId: number,
      data: UpdateFamilyMemberRequest[],
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListFamilyRelationVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/family-members`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name CallPatient
     * @request POST:/practices/{practiceId}/patients/{patientId}/call
     */
    callPatient: (practiceId: number, patientId: number, data: CallRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/call`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name RescheduleAppointment
     * @request POST:/practices/{practiceId}/patients/{patientId}/appointments/{appointmentId}/reschedule
     */
    rescheduleAppointment: (
      practiceId: number,
      patientId: number,
      appointmentId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/appointments/${appointmentId}/reschedule`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name CancelAppointment
     * @request POST:/practices/{practiceId}/patients/{patientId}/appointments/{appointmentId}/cancel
     */
    cancelAppointment: (
      practiceId: number,
      patientId: number,
      appointmentId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/appointments/${appointmentId}/cancel`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags adjustment-controller
     * @name CreateAdjustment
     * @request POST:/practices/{practiceId}/patients/{patientId}/adjustments
     */
    createAdjustment: (
      practiceId: number,
      patientId: number,
      data: CreateAdjustmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAdjustmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/adjustments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name LookupPatient
     * @request POST:/practices/{practiceId}/patients/lookup
     */
    lookupPatient: (practiceId: number, data: PatientLookupRequest, params: RequestParams = {}) =>
      this.request<SuccessResponsePatientVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/lookup`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name LookupPatientSummary
     * @request POST:/practices/{practiceId}/patients/lookup/summary
     */
    lookupPatientSummary: (practiceId: number, data: PatientLookupRequest, params: RequestParams = {}) =>
      this.request<SuccessResponsePatientSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/lookup/summary`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name GetPatients
     * @request GET:/practices/{practiceId}/patients/list
     */
    getPatients: (
      practiceId: number,
      query: {
        searchString?: string;
        sortColumn?: string;
        orderBy?: string;
        "patientCriteria.status"?: (
          | "PROSPECT"
          | "ACTIVE"
          | "INACTIVE"
          | "ARCHIVED"
          | "NONPATIENT"
          | "DECEASED"
        )[];
        "patientCriteria.gender"?: "MALE" | "FEMALE" | "OTHER" | "UNKNOWN";
        /** @format int32 */
        "patientCriteria.ageGroup.min"?: number;
        /** @format int32 */
        "patientCriteria.ageGroup.max"?: number;
        "patientCriteria.primaryInsuranceVerifyStatuses"?: (
          | "VERIFIED"
          | "AUTO_VERIFIED"
          | "NOT_VERIFIED"
          | "FAILED"
        )[];
        "patientCriteria.primaryInsuranceExpired"?: boolean;
        "patientCriteria.primaryInsuranceCarrierIds"?: number[];
        /** @uniqueItems true */
        "patientCriteria.carrierIds"?: number[];
        "patientCriteria.hasNoContact"?: boolean;
        /** @format int64 */
        "patientCriteria.contactPatientId"?: number;
        "patientCriteria.excludePatientIds"?: number[];
        /** @format int64 */
        "patientCriteria.feeScheduleId"?: number;
        /** @format uuid */
        "patientCriteria.insurancePlanUuid"?: string;
        "patientCriteria.birthdayRange.min"?: string;
        "patientCriteria.birthdayRange.max"?: string;
        /**
         * @format int32
         * @max 365
         */
        "patientCriteria.birthdayWithinDays"?: number;
        /** @format int64 */
        "patientCriteria.patientBalanceRange.min"?: number;
        /** @format int64 */
        "patientCriteria.patientBalanceRange.max"?: number;
        /** @format int64 */
        "patientCriteria.insuranceBalanceRange.min"?: number;
        /** @format int64 */
        "patientCriteria.insuranceBalanceRange.max"?: number;
        /** @format int64 */
        "patientCriteria.remainingBenefitAmountRange.min"?: number;
        /** @format int64 */
        "patientCriteria.remainingBenefitAmountRange.max"?: number;
        "patientCriteria.preferredLanguages"?: string[];
        "patientCriteria.hasPreferredLanguage"?: boolean;
        "patientCriteria.genders"?: ("MALE" | "FEMALE" | "OTHER" | "UNKNOWN")[];
        /** @deprecated */
        appointmentCriteria?:
          | "NO_APPOINTMENT"
          | "APPOINTMENT_WITHIN_7_DAYS"
          | "APPOINTMENT_WITHIN_15_DAYS"
          | "APPOINTMENT_WITHIN_30_DAYS";
        "appointmentDateRange.min"?: string;
        "appointmentDateRange.max"?: string;
        hasNoAppointment?: boolean;
        /**
         * @format int32
         * @min 0
         */
        appointmentWithinDays?: number;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPatientSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/list`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name GetPatientsV2
     * @request POST:/practices/{practiceId}/patients/list
     */
    getPatientsV2: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: PatientListRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPatientSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/list`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-insurance-controller
     * @name BatchAutoVerifyEligibility
     * @request POST:/practices/{practiceId}/patients/insurances/auto/batch-verify
     */
    batchAutoVerifyEligibility: (
      practiceId: number,
      data: BatchAutoVerifyEligibilityRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/patients/insurances/auto/batch-verify`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name SwitchDentalProcedureOnPatientProcedure
     * @request POST:/practices/{practiceId}/patient-procedures/{patientProcedureId}/switch
     */
    switchDentalProcedureOnPatientProcedure: (
      practiceId: number,
      patientProcedureId: number,
      data: CreatePatientProcedureRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientProcedureVO, ErrorResponse>({
        path: `/practices/${practiceId}/patient-procedures/${patientProcedureId}/switch`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name ResetPrimaryEstimates
     * @request POST:/practices/{practiceId}/patient-procedures/{patientProcedureId}/reset-primary-estimates
     */
    resetPrimaryEstimates: (practiceId: number, patientProcedureId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePatientProcedureVO, ErrorResponse>({
        path: `/practices/${practiceId}/patient-procedures/${patientProcedureId}/reset-primary-estimates`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name OnboardPracticeWithPearl
     * @request POST:/practices/{practiceId}/onboard-pearl
     */
    onboardPracticeWithPearl: (
      practiceId: number,
      data: PearlOrganizationProcessAgreementsUrlsRequired,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/onboard-pearl`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration-controller
     * @name CreateMigrationRun
     * @request POST:/practices/{practiceId}/migration-runs
     */
    createMigrationRun: (practiceId: number, data: CreateMigrationRunRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseMigrationRunVO, ErrorResponse>({
        path: `/practices/${practiceId}/migration-runs`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration-controller
     * @name CreateSourceTargetLink
     * @request POST:/practices/{practiceId}/migration-runs/{migrationRunId}/source-target-links
     */
    createSourceTargetLink: (
      practiceId: number,
      migrationRunId: number,
      data: CreateMigrationSourceTargetLinkRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/migration-runs/${migrationRunId}/source-target-links`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration-controller
     * @name CreateSourceEntry
     * @request POST:/practices/{practiceId}/migration-runs/{migrationRunId}/source-entries
     */
    createSourceEntry: (
      practiceId: number,
      migrationRunId: number,
      data: CreateMigrationSourceEntryRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/migration-runs/${migrationRunId}/source-entries`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration-controller
     * @name SeekMigrationRun
     * @request POST:/practices/{practiceId}/migration-runs/{migrationRunId}/seek
     */
    seekMigrationRun: (
      practiceId: number,
      migrationRunId: number,
      data: SeekMigrationRunRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMigrationRunVO, ErrorResponse>({
        path: `/practices/${practiceId}/migration-runs/${migrationRunId}/seek`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration-controller
     * @name ResetMigrationRun
     * @request POST:/practices/{practiceId}/migration-runs/{migrationRunId}/reset
     */
    resetMigrationRun: (practiceId: number, migrationRunId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseMigrationRunVO, ErrorResponse>({
        path: `/practices/${practiceId}/migration-runs/${migrationRunId}/reset`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration-controller
     * @name ExecuteMigrationRun
     * @request POST:/practices/{practiceId}/migration-runs/{migrationRunId}/execute
     */
    executeMigrationRun: (
      practiceId: number,
      migrationRunId: number,
      data: ExecuteMigrationRunRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMigrationRunVO, ErrorResponse>({
        path: `/practices/${practiceId}/migration-runs/${migrationRunId}/execute`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration-controller
     * @name BatchCreateSourceEntries
     * @request POST:/practices/{practiceId}/migration-runs/{migrationRunId}/batch-source-entries
     */
    batchCreateSourceEntries: (
      practiceId: number,
      migrationRunId: number,
      data: BatchCreateMigrationEntriesRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/migration-runs/${migrationRunId}/batch-source-entries`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name CancelScheduledMessage
     * @request POST:/practices/{practiceId}/messages/{messageId}
     */
    cancelScheduledMessage: (
      practiceId: number,
      messageId: number,
      query?: {
        cancelAllInJourney?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/messages/${messageId}`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name RetryFailedMessage
     * @request POST:/practices/{practiceId}/messages/{messageId}/retry
     */
    retryFailedMessage: (
      practiceId: number,
      messageId: number,
      data: MessageRetryRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/messages/${messageId}/retry`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-campaign-controller
     * @name ListMessageCampaigns
     * @request GET:/practices/{practiceId}/message-campaign
     */
    listMessageCampaigns: (
      practiceId: number,
      query: {
        statuses?: ("DRAFT" | "SCHEDULED" | "SENT" | "CANCELED")[];
        channels?: ("PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL")[];
        isNamedCampaign?: boolean;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListMessageCampaignSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/message-campaign`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-campaign-controller
     * @name CreateMessageCampaign
     * @request POST:/practices/{practiceId}/message-campaign
     */
    createMessageCampaign: (practiceId: number, data: MessageCampaignRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseMessageCampaignVO, ErrorResponse>({
        path: `/practices/${practiceId}/message-campaign`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-campaign-controller
     * @name TriggerMessageCampaign
     * @request POST:/practices/{practiceId}/message-campaign/{messageCampaignUuid}/trigger
     */
    triggerMessageCampaign: (practiceId: number, messageCampaignUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseMessageCampaignVO, ErrorResponse>({
        path: `/practices/${practiceId}/message-campaign/${messageCampaignUuid}/trigger`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-campaign-controller
     * @name PreviewMessageCampaign
     * @request POST:/practices/{practiceId}/message-campaign/{messageCampaignUuid}/preview
     */
    previewMessageCampaign: (
      practiceId: number,
      messageCampaignUuid: string,
      data: PreviewMessageCampaignRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMessageCampaignVO, ErrorResponse>({
        path: `/practices/${practiceId}/message-campaign/${messageCampaignUuid}/preview`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-campaign-controller
     * @name CancelMessageCampaign
     * @request POST:/practices/{practiceId}/message-campaign/{messageCampaignUuid}/cancel
     */
    cancelMessageCampaign: (practiceId: number, messageCampaignUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseMessageCampaignVO, ErrorResponse>({
        path: `/practices/${practiceId}/message-campaign/${messageCampaignUuid}/cancel`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags twilio-practice-config-controller
     * @name PurchaseTwilioPhoneNumber
     * @request POST:/practices/{practiceId}/mass-text/twilio-purchase
     */
    purchaseTwilioPhoneNumber: (
      practiceId: number,
      data: TwilioPhoneNumberPurchaseRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/mass-text/twilio-purchase`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags twilio-practice-config-controller
     * @name InitTwilioSubaccountAndProfile
     * @request POST:/practices/{practiceId}/mass-text/twilio-init
     */
    initTwilioSubaccountAndProfile: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/mass-text/twilio-init`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags mango-controller
     * @name UpsertMangoAuthorization
     * @request POST:/practices/{practiceId}/mango-authorization
     */
    upsertMangoAuthorization: (
      practiceId: number,
      data: MangoAuthorizationRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/mango-authorization`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags license-controller
     * @name GetLicenses
     * @request GET:/practices/{practiceId}/licenses
     */
    getLicenses: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListLicenseVO, ErrorResponse>({
        path: `/practices/${practiceId}/licenses`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags license-controller
     * @name CreateLicenses
     * @request POST:/practices/{practiceId}/licenses
     */
    createLicenses: (practiceId: number, data: BatchCreateLicenseRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseListLicenseVO, ErrorResponse>({
        path: `/practices/${practiceId}/licenses`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags license-controller
     * @name DeleteLicenses
     * @request DELETE:/practices/{practiceId}/licenses
     */
    deleteLicenses: (practiceId: number, data: BatchDeleteLicenseRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/licenses`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name GetLabs
     * @request GET:/practices/{practiceId}/labs
     */
    getLabs: (
      practiceId: number,
      query?: {
        /** @default false */
        includeArchived?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListLabVO, ErrorResponse>({
        path: `/practices/${practiceId}/labs`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name CreateLab
     * @request POST:/practices/{practiceId}/labs
     */
    createLab: (practiceId: number, data: LabRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseLabVO, ErrorResponse>({
        path: `/practices/${practiceId}/labs`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name UnarchiveLab
     * @request POST:/practices/{practiceId}/labs/{labUuid}/unarchive
     */
    unarchiveLab: (practiceId: number, labUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseLabVO, ErrorResponse>({
        path: `/practices/${practiceId}/labs/${labUuid}/unarchive`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name ArchiveLab
     * @request POST:/practices/{practiceId}/labs/{labUuid}/archive
     */
    archiveLab: (practiceId: number, labUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseLabVO, ErrorResponse>({
        path: `/practices/${practiceId}/labs/${labUuid}/archive`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name GetLabCases
     * @request GET:/practices/{practiceId}/lab-cases
     */
    getLabCases: (
      practiceId: number,
      query: {
        /** @format int64 */
        patientId?: number;
        patientSearchString?: string;
        statuses?: ("DRAFT" | "SENT" | "RECEIVED" | "RETURNED" | "READY" | "COMPLETE")[];
        sortField?:
          | "DATE"
          | "PATIENT"
          | "LAB"
          | "ESTIMATED_RECEIVE_DATE"
          | "NEXT_PATIENT_PROCEDURE_DATE"
          | "STATUS";
        sortDirection?: "ASCENDING" | "DESCENDING";
        includeArchived?: boolean;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListLabCaseVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-cases`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name CreateLabCase
     * @request POST:/practices/{practiceId}/lab-cases
     */
    createLabCase: (practiceId: number, data: LabCaseRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseLabCaseVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-cases`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name UnarchiveLabCase
     * @request POST:/practices/{practiceId}/lab-cases/{labCaseUuid}/unarchive
     */
    unarchiveLabCase: (practiceId: number, labCaseUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseLabCaseVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-cases/${labCaseUuid}/unarchive`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name ArchiveLabCase
     * @request POST:/practices/{practiceId}/lab-cases/{labCaseUuid}/archive
     */
    archiveLabCase: (practiceId: number, labCaseUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseLabCaseVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-cases/${labCaseUuid}/archive`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name GetLabCaseTypes
     * @request GET:/practices/{practiceId}/lab-case-types
     */
    getLabCaseTypes: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListLabCaseTypeVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-case-types`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name CreateLabCaseType
     * @request POST:/practices/{practiceId}/lab-case-types
     */
    createLabCaseType: (practiceId: number, data: LabCaseTypeRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseLabCaseTypeVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-case-types`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name GetLabCaseReturnReasons
     * @request GET:/practices/{practiceId}/lab-case-return-reasons
     */
    getLabCaseReturnReasons: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListLabCaseReturnReasonVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-case-return-reasons`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name CreateLabCaseReturnReason
     * @request POST:/practices/{practiceId}/lab-case-return-reasons
     */
    createLabCaseReturnReason: (
      practiceId: number,
      data: LabCaseReturnReasonRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseLabCaseReturnReasonVO, ErrorResponse>({
        path: `/practices/${practiceId}/lab-case-return-reasons`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags automation-controller
     * @name PreviewSmsAutomation
     * @request POST:/practices/{practiceId}/journeys/{journeyUuidOrType}/preview/sms
     */
    previewSmsAutomation: (
      practiceId: number,
      journeyUuidOrType: string | "APPOINTMENT" | "POST_APPOINTMENT" | "RECALL" | "FORMS",
      data: PreviewSmsAutomationRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/journeys/${journeyUuidOrType}/preview/sms`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags automation-controller
     * @name PreviewEmailAutomation
     * @request POST:/practices/{practiceId}/journeys/{journeyUuidOrType}/preview/email
     */
    previewEmailAutomation: (
      practiceId: number,
      journeyUuidOrType: string | "APPOINTMENT" | "POST_APPOINTMENT" | "RECALL" | "FORMS",
      data: PreviewEmailAutomationRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/journeys/${journeyUuidOrType}/preview/email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags automation-controller
     * @name CreateAutomationAction
     * @request POST:/practices/{practiceId}/journeys/{journeyUuidOrType}/actions
     */
    createAutomationAction: (
      practiceId: number,
      journeyUuidOrType: string | "APPOINTMENT" | "POST_APPOINTMENT" | "RECALL" | "FORMS",
      data: AutomationActionRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAutomationActionVO, ErrorResponse>({
        path: `/practices/${practiceId}/journeys/${journeyUuidOrType}/actions`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name CreatePatientEngagementAction
     * @request POST:/practices/{practiceId}/journey/{journeyUuid}/action
     */
    createPatientEngagementAction: (
      practiceId: number,
      journeyUuid: string,
      data: CreateActionRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseActionVO, ErrorResponse>({
        path: `/practices/${practiceId}/journey/${journeyUuid}/action`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name CreateInvoice
     * @request POST:/practices/{practiceId}/invoices
     */
    createInvoice: (practiceId: number, data: CreateInvoiceRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseInvoiceVO, ErrorResponse>({
        path: `/practices/${practiceId}/invoices`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name CreatePaymentRecord
     * @request POST:/practices/{practiceId}/invoices/{invoiceUuid}/payments
     */
    createPaymentRecord: (
      practiceId: number,
      invoiceUuid: string,
      data: PaymentCreationRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/invoices/${invoiceUuid}/payments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name CreatePaymentCorrectionRecord
     * @request POST:/practices/{practiceId}/invoices/{invoiceUuid}/corrections
     */
    createPaymentCorrectionRecord: (
      practiceId: number,
      invoiceUuid: string,
      data: PaymentCorrectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/invoices/${invoiceUuid}/corrections`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name AddAdjustmentToInvoice
     * @request POST:/practices/{practiceId}/invoices/{invoiceUuid}/adjust
     */
    addAdjustmentToInvoice: (
      practiceId: number,
      invoiceUuid: string,
      data: AddAdjustmentToInvoiceRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseInvoiceVO, ErrorResponse>({
        path: `/practices/${practiceId}/invoices/${invoiceUuid}/adjust`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags intra-office-chat-controller
     * @name IssueChatToken
     * @request POST:/practices/{practiceId}/intra-office-chat/token
     */
    issueChatToken: (practiceId: number, data: IssueChatTokenRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseChatTokenResponse, ErrorResponse>({
        path: `/practices/${practiceId}/intra-office-chat/token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name BatchUpsertFrequencyLimitationCategories
     * @request POST:/practices/{practiceId}/insurances/frequency-limitation-categories
     */
    batchUpsertFrequencyLimitationCategories: (
      practiceId: number,
      data: BatchUpsertFrequencyLimitationCategoriesRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListFrequencyLimitationCategoryVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurances/frequency-limitation-categories`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name HideUnhideDefaultFrequencyLimitationCategory
     * @request POST:/practices/{practiceId}/insurances/frequency-limitation-categories/{frequencyLimitationCategoryId}/hide
     */
    hideUnhideDefaultFrequencyLimitationCategory: (
      practiceId: number,
      frequencyLimitationCategoryId: number,
      data: HideUnhideFrequencyLimitationCategoryRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/insurances/frequency-limitation-categories/${frequencyLimitationCategoryId}/hide`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-carrier-controller
     * @name GetAllPracticeInsuranceCarriers
     * @request GET:/practices/{practiceId}/insurances/carriers
     */
    getAllPracticeInsuranceCarriers: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListInsuranceCarrierVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurances/carriers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-carrier-controller
     * @name CreateCustomCarrier
     * @request POST:/practices/{practiceId}/insurances/carriers
     */
    createCustomCarrier: (practiceId: number, data: CreateCustomCarrierRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseInsuranceCarrierDetailsVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurances/carriers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-carrier-controller
     * @name SyncCarriers
     * @request POST:/practices/{practiceId}/insurances/carriers/sync
     */
    syncCarriers: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseInsuranceCarrierSyncVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurances/carriers/sync`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-plan-controller
     * @name GetInsurancePlans
     * @request GET:/practices/{practiceId}/insurance-plans
     */
    getInsurancePlans: (
      practiceId: number,
      query: {
        sortColumn?: string;
        orderBy?: string;
        /** @uniqueItems true */
        carrierIds?: number[];
        searchString?: string;
        /** @format int64 */
        feeScheduleId?: number;
        hidePlansWithNoPatients?: boolean;
        autoVerified?: boolean;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListInsurancePlanSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name CreateInsurancePlan
     * @request POST:/practices/{practiceId}/insurance-plans
     */
    createInsurancePlan: (practiceId: number, data: CreateInsurancePlanRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseInsurancePlanVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name VerifyInsurancePlanBenefit
     * @request POST:/practices/{practiceId}/insurance-plans/{insurancePlanUuid}/verify
     */
    verifyInsurancePlanBenefit: (
      practiceId: number,
      insurancePlanUuid: string,
      data: InsuranceVerifyRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseInsurancePlanVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans/${insurancePlanUuid}/verify`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name GetBenefitLimitations
     * @request GET:/practices/{practiceId}/insurance-plans/{insurancePlanUuid}/benefit-limitation
     */
    getBenefitLimitations: (practiceId: number, insurancePlanUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseListBenefitLimitationVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans/${insurancePlanUuid}/benefit-limitation`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name UpsertBenefitLimitations
     * @request POST:/practices/{practiceId}/insurance-plans/{insurancePlanUuid}/benefit-limitation
     */
    upsertBenefitLimitations: (
      practiceId: number,
      insurancePlanUuid: string,
      data: BatchUpsertBenefitLimitationRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListBenefitLimitationVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans/${insurancePlanUuid}/benefit-limitation`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name GetBenefitCoverages
     * @request GET:/practices/{practiceId}/insurance-plans/{insurancePlanUuid}/benefit-coverage
     */
    getBenefitCoverages: (practiceId: number, insurancePlanUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseListBenefitCoverageVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans/${insurancePlanUuid}/benefit-coverage`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name UpsertBenefitCoverages
     * @request POST:/practices/{practiceId}/insurance-plans/{insurancePlanUuid}/benefit-coverage
     */
    upsertBenefitCoverages: (
      practiceId: number,
      insurancePlanUuid: string,
      data: BatchUpsertBenefitCoverageRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListBenefitCoverageVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans/${insurancePlanUuid}/benefit-coverage`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-plan-controller
     * @name MergeInsurancePlans
     * @request POST:/practices/{practiceId}/insurance-plans/merge
     */
    mergeInsurancePlans: (practiceId: number, data: InsurancePlanMergeRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans/merge`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags huddle-controller
     * @name GetDailyHuddle
     * @request POST:/practices/{practiceId}/huddle
     */
    getDailyHuddle: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      data: DailyHuddleRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListDailyHuddleAppointmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/huddle`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags huddle-controller
     * @name GetDailyHuddleOverview
     * @request POST:/practices/{practiceId}/huddle/overview
     */
    getDailyHuddleOverview: (
      practiceId: number,
      data: DailyHuddleOverviewRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDailyHuddleOverview, ErrorResponse>({
        path: `/practices/${practiceId}/huddle/overview`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name GustoLinkPractice
     * @request POST:/practices/{practiceId}/gusto-oauth2
     */
    gustoLinkPractice: (practiceId: number, data: GustoLinkPracticeRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/gusto-oauth2`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name GetFormSummaries
     * @request GET:/practices/{practiceId}/forms
     */
    getFormSummaries: (
      practiceId: number,
      query?: {
        publishedOnly?: boolean;
        /** @uniqueItems true */
        types?: ("INTAKE" | "CLINICAL_NOTE")[];
        /** @format int64 */
        appointmentId?: number;
        uuids?: string[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListFormSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/forms`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name CreateForm
     * @request POST:/practices/{practiceId}/forms
     */
    createForm: (practiceId: number, data: FormRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseFormVO, ErrorResponse>({
        path: `/practices/${practiceId}/forms`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name GetClinicalNoteFormPreview
     * @request POST:/practices/{practiceId}/forms/{uuid}/clinical-note-preview
     */
    getClinicalNoteFormPreview: (
      practiceId: number,
      uuid: string,
      data: ClinicalNoteFormPreviewRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseClinicalNotePreviewVO, ErrorResponse>({
        path: `/practices/${practiceId}/forms/${uuid}/clinical-note-preview`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name PublishForm
     * @request POST:/practices/{practiceId}/forms/{uuidOrSlug}/publish
     */
    publishForm: (
      practiceId: number,
      uuidOrSlug:
        | string
        | "DENTAL_HISTORY"
        | "MEDICAL_HISTORY"
        | "CONSENT"
        | "TREATMENT_PLAN_FORM"
        | "APPOINTMENT_FORM",
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormVO, ErrorResponse>({
        path: `/practices/${practiceId}/forms/${uuidOrSlug}/publish`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name ArchiveForm
     * @request POST:/practices/{practiceId}/forms/{uuidOrSlug}/archive
     */
    archiveForm: (
      practiceId: number,
      uuidOrSlug:
        | string
        | "DENTAL_HISTORY"
        | "MEDICAL_HISTORY"
        | "CONSENT"
        | "TREATMENT_PLAN_FORM"
        | "APPOINTMENT_FORM",
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormVO, ErrorResponse>({
        path: `/practices/${practiceId}/forms/${uuidOrSlug}/archive`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-task-controller
     * @name SubmitFormTask
     * @request POST:/practices/{practiceId}/form-tasks/{formTaskUuid}/submit
     */
    submitFormTask: (
      practiceId: number,
      formTaskUuid: string,
      data: SubmitFormTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormTaskVO, ErrorResponse>({
        path: `/practices/${practiceId}/form-tasks/${formTaskUuid}/submit`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-task-controller
     * @name ResetFormTask
     * @request POST:/practices/{practiceId}/form-tasks/{formTaskUuid}/reset
     */
    resetFormTask: (practiceId: number, formTaskUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseFormTaskVO, ErrorResponse>({
        path: `/practices/${practiceId}/form-tasks/${formTaskUuid}/reset`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-task-controller
     * @name ArchiveFormTask
     * @request POST:/practices/{practiceId}/form-tasks/{formTaskUuid}/archive
     */
    archiveFormTask: (practiceId: number, formTaskUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseFormTaskVO, ErrorResponse>({
        path: `/practices/${practiceId}/form-tasks/${formTaskUuid}/archive`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-task-controller
     * @name SendFormTasks
     * @request POST:/practices/{practiceId}/form-tasks/send
     */
    sendFormTasks: (practiceId: number, data: SendFormTaskRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/form-tasks/send`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-task-controller
     * @name GenerateFormTaskKioskCode
     * @request POST:/practices/{practiceId}/form-tasks/generatekiosk
     */
    generateFormTaskKioskCode: (
      practiceId: number,
      data: GenerateFormTaskKioskCodeRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseString, ErrorResponse>({
        path: `/practices/${practiceId}/form-tasks/generatekiosk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags finix-merchant-onboarding-controller
     * @name OnboardCardPresent
     * @request POST:/practices/{practiceId}/finix-onboarding/onboard-card-present
     */
    onboardCardPresent: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/finix-onboarding/onboard-card-present`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags finix-merchant-onboarding-controller
     * @name OnboardAllInOne
     * @request POST:/practices/{practiceId}/finix-onboarding/onboard-all-in-one
     */
    onboardAllInOne: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/finix-onboarding/onboard-all-in-one`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name PrepareFileUpload
     * @request POST:/practices/{practiceId}/files/prepare-file-upload
     */
    prepareFileUpload: (practiceId: number, data: PrepareFileUploadRequest, params: RequestParams = {}) =>
      this.request<SuccessResponsePrepareFileUploadResponse, ErrorResponse>({
        path: `/practices/${practiceId}/files/prepare-file-upload`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name BatchPrepareFileUpload
     * @request POST:/practices/{practiceId}/files/batch-prepare-file-upload
     */
    batchPrepareFileUpload: (
      practiceId: number,
      data: BatchPrepareFileUploadRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseBatchPrepareFileUploadResponse, ErrorResponse>({
        path: `/practices/${practiceId}/files/batch-prepare-file-upload`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name AddFeeSchedule
     * @request POST:/practices/{practiceId}/fee-schedules
     */
    addFeeSchedule: (practiceId: number, data: AddFeeScheduleRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseFeeScheduleVO, ErrorResponse>({
        path: `/practices/${practiceId}/fee-schedules`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags fee-schedule-controller
     * @name UnarchiveFeeSchedule
     * @request POST:/practices/{practiceId}/fee-schedules/{feeScheduleId}/unarchive
     */
    unarchiveFeeSchedule: (practiceId: number, feeScheduleId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseFeeScheduleVO, ErrorResponse>({
        path: `/practices/${practiceId}/fee-schedules/${feeScheduleId}/unarchive`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags fee-schedule-controller
     * @name ArchiveFeeSchedule
     * @request POST:/practices/{practiceId}/fee-schedules/{feeScheduleId}/archive
     */
    archiveFeeSchedule: (
      practiceId: number,
      feeScheduleId: number,
      data: ArchiveFeeScheduleRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFeeScheduleVO, ErrorResponse>({
        path: `/practices/${practiceId}/fee-schedules/${feeScheduleId}/archive`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name RunFeeCalc
     * @request POST:/practices/{practiceId}/fee-calc
     */
    runFeeCalc: (practiceId: number, data: FeeCalcRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/fee-calc`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name GetEmployees
     * @request GET:/practices/{practiceId}/employees
     */
    getEmployees: (
      practiceId: number,
      query: {
        searchString?: string;
        statuses?: ("ACTIVE" | "INACTIVE" | "ARCHIVED" | "PENDING" | "FURLOUGHED")[];
        isOwner?: boolean;
        sortColumn?: string;
        orderBy?: string;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListEmployeeVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name CreateEmployee
     * @request POST:/practices/{practiceId}/employees
     */
    createEmployee: (practiceId: number, data: CreateEmployeeRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseEmployeeVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name MultiUpdate1
     * @request DELETE:/practices/{practiceId}/employees
     */
    multiUpdate1: (
      practiceId: number,
      query: {
        action: "DELETE" | "ARCHIVE";
      },
      data: MultiUpdateVO,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees`,
        method: "DELETE",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags worktime-controller
     * @name AddWorktimeEntry
     * @request POST:/practices/{practiceId}/employees/{employeeId}/worktime
     */
    addWorktimeEntry: (
      practiceId: number,
      employeeId: number,
      data: WorktimeActionRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/worktime`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name UpsertProviderWorkingHours
     * @request POST:/practices/{practiceId}/employees/{employeeId}/working-hours
     */
    upsertProviderWorkingHours: (
      practiceId: number,
      employeeId: number,
      data: WorkingHourItemVO[],
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/working-hours`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name VerifyEmployeePin
     * @request POST:/practices/{practiceId}/employees/{employeeId}/verify-pin
     */
    verifyEmployeePin: (practiceId: number, employeeId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/verify-pin`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name ResendEmployeeInvite
     * @request POST:/practices/{practiceId}/employees/{employeeId}/resend-invite
     */
    resendEmployeeInvite: (employeeId: number, practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/resend-invite`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prescription-controller
     * @name CreateClinician
     * @request POST:/practices/{practiceId}/employees/{employeeId}/prescription/onboard
     */
    createClinician: (
      practiceId: number,
      employeeId: number,
      data: CreateClinicianRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/prescription/onboard`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prescription-controller
     * @name DeactivateClinician
     * @request POST:/practices/{practiceId}/employees/{employeeId}/prescription/deactivate
     */
    deactivateClinician: (practiceId: number, employeeId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/prescription/deactivate`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name CreateEmployeeFlow
     * @request POST:/practices/{practiceId}/employees/{employeeId}/payrolls/flows
     */
    createEmployeeFlow: (
      practiceId: number,
      employeeId: number,
      data: GustoEmployeeFlowRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseGustoFlowVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/payrolls/flows`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name UploadEmployeeOnboardingDocument
     * @request POST:/practices/{practiceId}/employees/{employeeId}/onboard/upload-document
     */
    uploadEmployeeOnboardingDocument: (
      practiceId: number,
      employeeId: number,
      data: {
        /** @format binary */
        document: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDocumentVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/onboard/upload-document`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-mfa-controller
     * @name ResetEmployeeMfa
     * @request POST:/practices/{practiceId}/employees/{employeeId}/mfa/reset
     */
    resetEmployeeMfa: (practiceId: number, employeeId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseUserMfaVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/mfa/reset`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name UpsertProviderCustomHolidays
     * @request POST:/practices/{practiceId}/employees/{employeeId}/custom-holidays
     */
    upsertProviderCustomHolidays: (
      practiceId: number,
      employeeId: number,
      data: CustomHolidayVO[],
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListCustomHolidayVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/custom-holidays`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags email-config-controller
     * @name VerifyDomain
     * @request POST:/practices/{practiceId}/email-config/verify-domain
     */
    verifyDomain: (practiceId: number, data: VerifyDomainRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseEmailConfigVO, ErrorResponse>({
        path: `/practices/${practiceId}/email-config/verify-domain`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dental-procedure-controller
     * @name OverrideAdaProcedure
     * @request POST:/practices/{practiceId}/dental-procedures/{dentalProcedureId}/override
     */
    overrideAdaProcedure: (
      practiceId: number,
      dentalProcedureId: number,
      data: AdaProcedureOverrideRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDentalProcedureVO, ErrorResponse>({
        path: `/practices/${practiceId}/dental-procedures/${dentalProcedureId}/override`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dental-procedure-controller
     * @name DeleteOverride
     * @request DELETE:/practices/{practiceId}/dental-procedures/{dentalProcedureId}/override
     */
    deleteOverride: (practiceId: number, dentalProcedureId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/dental-procedures/${dentalProcedureId}/override`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dental-procedure-controller
     * @name CreateCustomProcedure
     * @request POST:/practices/{practiceId}/dental-procedures/custom
     */
    createCustomProcedure: (
      practiceId: number,
      data: CreateCustomProcedureRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDentalProcedureVO, ErrorResponse>({
        path: `/practices/${practiceId}/dental-procedures/custom`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name GetCustomPatientFolders
     * @request GET:/practices/{practiceId}/custom-patient-folders
     */
    getCustomPatientFolders: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseFetchCustomPatientFolderResponse, ErrorResponse>({
        path: `/practices/${practiceId}/custom-patient-folders`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name CreateCustomPatientFolder
     * @request POST:/practices/{practiceId}/custom-patient-folders
     */
    createCustomPatientFolder: (
      practiceId: number,
      data: CreateCustomPatientFolderRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseCustomPatientFolderVO, ErrorResponse>({
        path: `/practices/${practiceId}/custom-patient-folders`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetCustomHolidays
     * @request GET:/practices/{practiceId}/custom-holidays
     */
    getCustomHolidays: (
      practiceId: number,
      query?: {
        /** @format int64 */
        providerId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListCustomHolidayVO, ErrorResponse>({
        path: `/practices/${practiceId}/custom-holidays`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name UpsertCustomHolidays
     * @request POST:/practices/{practiceId}/custom-holidays
     */
    upsertCustomHolidays: (practiceId: number, data: CustomHolidayVO[], params: RequestParams = {}) =>
      this.request<SuccessResponseListCustomHolidayVO, ErrorResponse>({
        path: `/practices/${practiceId}/custom-holidays`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name CreateClaim
     * @request POST:/practices/{practiceId}/claims
     */
    createClaim: (practiceId: number, data: ClaimCreateRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name UploadEobToClaim
     * @request POST:/practices/{practiceId}/claims/{claimUuid}/eob
     */
    uploadEobToClaim: (
      practiceId: number,
      claimUuid: string,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseClaimEobFileVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/eob`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name CreateClaimPaymentAdjustment
     * @request POST:/practices/{practiceId}/claims/{claimUuid}/create-payment-adjustment
     */
    createClaimPaymentAdjustment: (
      practiceId: number,
      claimUuid: string,
      data: ClaimAdjustmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/create-payment-adjustment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name AsyncValidateClaim
     * @request POST:/practices/{practiceId}/claims/{claimUuid}/async-validate
     */
    asyncValidateClaim: (practiceId: number, claimUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/async-validate`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name CreatePreAuthClaim
     * @request POST:/practices/{practiceId}/claims/pre-auth
     */
    createPreAuthClaim: (practiceId: number, data: CreatePreAuthClaimRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/pre-auth`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name CreateOffsiteClaimPayment
     * @request POST:/practices/{practiceId}/claims/offsite-claim-payment
     */
    createOffsiteClaimPayment: (
      practiceId: number,
      data: OffsiteClaimPaymentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/offsite-claim-payment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetDraftEobPaymentSummaries
     * @request GET:/practices/{practiceId}/claims/draft-eob-payment
     */
    getDraftEobPaymentSummaries: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListDraftEobPaymentSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/draft-eob-payment`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name CreateDraftEobPayment
     * @request POST:/practices/{practiceId}/claims/draft-eob-payment
     */
    createDraftEobPayment: (
      practiceId: number,
      data: CreateDraftEobPaymentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDraftEobPaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/draft-eob-payment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name UploadEobFileToDraftEobPayment
     * @request POST:/practices/{practiceId}/claims/draft-eob-payment/{draftEobPaymentUuid}/eob
     */
    uploadEobFileToDraftEobPayment: (
      practiceId: number,
      draftEobPaymentUuid: string,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseClaimEobFileVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/draft-eob-payment/${draftEobPaymentUuid}/eob`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name CreateReceiverAccount
     * @request POST:/practices/{practiceId}/claims/config/create-account
     */
    createReceiverAccount: (
      practiceId: number,
      data: ClaimCreateAccountRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseClaimsConfigVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/config/create-account`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags statement-controller
     * @name CreateBulkStatement
     * @request POST:/practices/{practiceId}/bulk-statements
     */
    createBulkStatement: (practiceId: number, data: CreateBulkStatementRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/bulk-statements`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name AddAppointment
     * @request POST:/practices/{practiceId}/appointments
     */
    addAppointment: (practiceId: number, data: AppointmentRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseAppointmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetAppointmentCategories
     * @request GET:/practices/{practiceId}/appointments/categories
     */
    getAppointmentCategories: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseCollectionAppointmentCategoryVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/categories`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name UpsertAppointmentCategories
     * @request POST:/practices/{practiceId}/appointments/categories
     */
    upsertAppointmentCategories: (
      practiceId: number,
      data: AppointmentCategoryRequest[],
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListAppointmentCategoryVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/categories`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetAsapAppointments
     * @request GET:/practices/{practiceId}/appointments/asap
     */
    getAsapAppointments: (
      practiceId: number,
      query: {
        sortColumn?: string;
        sortDirection?: "ASC" | "DESC";
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListAppointmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/asap`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name BatchUpdateAsapAppointments
     * @request POST:/practices/{practiceId}/appointments/asap
     */
    batchUpdateAsapAppointments: (
      practiceId: number,
      data: BatchUpdateAsapRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/asap`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags adjustment-controller
     * @name GetAdjustmentTypesForPractice
     * @request GET:/practices/{practiceId}/adjustments/types
     */
    getAdjustmentTypesForPractice: (
      practiceId: number,
      query?: {
        hideArchived?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListCustomAdjustmentTypeVO, ErrorResponse>({
        path: `/practices/${practiceId}/adjustments/types`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags adjustment-controller
     * @name CreateAdjustmentType
     * @request POST:/practices/{practiceId}/adjustments/types
     */
    createAdjustmentType: (
      practiceId: number,
      data: CreateCustomAdjustmentTypeRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseCustomAdjustmentTypeVO, ErrorResponse>({
        path: `/practices/${practiceId}/adjustments/types`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags adjustment-controller
     * @name ArchiveAdjustmentType
     * @request POST:/practices/{practiceId}/adjustments/types/{adjustmentTypeId}/archive
     */
    archiveAdjustmentType: (practiceId: number, adjustmentTypeId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseCustomAdjustmentTypeVO, ErrorResponse>({
        path: `/practices/${practiceId}/adjustments/types/${adjustmentTypeId}/archive`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name DeleteFolder
     * @request DELETE:/practices/{practiceId}/users/{userId}/folders/{folderId}
     */
    deleteFolder: (practiceId: number, userId: number, folderId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders/${folderId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name UpdateFolder
     * @request PATCH:/practices/{practiceId}/users/{userId}/folders/{folderId}
     */
    updateFolder: (
      practiceId: number,
      folderId: number,
      userId: number,
      data: UpdateFolderRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFolderVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders/${folderId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name DeleteDocument
     * @request DELETE:/practices/{practiceId}/users/{userId}/folders/{folderId}/documents/{documentId}
     */
    deleteDocument: (
      practiceId: number,
      userId: number,
      folderId: number,
      documentId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders/${folderId}/documents/${documentId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name UpdateDocument
     * @request PATCH:/practices/{practiceId}/users/{userId}/folders/{folderId}/documents/{documentId}
     */
    updateDocument: (
      practiceId: number,
      userId: number,
      folderId: number,
      documentId: number,
      data: UpdateDocumentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDocumentVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders/${folderId}/documents/${documentId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name MoveDocument
     * @request PATCH:/practices/{practiceId}/users/{userId}/folders/{folderId}/documents/{documentId}/move
     */
    moveDocument: (
      practiceId: number,
      userId: number,
      folderId: number,
      documentId: number,
      data: MoveDocumentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDocumentVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders/${folderId}/documents/${documentId}/move`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name UpdatePracticeEin
     * @request PATCH:/practices/{practiceId}/tax-id
     */
    updatePracticeEin: (practiceId: number, data: UpdatePracticeTaxIdRequest, params: RequestParams = {}) =>
      this.request<SuccessResponsePracticeVO, ErrorResponse>({
        path: `/practices/${practiceId}/tax-id`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags security-controller
     * @name DeleteIpFilter
     * @request DELETE:/practices/{practiceId}/security/ip-filters/{ipFilterUuid}
     */
    deleteIpFilter: (practiceId: number, ipFilterUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/security/ip-filters/${ipFilterUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags security-controller
     * @name UpdateIpFilter
     * @request PATCH:/practices/{practiceId}/security/ip-filters/{ipFilterUuid}
     */
    updateIpFilter: (
      practiceId: number,
      ipFilterUuid: string,
      data: UpdateIPFilterRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseIPFilterVO, ErrorResponse>({
        path: `/practices/${practiceId}/security/ip-filters/${ipFilterUuid}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags saved-filter-controller
     * @name GetSavedFilter
     * @request GET:/practices/{practiceId}/saved-filters/{savedFilterUuid}
     */
    getSavedFilter: (practiceId: number, savedFilterUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseSavedFilterVO, ErrorResponse>({
        path: `/practices/${practiceId}/saved-filters/${savedFilterUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags saved-filter-controller
     * @name DeleteSavedFilter
     * @request DELETE:/practices/{practiceId}/saved-filters/{savedFilterUuid}
     */
    deleteSavedFilter: (practiceId: number, savedFilterUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/saved-filters/${savedFilterUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags saved-filter-controller
     * @name UpdateSavedFilter
     * @request PATCH:/practices/{practiceId}/saved-filters/{savedFilterUuid}
     */
    updateSavedFilter: (
      practiceId: number,
      savedFilterUuid: string,
      data: UpdateSavedFilterRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSavedFilterVO, ErrorResponse>({
        path: `/practices/${practiceId}/saved-filters/${savedFilterUuid}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-device-controller
     * @name GetPaymentDevice
     * @request GET:/practices/{practiceId}/payment-devices/{paymentDeviceUuid}
     */
    getPaymentDevice: (practiceId: number, paymentDeviceUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponsePaymentDeviceVO, ErrorResponse>({
        path: `/practices/${practiceId}/payment-devices/${paymentDeviceUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-device-controller
     * @name DeletePaymentDevice
     * @request DELETE:/practices/{practiceId}/payment-devices/{paymentDeviceUuid}
     */
    deletePaymentDevice: (practiceId: number, paymentDeviceUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponsePaymentDeviceVO, ErrorResponse>({
        path: `/practices/${practiceId}/payment-devices/${paymentDeviceUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-device-controller
     * @name UpdatePaymentDevice
     * @request PATCH:/practices/{practiceId}/payment-devices/{paymentDeviceUuid}
     */
    updatePaymentDevice: (
      practiceId: number,
      paymentDeviceUuid: string,
      data: UpdatePaymentDeviceRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentDeviceVO, ErrorResponse>({
        path: `/practices/${practiceId}/payment-devices/${paymentDeviceUuid}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet-controller
     * @name UpdateWallet
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/wallets/{walletUuid}
     */
    updateWallet: (
      practiceId: number,
      patientId: number,
      walletUuid: string,
      data: UpdateWalletRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseWalletVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/wallets/${walletUuid}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet-controller
     * @name EditWalletActivity
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/wallets/{walletUuid}/activities/{walletActivityId}/correction
     */
    editWalletActivity: (
      practiceId: number,
      patientId: number,
      walletUuid: string,
      walletActivityId: number,
      data: WalletEditRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseWalletActivityVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/wallets/${walletUuid}/activities/${walletActivityId}/correction`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recall-controller
     * @name GetRecall
     * @request GET:/practices/{practiceId}/patients/{patientId}/recall/{recallUuid}
     */
    getRecall: (practiceId: number, patientId: number, recallUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseRecallVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/recall/${recallUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recall-controller
     * @name UpdateRecall
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/recall/{recallUuid}
     */
    updateRecall: (
      practiceId: number,
      patientId: number,
      recallUuid: string,
      data: UpdateRecallRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseRecallVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/recall/${recallUuid}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags perio-chart-exam-controller
     * @name GetPerioChartExam
     * @request GET:/practices/{practiceId}/patients/{patientId}/perio-chart-exams/{perioChartExamUuid}
     */
    getPerioChartExam: (
      practiceId: number,
      patientId: number,
      perioChartExamUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePerioChartExamVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/perio-chart-exams/${perioChartExamUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags perio-chart-exam-controller
     * @name DeletePerioChartExam
     * @request DELETE:/practices/{practiceId}/patients/{patientId}/perio-chart-exams/{perioChartExamUuid}
     */
    deletePerioChartExam: (
      practiceId: number,
      patientId: number,
      perioChartExamUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/perio-chart-exams/${perioChartExamUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags perio-chart-exam-controller
     * @name UpdatePerioChartExam
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/perio-chart-exams/{perioChartExamUuid}
     */
    updatePerioChartExam: (
      practiceId: number,
      patientId: number,
      perioChartExamUuid: string,
      data: UpdatePerioChartExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePerioChartExamVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/perio-chart-exams/${perioChartExamUuid}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-profile-controller
     * @name GetVisiblePaymentProfileByUuid
     * @request GET:/practices/{practiceId}/patients/{patientId}/payment-profiles/{paymentProfileUuid}
     */
    getVisiblePaymentProfileByUuid: (
      patientId: number,
      practiceId: number,
      paymentProfileUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentProfileVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/payment-profiles/${paymentProfileUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-profile-controller
     * @name MarkPaymentProfileForDeletion
     * @request DELETE:/practices/{practiceId}/patients/{patientId}/payment-profiles/{paymentProfileUuid}
     */
    markPaymentProfileForDeletion: (
      patientId: number,
      practiceId: number,
      paymentProfileUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/payment-profiles/${paymentProfileUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-profile-controller
     * @name UpdatePaymentProfile
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/payment-profiles/{paymentProfileUuid}
     */
    updatePaymentProfile: (
      patientId: number,
      practiceId: number,
      paymentProfileUuid: string,
      data: UpdatePaymentProfileRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePaymentProfileVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/payment-profiles/${paymentProfileUuid}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name UnarchivePatientNote
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/patient-note/{patientNoteUuid}/unarchive
     */
    unarchivePatientNote: (
      practiceId: number,
      patientId: number,
      patientNoteUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientNoteVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-note/${patientNoteUuid}/unarchive`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name ArchivePatientNote
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/patient-note/{patientNoteUuid}/archive
     */
    archivePatientNote: (
      practiceId: number,
      patientId: number,
      patientNoteUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientNoteVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-note/${patientNoteUuid}/archive`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name GetMount
     * @request GET:/practices/{practiceId}/patients/{patientId}/mounts/{mountId}
     */
    getMount: (
      practiceId: number,
      patientId: number,
      mountId: number,
      query?: {
        /** @default false */
        includeArchived?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMountVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/mounts/${mountId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name UpdateMount
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/mounts/{mountId}
     */
    updateMount: (
      practiceId: number,
      patientId: number,
      mountId: number,
      data: UpdateMountRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMountVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/mounts/${mountId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name GetMedicalImageDetails
     * @request GET:/practices/{practiceId}/patients/{patientId}/medical-images/{imageId}
     */
    getMedicalImageDetails: (
      practiceId: number,
      patientId: number,
      imageId: number,
      query?: {
        /** @default false */
        populateDicomUrl?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMedicalImageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/medical-images/${imageId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name UpdateMedicalImageDetails
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/medical-images/{imageId}
     */
    updateMedicalImageDetails: (
      practiceId: number,
      patientId: number,
      imageId: number,
      data: MedicalImageVO,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMedicalImageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/medical-images/${imageId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name DismissPearlAnnotation
     * @request DELETE:/practices/{practiceId}/patients/{patientId}/medical-images/{imageId}/{annotationId}
     */
    dismissPearlAnnotation: (
      practiceId: number,
      patientId: number,
      imageId: number,
      annotationId: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/medical-images/${imageId}/${annotationId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name AcceptPearlAnnotation
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/medical-images/{imageId}/{annotationId}
     */
    acceptPearlAnnotation: (
      practiceId: number,
      patientId: number,
      imageId: number,
      annotationId: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/medical-images/${imageId}/${annotationId}`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name GetPatientInsurance
     * @request GET:/practices/{practiceId}/patients/{patientId}/insurances/{insuranceId}
     */
    getPatientInsurance: (
      practiceId: number,
      patientId: number,
      insuranceId: number,
      query?: {
        includeInsurancePlan?: boolean;
        includeBenefitCoverage?: boolean;
        includeProcedureHistory?: boolean;
        includeBenefitLimitation?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientInsuranceResponse, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances/${insuranceId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name UpdatePatientInsurance
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/insurances/{insuranceId}
     */
    updatePatientInsurance: (
      practiceId: number,
      patientId: number,
      insuranceId: number,
      data: UpdatePatientInsuranceRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientInsuranceVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances/${insuranceId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name UpdatePatientInsuranceState
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/insurances/{insuranceId}/state
     */
    updatePatientInsuranceState: (
      practiceId: number,
      patientId: number,
      insuranceId: number,
      data: UpdatePatientInsuranceStateRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientInsuranceVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances/${insuranceId}/state`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name UpdatePatientInsuranceOrdinal
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/insurances/{insuranceId}/ordinal
     */
    updatePatientInsuranceOrdinal: (
      practiceId: number,
      patientId: number,
      insuranceId: number,
      data: UpdatePatientInsuranceOrdinalRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientInsuranceVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances/${insuranceId}/ordinal`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags family-controller
     * @name UpdateGuardiansAndPhiAccess
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/family-members/access
     */
    updateGuardiansAndPhiAccess: (
      practiceId: number,
      patientId: number,
      data: UpdateGuardianOrPhiAccessRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/family-members/access`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags adjustment-controller
     * @name GetAdjustment
     * @request GET:/practices/{practiceId}/patients/{patientId}/adjustments/{adjustmentUuid}
     */
    getAdjustment: (
      practiceId: number,
      patientId: number,
      adjustmentUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAdjustmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/adjustments/${adjustmentUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags adjustment-controller
     * @name EditAdjustment
     * @request PATCH:/practices/{practiceId}/patients/{patientId}/adjustments/{adjustmentUuid}
     */
    editAdjustment: (
      practiceId: number,
      patientId: number,
      adjustmentUuid: string,
      data: EditAdjustmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAdjustmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/adjustments/${adjustmentUuid}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags intra-office-chat-controller
     * @name GetEmployeeChatPreference
     * @request GET:/practices/{practiceId}/intra-office-chat/preference
     */
    getEmployeeChatPreference: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseEmployeeChatPreferenceVO, ErrorResponse>({
        path: `/practices/${practiceId}/intra-office-chat/preference`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags intra-office-chat-controller
     * @name UpdateEmployeeChatPreference
     * @request PATCH:/practices/{practiceId}/intra-office-chat/preference
     */
    updateEmployeeChatPreference: (
      practiceId: number,
      data: UpdateEmployeeChatPreferenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseEmployeeChatPreferenceVO, ErrorResponse>({
        path: `/practices/${practiceId}/intra-office-chat/preference`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags worktime-controller
     * @name DeleteWorktimeEntry
     * @request DELETE:/practices/{practiceId}/employees/{employeeId}/worktime/{worktimeId}
     */
    deleteWorktimeEntry: (
      practiceId: number,
      employeeId: number,
      worktimeId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/worktime/${worktimeId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags worktime-controller
     * @name UpdateWorktimeEntry
     * @request PATCH:/practices/{practiceId}/employees/{employeeId}/worktime/{worktimeId}
     */
    updateWorktimeEntry: (
      practiceId: number,
      employeeId: number,
      worktimeId: number,
      data: UpdateWorktimeRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/worktime/${worktimeId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags worktime-controller
     * @name ApproveWorktimeEntries
     * @request PATCH:/practices/{practiceId}/employees/{employeeId}/worktime/approve
     */
    approveWorktimeEntries: (
      practiceId: number,
      employeeId: number,
      data: BatchApproveWorktimeRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/worktime/approve`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name DeleteCustomPatientFolder
     * @request DELETE:/practices/{practiceId}/custom-patient-folders/{customPatientFolderId}
     */
    deleteCustomPatientFolder: (
      practiceId: number,
      customPatientFolderId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseCustomPatientFolderVO, ErrorResponse>({
        path: `/practices/${practiceId}/custom-patient-folders/${customPatientFolderId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name UpdateCustomPatientFolder
     * @request PATCH:/practices/{practiceId}/custom-patient-folders/{customPatientFolderId}
     */
    updateCustomPatientFolder: (
      practiceId: number,
      customPatientFolderId: number,
      data: UpdateCustomPatientFolderRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseCustomPatientFolderVO, ErrorResponse>({
        path: `/practices/${practiceId}/custom-patient-folders/${customPatientFolderId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetClaim
     * @request GET:/practices/{practiceId}/claims/{claimUuid}
     */
    getClaim: (practiceId: number, claimUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name DeleteClaim
     * @request DELETE:/practices/{practiceId}/claims/{claimUuid}
     */
    deleteClaim: (practiceId: number, claimUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name UpdateClaim
     * @request PATCH:/practices/{practiceId}/claims/{claimUuid}
     */
    updateClaim: (
      practiceId: number,
      claimUuid: string,
      data: ClaimUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name VoidClaim
     * @request PATCH:/practices/{practiceId}/claims/{claimUuid}/void
     */
    voidClaim: (practiceId: number, claimUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/void`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name SubmitClaim
     * @request PATCH:/practices/{practiceId}/claims/{claimUuid}/submit
     */
    submitClaim: (practiceId: number, claimUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/submit`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name ValidateClaimForSubmission
     * @request PATCH:/practices/{practiceId}/claims/{claimUuid}/submit-validate
     * @deprecated
     */
    validateClaimForSubmission: (practiceId: number, claimUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/submit-validate`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name SubmitClaimManually
     * @request PATCH:/practices/{practiceId}/claims/{claimUuid}/submit-manually
     */
    submitClaimManually: (practiceId: number, claimUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/submit-manually`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name SkipClaimPayment
     * @request PATCH:/practices/{practiceId}/claims/{claimUuid}/skip-payment
     */
    skipClaimPayment: (practiceId: number, claimUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/skip-payment`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name ReplaceClaim
     * @request PATCH:/practices/{practiceId}/claims/{claimUuid}/replace
     */
    replaceClaim: (practiceId: number, claimUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/replace`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name UpdateClaimLineItems
     * @request PATCH:/practices/{practiceId}/claims/{claimUuid}/line-items
     */
    updateClaimLineItems: (
      practiceId: number,
      claimUuid: string,
      data: ClaimLineItemChangeRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseClaimVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/line-items`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name UpdateEobVerified
     * @request PATCH:/practices/{practiceId}/claims/{claimUuid}/eob-verification
     */
    updateEobVerified: (
      practiceId: number,
      claimUuid: string,
      query: {
        verified: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/eob-verification`,
        method: "PATCH",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetDraftEobPayment
     * @request GET:/practices/{practiceId}/claims/draft-eob-payment/{draftEobPaymentUuid}
     */
    getDraftEobPayment: (practiceId: number, draftEobPaymentUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseDraftEobPaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/draft-eob-payment/${draftEobPaymentUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name DeleteDraftEobPayment
     * @request DELETE:/practices/{practiceId}/claims/draft-eob-payment/{draftEobPaymentUuid}
     */
    deleteDraftEobPayment: (practiceId: number, draftEobPaymentUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseDraftEobPaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/draft-eob-payment/${draftEobPaymentUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name UpdateDraftEobPayment
     * @request PATCH:/practices/{practiceId}/claims/draft-eob-payment/{draftEobPaymentUuid}
     */
    updateDraftEobPayment: (
      practiceId: number,
      draftEobPaymentUuid: string,
      data: UpdateDraftEobPaymentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDraftEobPaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/draft-eob-payment/${draftEobPaymentUuid}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name SkipDraftEobPayment
     * @request PATCH:/practices/{practiceId}/claims/draft-eob-payment/{draftEobPaymentUuid}/skip-payment
     */
    skipDraftEobPayment: (practiceId: number, draftEobPaymentUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/claims/draft-eob-payment/${draftEobPaymentUuid}/skip-payment`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name PayDraftEobPayment
     * @request PATCH:/practices/{practiceId}/claims/draft-eob-payment/{draftEobPaymentUuid}/pay
     */
    payDraftEobPayment: (
      practiceId: number,
      draftEobPaymentUuid: string,
      data: ClaimPaymentRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/claims/draft-eob-payment/${draftEobPaymentUuid}/pay`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name BatchSubmitClaims
     * @request PATCH:/practices/{practiceId}/claims/batch-submit
     */
    batchSubmitClaims: (practiceId: number, data: ClaimBatchSubmitRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/claims/batch-submit`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-billing-setting-controller
     * @name GetPracticeBillingSetting
     * @request GET:/practices/{practiceId}/billing-settings
     */
    getPracticeBillingSetting: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePracticeBillingSettingVO, ErrorResponse>({
        path: `/practices/${practiceId}/billing-settings`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-billing-setting-controller
     * @name UpdatePracticeBillingSetting
     * @request PATCH:/practices/{practiceId}/billing-settings
     */
    updatePracticeBillingSetting: (
      practiceId: number,
      data: UpdatePracticeBillingSettingRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePracticeBillingSettingVO, ErrorResponse>({
        path: `/practices/${practiceId}/billing-settings`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetSsn
     * @request GET:/practices/{practiceId}/users/{userId}/ssn
     */
    getSsn: (userId: number, practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseSsnResponse, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/ssn`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name GetPreSignedUrl
     * @request GET:/practices/{practiceId}/users/{userId}/folders/{folderId}/documents/{documentId}/url
     */
    getPreSignedUrl: (
      practiceId: number,
      userId: number,
      folderId: number,
      documentId: number,
      query?: {
        /** @default false */
        download?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseURL, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders/${folderId}/documents/${documentId}/url`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name DownloadDocument
     * @request GET:/practices/{practiceId}/users/{userId}/folders/{folderId}/documents/{documentId}/download
     */
    downloadDocument: (
      practiceId: number,
      userId: number,
      documentId: number,
      folderId: string,
      params: RequestParams = {}
    ) =>
      this.request<File, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/folders/${folderId}/documents/${documentId}/download`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name GetDocument
     * @request GET:/practices/{practiceId}/users/{userId}/documents/{documentId}
     */
    getDocument: (
      practiceId: number,
      userId: number,
      documentId: number,
      query?: {
        includeMetadata?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseDocumentVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/documents/${documentId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name SearchDocument
     * @request GET:/practices/{practiceId}/users/{userId}/documents/search
     */
    searchDocument: (
      practiceId: number,
      userId: number,
      query: {
        searchString: string;
        includeMetadata?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListDocumentVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/documents/search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags permission-controller
     * @name GetAssignableRolesForUser
     * @request GET:/practices/{practiceId}/users/{userId}/assignable-roles
     */
    getAssignableRolesForUser: (practiceId: number, userId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListRoleVO, ErrorResponse>({
        path: `/practices/${practiceId}/users/${userId}/assignable-roles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags email-config-controller
     * @name GetUnlayerToken
     * @request GET:/practices/{practiceId}/unlayer-token
     */
    getUnlayerToken: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseUnlayerTokenVO, ErrorResponse>({
        path: `/practices/${practiceId}/unlayer-token`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name GetPatientEngagementTemplateVariables
     * @request GET:/practices/{practiceId}/template-variables
     */
    getPatientEngagementTemplateVariables: (
      practiceId: number,
      query?: {
        eventType?:
          | "CONFIRMED_APPOINTMENT"
          | "UNCONFIRMED_APPOINTMENT"
          | "APPOINTMENT_COMPLETED"
          | "APPOINTMENT_CREATED"
          | "APPOINTMENT_REQUESTED"
          | "PATIENT_CREATED"
          | "PROPHY_DUE"
          | "PERIO_DUE";
        category?: "FORM" | "ACTION" | "MESSAGE_CAMPAIGN";
        messageCampaignSelectionCategory?:
          | "PATIENTS"
          | "PATIENTS_WITH_APPOINTMENT"
          | "DAILY_HUDDLE"
          | "ASAP_LIST_SCHEDULED"
          | "ASAP_LIST_UNSCHEDULED"
          | "ASAP_LIST_ALL"
          | "REPORTING_UNCOLLECTED"
          | "REPORTING_RECALL";
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListTemplateVariableGroupVO, ErrorResponse>({
        path: `/practices/${practiceId}/template-variables`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags support-user-controller
     * @name GetSupportUser
     * @request GET:/practices/{practiceId}/support-users/{supportUserId}
     */
    getSupportUser: (practiceId: number, supportUserId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseSupportUserVO, ErrorResponse>({
        path: `/practices/${practiceId}/support-users/${supportUserId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags sso-controller
     * @name ZendeskSso
     * @request GET:/practices/{practiceId}/sso/zendesk
     */
    zendeskSso: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseZendeskSsoResponse, ErrorResponse>({
        path: `/practices/${practiceId}/sso/zendesk`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags sso-controller
     * @name LearnUponSso
     * @request GET:/practices/{practiceId}/sso/learnupon
     */
    learnUponSso: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseLearnuponSsoResponse, ErrorResponse>({
        path: `/practices/${practiceId}/sso/learnupon`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags schedule-block-controller
     * @name GetScheduleBlockInstance
     * @request GET:/practices/{practiceId}/schedule-blocks/{scheduleBlockId}/instance-date/{instanceDate}
     */
    getScheduleBlockInstance: (
      practiceId: number,
      scheduleBlockId: number,
      instanceDate: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseScheduleBlockVO, ErrorResponse>({
        path: `/practices/${practiceId}/schedule-blocks/${scheduleBlockId}/instance-date/${instanceDate}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetRoomOpenHours
     * @request GET:/practices/{practiceId}/room-open-hours
     */
    getRoomOpenHours: (
      practiceId: number,
      query: {
        date: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListRoomVO, ErrorResponse>({
        path: `/practices/${practiceId}/room-open-hours`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags permission-controller
     * @name GetEmployeesWithRoleV2
     * @request GET:/practices/{practiceId}/roles/{roleId}/employees
     */
    getEmployeesWithRoleV2: (practiceId: number, roleId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListNameVO, ErrorResponse>({
        path: `/practices/${practiceId}/roles/${roleId}/employees`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags permission-controller
     * @name GetPermissionActions
     * @request GET:/practices/{practiceId}/roles/permission-actions
     */
    getPermissionActions: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListPermissionActionVO, ErrorResponse>({
        path: `/practices/${practiceId}/roles/permission-actions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetFuturePublicHolidays
     * @request GET:/practices/{practiceId}/public-holidays/future
     */
    getFuturePublicHolidays: (
      practiceId: number,
      query?: {
        currentDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPublicHolidayVO, ErrorResponse>({
        path: `/practices/${practiceId}/public-holidays/future`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name GetAllProviders
     * @request GET:/practices/{practiceId}/providers
     */
    getAllProviders: (
      practiceId: number,
      query?: {
        /** @uniqueItems true */
        statuses?: ("ACTIVE" | "INACTIVE" | "ARCHIVED" | "PENDING" | "FURLOUGHED")[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListProviderVO, ErrorResponse>({
        path: `/practices/${practiceId}/providers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name GetInactiveProviders
     * @request GET:/practices/{practiceId}/providers/{providerIds}
     */
    getInactiveProviders: (practiceId: number, providerIds: number[], params: RequestParams = {}) =>
      this.request<SuccessResponseListProviderVO, ErrorResponse>({
        path: `/practices/${practiceId}/providers/${providerIds}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetProviderOpenHours
     * @request GET:/practices/{practiceId}/provider-open-hours
     */
    getProviderOpenHours: (
      practiceId: number,
      query: {
        date: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListProviderVO, ErrorResponse>({
        path: `/practices/${practiceId}/provider-open-hours`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prescription-controller
     * @name GetPracticeDosespotEntities
     * @request GET:/practices/{practiceId}/prescription/dosespot-entities
     */
    getPracticeDosespotEntities: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListDosespotEntityVO, ErrorResponse>({
        path: `/practices/${practiceId}/prescription/dosespot-entities`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-feature-controller
     * @name GetPracticeFeatureStates
     * @request GET:/practices/{practiceId}/practice-features
     */
    getPracticeFeatureStates: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListPracticeFeatureStateVO, ErrorResponse>({
        path: `/practices/${practiceId}/practice-features`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags mango-controller
     * @name GetPhoneExtensions
     * @request GET:/practices/{practiceId}/phone-extensions
     */
    getPhoneExtensions: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListPhoneExtensionVO, ErrorResponse>({
        path: `/practices/${practiceId}/phone-extensions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name GetPayrolls
     * @request GET:/practices/{practiceId}/payrolls
     */
    getPayrolls: (
      practiceId: number,
      query: {
        processed?: boolean;
        unprocessed?: boolean;
        /** @format int32 */
        year?: number;
        type?: "REGULAR" | "OFF_CYCLE" | "DISMISSAL";
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPayrollSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/payrolls`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name GetPayroll
     * @request GET:/practices/{practiceId}/payrolls/{payrollUuid}
     */
    getPayroll: (practiceId: number, payrollUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponsePayrollVO, ErrorResponse>({
        path: `/practices/${practiceId}/payrolls/${payrollUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name GetPayrollReceipt
     * @request GET:/practices/{practiceId}/payrolls/{payrollUuid}/receipt
     */
    getPayrollReceipt: (practiceId: number, payrollUuid: string, params: RequestParams = {}) =>
      this.request<string[], ErrorResponse>({
        path: `/practices/${practiceId}/payrolls/${payrollUuid}/receipt`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name GetPayPeriods
     * @request GET:/practices/{practiceId}/payrolls/pay-periods
     */
    getPayPeriods: (
      practiceId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPayPeriodVO, ErrorResponse>({
        path: `/practices/${practiceId}/payrolls/pay-periods`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name GetEmployeeConsolidation
     * @request GET:/practices/{practiceId}/payrolls/employee-consolidation
     */
    getEmployeeConsolidation: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseEmployeeConsolidationVO, ErrorResponse>({
        path: `/practices/${practiceId}/payrolls/employee-consolidation`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name GetPayment
     * @request GET:/practices/{practiceId}/payments/{paymentUuid}
     */
    getPayment: (practiceId: number, paymentUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponsePaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/payments/${paymentUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet-controller
     * @name GetWalletActivities
     * @request GET:/practices/{practiceId}/patients/{patientId}/wallets/{walletUuid}/activities
     */
    getWalletActivities: (
      practiceId: number,
      patientId: number,
      walletUuid: string,
      query: {
        types?: ("WITHDRAW" | "TOP_UP" | "CREDIT" | "REFUND")[];
        /** @format int64 */
        activityCreatedAtGe?: number;
        /** @format int64 */
        activityCreatedAtLe?: number;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListWalletActivityVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/wallets/${walletUuid}/activities`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet-controller
     * @name GetWalletActivityById
     * @request GET:/practices/{practiceId}/patients/{patientId}/wallets/{walletUuid}/activities/{walletActivityId}
     */
    getWalletActivityById: (
      practiceId: number,
      patientId: number,
      walletUuid: string,
      walletActivityId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseWalletActivityVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/wallets/${walletUuid}/activities/${walletActivityId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name GetPatientTreatmentPlansV2
     * @request GET:/practices/{practiceId}/patients/{patientId}/v2/treatment-plans
     */
    getPatientTreatmentPlansV2: (
      patientId: number,
      practiceId: number,
      query?: {
        includeStates?: ("INACTIVE" | "ACTIVE" | "ARCHIVED")[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListTreatmentPlanVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/v2/treatment-plans`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name GetPatientSummary
     * @request GET:/practices/{practiceId}/patients/{patientId}/summary
     */
    getPatientSummary: (patientId: number, practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePatientSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/summary`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags statement-controller
     * @name PrintStatement
     * @request GET:/practices/{practiceId}/patients/{patientId}/statements/{statementUuid}/print
     */
    printStatement: (
      practiceId: number,
      patientId: number,
      statementUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseURL, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/statements/${statementUuid}/print`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags statement-controller
     * @name GetStatementItemCountPerDay
     * @request GET:/practices/{practiceId}/patients/{patientId}/statement-item-count-per-day
     */
    getStatementItemCountPerDay: (
      practiceId: number,
      patientId: number,
      query: {
        /** Inclusive start date */
        startDate: string;
        /** Inclusive end date */
        endDate: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListStatementItemCountPerDateVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/statement-item-count-per-day`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags statement-controller
     * @name GetStatementDateRange
     * @request GET:/practices/{practiceId}/patients/{patientId}/statement-date-range
     */
    getStatementDateRange: (practiceId: number, patientId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseStatementDateRangeVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/statement-date-range`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name ListRefundablePayments
     * @request GET:/practices/{practiceId}/patients/{patientId}/refundable-payments
     */
    listRefundablePayments: (
      practiceId: number,
      patientId: number,
      query: {
        paymentMethods?: (
          | "STORED_PROFILE"
          | "CASH"
          | "CHECK"
          | "STORED_POS"
          | "EXTERNAL_POS"
          | "EFT"
          | "WALLET"
          | "CARE_CREDIT"
          | "THIRD_PARTY_FINANCING"
          | "BALANCE_TRANSFER"
        )[];
        paymentReferenceTypes?: (
          | "INVOICE"
          | "CLAIM_PAYMENT"
          | "WALLET_PAYMENT"
          | "BATCH_PAYMENT"
          | "OFFSITE_PAYMENT"
        )[];
        paymentProfileUuids?: string[];
        /**
         * @format int64
         * @min 1
         */
        minAmount?: number;
        includeFamily?: boolean;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListRefundablePaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/refundable-payments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name GetPayment1
     * @request GET:/practices/{practiceId}/patients/{patientId}/payments/{paymentUuid}
     */
    getPayment1: (practiceId: number, patientId: number, paymentUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponsePaymentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/payments/${paymentUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name PrintPayment
     * @request GET:/practices/{practiceId}/patients/{patientId}/payments/{paymentUuid}/print
     */
    printPayment: (practiceId: number, patientId: number, paymentUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseURL, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/payments/${paymentUuid}/print`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name GetClinicalNoteRenderedForms
     * @request GET:/practices/{practiceId}/patients/{patientId}/patient-note/clinical/rendered
     */
    getClinicalNoteRenderedForms: (
      practiceId: number,
      patientId: number,
      query?: {
        /** @format uuid */
        noteUuid?: string;
        formUuids?: string[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseClinicalNoteFormsResponse, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-note/clinical/rendered`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name GetPatientNoteAlerts
     * @request GET:/practices/{practiceId}/patients/{patientId}/patient-note-alerts
     */
    getPatientNoteAlerts: (
      practiceId: number,
      patientId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPatientNoteVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/patient-note-alerts`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name FetchPatientOutstandingBalanceSummary
     * @request GET:/practices/{practiceId}/patients/{patientId}/outstanding-balance
     */
    fetchPatientOutstandingBalanceSummary: (
      patientId: number,
      practiceId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientOutstandingBalanceSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/outstanding-balance`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetNextPatientAppointment
     * @request GET:/practices/{practiceId}/patients/{patientId}/next-appointment
     */
    getNextPatientAppointment: (
      patientId: number,
      practiceId: number,
      query?: {
        fetchIcons?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseOptionalAppointmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/next-appointment`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags migrated-transaction-controller
     * @name ListMigratedTransactions
     * @request GET:/practices/{practiceId}/patients/{patientId}/migrated-transactions
     */
    listMigratedTransactions: (
      patientId: number,
      practiceId: number,
      query: {
        types?: ("ADJUSTMENT" | "PAYMENT" | "PROCEDURE" | "OTHER")[];
        sortDirection?: "ASCENDING" | "DESCENDING";
        sortField?: string;
        searchString?: string;
        employeeIds?: number[];
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListMigratedTransactionVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/migrated-transactions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name GetPatientMessagesOverview
     * @request GET:/practices/{practiceId}/patients/{patientId}/messages/overview
     */
    getPatientMessagesOverview: (patientId: number, practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseMessageOverviewVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/messages/overview`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name GetMedicalImages
     * @request GET:/practices/{practiceId}/patients/{patientId}/medical-images
     */
    getMedicalImages: (
      practiceId: number,
      patientId: number,
      query: {
        sortColumn?: string;
        sortDirection?: "ASCENDING" | "DESCENDING";
        teeth?: string[];
        imageTeethSearchMode?: "CONTAINS_ALL" | "CONTAINS_ANY";
        imageIds?: number[];
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListMedicalImageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/medical-images`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name GetPearlAnalysisForImage
     * @request GET:/practices/{practiceId}/patients/{patientId}/medical-images/{imageId}/pearl-analysis
     */
    getPearlAnalysisForImage: (
      practiceId: number,
      patientId: number,
      imageId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePearlImageResponseGetEndpoints, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/medical-images/${imageId}/pearl-analysis`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags medical-history-controller
     * @name GetMedicalHistoryForm
     * @request GET:/practices/{practiceId}/patients/{patientId}/medical-history/form
     */
    getMedicalHistoryForm: (
      practiceId: number,
      patientId: number,
      query?: {
        type?: "MEDICAL" | "DENTAL";
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMedicalHistoryFormVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/medical-history/form`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ledger-order-controller
     * @name GetLedgerOrders
     * @request GET:/practices/{practiceId}/patients/{patientId}/ledger-orders
     */
    getLedgerOrders: (
      patientId: number,
      practiceId: number,
      query: {
        withoutInvoice?: boolean;
        types?: (
          | "APPOINTMENT"
          | "APPOINTMENT_ADJUSTMENT"
          | "APPOINTMENT_CLAIM_ADJUSTMENT"
          | "CLAIM_PAYMENT"
          | "CLAIM_SUBMISSION"
          | "ADJUSTMENT"
          | "PAYMENT"
          | "INVOICE"
          | "INVOICE_APPOINTMENT_ADJUSTMENT"
        )[];
        /** @format int64 */
        orderCreatedAtGe?: number;
        /** @format int64 */
        orderCreatedAtLe?: number;
        includeReversedOrders?: boolean;
        newView?: boolean;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListLedgerOrderVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/ledger-orders`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ledger-order-controller
     * @name GetLedgerBalanceSummary
     * @request GET:/practices/{practiceId}/patients/{patientId}/ledger-orders/summary
     */
    getLedgerBalanceSummary: (patientId: number, practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseLedgerBalanceSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/ledger-orders/summary`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ledger-order-controller
     * @name GetLedgerEntries
     * @request GET:/practices/{practiceId}/patients/{patientId}/ledger-entries
     */
    getLedgerEntries: (
      patientId: number,
      practiceId: number,
      query: {
        sortField?: string;
        sortDirection?: "ASCENDING" | "DESCENDING";
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListLedgerV2EntryVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/ledger-entries`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ledger-order-controller
     * @name GetLedgerEntryByTypeAndId
     * @request GET:/practices/{practiceId}/patients/{patientId}/ledger-entries/{entryType}/{entryId}
     */
    getLedgerEntryByTypeAndId: (
      patientId: number,
      practiceId: number,
      entryType: "APPOINTMENT" | "ADJUSTMENT",
      entryId: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseLedgerV2EntryVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/ledger-entries/${entryType}/${entryId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetInvoices
     * @request GET:/practices/{practiceId}/patients/{patientId}/invoices
     */
    getInvoices: (
      patientId: number,
      practiceId: number,
      query: {
        includeOrders?: boolean;
        includePayments?: boolean;
        includePayerInfo?: boolean;
        includePracticeInfo?: boolean;
        includeAttributions?: boolean;
        sortDirection?: "ASCENDING" | "DESCENDING";
        sortField?: string;
        states?: ("FINALIZED" | "VOID" | "PARTIALLY_PAID" | "PAID")[];
        paymentStates?: (
          | "CREATED"
          | "IN_PROGRESS"
          | "AWAITING_RESULT"
          | "PENDING"
          | "SETTLED"
          | "DENIED"
          | "CHARGEBACK"
          | "VOID"
        )[];
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListInvoiceVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/invoices`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetInvoice
     * @request GET:/practices/{practiceId}/patients/{patientId}/invoices/{invoiceUuid}
     */
    getInvoice: (
      patientId: number,
      practiceId: number,
      invoiceUuid: string,
      query?: {
        includeOrders?: boolean;
        includePayments?: boolean;
        includePayerInfo?: boolean;
        includePracticeInfo?: boolean;
        includeAttributions?: boolean;
        paymentStates?: (
          | "CREATED"
          | "IN_PROGRESS"
          | "AWAITING_RESULT"
          | "PENDING"
          | "SETTLED"
          | "DENIED"
          | "CHARGEBACK"
          | "VOID"
        )[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseInvoiceVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/invoices/${invoiceUuid}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name PrintInvoice
     * @request GET:/practices/{practiceId}/patients/{patientId}/invoices/{invoiceUuid}/print
     */
    printInvoice: (patientId: number, practiceId: number, invoiceUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseURL, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/invoices/${invoiceUuid}/print`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetPendingInvoiceEntries
     * @request GET:/practices/{practiceId}/patients/{patientId}/invoice-pending-entries
     */
    getPendingInvoiceEntries: (
      patientId: number,
      practiceId: number,
      query?: {
        hideZeroAmounts?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListInvoiceEntryVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/invoice-pending-entries`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-insurance-controller
     * @name GetAutoEligibilityStatus
     * @request GET:/practices/{practiceId}/patients/{patientId}/insurances/{insuranceId}/auto/status
     */
    getAutoEligibilityStatus: (
      patientId: number,
      practiceId: number,
      insuranceId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAutoEligibilityStatusVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/insurances/${insuranceId}/auto/status`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name GetFormSubmissions
     * @request GET:/practices/{practiceId}/patients/{patientId}/forms/{uuidOrSlug}/submissions
     */
    getFormSubmissions: (
      practiceId: number,
      patientId: number,
      uuidOrSlug:
        | string
        | "DENTAL_HISTORY"
        | "MEDICAL_HISTORY"
        | "CONSENT"
        | "TREATMENT_PLAN_FORM"
        | "APPOINTMENT_FORM",
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListFormSubmissionSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/forms/${uuidOrSlug}/submissions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name GetFormSubmissionByPublishedFormContentUuid
     * @request GET:/practices/{practiceId}/patients/{patientId}/forms/{uuidOrSlug}/submissions/{formPublishedContentUuid}
     */
    getFormSubmissionByPublishedFormContentUuid: (
      practiceId: number,
      patientId: number,
      uuidOrSlug:
        | string
        | "DENTAL_HISTORY"
        | "MEDICAL_HISTORY"
        | "CONSENT"
        | "TREATMENT_PLAN_FORM"
        | "APPOINTMENT_FORM",
      formPublishedContentUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormSubmissionVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/forms/${uuidOrSlug}/submissions/${formPublishedContentUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name GetPatientPublishedForm
     * @request GET:/practices/{practiceId}/patients/{patientId}/forms/{uuidOrSlug}/published
     */
    getPatientPublishedForm: (
      practiceId: number,
      patientId: number,
      uuidOrSlug:
        | string
        | "DENTAL_HISTORY"
        | "MEDICAL_HISTORY"
        | "CONSENT"
        | "TREATMENT_PLAN_FORM"
        | "APPOINTMENT_FORM",
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/forms/${uuidOrSlug}/published`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name GetLatestFormSubmission
     * @request GET:/practices/{practiceId}/patients/{patientId}/forms/{uuidOrSlug}/latest-submission
     */
    getLatestFormSubmission: (
      practiceId: number,
      patientId: number,
      uuidOrSlug:
        | string
        | "DENTAL_HISTORY"
        | "MEDICAL_HISTORY"
        | "CONSENT"
        | "TREATMENT_PLAN_FORM"
        | "APPOINTMENT_FORM",
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormSubmissionVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/forms/${uuidOrSlug}/latest-submission`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetFamilyMembersWithPendingInvoiceEntries
     * @request GET:/practices/{practiceId}/patients/{patientId}/family-members-with-pending-invoice-entries
     */
    getFamilyMembersWithPendingInvoiceEntries: (
      practiceId: number,
      patientId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListLong, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/family-members-with-pending-invoice-entries`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-subscription-controller
     * @name GetEmailSubscriptionByPatient
     * @request GET:/practices/{practiceId}/patients/{patientId}/email-subscription
     */
    getEmailSubscriptionByPatient: (patientId: number, practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseMessageSubscriptionVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/email-subscription`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name GetPatientCallCard
     * @request GET:/practices/{practiceId}/patients/{patientId}/call-card
     */
    getPatientCallCard: (
      practiceId: number,
      patientId: number,
      query?: {
        phone?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientCallCardVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/call-card`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name GetMedicalHistoryAudit
     * @request GET:/practices/{practiceId}/patients/{patientId}/audit-history
     */
    getMedicalHistoryAudit: (patientId: number, practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListMedicalHistoryAuditVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/audit-history`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetPatientAppointments
     * @request GET:/practices/{practiceId}/patients/{patientId}/appointments
     */
    getPatientAppointments: (
      patientId: number,
      practiceId: number,
      query: {
        dateMode?: "FUTURE" | "PAST";
        includeStates?: (
          | "UNSCHEDULED"
          | "UNCONFIRMED"
          | "CONFIRMED"
          | "CANCELED"
          | "NO_SHOW"
          | "ARRIVED"
          | "READY"
          | "IN_PROGRESS"
          | "CHECKOUT"
          | "COMPLETED"
          | "_DELETED"
          | "REQUESTED"
        )[];
        excludeStates?: (
          | "UNSCHEDULED"
          | "UNCONFIRMED"
          | "CONFIRMED"
          | "CANCELED"
          | "NO_SHOW"
          | "ARRIVED"
          | "READY"
          | "IN_PROGRESS"
          | "CHECKOUT"
          | "COMPLETED"
          | "_DELETED"
          | "REQUESTED"
        )[];
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListAppointmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/appointments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetPatientAppointment
     * @request GET:/practices/{practiceId}/patients/{patientId}/appointments/{appointmentId}
     */
    getPatientAppointment: (
      practiceId: number,
      patientId: number,
      appointmentId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAppointmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/appointments/${appointmentId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name PrintAppointment
     * @request GET:/practices/{practiceId}/patients/{patientId}/appointments/{appointmentId}/print
     */
    printAppointment: (
      practiceId: number,
      patientId: number,
      appointmentId: number,
      query?: {
        date?: string;
        forceInsuranceEstimate?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<string[], ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/appointments/${appointmentId}/print`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name GetAppointmentClinicalNote
     * @request GET:/practices/{practiceId}/patients/{patientId}/appointment-note/{appointmentId}
     */
    getAppointmentClinicalNote: (
      practiceId: number,
      patientId: number,
      appointmentId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientNoteVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/${patientId}/appointment-note/${appointmentId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name GetPatientOverview
     * @request GET:/practices/{practiceId}/patients/overview
     */
    getPatientOverview: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePatientOverviewVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/overview`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name GetPatientProcedureInsuranceStatus
     * @request GET:/practices/{practiceId}/patient-procedures/{patientProcedureId}/insurance-status
     */
    getPatientProcedureInsuranceStatus: (
      practiceId: number,
      patientProcedureId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponsePatientProcedureInsuranceStatusVO, ErrorResponse>({
        path: `/practices/${practiceId}/patient-procedures/${patientProcedureId}/insurance-status`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags charting-controller
     * @name PrintPatientProcedures
     * @request GET:/practices/{practiceId}/patient-procedures/print
     */
    printPatientProcedures: (
      practiceId: number,
      query?: {
        /** @format uuid */
        treatmentPlanUuid?: string;
        /** @uniqueItems true */
        procedureIds?: number[];
        date?: string;
        forceInsuranceEstimate?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<string[], ErrorResponse>({
        path: `/practices/${practiceId}/patient-procedures/print`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name GetPatientNoteAuditHistory
     * @request GET:/practices/{practiceId}/patient-note/{patientNoteUuid}/audit-history
     */
    getPatientNoteAuditHistory: (
      practiceId: number,
      patientNoteUuid: string,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPatientNoteAuditEntryVO, ErrorResponse>({
        path: `/practices/${practiceId}/patient-note/${patientNoteUuid}/audit-history`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetPracticeOpenHours
     * @request GET:/practices/{practiceId}/open-hours
     */
    getPracticeOpenHours: (
      practiceId: number,
      query: {
        date: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListTimeRangeVO, ErrorResponse>({
        path: `/practices/${practiceId}/open-hours`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration-controller
     * @name GetMigrationRun
     * @request GET:/practices/{practiceId}/migration-runs/{migrationRunId}
     */
    getMigrationRun: (practiceId: number, migrationRunId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseMigrationRunVO, ErrorResponse>({
        path: `/practices/${practiceId}/migration-runs/${migrationRunId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration-controller
     * @name GetMigrationSourceEntryCount
     * @request GET:/practices/{practiceId}/migration-runs/{migrationRunId}/source-entry-counts
     */
    getMigrationSourceEntryCount: (practiceId: number, migrationRunId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListMigrationSourceEntryCountVO, ErrorResponse>({
        path: `/practices/${practiceId}/migration-runs/${migrationRunId}/source-entry-counts`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration-controller
     * @name GetMigrationSourceEntry
     * @request GET:/practices/{practiceId}/migration-runs/{migrationRunId}/source-entries/{sourceEntryId}
     */
    getMigrationSourceEntry: (
      migrationRunId: number,
      sourceEntryId: number,
      practiceId: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseMigrationSourceEntryVO, ErrorResponse>({
        path: `/practices/${practiceId}/migration-runs/${migrationRunId}/source-entries/${sourceEntryId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name GetPracticeMessages
     * @request GET:/practices/{practiceId}/messages
     */
    getPracticeMessages: (
      practiceId: number,
      query: {
        searchString?: string;
        type?: "CHAT" | "EMAIL" | "CALL" | "SMS" | "MAIL";
        /** @format int64 */
        patientId?: number;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListRankedPatientMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/messages`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags twilio-practice-config-controller
     * @name GetTwilioRegistrationStatus
     * @request GET:/practices/{practiceId}/mass-text/twilio-status
     */
    getTwilioRegistrationStatus: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseTwilioOnboardingStatusVO, ErrorResponse>({
        path: `/practices/${practiceId}/mass-text/twilio-status`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags twilio-practice-config-controller
     * @name FetchAvailableTwilioPhoneNumbers
     * @request GET:/practices/{practiceId}/mass-text/twilio-numbers
     */
    fetchAvailableTwilioPhoneNumbers: (
      practiceId: number,
      query: {
        startsWith: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListAvailableTwilioPhoneNumberVO, ErrorResponse>({
        path: `/practices/${practiceId}/mass-text/twilio-numbers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags twilio-practice-config-controller
     * @name FetchMassTextStatus
     * @request GET:/practices/{practiceId}/mass-text/status
     */
    fetchMassTextStatus: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseMassTextStatus, ErrorResponse>({
        path: `/practices/${practiceId}/mass-text/status`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lab-case-controller
     * @name PrintLabOrderForm
     * @request GET:/practices/{practiceId}/labs/{labUuid}/print-order-form
     */
    printLabOrderForm: (practiceId: number, labUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseURL, ErrorResponse>({
        path: `/practices/${practiceId}/labs/${labUuid}/print-order-form`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags automation-controller
     * @name GetAutomationJourney
     * @request GET:/practices/{practiceId}/journeys/{journeyUuidOrType}
     */
    getAutomationJourney: (
      practiceId: number,
      journeyUuidOrType: string | "APPOINTMENT" | "POST_APPOINTMENT" | "RECALL" | "FORMS",
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseAutomationJourneyVO, ErrorResponse>({
        path: `/practices/${practiceId}/journeys/${journeyUuidOrType}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags comms-controller
     * @name GetPatientEngagementJourneysForPractice
     * @request GET:/practices/{practiceId}/journey
     */
    getPatientEngagementJourneysForPractice: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListJourneyVO, ErrorResponse>({
        path: `/practices/${practiceId}/journey`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags intra-office-chat-controller
     * @name GetUnreadMessageCount
     * @request GET:/practices/{practiceId}/intra-office-chat/unread-message-count
     */
    getUnreadMessageCount: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseChatUnreadMessage, ErrorResponse>({
        path: `/practices/${practiceId}/intra-office-chat/unread-message-count`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-carrier-controller
     * @name GetPracticeInsuranceCarriers
     * @request GET:/practices/{practiceId}/insurances/carriers/list
     */
    getPracticeInsuranceCarriers: (
      practiceId: number,
      query: {
        searchString?: string;
        inNetwork?: boolean;
        sortColumn?: string;
        orderBy?: string;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListInsuranceCarrierListEntryVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurances/carriers/list`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name GetInsurancePlanOverview
     * @request GET:/practices/{practiceId}/insurance-plans/{insurancePlanUuid}/overview
     */
    getInsurancePlanOverview: (practiceId: number, insurancePlanUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseInsurancePlanOverviewVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans/${insurancePlanUuid}/overview`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name GetGroupedBenefitLimitations
     * @request GET:/practices/{practiceId}/insurance-plans/{insurancePlanUuid}/grouped-benefit-limitations
     */
    getGroupedBenefitLimitations: (
      practiceId: number,
      insurancePlanUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseGroupedBenefitLimitationsVO, ErrorResponse>({
        path: `/practices/${practiceId}/insurance-plans/${insurancePlanUuid}/grouped-benefit-limitations`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetPracticeInfoById
     * @request GET:/practices/{practiceId}/info
     */
    getPracticeInfoById: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePracticeInfoVO, ErrorResponse>({
        path: `/practices/${practiceId}/info`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imaging-controller
     * @name GetPracticeImagingSettings
     * @request GET:/practices/{practiceId}/imaging-settings
     */
    getPracticeImagingSettings: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePracticeImagingSettingsVO, ErrorResponse>({
        path: `/practices/${practiceId}/imaging-settings`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name GetCompanyOnboardingStatus
     * @request GET:/practices/{practiceId}/gusto-onboarding-status
     */
    getCompanyOnboardingStatus: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseCompanyOnboardingStatusVO, ErrorResponse>({
        path: `/practices/${practiceId}/gusto-onboarding-status`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name GetGustoOnboardingErrors
     * @request GET:/practices/{practiceId}/gusto-onboarding-errors
     */
    getGustoOnboardingErrors: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseGustoOnboardingErrorsVO, ErrorResponse>({
        path: `/practices/${practiceId}/gusto-onboarding-errors`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name GetPublishedForm
     * @request GET:/practices/{practiceId}/forms/{uuidOrSlug}/published
     */
    getPublishedForm: (
      practiceId: number,
      uuidOrSlug:
        | string
        | "DENTAL_HISTORY"
        | "MEDICAL_HISTORY"
        | "CONSENT"
        | "TREATMENT_PLAN_FORM"
        | "APPOINTMENT_FORM",
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormVO, ErrorResponse>({
        path: `/practices/${practiceId}/forms/${uuidOrSlug}/published`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name GetDraftForm
     * @request GET:/practices/{practiceId}/forms/{uuidOrSlug}/draft
     */
    getDraftForm: (
      practiceId: number,
      uuidOrSlug:
        | string
        | "DENTAL_HISTORY"
        | "MEDICAL_HISTORY"
        | "CONSENT"
        | "TREATMENT_PLAN_FORM"
        | "APPOINTMENT_FORM",
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormVO, ErrorResponse>({
        path: `/practices/${practiceId}/forms/${uuidOrSlug}/draft`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags form-controller
     * @name DiscardDraft
     * @request DELETE:/practices/{practiceId}/forms/{uuidOrSlug}/draft
     */
    discardDraft: (
      practiceId: number,
      uuidOrSlug:
        | string
        | "DENTAL_HISTORY"
        | "MEDICAL_HISTORY"
        | "CONSENT"
        | "TREATMENT_PLAN_FORM"
        | "APPOINTMENT_FORM",
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFormVO, ErrorResponse>({
        path: `/practices/${practiceId}/forms/${uuidOrSlug}/draft`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags finix-merchant-onboarding-controller
     * @name GetFinixMerchantIdentity
     * @request GET:/practices/{practiceId}/finix-onboarding
     */
    getFinixMerchantIdentity: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseFinixMerchantIdentityVO, ErrorResponse>({
        path: `/practices/${practiceId}/finix-onboarding`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags finix-merchant-onboarding-controller
     * @name GetFinixOnboardingFormUrl
     * @request GET:/practices/{practiceId}/finix-onboarding/form
     */
    getFinixOnboardingFormUrl: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseFinixOnboardingFormVO, ErrorResponse>({
        path: `/practices/${practiceId}/finix-onboarding/form`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name GetFeeScheduleTargetProviders
     * @request GET:/practices/{practiceId}/fee-schedules/{feeScheduleId}/providers
     */
    getFeeScheduleTargetProviders: (practiceId: number, feeScheduleId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListProviderVO, ErrorResponse>({
        path: `/practices/${practiceId}/fee-schedules/${feeScheduleId}/providers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name GetProvidersWithoutUcr
     * @request GET:/practices/{practiceId}/fee-schedules/providers
     */
    getProvidersWithoutUcr: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListProviderVO, ErrorResponse>({
        path: `/practices/${practiceId}/fee-schedules/providers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name GetPracticeFeeScheduleNames
     * @request GET:/practices/{practiceId}/fee-schedules/list
     */
    getPracticeFeeScheduleNames: (
      practiceId: number,
      query: {
        /** @format int64 */
        carrierId: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListFeeScheduleVO, ErrorResponse>({
        path: `/practices/${practiceId}/fee-schedules/list`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags worktime-controller
     * @name GetTimesheetData
     * @request GET:/practices/{practiceId}/employees/{employeeId}/worktime/timesheet
     */
    getTimesheetData: (
      practiceId: number,
      employeeId: number,
      query: {
        startDate: string;
        endDate: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListDateWorktimeVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/worktime/timesheet`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags worktime-controller
     * @name GetWorktimeOverview
     * @request GET:/practices/{practiceId}/employees/{employeeId}/worktime/overview
     */
    getWorktimeOverview: (
      practiceId: number,
      employeeId: number,
      query: {
        startDate: string;
        endDate: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseWorktimeOverviewVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/worktime/overview`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags worktime-controller
     * @name GetLastWorktimeAction
     * @request GET:/practices/{practiceId}/employees/{employeeId}/worktime/last-action
     */
    getLastWorktimeAction: (practiceId: number, employeeId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseLastWorktimeVOV2, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/worktime/last-action`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prescription-controller
     * @name GetClinicianPrescriptionStatus
     * @request GET:/practices/{practiceId}/employees/{employeeId}/prescription/status
     */
    getClinicianPrescriptionStatus: (practiceId: number, employeeId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePrescriptionStatus, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/prescription/status`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prescription-controller
     * @name GetDosespotNotificationCount
     * @request GET:/practices/{practiceId}/employees/{employeeId}/prescription/dosespot-notification-count
     */
    getDosespotNotificationCount: (practiceId: number, employeeId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseProviderDosespotNotificationCount, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/prescription/dosespot-notification-count`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name PrintEmployeePayStub
     * @request GET:/practices/{practiceId}/employees/{employeeId}/payrolls/{payrollUuid}/pay-stub/print
     */
    printEmployeePayStub: (
      practiceId: number,
      employeeId: number,
      payrollUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseURL, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/payrolls/${payrollUuid}/pay-stub/print`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name GetEmployeeOnboardingDocuments
     * @request GET:/practices/{practiceId}/employees/{employeeId}/onboard/fetch-documents
     */
    getEmployeeOnboardingDocuments: (
      practiceId: number,
      employeeId: number,
      query?: {
        includeMetadata?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListDocumentVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/onboard/fetch-documents`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payroll-controller
     * @name GetEmployeeOnboardStatus
     * @request GET:/practices/{practiceId}/employees/{employeeId}/gusto-onboarding-status
     */
    getEmployeeOnboardStatus: (practiceId: number, employeeId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseEmployeeOnboardingStatusVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/gusto-onboarding-status`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name GetManagers
     * @request GET:/practices/{practiceId}/employees/populate/managers
     */
    getManagers: (
      practiceId: number,
      query?: {
        /** @format int64 */
        employeeId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListNameVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/populate/managers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name GetEmployeeOverview
     * @request GET:/practices/{practiceId}/employees/overview
     */
    getEmployeeOverview: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseEmployeeOverviewVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/overview`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee-controller
     * @name GetEmployeeNames
     * @request GET:/practices/{practiceId}/employees/names
     */
    getEmployeeNames: (
      practiceId: number,
      query?: {
        /** @uniqueItems true */
        statuses?: ("ACTIVE" | "INACTIVE" | "ARCHIVED" | "PENDING" | "FURLOUGHED")[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListNameVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/names`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dental-procedure-controller
     * @name GetDentalProceduresByPractice
     * @request GET:/practices/{practiceId}/dental-procedures
     */
    getDentalProceduresByPractice: (
      practiceId: number,
      query?: {
        category?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListDentalProcedureVO, ErrorResponse>({
        path: `/practices/${practiceId}/dental-procedures`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetFutureCustomHolidays
     * @request GET:/practices/{practiceId}/custom-holidays/future
     */
    getFutureCustomHolidays: (
      practiceId: number,
      query?: {
        currentDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListCustomHolidayVO, ErrorResponse>({
        path: `/practices/${practiceId}/custom-holidays/future`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetPracticeCounters
     * @request GET:/practices/{practiceId}/counters
     */
    getPracticeCounters: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePracticeCountersVO, ErrorResponse>({
        path: `/practices/${practiceId}/counters`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags practice-controller
     * @name GetPracticeConnectedRemoteSettings
     * @request GET:/practices/{practiceId}/connected-remote-settings
     */
    getPracticeConnectedRemoteSettings: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponsePracticeConnectedRemoteSettingsVO, ErrorResponse>({
        path: `/practices/${practiceId}/connected-remote-settings`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name PrintClaim
     * @request GET:/practices/{practiceId}/claims/{claimUuid}/print
     */
    printClaim: (
      practiceId: number,
      claimUuid: string,
      query: {
        /**
         * @format int32
         * @default 2019
         */
        printVersion: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<string[], ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/print`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetClaimSummaries
     * @request GET:/practices/{practiceId}/claims/search
     */
    getClaimSummaries: (
      practiceId: number,
      query: {
        /** @uniqueItems true */
        patientIds?: number[];
        /** @uniqueItems true */
        billingProviderIds?: number[];
        /** @uniqueItems true */
        treatingProviderIds?: number[];
        /** @uniqueItems true */
        insuranceCarrierIds?: number[];
        preAuth?: boolean;
        /** @uniqueItems true */
        insurancePaymentStatuses?: (
          | "UNPAID"
          | "NOT_SUBMITTED"
          | "PAID_AS_EXPECTED"
          | "OVERPAID"
          | "UNDERPAID"
          | "DENIED"
        )[];
        /** @uniqueItems true */
        states?: ("PENDING" | "SUBMITTING" | "SUBMITTED" | "FAILED" | "COMPLETED" | "VOIDED")[];
        /** @uniqueItems true */
        insuranceOrdinals?: ("PRIMARY" | "SECONDARY" | "OTHER")[];
        searchString?: string;
        /** @uniqueItems true */
        patientInsuranceIds?: number[];
        sortDirection?: "ASCENDING" | "DESCENDING";
        sortField?:
          | "SERVICE_DATE"
          | "PATIENT_FULL_NAME"
          | "PATIENT_FIRST_NAME"
          | "PATIENT_LAST_NAME"
          | "TREATING_PROVIDER_FULL_NAME"
          | "TREATING_PROVIDER_FIRST_NAME"
          | "TREATING_PROVIDER_LAST_NAME"
          | "UCR_AMOUNT"
          | "PATIENT_AMOUNT"
          | "INSURANCE_MEMBER_ID"
          | "INSURANCE_AMOUNT"
          | "INSURANCE_ESTIMATED_AMOUNT"
          | "INSURANCE_PLAN_TYPE"
          | "INSURANCE_PAYMENT_STATUS"
          | "INSURANCE_CARRIER"
          | "NEEDS_ATTACHMENT"
          | "STATE"
          | "SUBMITTED_AT";
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListClaimSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetPrimaryEobs
     * @request GET:/practices/{practiceId}/claims/primary-eobs/{appointmentId}
     */
    getPrimaryEobs: (practiceId: number, appointmentId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListClaimEobFileVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/primary-eobs/${appointmentId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetPaymentEligibleClaimSummaries
     * @request GET:/practices/{practiceId}/claims/payment-eligible-search
     */
    getPaymentEligibleClaimSummaries: (
      practiceId: number,
      query: {
        /** @uniqueItems true */
        filters: ("SUBMITTED" | "COMPLETED" | "PENDING_NON_PRIMARY")[];
        /** @uniqueItems true */
        insuranceCarrierIds: number[];
        searchString?: string;
        sortDirection?: "ASCENDING" | "DESCENDING";
        sortField?:
          | "SERVICE_DATE"
          | "PATIENT_FULL_NAME"
          | "PATIENT_FIRST_NAME"
          | "PATIENT_LAST_NAME"
          | "TREATING_PROVIDER_FULL_NAME"
          | "TREATING_PROVIDER_FIRST_NAME"
          | "TREATING_PROVIDER_LAST_NAME"
          | "UCR_AMOUNT"
          | "PATIENT_AMOUNT"
          | "INSURANCE_MEMBER_ID"
          | "INSURANCE_AMOUNT"
          | "INSURANCE_ESTIMATED_AMOUNT"
          | "INSURANCE_PLAN_TYPE"
          | "INSURANCE_PAYMENT_STATUS"
          | "INSURANCE_CARRIER"
          | "NEEDS_ATTACHMENT"
          | "STATE"
          | "SUBMITTED_AT";
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListClaimSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/payment-eligible-search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetClaimsOverview
     * @request GET:/practices/{practiceId}/claims/overview
     */
    getClaimsOverview: (
      practiceId: number,
      query?: {
        /** @uniqueItems true */
        patientIds?: number[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseClaimsOverviewVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/overview`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetClaimInsuranceCarriers
     * @request GET:/practices/{practiceId}/claims/insurance-carriers
     */
    getClaimInsuranceCarriers: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListClaimInsuranceCarrierVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/insurance-carriers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetEobUrl
     * @request GET:/practices/{practiceId}/claims/eob-url/{eobUuid}
     */
    getEobUrl: (practiceId: number, eobUuid: string, params: RequestParams = {}) =>
      this.request<SuccessResponseString, ErrorResponse>({
        path: `/practices/${practiceId}/claims/eob-url/${eobUuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name PrintEobFile
     * @request GET:/practices/{practiceId}/claims/eob-url/{eobUuid}/print
     */
    printEobFile: (practiceId: number, eobUuid: string, params: RequestParams = {}) =>
      this.request<string[], ErrorResponse>({
        path: `/practices/${practiceId}/claims/eob-url/${eobUuid}/print`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetReceiverLoginUrl
     * @request GET:/practices/{practiceId}/claims/config/receiver-login-url
     */
    getReceiverLoginUrl: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseClaimReceiverUrlVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/config/receiver-login-url`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetClaimsBillingProviders
     * @request GET:/practices/{practiceId}/claims/billing-providers
     */
    getClaimsBillingProviders: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListProviderVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/billing-providers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name GetClaimsByAppointment
     * @request GET:/practices/{practiceId}/claims/appointment
     */
    getClaimsByAppointment: (
      practiceId: number,
      query: {
        ids: number[];
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListAppointmentClaimSummaryVO, ErrorResponse>({
        path: `/practices/${practiceId}/claims/appointment`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-insurance-controller
     * @name GetAutoEligibilityCarriers
     * @request GET:/practices/{practiceId}/auto/carriers
     */
    getAutoEligibilityCarriers: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseAutoEligibilityCarriersVO, ErrorResponse>({
        path: `/practices/${practiceId}/auto/carriers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetAppointmentRequests
     * @request GET:/practices/{practiceId}/appointments/requests
     */
    getAppointmentRequests: (
      practiceId: number,
      query: {
        sortColumn?: string;
        sortDirection?: "ASC" | "DESC";
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListAppointmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/requests`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetOpenSlots
     * @request GET:/practices/{practiceId}/appointments/open-slots
     */
    getOpenSlots: (
      practiceId: number,
      query: {
        /** @uniqueItems true */
        providerIds?: number[];
        /** @format int64 */
        appointmentDurationInMinutes: number;
        timeRanges?: string[];
        daysPreferred?: (
          | "MONDAY"
          | "TUESDAY"
          | "WEDNESDAY"
          | "THURSDAY"
          | "FRIDAY"
          | "SATURDAY"
          | "SUNDAY"
        )[];
        startDate: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListAppointmentSeriesVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/open-slots`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetAppointmentHolds
     * @request GET:/practices/{practiceId}/appointments/holds
     */
    getAppointmentHolds: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListAppointmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/holds`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetAppointmentCount
     * @request GET:/practices/{practiceId}/appointments/count
     */
    getAppointmentCount: (
      practiceId: number,
      query: {
        filterDate: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseLong, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/count`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetAppointmentCards
     * @request GET:/practices/{practiceId}/appointments/cards
     */
    getAppointmentCards: (
      practiceId: number,
      query: {
        filterDate: string;
        /** @uniqueItems true */
        excludeStates?: (
          | "UNSCHEDULED"
          | "UNCONFIRMED"
          | "CONFIRMED"
          | "CANCELED"
          | "NO_SHOW"
          | "ARRIVED"
          | "READY"
          | "IN_PROGRESS"
          | "CHECKOUT"
          | "COMPLETED"
          | "_DELETED"
          | "REQUESTED"
        )[];
        includeHuddleIcons?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListAppointmentVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/cards`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetAvailableRooms
     * @request GET:/practices/{practiceId}/appointments/available-rooms
     */
    getAvailableRooms: (
      practiceId: number,
      query: {
        /** @format int64 */
        appointmentDurationInMinutes: number;
        startDate: string;
        /** @format date-time */
        startTime: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListRoomVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/available-rooms`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-controller
     * @name GetAvailableProviders
     * @request GET:/practices/{practiceId}/appointments/available-providers
     */
    getAvailableProviders: (
      practiceId: number,
      query: {
        /** @format int64 */
        appointmentDurationInMinutes: number;
        startDate: string;
        /** @format date-time */
        startTime: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListProviderVO, ErrorResponse>({
        path: `/practices/${practiceId}/appointments/available-providers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name ClearRecentSearchHistory
     * @request DELETE:/practices/{practiceId}/patients/recent/clear
     */
    clearRecentSearchHistory: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/patients/recent/clear`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name DeleteFrequencyLimitationCategory
     * @request DELETE:/practices/{practiceId}/insurances/frequency-limitation-categories/{frequencyLimitationCategoryId}
     */
    deleteFrequencyLimitationCategory: (
      practiceId: number,
      frequencyLimitationCategoryId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/insurances/frequency-limitation-categories/${frequencyLimitationCategoryId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags document-controller
     * @name DeleteEmployeeOnboardingDocument
     * @request DELETE:/practices/{practiceId}/employees/{employeeId}/onboard/documents/{documentId}
     */
    deleteEmployeeOnboardingDocument: (
      practiceId: number,
      employeeId: number,
      documentId: number,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/practices/${practiceId}/employees/${employeeId}/onboard/documents/${documentId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name DeleteEobFromClaim
     * @request DELETE:/practices/{practiceId}/claims/{claimUuid}/eob/{eobUuid}
     */
    deleteEobFromClaim: (
      practiceId: number,
      claimUuid: string,
      eobUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/claims/${claimUuid}/eob/${eobUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags claims-controller
     * @name DeleteEobFileFromDraftEobPayment
     * @request DELETE:/practices/{practiceId}/claims/draft-eob-payment/{draftEobPaymentUuid}/eob/{eobUuid}
     */
    deleteEobFileFromDraftEobPayment: (
      practiceId: number,
      draftEobPaymentUuid: string,
      eobUuid: string,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/practices/${practiceId}/claims/draft-eob-payment/${draftEobPaymentUuid}/eob/${eobUuid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  insurances = {
    /**
     * No description
     *
     * @tags insurance-controller
     * @name GetArchyCarrier
     * @request GET:/insurances/carriers/{carrierId}
     */
    getArchyCarrier: (carrierId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseInsuranceCarrierDetailsVO, ErrorResponse>({
        path: `/insurances/carriers/${carrierId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name UpdateArchyCarrier
     * @request PUT:/insurances/carriers/{carrierId}
     */
    updateArchyCarrier: (carrierId: number, data: InsuranceCarrierRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseInsuranceCarrierDetailsVO, ErrorResponse>({
        path: `/insurances/carriers/${carrierId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name DeleteArchyCarrier
     * @request DELETE:/insurances/carriers/{carrierId}
     */
    deleteArchyCarrier: (carrierId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseSuccessEndpointResponseMessageVO, ErrorResponse>({
        path: `/insurances/carriers/${carrierId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name RenewPatientInsurance
     * @request POST:/insurances/renew-benefits
     */
    renewPatientInsurance: (data: BenefitRenewalRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseVoid, ErrorResponse>({
        path: `/insurances/renew-benefits`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name CreateArchyCarrier
     * @request POST:/insurances/carriers
     */
    createArchyCarrier: (data: CreateInsuranceCarrierRequest, params: RequestParams = {}) =>
      this.request<SuccessResponseInsuranceCarrierDetailsVO, ErrorResponse>({
        path: `/insurances/carriers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  v2 = {
    /**
     * No description
     *
     * @tags charting-controller
     * @name CreateTreatmentPlanPrintFormTask
     * @request POST:/v2/practices/{practiceId}/patient-procedures/print
     * @deprecated
     */
    createTreatmentPlanPrintFormTask: (
      practiceId: number,
      data: PrintPatientProceduresWithFormTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseUUID, ErrorResponse>({
        path: `/v2/practices/${practiceId}/patient-procedures/print`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-controller
     * @name ListPatientPaymentSummaries
     * @request GET:/v2/practices/{practiceId}/patients/{patientId}/payments
     */
    listPatientPaymentSummaries: (
      practiceId: number,
      patientId: number,
      query: {
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPaymentSummaryVO, ErrorResponse>({
        path: `/v2/practices/${practiceId}/patients/${patientId}/payments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-note-controller
     * @name GetPatientNotesV2
     * @request GET:/v2/practices/{practiceId}/patients/{patientId}/patient-note
     */
    getPatientNotesV2: (
      practiceId: number,
      patientId: number,
      query: {
        types?: ("CHART" | "GENERAL")[];
        tagIds?: number[];
        includeArchived?: boolean;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListPatientNoteVO, ErrorResponse>({
        path: `/v2/practices/${practiceId}/patients/${patientId}/patient-note`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-tooth-controller
     * @name GetPatientChartV2
     * @request GET:/v2/practices/{practiceId}/patients/{patientId}/patient-charts
     */
    getPatientChartV2: (practiceId: number, patientId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListPatientChartEntryVO, ErrorResponse>({
        path: `/v2/practices/${practiceId}/patients/${patientId}/patient-charts`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags family-controller
     * @name GetFamilyMembersV2
     * @request GET:/v2/practices/{practiceId}/patients/{patientId}/family-members
     */
    getFamilyMembersV2: (
      practiceId: number,
      patientId: number,
      query?: {
        /** @default true */
        includeUnlinked?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseFamilyMemberResponse, ErrorResponse>({
        path: `/v2/practices/${practiceId}/patients/${patientId}/family-members`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags patient-controller
     * @name GetRecentSearchHistoryV2
     * @request GET:/v2/practices/{practiceId}/patients/recent
     */
    getRecentSearchHistoryV2: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListPatientSummaryVO, ErrorResponse>({
        path: `/v2/practices/${practiceId}/patients/recent`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-controller
     * @name GetFrequencyLimitationCategories
     * @request GET:/v2/practices/{practiceId}/insurances/frequency-limitation-categories
     */
    getFrequencyLimitationCategories: (practiceId: number, params: RequestParams = {}) =>
      this.request<SuccessResponseListFrequencyLimitationCategoryVO, ErrorResponse>({
        path: `/v2/practices/${practiceId}/insurances/frequency-limitation-categories`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags fee-schedule-controller
     * @name GetFeeSchedulesV2
     * @request GET:/v2/practices/{practiceId}/fee-schedules
     */
    getFeeSchedulesV2: (
      practiceId: number,
      query: {
        sortColumn?: string;
        orderBy?: string;
        /** @uniqueItems true */
        providerIds?: number[];
        /** @uniqueItems true */
        states?: ("ACTIVE" | "ARCHIVED")[];
        /** @uniqueItems true */
        types?: ("PRACTICE_UCR" | "PROVIDER_UCR" | "CARRIER" | "BLUE_BOOK")[];
        inNetwork?: boolean;
        /** @uniqueItems true */
        carrierIds?: number[];
        searchString?: string;
        /** @format int32 */
        maxPageSize?: number;
        /** @format int32 */
        pageNumber: number;
        /** @format int32 */
        pageSize: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListFeeScheduleListVO, ErrorResponse>({
        path: `/v2/practices/${practiceId}/fee-schedules`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  toothConditions = {
    /**
     * No description
     *
     * @tags patient-tooth-controller
     * @name GetToothConditionRequirements
     * @request GET:/tooth-conditions
     */
    getToothConditionRequirements: (params: RequestParams = {}) =>
      this.request<SuccessResponseListToothConditionVO, ErrorResponse>({
        path: `/tooth-conditions`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  taxonomyCodes = {
    /**
     * No description
     *
     * @tags practice-controller
     * @name GetAllTaxonomyCodes
     * @request GET:/taxonomy-codes
     */
    getAllTaxonomyCodes: (params: RequestParams = {}) =>
      this.request<SuccessResponseListTaxonomyCodeVO, ErrorResponse>({
        path: `/taxonomy-codes`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  security = {
    /**
     * No description
     *
     * @tags security-controller
     * @name GetIpAddress
     * @request GET:/security/ip-address
     */
    getIpAddress: (params: RequestParams = {}) =>
      this.request<SuccessResponseString, ErrorResponse>({
        path: `/security/ip-address`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  procedureShortcuts = {
    /**
     * No description
     *
     * @tags charting-controller
     * @name GetAllProcedureShortcutCategories
     * @request GET:/procedure-shortcuts/categories
     */
    getAllProcedureShortcutCategories: (params: RequestParams = {}) =>
      this.request<SuccessResponseListProcedureShortcutCategoryVO, ErrorResponse>({
        path: `/procedure-shortcuts/categories`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  journey = {
    /**
     * No description
     *
     * @tags comms-controller
     * @name GetPatientEngagementActionEvents
     * @request GET:/journey/action-events
     */
    getPatientEngagementActionEvents: (
      query: {
        type: "APPOINTMENT" | "POST_APPOINTMENT" | "RECALL" | "FORMS";
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponseListActionEventGroupVO, ErrorResponse>({
        path: `/journey/action-events`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  jobTitlesV2 = {
    /**
     * No description
     *
     * @tags employee-controller
     * @name GetEmployeeJobTitlesV2
     * @request GET:/job-titles-v2
     */
    getEmployeeJobTitlesV2: (params: RequestParams = {}) =>
      this.request<SuccessResponseListEmployeeJobTitleV2VO, ErrorResponse>({
        path: `/job-titles-v2`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  employees = {
    /**
     * No description
     *
     * @tags employee-controller
     * @name GetEmployeeJobTitles
     * @request GET:/employees/job-titles
     * @deprecated
     */
    getEmployeeJobTitles: (params: RequestParams = {}) =>
      this.request<SuccessResponseListEmployeeJobTitleVO, ErrorResponse>({
        path: `/employees/job-titles`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  dentalProcedures = {
    /**
     * No description
     *
     * @tags dental-procedure-controller
     * @name GetDentalProcedureCategories
     * @request GET:/dental-procedures/categories
     */
    getDentalProcedureCategories: (params: RequestParams = {}) =>
      this.request<SuccessResponseCollectionString, ErrorResponse>({
        path: `/dental-procedures/categories`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
