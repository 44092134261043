import { ChangeEvent } from "react";
import { cx } from "@libs/utils/cx";
import { ValueTypes } from "@libs/components/UI/OptionInput";
import { ReactComponent as BackIcon } from "@libs/assets/icons/left-caret.svg";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { InternalLink } from "components/UI/Link";
import { ToggleGroup } from "components/UI/ToggleGroup";
import { useResponsiveBreakpoint } from "hooks/useResponsiveBreakpoint";

export const ToggleHeader = <V extends ValueTypes, T extends OptionInputOption<V>>({
  options,
  backTo,
  heightClass = "h-12",
  rightContent,
  onChange,
  disabled = false,
  selectedValue,
}: {
  options: T[];
  backTo: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>, option: T) => void;
  title?: React.ReactNode;
  heightClass?: string;
  children?: React.ReactNode;
  rightContent?: React.ReactNode;
  titleDisclosure?: string;
  selectedValue: V;
  disabled?: boolean;
}) => {
  const { screenMatches } = useResponsiveBreakpoint();

  const largeScreen = screenMatches.has("sm");

  return (
    <>
      <div
        className={cx(
          `shadow-[2px_2px_4px_rgba(188,188,188,0.25)]
           absolute
           top-0
           left-0
           right-0
           z-0
           flex
           flex-col
           justify-center
           bg-white
           px-3`,
          heightClass
        )}
      >
        <div className="items-center flex justify-between">
          <InternalLink className="sm:hidden" to={backTo}>
            <BackIcon />
          </InternalLink>
          <div className="hidden sm:block" />
          <ToggleGroup
            size={largeScreen ? "lg" : "md"}
            className="ml-4"
            disabled={disabled}
            options={options}
            selectedValue={selectedValue}
            onChange={onChange}
          />
          {rightContent ?? <div className="w-6" />}
        </div>
      </div>
      <div className={cx("flex-none", heightClass)} />
    </>
  );
};
