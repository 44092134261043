// Sentry
import { init as sentryInit, reactRouterV6BrowserTracingIntegration } from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import React from "react";

type PossibleEnvValues = "production" | "staging" | "preprod" | "demo" | "development";

const sentrySampleRates: Record<PossibleEnvValues, number> = {
  // TODO: change to lower value, for example `0.2` (20%) when we are live in
  // production with enough clients that we'd have to turn this down. In the
  // meantime we want to see all error since we don't have a lot of traffic, so
  // let's set to 1.
  production: 1,
  staging: 1,
  preprod: 1,
  demo: 1,
  development: 0,
};

interface InitializeSentryOptions {
  appEnv: "production" | "staging" | "preprod" | "demo" | "development";
  dsn: string;
  release: string | undefined;
}

export const initializeSentry = ({ appEnv, dsn, release }: InitializeSentryOptions) => {
  // Per https://docs.sentry.io/platforms/javascript/guides/react/
  sentryInit({
    enabled: sentrySampleRates[appEnv] > 0,
    release,
    environment: appEnv,
    dsn,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    ignoreErrors: ["ResizeObserver loop completed with undelivered notifications."],
    tracesSampleRate: sentrySampleRates[appEnv],
  });
};
