import { LoaderFunctionArgs, Outlet, redirect } from "react-router-dom";
import { parseParams } from "@libs/router/url";
import { SentryRouteErrorFallback } from "components/Main/SentryRouterErrorFallback";
import { MainSignIn } from "components/SignIn/MainSignIn";
import { SignInForgotPassword } from "components/SignIn/SignInForgotPassword";
import { WelcomeLanding } from "components/SignIn/WelcomeLanding";
import { FirstSignIn } from "components/SignIn/FirstSignIn";
import { SelfBookingRoute } from "components/SelfBooking/SelfBookingRoute";
import { PatientFormPreviewRoute } from "components/PatientForms/PatientFormPreview/PatientFormPreviewRoute";
import { PatientFormTasksRoute } from "components/PatientForms/PatientFormTasks/PatientFormTasksRoute";
import { PatientKioskRoute } from "components/PatientForms/PatientKiosk/PatientKioskRoute";
import { GuestCheckoutRoute } from "components/GuestCheckout/GuestCheckoutRoute";
import { EmailPreferencesRoute } from "components/Account/EmailPreferencesRoute";
import { ConfirmAppointmentRoute } from "components/SelfBooking/ConfirmAppointmentRoute";
import { NotFoundRoute } from "components/NotFoundRoute";
import { SignedInApp, loader as signedInLoader } from "components/Main/SignedInApp";
import { SignOutRoute, loader as signOutLoader } from "components/Main/SignOutRoute";
import { HomeRoute } from "components/HomeRoute";
import { AppointmentsRoute } from "components/AppointmentsRoute";
import { InvoicesRoute } from "components/Billing/InvoicesRoute";
import { InvoiceDetailsRoute } from "components/Billing/InvoiceDetailsRoute";
import { ProcessPaymentRoute } from "components/Billing/ProcessPayment/ProcessPaymentRoute";
import { AddPatientInsuranceRoute } from "components/PatientInsurances/AddPatientInsuranceRoute";
import { AddPaymentMethodRoute } from "components/Billing/AddPaymentMethodRoute";
import { OnboardingPages } from "components/Onboarding/OnboardingPages";
import { AppointmentDetailsRoute } from "components/AppointmentDetailsRoute";
import { AccountProfile } from "components/Account/AccountProfile";
import { ChangePasswordRoute } from "components/Account/ChangePasswordRoute";
import { AccountForgotPassword } from "components/Account/AccountForgotPassword";
import { AccountDentalHistoryRoute } from "components/Account/AccountDentalHistoryRoute";
import { AccountMedicalHistoryRoute } from "components/Account/AccountMedicalHistoryRoute";
import { AccountNavigationRoute } from "components/Account/AccountNavigationRoute";
import { PatientInsurancesPage } from "components/PatientInsurances/PatientInsurancesPage";
import { ManagePaymentPage } from "components/Account/ManagePaymentPage";
import { OauthSignInRoute } from "components/SignIn/OauthSignInRoute";
import { PostSignOutRoute } from "components/Main/PostSignOutRoute";
import { ensureSignedOut, redirectAuthUser } from "router/loaders";
import { WaitingRoomRoute, loader as waitingRoomLoader } from "components/SignIn/WaitingRoom";
import { SelectAccountRoute, loader as selectAccountLoader } from "components/Main/SelectAccountRoute";
import { AuthLayout } from "components/Main/AuthLayout";
import { MainRouteTabs } from "components/Main/MainRouteTabs";
import { AccountRoute } from "components/Account/AccountRoute";
import { PatientRouterContext } from "router/types";
import { PatientApiClientProvider } from "components/Main/PatientApiClientProvider";
import { SessionIdleTimerRoute } from "components/Main/SessionIdleTimerRoute";
import { SignedOutLayout } from "components/Main/SignedOutLayout";
import { paths, routesConfig } from "router/paths";

const appendSearch = (path: string, url: string) => {
  const search = new URL(url).search;

  return `${path}${search}`;
};

export const getRoutes = (routerContext: PatientRouterContext) => {
  return [
    {
      element: <Outlet />,
      errorElement: <SentryRouteErrorFallback />,
      children: [
        {
          path: "sign-out",
          element: <SignOutRoute />,
          loader: signOutLoader(routerContext),
        },
        {
          path: "post-sign-out",
          element: <PostSignOutRoute />,
        },
        {
          path: "oauth/sign-in",
          element: <OauthSignInRoute />,
        },

        // Todo - remove these older public routes when the api generated links stop pointing to them
        {
          path: "self-booking/:practiceUuid",
          loader: ({ request, params }: LoaderFunctionArgs) => {
            const { practiceUuid } = parseParams(routesConfig.oldSelfBooking.params, params);
            const newUrl = appendSearch(paths.selfBooking({ practiceUuid }), request.url);

            return redirect(newUrl);
          },
        },
        {
          path: "form-preview/:practiceUuid/:formUuid",
          loader: ({ request, params }: LoaderFunctionArgs) => {
            const { practiceUuid, formUuid } = parseParams(routesConfig.oldFormPreview.params, params);
            const newUrl = appendSearch(paths.previewForm({ practiceUuid, formUuid }), request.url);

            return redirect(newUrl);
          },
        },
        {
          path: "form-tasks/:practiceUuid",
          loader: ({ request, params }: LoaderFunctionArgs) => {
            const { practiceUuid } = parseParams(routesConfig.oldFormTasks.params, params);
            const newUrl = appendSearch(paths.formTasks({ practiceUuid }), request.url);

            return redirect(newUrl);
          },
        },
        {
          path: "kiosk/:practiceUuid",
          loader: ({ request, params }: LoaderFunctionArgs) => {
            const { practiceUuid } = parseParams(routesConfig.oldKiosk.params, params);
            const newUrl = appendSearch(paths.kiosk({ practiceUuid }), request.url);

            return redirect(newUrl);
          },
        },
        {
          element: <PatientApiClientProvider />,
          children: [
            {
              element: <SignedOutLayout />,
              children: [
                {
                  path: "signIn",
                  element: <MainSignIn />,
                  loader: redirectAuthUser(routerContext),
                },
                {
                  path: "signUp",
                  element: <FirstSignIn />,
                  loader: redirectAuthUser(routerContext),
                },
                {
                  path: "forgot-password",
                  element: <SignInForgotPassword />,
                  loader: redirectAuthUser(routerContext),
                },
                {
                  path: "welcome",
                  element: <WelcomeLanding />,
                  loader: ensureSignedOut(routerContext),
                },
              ],
            },
            {
              path: "p/:practiceUuid/*",
              element: <Outlet />,
              loader: ensureSignedOut(routerContext),
              children: [
                {
                  path: "guest-checkout",
                  element: <GuestCheckoutRoute />,
                },
                {
                  path: "self-booking",
                  element: <SelfBookingRoute />,
                },
                {
                  path: "form-preview/:formUuid",
                  element: <PatientFormPreviewRoute />,
                },
                {
                  path: "form-tasks",
                  element: <PatientFormTasksRoute />,
                },
                {
                  path: "kiosk",
                  element: <PatientKioskRoute />,
                },
                {
                  path: "email-preferences/:subscriptionUuid",
                  element: <EmailPreferencesRoute />,
                },
                {
                  path: "confirm-appointment/:confirmToken",
                  element: <ConfirmAppointmentRoute />,
                },
                {
                  path: "*",
                  element: <NotFoundRoute />,
                },
              ],
            },
            {
              element: <SessionIdleTimerRoute />,
              children: [
                {
                  element: <SignedOutLayout />,
                  children: [
                    {
                      path: "waiting-room",
                      element: <WaitingRoomRoute />,
                      loader: waitingRoomLoader(routerContext),
                    },
                  ],
                },
                {
                  path: "select-account",
                  element: <SelectAccountRoute />,
                  loader: selectAccountLoader(routerContext),
                },
                {
                  element: <SignedInApp />,
                  loader: signedInLoader(routerContext),
                  children: [
                    {
                      element: <AuthLayout />,
                      children: [
                        {
                          element: <MainRouteTabs />,
                          children: [
                            {
                              index: true,
                              element: <HomeRoute />,
                            },
                            {
                              element: <AppointmentsRoute />,
                              children: [
                                {
                                  path: "appointments",
                                  element: null,
                                },
                                {
                                  path: "appointments/:appointmentId",
                                  element: <AppointmentDetailsRoute />,
                                },
                              ],
                            },
                            {
                              path: "invoices",
                              element: <InvoicesRoute />,
                            },
                            {
                              element: <AccountNavigationRoute />,
                              children: [
                                {
                                  path: "account",
                                  element: <AccountRoute />,
                                },
                              ],
                            },
                          ],
                        },

                        {
                          path: "invoice-details/:invoiceUuid",
                          element: <InvoiceDetailsRoute />,
                        },
                        {
                          path: "pay",
                          element: <ProcessPaymentRoute />,
                        },
                        {
                          path: "insurances/new",
                          element: <AddPatientInsuranceRoute />,
                        },
                        {
                          element: <AccountNavigationRoute />,
                          children: [
                            { path: "account/profile", element: <AccountProfile /> },
                            { path: "account/change-password", element: <ChangePasswordRoute /> },
                            { path: "account/forgot-password", element: <AccountForgotPassword /> },
                            { path: "account/dental-history", element: <AccountDentalHistoryRoute /> },
                            { path: "account/medical-history", element: <AccountMedicalHistoryRoute /> },
                            {
                              path: "insurances",
                              element: <PatientInsurancesPage />,
                            },
                            {
                              path: "payment-methods",
                              element: <ManagePaymentPage />,
                            },
                          ],
                        },
                        {
                          path: "payment-methods/new",
                          element: <AddPaymentMethodRoute />,
                        },
                        {
                          path: "*",
                          element: <NotFoundRoute />,
                        },
                      ],
                    },
                    {
                      path: "onboarding",
                      element: <OnboardingPages />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};
