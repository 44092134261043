import React from "react";
import { SingleValue } from "react-select";
import { useTranslation } from "react-i18next";
import { PersonalDetailsVO } from "@libs/api/generated-api";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";

export const useGenderOptions = () => {
  const { t } = useTranslation();
  const genderOptions = React.useMemo(() => {
    return [
      { value: "MALE" as const, label: t("Male") },
      { value: "FEMALE" as const, label: t("Female") },
      { value: "OTHER" as const, label: t("Other") },
      { value: "UNKNOWN" as const, label: t("Unknown") },
    ];
  }, [t]);

  return genderOptions;
};
export const FormFieldSelectGender: React.FC<
  FormFieldProps & {
    value: PersonalDetailsVO["gender"];
    placeholder?: string;
    menuPlacement?: "auto" | "top" | "bottom";
    onChange: (
      option: SingleValue<{
        label: string;
        value: NonNullable<PersonalDetailsVO["gender"]>;
      }>
    ) => void;
  }
> = ({
  layout,
  label,
  value,
  edit = true,
  onChange,
  menuPlacement = "auto",
  placeholder,
  required,
  error,
}) => {
  return (
    <FormFieldSelect
      layout={layout}
      label={label}
      display="label"
      edit={edit}
      required={required}
      value={value}
      error={error}
      menuPlacement={menuPlacement}
      placeholder={placeholder}
      options={[
        { label: "Female", value: "FEMALE" },
        { label: "Male", value: "MALE" },
        { label: "Unknown", value: "UNKNOWN" },
        { label: "Other", value: "OTHER" },
      ]}
      onChange={onChange}
    />
  );
};
