import React from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { pdf } from "@react-pdf/renderer";
import { PersonalDetailsVO } from "@libs/api/generated-api";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadedPatientForm } from "components/PatientForms/LoadedPatientForm";
import { getFormPreviewQuery } from "api/forms/queries";
import { getPracticeInfoByUuid } from "api/user/queries";
import { PatientFormPreviewHeader } from "components/PatientForms/PatientFormPreview/PatientFormPreviewHeader";
import { defaultSnackbarOptions } from "utils/snackbar";
import { PatientResponses } from "components/PatientForms/hooks/usePatientResponses";
import { PatientFormPdf } from "components/PatientForms/FormPDFElements/PatientFormPdf";
import { FormTaskChrome } from "components/PatientForms/PatientFormTasks/FormTaskChrome";
import { getPracticeLogoWithDimensions } from "components/PatientForms/FormPDFElements/utils/getPracticeLogo";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { PublicPracticeInstrumentation } from "components/Main/PublicPracticeInstrumentation";

export const PatientFormPreviewRoute = () => {
  const { t } = useTranslation();
  const [mockResponses, updateMockResponses] = useObjectState<PatientResponses>({});
  const params = usePathParams("previewForm");
  const { query } = useQueryParams("previewForm");

  const age = query.age;
  const gender = query.gender;
  const firstName = query.firstName;
  const lastName = query.lastName ?? "";

  const { practiceUuid, formUuid } = params;

  if (!practiceUuid) {
    throw new Error("Practice uuid required to preview");
  }

  if (!formUuid) {
    throw new Error("uuidOrSlug required to preview");
  }

  const [previewFormQuery, practiceInfoQuery] = useApiQueries([
    getFormPreviewQuery({
      args: {
        practiceUuid,
        uuid: formUuid,
      },
    }),
    getPracticeInfoByUuid({
      args: {
        practiceUuid,
      },
    }),
  ]);
  const { data: formData } = previewFormQuery;
  const { data: practiceData } = practiceInfoQuery;
  const [patientAttributes, updatePatientAttributes] = useObjectState<PersonalDetailsVO>({
    firstName: firstName ?? "[Patient Name]",
    lastName: firstName ? lastName : "",
    dob: "0000-01-01",
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    age: age ?? 18,
    gender: gender ?? "MALE",
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSuccessfulSubmission = React.useCallback(() => {
    enqueueSnackbar(t("pages.formPreview.validationSuccess"), defaultSnackbarOptions);
  }, [enqueueSnackbar, t]);
  const handlePdfClick = React.useCallback(async () => {
    if (practiceData && formData) {
      // const logo = await getPracticeLogoBase64(practiceData);
      const logo = await getPracticeLogoWithDimensions(practiceData);

      const formPdf = await pdf(
        <PatientFormPdf
          practice={practiceData}
          patientPersonalDetails={patientAttributes}
          formData={formData}
          responses={mockResponses}
          logo={logo}
        />
      ).toBlob();
      const url = window.URL.createObjectURL(formPdf);

      window.open(url);

      // Clean up memory.
      window.URL.revokeObjectURL(url);
    }
  }, [formData, mockResponses, patientAttributes, practiceData]);

  return (
    <QueryResult queries={[previewFormQuery]}>
      {formData && practiceData && (
        <>
          <PublicPracticeInstrumentation practice={practiceData} />
          <PatientFormPreviewHeader
            onClickPdf={handlePdfClick}
            patientAttributes={patientAttributes}
            onChangePatientAttributes={updatePatientAttributes}
            formData={formData}
          />
          <div className="relative min-h-0">
            <FormTaskChrome practice={practiceData}>
              <LoadedPatientForm
                formData={formData}
                patientAttributes={patientAttributes}
                responses={mockResponses}
                practice={practiceData}
                uuidOrSlug={formUuid}
                edit
                fixedFooter
                onSubmit={handleSuccessfulSubmission}
                showInWizard={false}
                onChangeResponses={updateMockResponses}
              />
            </FormTaskChrome>
          </div>
        </>
      )}
    </QueryResult>
  );
};
