import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useValidation } from "@libs/hooks/useValidation";
import { eq, password, required } from "@libs/utils/validators";
import { useObjectState } from "@libs/hooks/useObjectState";
import { CognitoErrorCode, isCognitoErrorCode } from "@libs/utils/cognito";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { TitleBar } from "components/UI/TitleBar";
import { EditButton } from "components/Account/EditButton";
import { FormFieldPassword } from "components/UI/FormFieldPassword";
import { StickySubmissionFooter } from "components/UI/StickySubmissionFooter";
import { useHandleError } from "api/handleErrorResponse";
import { PasswordGuidelines } from "components/SignIn/PasswordGuidelines";
import { ResponsiveActionModal } from "components/UI/ResponsiveActionModal";
import { semibold16 } from "assets/styles/textSize";
import { paths } from "router/paths";

const cxStyles = {
  content: "max-w-2xl flex flex-col gap-5 h-full min-h-0 px-4 text-sm",
};
const ReadOnlyPassword: React.FC<{ onClickEdit: Func }> = ({ onClickEdit }) => {
  const { t } = useTranslation();

  return (
    <div className={cxStyles.content}>
      <div className="flex justify-between">
        <FormFieldInput
          layout="labelOut"
          label={t("Current Password")}
          id="current-password"
          value="•••••••"
          edit={false}
        />
        <EditButton onClick={onClickEdit} />
      </div>
      <div className="italic text-xs">{t("app.page.labels.account.changePasswordText")}</div>
    </div>
  );
};

type EditPasswordDraft = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmed: string;
};

const usePasswordValidationSchema = (draft: EditPasswordDraft) => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      currentPassword: [{ $v: required, $error: t("required") }],
      newPassword: [
        { $v: required, $error: t("required") },
        { $v: password, $error: t("app.pages.messages.passwordDidNotConfirmPolicy") },
      ],
      newPasswordConfirmed: [
        { $v: eq(draft.newPassword), $error: t("app.pages.messages.passwordConfirmPasswordMismatch") },
      ],
    }),
    [draft.newPassword, t]
  );
};
const EditPassword: React.FC<{ onDone: Func; onCancel: Func }> = ({ onDone, onCancel }) => {
  const { t } = useTranslation();
  const [editPasswordDraft, handleDraftUpdate] = useObjectState<EditPasswordDraft>({
    currentPassword: "",
    newPassword: "",
    newPasswordConfirmed: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const handleError = useHandleError();
  const [amplifyErrorMessage, setAmplifyErrorMessage] = useState<string | undefined>();
  const passwordUpdateSchema = usePasswordValidationSchema(editPasswordDraft);
  const validation = useValidation(editPasswordDraft, passwordUpdateSchema);
  const handleSubmit = useCallback(
    async (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (validation.validate().$isValid) {
        setIsLoading(true);

        try {
          const currentUser = (await Auth.currentAuthenticatedUser()) as CognitoUser;

          await Auth.changePassword(
            currentUser,
            editPasswordDraft.currentPassword,
            editPasswordDraft.newPassword
          );
          onDone();
        } catch (err) {
          const cognitoError = err as { code?: CognitoErrorCode };

          if (cognitoError.code && isCognitoErrorCode(cognitoError.code)) {
            if (cognitoError.code === "NotAuthorizedException") {
              setAmplifyErrorMessage(t(`cognito.passwordUpdate.${cognitoError.code}`));
            } else {
              setAmplifyErrorMessage(t(`cognito.${cognitoError.code}`));
            }
          } else {
            handleError(err);
          }
        }

        setIsLoading(false);
      }
    },
    [editPasswordDraft.currentPassword, editPasswordDraft.newPassword, handleError, onDone, t, validation]
  );

  return (
    <>
      <Form id="update-password-form" className={cxStyles.content} onSubmit={handleSubmit}>
        {t("app.page.labels.account.changePasswordEnterCurrentPasswordText")}
        <div className="flex flex-col items-start gap-2">
          <div className="w-full">
            <FormFieldPassword
              id="current-password"
              label={t("Current Password")}
              value={editPasswordDraft.currentPassword}
              layout="labelOut"
              placeholder={t("Enter password")}
              error={validation.result.currentPassword.$error ?? amplifyErrorMessage}
              onChange={(e) => {
                setAmplifyErrorMessage(undefined);
                handleDraftUpdate({ currentPassword: e.target.value });
              }}
            />
          </div>
          <Link to={paths.forgotPassword()} className="text-primaryTheme text-xs font-sansSemiBold">
            {t("Forgot Password?")}
          </Link>
        </div>

        <PasswordGuidelines />

        <FormFieldPassword
          id="new-password"
          label={t("New Password")}
          value={editPasswordDraft.newPassword}
          layout="labelOut"
          placeholder={t("Enter password")}
          error={validation.result.newPassword.$error}
          onChange={(e) => {
            handleDraftUpdate({ newPassword: e.target.value });
          }}
        />
        <FormFieldPassword
          id="new-password-confirmed"
          label={t("Confirm New Password")}
          value={editPasswordDraft.newPasswordConfirmed}
          layout="labelOut"
          placeholder={t("Enter password")}
          error={validation.result.newPasswordConfirmed.$error}
          onChange={(e) => handleDraftUpdate({ newPasswordConfirmed: e.target.value })}
        />
      </Form>
      <StickySubmissionFooter
        secondaryText={t("Cancel")}
        primaryText={t("Save")}
        onClickSecondary={onCancel}
        form="update-password-form"
        isSubmitting={isLoading}
        className="sticky"
      />
    </>
  );
};

export const ChangePasswordRoute: React.FC = () => {
  const { t } = useTranslation();
  const editing = useBoolean(false);
  const modalOpen = useBoolean(false);
  const modalOn = modalOpen.on;
  const turnOffEditing = editing.off;

  const handleEditingComplete = useCallback(() => {
    turnOffEditing();
    modalOn();
  }, [modalOn, turnOffEditing]);

  return (
    <div className="relative flex items-center flex-col gap-5 h-full min-h-0">
      <TitleBar responsiveBackButton backTo={paths.account()} title={t("Change Password")} />
      {editing.isOn ? (
        <EditPassword onDone={handleEditingComplete} onCancel={editing.off} />
      ) : (
        <ReadOnlyPassword onClickEdit={editing.on} />
      )}

      {modalOpen.isOn && (
        <ResponsiveActionModal
          onClose={modalOpen.off}
          title={<div className={semibold16}>{t("app.pages.messages.passwordUpdated")}</div>}
          text={t("app.pages.messages.passwordChangedSigninWithNewPassword")}
          primaryButtonLabel="Go Back"
          onClickPrimaryButton={() => {
            modalOpen.off();

            return Promise.resolve();
          }}
        />
      )}
    </div>
  );
};
