import { createContext, useContext, useMemo } from "react";
import { FormFieldLayout } from "@libs/types/form";

export interface FormContextValue {
  fieldLayout: FormFieldLayout;
}

const defaultValue: FormContextValue = { fieldLayout: "labelIn" };
const Context = createContext<FormContextValue>(defaultValue);

Context.displayName = "FormContext";
export const FormContext = Context;
export const useFormContext = () => useContext(FormContext);
export const useMergeFormContext = (context: FormContextValue, fieldProps: { layout?: FormFieldLayout }) => {
  return useMemo(
    () => ({
      layout: fieldProps.layout || context.fieldLayout,
    }),
    [fieldProps.layout, context]
  );
};
