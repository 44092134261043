import { PersonalDetailsVO } from "@libs/api/generated-api";
import {
  BoolVal,
  Enum,
  NumVal,
  StrCSV,
  StrVal,
  compileRoutesConfig,
  routesConfigHelper,
} from "@libs/router/url";
import { email } from "@libs/utils/validators";
import { SignOutReason } from "router/types";

const EmailVal = {
  get: (val: string) => (email(val) ? val : undefined),
  set: (val: string) => val,
  type: "string",
};

const signOutReason = Enum<SignOutReason>().Val([
  "ISSUE_TOKEN_ERROR",
  "GET_USER_ACCOUNTS_ERROR",
  "UNAUTHORIZED",
  "SESSION_EXPIRED",
  "NO_CURRENT_USER",
  "IDENTITY_EMAIL_NOT_FOUND",
]);

export const routesConfig = routesConfigHelper({
  home: {
    path: "/",
  },
  signIn: {
    path: "/signIn",
    query: {
      email: EmailVal,
      returnUrl: StrVal,
      lastUserId: NumVal,
      signOutReason,
    },
  },
  welcome: {
    path: "/welcome",
    query: {
      uuid: StrVal,
      email: EmailVal,
    },
  },
  selectAccount: {
    path: "/select-account",
    query: {
      returnUrl: StrVal,
      lastUserId: NumVal,
    },
  },
  forgotPasswordSignedOut: {
    path: "/forgot-password",
  },
  signUp: {
    path: "/signUp",
    query: {
      email: EmailVal,
    },
  },
  waitingRoom: {
    path: "/waiting-room",
  },
  signOut: {
    path: "/sign-out",
    query: {
      returnUrl: StrVal,
      to: StrVal,
      signOutReason,
    },
  },
  postSignOut: {
    path: "/post-sign-out",
  },
  appointments: {
    path: "/appointments",
  },
  appointmentDetails: {
    path: "/appointments/:appointmentId",
    params: {
      appointmentId: NumVal,
    },
    query: {
      fullScreen: BoolVal,
      from: StrVal,
    },
  },
  invoices: {
    path: "/invoices",
    query: {
      tab: Enum<"pending" | "completed" | "statements">().Val(["pending", "completed", "statements"]),
    },
  },
  invoiceDetails: {
    path: "/invoice-details/:invoiceUuid",
    params: {
      invoiceUuid: StrVal,
    },
    query: {
      from: StrVal,
    },
  },
  pay: {
    path: "/pay",
    query: {
      invoiceUuids: StrCSV,
      from: StrVal,
    },
  },
  account: {
    path: "/account",
  },
  changePassword: {
    path: "/account/change-password",
  },
  forgotPassword: {
    path: "/account/forgot-password",
  },
  medicalHistory: {
    path: "/account/medical-history",
  },
  dentalHistory: {
    path: "/account/dental-history",
  },
  insurances: {
    path: "/insurances",
  },
  profile: {
    path: "/account/profile",
    query: {
      form: Enum<"personal" | "contact">().Val(["personal", "contact"]),
      edit: BoolVal,
    },
  },
  addInsurance: {
    path: "/insurances/new",
  },
  paymentMethods: {
    path: "/payment-methods",
  },
  onboarding: {
    path: "/onboarding",
  },
  selfBooking: {
    path: "/p/:practiceUuid/self-booking",
    params: {
      practiceUuid: StrVal,
    },
    query: {
      messageCampaignUuid: StrVal,
      allowSignedIn: BoolVal,
    },
  },
  addPaymentMethod: {
    path: "/payment-methods/new",
    query: {
      successUrl: StrVal,
      from: StrVal,
    },
  },
  emailPreferences: {
    path: "/p/:practiceUuid/email-preferences/:subscriptionUuid",
    params: {
      practiceUuid: StrVal,
      subscriptionUuid: StrVal,
    },
  },
  guestCheckout: {
    path: "/p/:practiceUuid/guest-checkout",
    params: {
      practiceUuid: StrVal,
    },
    query: {
      patientToken: StrVal,
    },
  },
  previewForm: {
    path: "/p/:practiceUuid/form-preview/:formUuid",
    params: {
      practiceUuid: StrVal,
      formUuid: StrVal,
    },
    query: {
      age: NumVal,
      gender: Enum<NonNullable<PersonalDetailsVO["gender"]>>().Val(["MALE", "FEMALE", "OTHER", "UNKNOWN"]),
      firstName: StrVal,
      lastName: StrVal,
    },
  },
  formTasks: {
    path: "/p/:practiceUuid/form-tasks",
    params: {
      practiceUuid: StrVal,
    },
    query: {
      formTaskUuids: StrCSV,
      dob: StrVal,
      print: StrVal,
    },
  },
  kiosk: {
    path: "/p/:practiceUuid/kiosk",
    params: {
      practiceUuid: StrVal,
    },
  },
  confirmAppointment: {
    path: "/p/:practiceUuid/confirm-appointment/:confirmToken",
    params: {
      practiceUuid: StrVal,
      confirmToken: StrVal,
    },
  },
  oldSelfBooking: {
    path: "/self-booking/:practiceUuid",
    params: {
      practiceUuid: StrVal,
    },
    query: {
      messageCampaignUuid: StrVal,
    },
  },
  oldFormPreview: {
    path: "/form-preview/:practiceUuid/:formUuid",
    params: {
      practiceUuid: StrVal,
      formUuid: StrVal,
    },
  },
  oldFormTasks: {
    path: "/form-tasks/:practiceUuid",
    params: {
      practiceUuid: StrVal,
    },
  },
  oldKiosk: {
    path: "/kiosk/:practiceUuid",
    params: {
      practiceUuid: StrVal,
    },
  },
});
export const paths = compileRoutesConfig(routesConfig);

export type RoutesConfig = typeof routesConfig;

export const getRedirectToSignedInUrl = (
  currentUserId: number,
  lastUserId: number | undefined,
  returnUrl?: string | null
) => {
  const hasSignedInAsDifferentUser = Boolean(lastUserId && lastUserId !== currentUserId);

  return hasSignedInAsDifferentUser ? paths.home() : returnUrl ?? paths.home();
};
