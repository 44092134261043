import { ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";

interface Props {
  layout?: "horiz" | "vert";
  children?: ReactNode;
}

export const ErrorContent: React.FC<Props> = ({ children, layout = "vert" }) => {
  return (
    <div
      className={cx(
        "flex items-center justify-center p-2",
        layout === "vert" && "flex-col min-h-[inherit] h-full"
      )}
    >
      <div>
        <ErrorIcon className="text-red w-5 h-5" />
      </div>
      <div className={cx("text-xs", layout === "vert" ? "mt-2" : "ml-2")}>
        {children || "Sorry, something went wrong. Please try again shortly."}
      </div>
    </div>
  );
};
