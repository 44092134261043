import React from "react";
import { useTranslation } from "react-i18next";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { getPatientInsurancesQuery } from "api/patientInsurance/queries";
import { TitleBar } from "components/UI/TitleBar";
import { InsurancesList } from "components/PatientInsurances/InsurancesList";
import { AddButton } from "components/UI/AddButton";
import { paths } from "router/paths";

export const PatientInsurancesPage: React.FC = () => {
  const { t } = useTranslation();
  const { id: patientId, practiceId } = useAccount();

  const [insurancesQuery] = useApiQueries([getPatientInsurancesQuery({ args: { patientId, practiceId } })]);
  const { data: insurances, isLoading } = insurancesQuery;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const canAddInsurance = (insurances?.length ?? 0) < 2;

  return (
    <div className="relative flex flex-col h-full min-h-0">
      <TitleBar responsiveBackButton backTo={paths.account()} title={t("Insurance Information")} />
      <div className="p-4 divide-y divide-y-greyLighter overflow-y-auto">
        <InsurancesList query={insurancesQuery} />
        {!isLoading && canAddInsurance && (
          <div>
            <AddButton to={paths.addInsurance()}>{t("Add Insurance")}</AddButton>
          </div>
        )}
      </div>
    </div>
  );
};
