import { ButtonHTMLAttributes, forwardRef } from "react";
import { cx } from "@libs/utils/cx";
import { Spinner } from "@libs/components/UI/Spinner";
import { Button, ButtonProps } from "@libs/components/UI/Button";

export type AsyncButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonProps & {
    displayLoadingText?: boolean;
    isLoading: boolean;
  };

export const AsyncButton = forwardRef<HTMLButtonElement, AsyncButtonProps>(
  // eslint-disable-next-line complexity
  ({ isLoading, displayLoadingText = false, children, className, ...props }, ref) => {
    return (
      <Button
        inactive={isLoading}
        ref={ref}
        className={cx(displayLoadingText ? "flex items-center justify-center" : "relative", className)}
        {...props}
      >
        <span
          className={cx(
            !displayLoadingText && "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2",
            isLoading ? "block" : "hidden"
          )}
        >
          <Spinner
            className={cx(isLoading && displayLoadingText && "mr-1.5")}
            variant={
              props.theme === "secondary" || props.theme === "link" || props.theme === "tertiary"
                ? "primary"
                : "light"
            }
            animation="border"
            size={props.size === "small" ? "xs" : "sm"}
            layout="block"
          />
        </span>

        <span className={cx("contents", isLoading && !displayLoadingText && "invisible")}>{children}</span>
      </Button>
    );
  }
);
