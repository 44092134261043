import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { SelfBookingAppointmentRequest } from "@libs/api/generated-api";
import { formatPhoneNumber } from "@libs/utils/phone";
import { formatISOTimeAsAmPm, formatLongDayOfMonth, getLocalDate } from "@libs/utils/date";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { useHandleError } from "api/handleErrorResponse";
import { bookAppointment } from "api/self-booking/mutations";
import { getSelfBookingConfig } from "api/self-booking/queries";
import { getPracticeInfoByUuid } from "api/user/queries";
import { SelfBookingStep1 } from "components/SelfBooking/SelfBookingStep1";
import { SelfBookingStep2 } from "components/SelfBooking/SelfBookingStep2";
import { SelfBookingStep3 } from "components/SelfBooking/SelfBookingStep3";
import { useSelfBookingState } from "components/SelfBooking/useSelfBookingState";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { PublicPracticeInstrumentation } from "components/Main/PublicPracticeInstrumentation";

// eslint-disable-next-line complexity
export const SelfBookingRoute: React.FC = () => {
  const handleError = useHandleError();
  const { t } = useTranslation();
  const { practiceUuid: uuid } = usePathParams("selfBooking");
  const { query } = useQueryParams("selfBooking");
  const [step, setStep] = useState("1");
  const [confirmInfo, setConfirmInfo] = useState<{ day: string; time: string; isScheduled: boolean }>();

  const selfBookingState = useSelfBookingState();

  const [bookAppointmentMutation] = useApiMutations([bookAppointment]);

  // It's gauranteed if we are on this route.

  const [practiceQuery, selfBookableConfigQuery] = useApiQueries([
    getPracticeInfoByUuid({
      args: {
        practiceUuid: uuid,
      },
    }),
    getSelfBookingConfig({
      args: {
        practiceUuid: uuid,
      },
    }),
  ]);

  const handleBookAppointment = async (request: SelfBookingAppointmentRequest) => {
    try {
      await bookAppointmentMutation.mutateAsync({
        practiceUuid: uuid,
        data: {
          ...request,
          messageCampaignUuid: query.messageCampaignUuid,
        },
      });

      const category = selfBookableConfigQuery.data?.appointmentCategories.find(
        (cat) => cat.id === request.appointmentCategoryId
      );

      setConfirmInfo({
        day: formatLongDayOfMonth(getLocalDate(request.date)),
        time: formatISOTimeAsAmPm(request.startTime),
        isScheduled: category?.selfBookingConfig?.bookingType === "SCHEDULE",
      });
      setStep("4");
      selfBookingState.resetState();
    } catch (e) {
      handleError(e);
    }
  };

  const isEnabled =
    selfBookableConfigQuery.data &&
    selfBookableConfigQuery.data.enabled &&
    selfBookableConfigQuery.data.appointmentCategories.length > 0;

  const phoneNumber = practiceQuery.data?.phoneNumber || "";

  return (
    <div className="flex flex-col h-full items-center bg-white overflow-y-auto">
      <QueryResult
        queries={[practiceQuery, selfBookableConfigQuery]}
        loading={
          <div className="w-full h-full">
            <Skeleton className="w-full h-full p-6" />
          </div>
        }
      >
        {practiceQuery.data && selfBookableConfigQuery.data ? (
          <>
            <PublicPracticeInstrumentation practice={practiceQuery.data} />
            {isEnabled ? (
              <>
                {(!step || step === "1") && (
                  <SelfBookingStep1
                    practiceInfo={practiceQuery.data}
                    config={selfBookableConfigQuery.data}
                    onNextStep={() => setStep("2")}
                    {...selfBookingState}
                  />
                )}
                {step === "2" && (
                  <SelfBookingStep2
                    practiceInfo={practiceQuery.data}
                    config={selfBookableConfigQuery.data}
                    onNextStep={() => setStep("3")}
                    {...selfBookingState}
                  />
                )}
                {step === "3" && (
                  <SelfBookingStep3
                    practiceInfo={practiceQuery.data}
                    config={selfBookableConfigQuery.data}
                    onNextStep={handleBookAppointment}
                    onStartOver={() => setStep("1")}
                    {...selfBookingState}
                  />
                )}
                {step === "4" && (
                  <AnonUserMessagePage
                    homeUrl={practiceQuery.data.website}
                    logoUrl={practiceQuery.data.logo?.url}
                    title={t("selfBooking.completeTitle")}
                    subTitle={
                      <div className="text-center">
                        {t("selfBooking.completeText", {
                          type: confirmInfo?.isScheduled
                            ? t("selfBooking.scheduled")
                            : t("selfBooking.requested"),
                          day: confirmInfo?.day ?? "sdf",
                          time: confirmInfo?.time ?? "",
                        })}
                      </div>
                    }
                  />
                )}
              </>
            ) : (
              <AnonUserMessagePage
                homeUrl={practiceQuery.data.website}
                logoUrl={practiceQuery.data.logo?.url}
                title={t("selfBooking.unavailableTitle")}
                subTitle={
                  <div className="text-center">
                    {t("selfBooking.unavailableText")}{" "}
                    <a href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a>
                  </div>
                }
              />
            )}
          </>
        ) : null}
      </QueryResult>
    </div>
  );
};
