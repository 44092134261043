import { FormHTMLAttributes, forwardRef, useMemo } from "react";
import { FormContext, FormContextValue } from "@libs/contexts/FormContext";
import { FormFieldLayout } from "@libs/types/form";

export type FormProps = Omit<FormHTMLAttributes<HTMLFormElement>, "action" | "method"> & {
  fieldLayout?: FormFieldLayout;
};

export const Form = forwardRef<HTMLFormElement, FormProps>(({ fieldLayout, ...props }, ref) => {
  const value: FormContextValue = useMemo(() => ({ fieldLayout: fieldLayout || "labelOut" }), [fieldLayout]);

  return (
    <FormContext.Provider value={value}>
      {/*  eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <form ref={ref} {...props} action="/" method="post" noValidate role="form" />
    </FormContext.Provider>
  );
});
