import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { FormVO, PracticeInfoVO } from "@libs/api/generated-api";
import { Form } from "@libs/components/UI/Form";
import { UUIDOrSlug } from "api/forms/queries";
import { PatientFormPage } from "components/PatientForms/PatientFormPage";
import { PatientResponses, usePatientResponses } from "components/PatientForms/hooks/usePatientResponses";
import { usePatientFormValidation } from "components/PatientForms/hooks/usePatientFormValidation";
import { StickySubmissionFooter } from "components/UI/StickySubmissionFooter";
import { defaultSnackbarOptions } from "utils/snackbar";
import { useFilteredForm } from "components/PatientForms/hooks/useFilteredForm";
import { useNavigationBlocker } from "hooks/useNavigationBlocker";
import {
  FormInputElement,
  isInputElement,
  isInputResponsePresent,
  PatientAttributes,
} from "components/PatientForms/utils";

type Props = {
  patientAttributes: PatientAttributes;
  formData: FormVO;
  edit?: boolean;
  responses: PatientResponses;
  uuidOrSlug: UUIDOrSlug;
  onPrevious?: Func;
  onSubmit: (submission: { responsesById: PatientResponses; formPublishedContentUuid: string }) => void;
  isSubmitting?: boolean;
  fixedFooter?: boolean;
  reconsent?: boolean;
  onChangeResponses?: (updated: PatientResponses) => void;
  showInWizard?: boolean;

  warnWhenNavigating?: boolean;

  // For purposes of testing pdf viewer:
  practice?: PracticeInfoVO;
};

const sanitizeResponses = (form: FormVO, responses: PatientResponses) => {
  const sanitizedResponses: PatientResponses = { ...responses };
  const sanitizeConditionalElement = (elem: FormInputElement) => {
    if (elem.type === "BOOLEAN_INPUT" && elem.conditionalElement) {
      // The server will reject "" when submitting answers that have been cleared out for conditional elements
      // this cleans up the responses to avoid that.

      // We don't need to consider whether the conditional is answered yes/no, because validation
      // will catch the case where they answer "yes" but have an empty response
      const key = elem.conditionalElement.uuid;
      const submission = sanitizedResponses[key];

      if (!isInputResponsePresent(submission)) {
        delete sanitizedResponses[key];
      }
    }
  };

  for (const page of form.content) {
    for (const element of page.content) {
      if (isInputElement(element)) {
        sanitizeConditionalElement(element);
      } else if (element.type === "SECTION") {
        const content = element.content;

        for (const sectionElement of content) {
          // eslint-disable-next-line max-depth
          if (isInputElement(sectionElement)) {
            sanitizeConditionalElement(sectionElement);
          }
        }
      }
    }
  }

  return sanitizedResponses;
};

export const LoadedPatientForm: React.FC<Props> = ({
  formData,
  patientAttributes,
  edit = false,
  uuidOrSlug,
  onSubmit,
  responses,
  onPrevious,
  fixedFooter,
  onChangeResponses,
  showInWizard = true,
  reconsent,
  isSubmitting,
  warnWhenNavigating,
}) => {
  const matchedForm = useFilteredForm({
    edit,
    responses,
    form: formData,
    patientAttributes,
  });
  const { age, gender } = patientAttributes;
  const initialDemographics = React.useRef({ age, gender });

  const { responsesById, responsesDirty, handleResponseChanged, clearResponses } = usePatientResponses({
    latestForm: matchedForm,
    latestResponses: responses,
    onChangeResponses,
    reconsent,
  });

  const { validate, result, reset } = usePatientFormValidation({
    responsesById,
    pages: matchedForm.content,
    edit,
  });

  React.useEffect(() => {
    if (initialDemographics.current.age !== age || initialDemographics.current.gender !== gender) {
      initialDemographics.current = { age, gender };
      reset();
      clearResponses();
    }
  }, [age, gender, clearResponses, reset]);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const formPublishedContentUuid = matchedForm.publishedContentUuid ?? "";
  const handleSubmit = React.useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (validate().isValid) {
        onSubmit({ responsesById: sanitizeResponses(matchedForm, responsesById), formPublishedContentUuid });
      } else {
        enqueueSnackbar(t("onboard.validation.error"), defaultSnackbarOptions);
      }
    },
    [enqueueSnackbar, formPublishedContentUuid, matchedForm, onSubmit, responsesById, t, validate]
  );

  useNavigationBlocker({ when: edit && warnWhenNavigating && !isSubmitting && responsesDirty });

  const patientFormId = `patient-form-${uuidOrSlug}`;

  // For testing pdf rendering:
  // if (practice) {
  //   return (
  //     <PDFFormTester
  //       formData={matchedForm}
  //       responses={responses}
  //       patientAttributes={patientAttributes}
  //       practice={practice}
  //     />
  //   );
  // }

  return (
    <>
      <Form id={patientFormId} className="flex flex-col gap-6 p-4" onSubmit={handleSubmit}>
        {matchedForm.content.map((item) => {
          return (
            <PatientFormPage
              edit={edit}
              key={item.uuid}
              page={item}
              validation={result}
              onChangeResponse={handleResponseChanged}
              responsesById={responsesById}
            />
          );
        })}
      </Form>

      {edit && (
        <>
          <div className="h-20" />

          <StickySubmissionFooter
            form={patientFormId}
            secondaryText={showInWizard ? t("Previous") : t("Cancel")}
            primaryText={showInWizard ? t("registration.save.next") : t("Submit")}
            onClickSecondary={onPrevious}
            isSubmitting={isSubmitting}
            className={fixedFooter ? "fixed" : "sticky"}
          />
        </>
      )}
    </>
  );
};
