import React from "react";
import { useTranslation } from "react-i18next";
import { InsuranceCarrierVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";
import { getInsuranceCarrierNames } from "api/patientInsurance/queries";

type Props = {
  value?: number;
  label?: string;
  required?: boolean;
  error?: string;
  onChange: (carrier: InsuranceCarrierVO) => void;
  id: string;
};
export const SelectCarrier: React.FC<Props> = ({ onChange, value, label, required, error, id }) => {
  const { uuid: practiceUuid } = useCurrentPractice();
  const { t } = useTranslation();
  const [{ data: carriers, isLoading }] = useApiQueries([
    getInsuranceCarrierNames({ args: { practiceUuid } }),
  ]);
  const options = React.useMemo(
    () => carriers?.map((item) => ({ value: item.id, label: item.name })) ?? [],
    [carriers]
  );
  const onSelectCarrier = React.useCallback(
    (option: { label: string; value: number } | null) => {
      if (option) {
        const carrier = carriers?.find((item) => item.id === option.value);

        if (carrier) {
          onChange(carrier);
        }
      }
    },
    [carriers, onChange]
  );

  return (
    <FormFieldSelect
      error={error}
      id={id}
      options={options}
      loading={isLoading}
      label={label}
      placeholder={t("Enter Insurance Carrier")}
      required={required}
      value={value}
      onChange={onSelectCarrier}
      isSearchable
    />
  );
};
