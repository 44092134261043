/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Trans, useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { PracticeInfoVO, SelfBookingAppointmentRequest, SelfBookingConfigVO } from "@libs/api/generated-api";
import { required, phoneNumber, email } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { formatAsISODate, formatISOTimeAsAmPm, formatLongDayOfMonth, getLocalDate } from "@libs/utils/date";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { FormSection } from "components/UI/FormSection";
import { FormSectionTitle } from "components/UI/FormSectionTitle";
import { FormFieldDatePicker } from "components/UI/FormFieldDatePicker";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";
import { selfBookingCxStyles } from "components/SelfBooking/utils";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { PoweredBy } from "components/UI/PoweredBy";
import { AnonPatientHeader } from "components/UI/AnonPatientHeader";
import { FootnoteLink } from "components/UI/FootnoteLink";
import { SelfBookingV1 } from "storage/selfBooking";

export const SelfBookingStep3: React.FC<{
  practiceInfo: PracticeInfoVO;
  config: SelfBookingConfigVO;
  onNextStep: (request: SelfBookingAppointmentRequest) => Promise<void>;
  onStartOver: () => void;
  state: Partial<SelfBookingV1>;
  setState: (updates: Partial<SelfBookingV1>) => void;
}> = ({ practiceInfo, config, onNextStep, onStartOver, state, setState }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const schema = useMemo(() => {
    const requiredError = t("required");

    return {
      firstName: [{ $v: required, $error: requiredError }],
      lastName: [{ $v: required, $error: requiredError }],
      dob: [{ $v: required, $error: requiredError }],
      email: [
        { $v: required, $error: requiredError },
        {
          $v: email,
          $error: t("validation.email"),
        },
      ],
      phone: [
        { $v: required, $error: requiredError },
        {
          $v: phoneNumber,
          $error: t("validation.phone"),
        },
      ],
    };
  }, [t]);

  const { result, validate } = useValidation(state, schema);

  const handleNextStep = async () => {
    if (validate().$isValid) {
      try {
        setIsLoading(true);
        await onNextStep({
          appointmentCategoryId: state.categoryId!,
          date: state.isoDate!,
          providerId: state.providerId!,
          asap: state.seenSooner,
          carrierId: state.carrierId === 0 || config.useCustomCarriers ? undefined : state.carrierId,
          startTime: state.isoTime!,
          comments: state.comments,
          carrierName: state.carrierName,
          patient: {
            dob: state.dob!,
            email: state.email!,
            firstName: state.firstName!,
            lastName: state.lastName!,
            phone: state.phone!,
            type: state.existingPatient ? "EXISTING" : "NEW",
          },
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const disableContinue = result.$isValid === false;

  const selectedCategory = config.appointmentCategories.find((cat) => cat.id === state.categoryId);

  return (
    <div className={selfBookingCxStyles.mainStepContainer}>
      <AnonPatientHeader practiceInfo={practiceInfo}>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col">
            <div className="font-sansSemiBold text-sm/4">{state.providerName}</div>
            <div className="mt-1">{selectedCategory?.externalName || selectedCategory?.name}</div>
            <div>
              {t("selfBooking.date", {
                day: state.isoDate ? formatLongDayOfMonth(getLocalDate(state.isoDate)) : "",
                time: state.isoTime ? formatISOTimeAsAmPm(state.isoTime) : "",
              })}
            </div>
          </div>
          <ButtonIcon
            SvgIcon={EditIcon}
            theme="primary"
            className={selfBookingCxStyles.button}
            onClick={onStartOver}
          />
        </div>
      </AnonPatientHeader>
      <div className={selfBookingCxStyles.container}>
        <Form id="step1">
          <FormSection>
            <FormSectionTitle required>{t("selfBooking.name")}</FormSectionTitle>
            <div className="flex flex-row gap-2">
              <FormFieldInput
                id="firstName"
                placeholder={t("selfBooking.first")}
                className="w-full"
                value={state.firstName}
                onChange={(e) => setState({ firstName: e.target.value })}
                error={result.firstName.$error}
                maxLength={100}
              />
              <FormFieldInput
                id="lastName"
                placeholder={t("selfBooking.last")}
                className="w-full"
                value={state.lastName}
                onChange={(e) => setState({ lastName: e.target.value })}
                error={result.lastName.$error}
                maxLength={100}
              />
            </div>
          </FormSection>
          <FormSection>
            <FormSectionTitle required>{t("Date of Birth")}</FormSectionTitle>
            <FormFieldDatePicker
              id="dateOfBirth"
              pickerHiddenOnMobile
              selected={state.dob ? getLocalDate(state.dob) : undefined}
              onChange={(date) => setState({ dob: date ? formatAsISODate(date) : undefined })}
              error={result.dob.$error}
              maxDate={new Date()}
              calendarClassName="react-datepicker-archy-patient-calendar"
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle required>{t("Email")}</FormSectionTitle>
            <FormFieldInput
              id="email"
              type="email"
              value={state.email}
              onChange={(e) => setState({ email: e.target.value })}
              error={result.email.$error}
              maxLength={255}
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle required>{t("Phone")}</FormSectionTitle>
            <FormFieldPhoneInput
              id="phone"
              value={state.phone}
              onValueChange={(phone) => setState({ phone })}
              error={result.phone.$error}
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle>{t("Comments")}</FormSectionTitle>
            <FormFieldTextarea
              id="comments"
              rows={3}
              disableResize
              value={state.comments}
              onChange={(e) => setState({ comments: e.target.value })}
            />
          </FormSection>
          {config.allowAsap && (
            <FormSection>
              <FormSectionTitle>{t("selfBooking.seenSooner")}</FormSectionTitle>
              <Checkbox
                id="seenSooner"
                name="seenSooner"
                checked={state.seenSooner}
                onChange={(e) => setState({ seenSooner: e.target.checked })}
                className="text-sm/4"
              >
                {t("selfBooking.alertAvailibilities")}
              </Checkbox>
            </FormSection>
          )}
          <FormSection className="relative h-16 md:flex md:justify-center">
            <AsyncButton
              onClick={handleNextStep}
              className={selfBookingCxStyles.continueButton}
              disabled={disableContinue}
              isLoading={isLoading}
            >
              {t("Continue")}
            </AsyncButton>
          </FormSection>
          <FormSection className="text-grey-700 text-center">
            <Trans
              i18nKey="app.registration.sms.termsDisclaimer"
              values={{ practiceName: practiceInfo.name }}
              components={{
                termsConditions: <FootnoteLink href="https://www.archy.com/terms-of-use" target="_blank" />,
                privacyPolicy: <FootnoteLink href="https://www.archy.com/privacy-policy/" target="_blank" />,
              }}
            />
          </FormSection>
          <div className="flex justify-center p-6 mt-3">
            <PoweredBy className="text-secondaryTheme" />
          </div>
        </Form>
      </div>
    </div>
  );
};
