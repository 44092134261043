import { FC } from "react";
import { useTranslation } from "react-i18next";
import { InsuranceDetailsVO } from "@libs/api/generated-api";
import { RadioList } from "@libs/components/UI/RadioList";

interface Props {
  insuranceSubscriberType: InsuranceDetailsVO["type"];
  onUpdate: (type: InsuranceDetailsVO["type"]) => void;
  disabled?: boolean;
}

const SUBSCRIBER_OPTIONS: { label: string; value: InsuranceDetailsVO["type"] }[] = [
  {
    label: "Primary Subscriber",
    value: "PRIMARY_SUBSCRIBER",
  },
  {
    label: "Dependent",
    value: "DEPENDENT",
  },
];

export const SelectInsuranceSubscriberType: FC<Props> = ({
  insuranceSubscriberType,
  onUpdate,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <RadioList
      label={t("Subscriber Type")}
      required
      disabled={disabled}
      selectedValue={insuranceSubscriberType}
      options={SUBSCRIBER_OPTIONS}
      onChange={(e) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const value = e.target.value as InsuranceDetailsVO["type"];

        onUpdate(value);
      }}
    />
  );
};
