import React, { MouseEventHandler } from "react";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as Loading } from "@libs/assets/icons/refresh.svg";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/error.svg";
import { Modal } from "components/UI/Modal";
import { ModalContent } from "components/UI/ModalComponents";

const cxIconSize = "h-8 w-8 md:h-16 md:w-16";

type Props = {
  onClose: Func;
  primaryButtonLabel?: string;
  onClickPrimaryButton?: () => Promise<unknown>;
  onClickSecondaryButton?: MouseEventHandler<HTMLButtonElement>;
  onClickTertiaryButton?: MouseEventHandler<HTMLButtonElement>;
  isForceVisible?: boolean;
  secondaryButtonLabel?: string;
  tertiaryButtonLabel?: string;
  completedContent?: React.ReactNode;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  isLoading?: boolean;
  text: string;
};
type SVG = HTMLDivElement;

/*
This provides a pattern for a two step action modal

1. Action with options is presented, one confirm button one "dismiss button"
2. Confirm button does something Async, and then displays `completedContent` (when hasCompletedAsyncStep.isOn)
3. User can then dismiss modal
*/
export const ResponsiveActionModal: React.FC<Props> = ({
  onClose,
  title,
  text,
  icon = <WarningIcon />,
  primaryButtonLabel = "",
  secondaryButtonLabel = "",
  tertiaryButtonLabel = "",
  completedContent = "",
  onClickPrimaryButton,
  isLoading = false,
  onClickSecondaryButton,
  onClickTertiaryButton,
}) => {
  const hasCompletedAsyncStep = useBoolean(false);

  return (
    <Modal size="xs" onClose={onClose} onClickOutside={onClose}>
      <ModalContent className="flex flex-col items-center gap-5 p-5 pt-8 md:pt-5">
        <div className="hidden md:block text-right w-full">
          <button type="button" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div
          className={`
            bg-white
            p-1
            rounded-full
            absolute
            top-[-1rem]
            md:mt-[-1rem]
            md:block
            md:static
          `}
        >
          {isLoading ? (
            <Loading className={cx("animate-spin", cxIconSize)} />
          ) : (
            React.Children.map(icon, (child: React.ReactNode) => {
              if (!React.isValidElement<SVG>(child)) {
                return child;
              }

              const elementChild: React.ReactElement<SVG> = child;

              return React.cloneElement(elementChild, {
                ...child.props,
                className: cxIconSize,
              });
            })
          )}
        </div>

        {title && !hasCompletedAsyncStep.isOn && <div>{title}</div>}
        {hasCompletedAsyncStep.isOn ? completedContent : <div className="text-center">{text}</div>}

        {hasCompletedAsyncStep.isOff && (
          <div className="flex gap-2">
            {secondaryButtonLabel && (
              <Button
                className="min-w-32"
                theme="secondary"
                onClick={onClickSecondaryButton}
                disabled={isLoading}
              >
                {secondaryButtonLabel}
              </Button>
            )}
            {primaryButtonLabel && (
              <Button
                className="min-w-32"
                onClick={() => {
                  if (onClickPrimaryButton) {
                    onClickPrimaryButton().then(hasCompletedAsyncStep.on);
                  }
                }}
                disabled={isLoading}
              >
                {primaryButtonLabel}
              </Button>
            )}
          </div>
        )}

        {hasCompletedAsyncStep.isOn && (
          <div className="block md:hidden">
            {tertiaryButtonLabel && (
              <Button
                className="min-w-32"
                theme="secondary"
                onClick={onClickTertiaryButton}
                disabled={isLoading}
              >
                {tertiaryButtonLabel}
              </Button>
            )}
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};
