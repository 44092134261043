import { ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { ValueTypes } from "@libs/components/UI/OptionInput";
import { Radio } from "@libs/components/UI/Radio";
import {
  applyOptionClassName,
  Layout,
  OptionInputList,
  OptionInputOption,
  RadioInputListProps,
  RenderOptionItemProps,
} from "@libs/components/UI/OptionInputList";
import { LabelledOptions } from "@libs/components/UI/LabelledOptions";

const cxLabelThemes = {
  default: "md:text-xs text-sm font-sansSemiBold",
  smallBold: "text-sm font-sansSemiBold",
  regular: "md:text-xs text-sm",
};

export interface RadioListProps<V extends ValueTypes, T extends OptionInputOption<V>>
  extends Omit<RadioInputListProps<V, T>, "type"> {
  layout?: Layout;
  label?: ReactNode;
  required?: boolean;
  error?: string;
  inline?: boolean;
  verticalLayout?: "compact" | "skinny" | "slim" | "normal" | "comfortable";
  horizontalLayout?: "compact" | "skinny" | "slim" | "normal" | "comfortable";
  optionListClassName?: string;
  labelTheme?: keyof typeof cxLabelThemes;
  includeDarkMode?: boolean;
  displayErrorMessage?: boolean | undefined;
  renderOptionItem?: (props: RenderOptionItemProps<V>) => ReactNode;
}

// eslint-disable-next-line complexity
export const RadioList = <V extends ValueTypes, T extends OptionInputOption<V>>({
  className,
  optionClassName,
  optionListClassName,
  label,
  error,
  required,
  inline,
  labelTheme = "default",
  layout = "horiz",
  includeDarkMode = true,
  displayErrorMessage,
  verticalLayout = "comfortable",
  horizontalLayout = "comfortable",

  renderOptionItem = (props) => <Radio {...props} includeDarkMode={includeDarkMode} />,
  ...rest
}: RadioListProps<V, T>) => {
  return (
    <LabelledOptions
      label={label}
      error={error}
      required={required}
      layout={layout}
      className={className}
      inline={inline}
      displayErrorMessage={displayErrorMessage}
      labelClassName={cxLabelThemes[labelTheme]}
    >
      <OptionInputList
        {...rest}
        type="radio"
        className={cx(
          "mt-2",
          layout !== "custom" && "flex",
          layout === "vert" ? "flex-col" : layout === "custom" ? "" : "items-center flex-wrap",
          optionListClassName,
          horizontalLayout === "skinny" && "gap-x-1",
          horizontalLayout === "slim" && "gap-x-2",
          horizontalLayout === "normal" && "gap-x-3",
          horizontalLayout === "comfortable" && "gap-x-8",
          verticalLayout === "skinny" && "gap-y-1",
          verticalLayout === "slim" && "gap-y-2",
          verticalLayout === "normal" && "gap-y-3",
          verticalLayout === "comfortable" && "gap-y-4"
        )}
        optionClassName={(props) => cx(applyOptionClassName(optionClassName, props))}
        renderOptionItem={renderOptionItem}
      />
    </LabelledOptions>
  );
};
