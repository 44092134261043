import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UpdateMessageSubscriptionRequest, UpdatePatientRequest } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { AccountForm } from "components/Account/AccountForm";
import { StickySubmissionFooter } from "components/UI/StickySubmissionFooter";
import { getEmailSubscriptionByPatient } from "api/patient/queries";
import { useHandleError } from "api/handleErrorResponse";
import { updateMessageSubscription, updatePatient } from "api/patient/mutations";
import { useResponsiveBreakpoint } from "hooks/useResponsiveBreakpoint";
import { getOnboardingOptions } from "api/forms/queries";
import { ToggleHeader } from "components/UI/ToggleHeader";
import { paths } from "router/paths";
import { useQueryParams } from "hooks/useQueryParams";
import { useCurrentPatient } from "contexts/PatientContext";

export const AccountProfile: React.FC = () => {
  const { t } = useTranslation();
  const { query } = useQueryParams("profile");
  const form = query.form ?? "personal";
  const edit = useBoolean(false);
  const { screenMatches } = useResponsiveBreakpoint();
  const { id: patientId, practiceId } = useAccount();
  const practice = useCurrentPractice();
  const patient = useCurrentPatient();
  const patientFormId = `patient-profile-form-${form}`;
  const handleError = useHandleError();
  const [updatePatientMutation, updateMessageSubscriptionMutation] = useApiMutations([
    updatePatient,
    updateMessageSubscription,
  ]);
  const [onboardingOptionsQuery, emailSubscriptionQuery] = useApiQueries([
    getOnboardingOptions({ args: { practiceId } }),
    getEmailSubscriptionByPatient({ args: { practiceId, patientId } }),
  ]);

  const handleSubmit = async (
    generalInfo: UpdatePatientRequest,
    updateMessageSubscriptionRequest: UpdateMessageSubscriptionRequest
  ) => {
    if (!emailSubscriptionQuery.data) {
      return;
    }

    try {
      await Promise.all([
        updateMessageSubscriptionMutation.mutateAsync({
          practiceId,
          patientId,
          messageSubscriptionUuid: emailSubscriptionQuery.data.uuid,
          practiceUuid: practice.uuid,
          updates: updateMessageSubscriptionRequest,
        }),
        updatePatientMutation.mutateAsync({
          practiceId,
          patientId,
          data: generalInfo,
        }),
      ]);
      edit.off();
    } catch (error) {
      handleError(error);
    }
  };

  const navigate = useNavigate();

  const toggleHeaderOptions = useMemo(() => {
    return [
      { label: t("Personal Details"), value: "personal" as const },
      { label: t("Contact Details"), value: "contact" as const },
    ];
  }, [t]);

  return (
    <div className="relative h-full min-h-0 flex flex-col">
      <ToggleHeader
        backTo={screenMatches.has("sm") ? paths.home() : paths.account()}
        heightClass="h-16"
        options={toggleHeaderOptions}
        selectedValue={form === "personal" ? "personal" : "contact"}
        onChange={(_e, option) => {
          navigate(paths.profile({ form: option.value }));
        }}
        disabled={edit.isOn}
        rightContent={
          edit.isOff ? <ButtonIcon theme="primary" SvgIcon={EditIcon} onClick={edit.on} /> : undefined
        }
      />

      <div className="flex-1 gap-4 overflow-y-auto">
        <QueryResult queries={[onboardingOptionsQuery, emailSubscriptionQuery]}>
          {onboardingOptionsQuery.data && emailSubscriptionQuery.data && (
            <AccountForm
              id={patientFormId}
              onSubmit={handleSubmit}
              edit={edit.isOn}
              form={form}
              info={patient}
              preferences={emailSubscriptionQuery.data}
              onboardingOptions={onboardingOptionsQuery.data}
            />
          )}
        </QueryResult>
        {edit.isOn && (
          <StickySubmissionFooter
            form={patientFormId}
            secondaryText={t("Cancel")}
            primaryText={t("Save")}
            onClickSecondary={edit.off}
            isSubmitting={updatePatientMutation.isLoading || updateMessageSubscriptionMutation.isLoading}
            className="absolute"
          />
        )}
      </div>
    </div>
  );
};
