import React from "react";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { formatDate } from "@libs/utils/date";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import { FormFieldContainer } from "@libs/components/UI/FormFieldContainer";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { normal12, signature24 } from "assets/styles/textSize";

type Props = {
  id: string;
  showDate?: boolean;
  onChangeSignature: (signature: string) => void;
  placeholder?: string;
  value?: string;
  showErrorIcon?: boolean;
  showCloseIcon?: boolean;
  error?: string;
  componentName?: string;
  className?: string;
  edit?: boolean;
};

export const SignaturePad = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      onChangeSignature,
      // dateFormat = DATE_FORMATTER.DEFAULT,
      id,
      showCloseIcon = true,
      value = "",
      showDate = true,
      error,
      // showErrorIcon = true,
      // containerStyle = null,
      placeholder = null,
      className,
      edit = true,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const handleSignatureChanged: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
      (e) => {
        onChangeSignature(e.target.value);
      },
      [onChangeSignature]
    );

    const handleClearClicked = React.useCallback(() => {
      onChangeSignature("");
    }, [onChangeSignature]);
    const fontSizeClassName = value ? "text-2xl" : "text-base";

    const controlStyles = cxFormFieldStyle.control({
      hasIcon: true,
      hasLabel: false,
      layout: "labelOut",
      fontSizeClassName,
    });

    if (!edit) {
      return (
        <div
          className={cx(
            "border-b border-b-greyMedium select-none",
            value ? "font-dafoeRegular" : "text-greyLight pb-2",
            fontSizeClassName
          )}
        >
          {value || t("No signature provided")}
        </div>
      );
    }

    return (
      <div className={cx("flex flex-col relative gap-2", className)}>
        <FormFieldContainer className="relative" disabled={!error} error={error}>
          <input
            id={id}
            type="text"
            required={true}
            ref={ref}
            onChange={handleSignatureChanged}
            className={cx("w-full min-h-12", controlStyles, value && signature24)}
            value={value}
            placeholder={placeholder || t("Type your signature here")}
          />

          {showCloseIcon && (
            <ButtonIcon
              className="absolute right-3 top-1/2 -translate-y-1/2 z-10"
              SvgIcon={CloseIcon}
              theme="primary"
              disabled={!value}
              onClick={handleClearClicked}
            />
          )}
        </FormFieldContainer>
        {showDate && <div className={normal12}>{`Date: ${formatDate(new Date())}`}</div>}
      </div>
    );
  }
);
