import { redirect } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { formatAsISODate } from "@libs/utils/date";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { PaymentProfileVO } from "@libs/api/generated-api";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { fetchGuestCheckoutSummary } from "api/billing/queries";
import { ConfirmDOBForm } from "components/ConfirmDOBForm";
import { getPracticeInfoByUuid } from "api/user/queries";
import { GuestCheckoutForm } from "components/GuestCheckout/GuestCheckoutForm";
import { PaymentCompleteScreen } from "components/Billing/PaymentCompleteScreen";

import { getIsKioskCodeOrDOBError } from "utils/getIsKioskCodeOrDOBError";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "router/paths";
import { PublicPracticeInstrumentation } from "components/Main/PublicPracticeInstrumentation";

export const GuestCheckoutRoute: React.FC = () => {
  const [paymentDoneEmail, setPaymentDoneEmail] = useState("");
  const { practiceUuid } = usePathParams("guestCheckout");
  const { query } = useQueryParams("guestCheckout");
  const patientToken = query.patientToken ?? "";
  const [submittedDob, setSubmittedDob] = useState("");
  const [paymentProfile, setPaymentProfile] = useState<PaymentProfileVO | undefined>(undefined);
  const [practiceQuery, checkoutSummaryQuery] = useApiQueries([
    getPracticeInfoByUuid({
      args: { practiceUuid },
    }),
    fetchGuestCheckoutSummary({
      args: {
        practiceUuid,
        patientToken,
        data: { patientDob: submittedDob, paymentProfileUuid: paymentProfile?.uuid },
      },
      queryOptions: {
        enabled: Boolean(submittedDob),
        refetchOnWindowFocus: false,
      },
    }),
  ]);

  useEffect(() => {
    if (patientToken.length === 0) {
      redirect(paths.home());
    }
  }, [patientToken.length]);

  const isFormSubmissionError = getIsKioskCodeOrDOBError(checkoutSummaryQuery.error);
  const handleDobSubmitted = useCallback((dob: Date) => {
    setSubmittedDob(formatAsISODate(dob));
  }, []);

  return (
    <QueryResult queries={[practiceQuery]}>
      {practiceQuery.data ? (
        <>
          <PublicPracticeInstrumentation practice={practiceQuery.data} />
          {!submittedDob || isFormSubmissionError ? (
            <ConfirmDOBForm
              practice={practiceQuery.data}
              onSubmit={handleDobSubmitted}
              error={isFormSubmissionError}
            />
          ) : paymentDoneEmail ? (
            <PaymentCompleteScreen practice={practiceQuery.data} email={paymentDoneEmail} />
          ) : (
            <GuestCheckoutForm
              checkoutSummaryQuery={checkoutSummaryQuery}
              practiceUuid={practiceUuid}
              paymentProfile={paymentProfile}
              onChangePaymentProfile={setPaymentProfile}
              patientToken={patientToken}
              onPaymentComplete={setPaymentDoneEmail}
              practice={practiceQuery.data}
            />
          )}
        </>
      ) : null}
    </QueryResult>
  );
};
