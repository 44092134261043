import { noop } from "@libs/utils/noop";

/* eslint-disable @typescript-eslint/no-magic-numbers */
export const containImage = (
  imageSize: { w?: number; h?: number } | undefined,
  inSize: { w: number; h: number }
) => {
  const imageWidth = imageSize?.w ?? inSize.w;
  const imageHeight = imageSize?.h ?? inSize.h;
  const aspect = imageWidth / imageHeight;
  let width = inSize.w;
  let height = inSize.h;

  if (inSize.w <= inSize.h) {
    width = height * aspect;

    if (aspect >= 1 || width > inSize.w) {
      width = inSize.w;
      // Contained by width
      height = width / aspect;
    }
  } else {
    height = width / aspect;

    if (aspect <= 1 || height > inSize.h) {
      height = inSize.h;
      width = height * aspect;
    }
  }

  return { width, height };
};
export type ClampedImage = {
  url: string;
  blob: Blob | null;
  dimensions: { width: number; height: number };
  cleanup: Func;
};

/* istanbul ignore next */
export const clampImageDimensions = async (
  blob: Blob,
  maxSize: { w: number; h: number }
): Promise<ClampedImage> => {
  // createImageBitmap is not supported in Safari 13.1, iOS Safari 13.3
  // TODO need to catch errors and handle them in Safri for patient portal
  // eslint-disable-next-line compat/compat
  const img = await createImageBitmap(blob);

  if (maxSize.w >= img.width && maxSize.h >= img.height) {
    const url = URL.createObjectURL(blob);

    return {
      blob,
      dimensions: {
        width: img.width,
        height: img.height,
      },
      url,
      cleanup: () => URL.revokeObjectURL(url),
    };
  }

  const resizedDimensions = containImage({ w: img.width, h: img.height }, maxSize);

  const canvas = document.createElement("canvas");

  canvas.width = resizedDimensions.width;
  canvas.height = resizedDimensions.height;

  const ctx = canvas.getContext("2d");

  ctx?.drawImage(img, 0, 0, resizedDimensions.width, resizedDimensions.height);

  const url = canvas.toDataURL("image/png");

  return {
    url,
    blob: await new Promise<Blob | null>((resolve) => {
      canvas.toBlob((resizedBlob) => {
        resolve(resizedBlob);
        canvas.remove();
      });
    }),
    dimensions: resizedDimensions,
    cleanup: noop,
  };
};
