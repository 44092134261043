import { PracticeInfoVO, PreparePatientFormSubmissionResponse } from "@libs/api/generated-api";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { formatDate } from "@libs/utils/date";
import { PDFViewer } from "@react-pdf/renderer";
import { usePracticeLogo } from "components/PatientForms/hooks/usePracticeLogo";
import { PatientFormTasksPDF } from "components/PatientForms/PatientFormTasks/PatientFormTasksPDF";

type Props = {
  formTasksResponse: PreparePatientFormSubmissionResponse;
  practice: PracticeInfoVO;
};
export const PrintedFormTask: React.FC<Props> = (props) => {
  const logo = usePracticeLogo(props.practice);
  const title = `Consent Tasks - ${formatDate(new Date())}`;

  usePageTitle(title);

  if (logo === null) {
    // Logo not loaded yet
    return null;
  }

  return (
    <PDFViewer className="w-full h-full">
      <PatientFormTasksPDF logo={logo} title={title} {...props} />
    </PDFViewer>
  );
};
