import { FC, PropsWithChildren, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { isOneOf } from "@libs/utils/isOneOf";
import { FormFieldLayout } from "@libs/types/form";
import { FormFieldContainer } from "@libs/components/UI/FormFieldContainer";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { FormFieldNarrowContainer } from "@libs/components/UI/FormFieldNarrrowContainer";
import { FormFieldDescription } from "@libs/components/UI/FormFieldDescription";

export interface FormFieldProps {
  label?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  required?: boolean;
  className?: string;
  containerClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  error?: string;
  edit?: boolean;
  displayErrorMessage?: boolean;
  layout?: FormFieldLayout;
  errorId?: string;
}

export const cxStyle = {
  label: ({ layout, edit }: { layout: FormFieldLayout; edit: boolean }) =>
    cx(
      layout === "labelLeft" && "flex-1 items-center text-xs",
      layout === "labelIn" && edit && "px-4 pt-1 text-xxs",
      layout === "labelOut" && "font-sansSemiBold",
      layout === "labelOut" && edit && "pb-1 text-sm md:pb-2 md:text-xs",
      layout !== "labelLeft" && !edit && "pb-1 text-sm md:pb-2 md:text-xs"
    ),
};

export const FormField: React.FC<
  Omit<FormFieldProps, "layout"> & { layout: FormFieldLayout; id: string }
  // eslint-disable-next-line complexity
> = ({
  disabled,
  id,
  label,
  description,
  children,
  required,
  errorId,
  className,
  containerClassName,
  error,
  edit = true,
  layout,
  displayErrorMessage = true,
}) => {
  const formFieldLabel = label && (
    <FormFieldLabel
      content={label}
      error={error}
      disabled={disabled}
      required={required}
      edit={edit}
      id={id}
      inline={layout === "labelLeft" || layout === "tableValue"}
      className={cxStyle.label({ layout, edit })}
    />
  );

  const formFieldDescription = description && layout === "labelOut" && (
    <FormFieldDescription disabled={disabled}>{description}</FormFieldDescription>
  );

  return (
    <div className={className}>
      {isOneOf(layout, ["labelIn", "labelOut", "tableValue"]) && !edit && (
        <>
          {formFieldLabel}
          {formFieldDescription}
          {children}
        </>
      )}
      {layout === "labelIn" && edit && (
        <FormFieldContainer className={containerClassName} disabled={disabled} error={error}>
          {formFieldLabel}
          {children}
        </FormFieldContainer>
      )}
      {(layout === "labelOut" || layout === "tableValue") && edit && (
        <>
          {formFieldLabel}
          {formFieldDescription}
          <FormFieldContainer className={containerClassName} disabled={disabled} error={error}>
            {children}
          </FormFieldContainer>
        </>
      )}
      {layout === "labelLeft" && (
        <div className="flex text-xs items-start">
          {formFieldLabel}
          <FormFieldNarrowContainer disabled={disabled} error={error} edit={edit}>
            {children}
          </FormFieldNarrowContainer>
        </div>
      )}
      {layout === "embedded" && children}
      {error && edit && displayErrorMessage && (
        <FormFieldError id={errorId} indent={layout !== "embedded"} className="pt-1">
          {error}
        </FormFieldError>
      )}
    </div>
  );
};

const childrenContainerStyle = {
  align: {
    center: "top-1/2 -translate-y-1/2",
    bottom: "bottom-2",
  },
};

/**
 * Container component for the children of the FormField component to vertically center the children
 * of `FormField`.

* @example
 * ```tsx
 * import { FormFieldChildrenContainer } from "./FormField";
 *
 * const MyForm = () => {
 *   return (
 *     <FormFieldInput>
 *       <FormFieldChildrenContainer>
 *         <CharacterCounter currentCount={currentCount} max={max} />
 *       </FormFieldChildrenContainer>
 *    </FormFieldInput>
 *   );
 * };
 * ```
 *
 * @returns The rendered FormFieldChildrenContainer component.
 *
 */
export const FormFieldChildrenContainer: FC<
  PropsWithChildren<{ align: keyof typeof childrenContainerStyle.align }>
> = ({ children, align }) => (
  <div className={cx("text-xs absolute right-3", childrenContainerStyle.align[align])}>{children}</div>
);
