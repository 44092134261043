import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { GeneralInfoVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { formatDataUrl } from "@libs/utils/formatDataUrl";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { uploadProfileImage, updateOnboardingInfo } from "api/user/mutations";
import { useHandleError } from "api/handleErrorResponse";
import { getOnboardingInfoQuery, getOnboardingOptions } from "api/forms/queries";
import { CameraComponent } from "deprecated/camera-component";
import { semibold16 } from "assets/styles/textSize";
import { GeneralInformationForm } from "components/Onboarding/GeneralInformationForm";
import { RegistrationSubmissionFooter } from "components/Onboarding/RegistrationSubmissionFooter";
import { OnboardingLoading } from "components/Onboarding/OnboardingLoading";
import { EditProfileImageModal } from "components/Account/EditProfileImageModal";
import { getPatientSummary, getProfileImageQuery } from "api/patient/queries";

type Props = {
  onSuccess: Func;
};

const PATIENT_FORM = "patient-general-info";

export const GeneralInformation: React.FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const handleError = useHandleError();
  const { id: patientId, practiceId } = useAccount();
  const practice = useCurrentPractice();

  const [imageEditing, setImageEditing] = useState<File | undefined>();

  const [infoQuery, onboardingOptionsQuery, patientQuery, profileImageQuery] = useApiQueries([
    getOnboardingInfoQuery({ args: { patientId, practiceId, query: { onboardingState: "GENERAL_INFO" } } }),
    getOnboardingOptions({ args: { practiceId } }),
    getPatientSummary({
      args: { patientId, practiceId },
    }),
    getProfileImageQuery({ args: { practiceId, userId: patientId } }),
  ]);

  const [uploadProfileImageMutation] = useApiMutations([uploadProfileImage]);
  const handleImageSelected = useCallback((image: File) => {
    setImageEditing(image);
  }, []);
  const handleUploadImage = React.useCallback(
    (resizedImage: File) => {
      uploadProfileImageMutation.mutate(
        { practiceId, userId: patientId, image: resizedImage },
        {
          onSettled: () => {
            setImageEditing(undefined);
          },
          onError: handleError,
        }
      );
    },
    [handleError, patientId, practiceId, uploadProfileImageMutation]
  );

  const [{ mutateAsync: mutateOnboardingAsync, isLoading: isSaving }] = useApiMutations([
    updateOnboardingInfo,
  ]);

  const handleSubmit = React.useCallback(
    (generalInfo: GeneralInfoVO) => {
      mutateOnboardingAsync(
        {
          practiceId,
          patientId,
          onboardingState: "GENERAL_INFO",
          data: {
            generalInfo,
          },
        },
        {
          onSuccess,
          onError: handleError,
        }
      );
    },
    [handleError, mutateOnboardingAsync, onSuccess, patientId, practiceId]
  );

  return (
    <>
      {imageEditing && (
        <EditProfileImageModal
          onClose={() => {
            setImageEditing(undefined);
          }}
          onDone={handleUploadImage}
          initialImage={imageEditing}
          isSaving={uploadProfileImageMutation.isLoading}
        />
      )}

      <QueryResult
        queries={[infoQuery, onboardingOptionsQuery, patientQuery, profileImageQuery]}
        loading={<OnboardingLoading />}
      >
        {infoQuery.data?.generalInfo &&
          onboardingOptionsQuery.data &&
          patientQuery.data &&
          profileImageQuery.data && (
            <div className="flex flex-col gap-4 p-4">
              <GeneralInformationForm
                id={PATIENT_FORM}
                info={infoQuery.data.generalInfo}
                onboardingOptions={onboardingOptionsQuery.data}
                onSubmit={handleSubmit}
                canEditEmail={Boolean(!infoQuery.data.generalInfo.contactDetails.email)}
                showPreferredPronouns={practice.practicePreferences.showPreferredPronouns}
                patient={patientQuery.data}
              >
                <div className={cx("text-secondaryTheme", semibold16)}>{t("Personal Details")}</div>
                <CameraComponent
                  fieldText="Upload profile picture"
                  setImage={handleImageSelected}
                  profilePicture={formatDataUrl(profileImageQuery.data.thumbnail)}
                />
              </GeneralInformationForm>

              <RegistrationSubmissionFooter form={PATIENT_FORM} isSubmitting={isSaving} />
            </div>
          )}
      </QueryResult>
    </>
  );
};
