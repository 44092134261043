import React, { useCallback, useEffect, useRef } from "react";
import { pdf } from "@react-pdf/renderer";
import { add, isBefore } from "date-fns";
import { Breadcrumb, addBreadcrumb } from "@sentry/react";
import { PracticeInfoVO, PreparePatientFormSubmissionResponse } from "@libs/api/generated-api";
import { blobToFile } from "@libs/utils/dataUrl";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";
import { SECOND_IN_MS } from "@libs/utils/date";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { LoadedPatientForm } from "components/PatientForms/LoadedPatientForm";
import { PatientResponses, ResponseValue } from "components/PatientForms/hooks/usePatientResponses";
import { patientSubmitFormTask, prepareFileUploadForFormTask } from "api/forms/mutations";
import { PatientFormPdf } from "components/PatientForms/FormPDFElements/PatientFormPdf";
import { useHandleError } from "api/handleErrorResponse";
import { usePatientAttributesFromFormTasks } from "components/PatientForms/hooks/usePatientAttributesFromFormTasks";
import { FormTaskChrome } from "components/PatientForms/PatientFormTasks/FormTaskChrome";
import { TaskProgressHeader } from "components/UI/TaskProgressHeader";
import { FormTasksCompleted } from "components/PatientForms/PatientFormTasks/FormTasksCompleted";
import { FormTasksCompletedKiosk } from "components/PatientForms/PatientFormTasks/FormTasksCompletedKiosk";
import { getPracticeLogoWithDimensions } from "components/PatientForms/FormPDFElements/utils/getPracticeLogo";
import { IdleTimer } from "components/Main/IdleTimer";
import { PrintedFormTask } from "components/PatientForms/PatientFormTasks/PrintedFormTask";

const EMPTY_RESPONSES = {};

type Props = {
  formTasksResponse: PreparePatientFormSubmissionResponse;
  practice: PracticeInfoVO;
  print: boolean;
  isKiosk?: boolean;
  token: string;
  onSessionExpired: Func;
  expiresAt: Date;
  idleConfig: {
    timeout: number;
    promptBeforeIdle: number;
    eventsThrottle: number;
    name: string;
  };
};

const TOKEN_CHECK_EXPIRY_INTERVAL_SECONDS = 5;

// Tokens last an hour, so refresh every 50 min
export const getFormTaskExpiry = () => add(new Date(), { minutes: 50 });

const useTokenExpiration = ({ onSessionExpired, expiresAt }: { onSessionExpired: Func; expiresAt: Date }) => {
  const hasSessionExpired = useRef(false);

  useEffect(() => {
    if (isBefore(new Date(), expiresAt)) {
      hasSessionExpired.current = false;

      const updateInterval = setInterval(() => {
        const now = new Date();

        if (isDefined(expiresAt)) {
          // Add buffer to now, so we never run the risk of a race condition where user clicks submit right as their upload token expires
          const nowWithBuffer = add(now, { minutes: 1 });

          if (isBefore(expiresAt, nowWithBuffer) && !hasSessionExpired.current) {
            hasSessionExpired.current = true;
            onSessionExpired();
          }
        }
      }, TOKEN_CHECK_EXPIRY_INTERVAL_SECONDS * SECOND_IN_MS);

      return () => clearInterval(updateInterval);
    }

    return undefined;
  }, [expiresAt, onSessionExpired]);
};

export const LoadedPatientFormTasks: React.FC<Props> = ({
  formTasksResponse,
  practice,
  token,
  print,
  isKiosk = false,
  onSessionExpired,
  expiresAt,
  idleConfig,
}) => {
  const submitting = useBoolean(false);
  const { tasks } = formTasksResponse;
  const practiceUuid = practice.uuid;
  const patientAttributes = usePatientAttributesFromFormTasks(formTasksResponse);
  const currentFormTask = React.useMemo(() => tasks.find((task) => task.state === "PENDING"), [tasks]);
  const idlePrompt = useBoolean(false);

  useTokenExpiration({ onSessionExpired, expiresAt });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentFormTask?.uuid]);

  const [patientSubmitFormTaskMutation, prepareFileUploadForFormTaskMutation] = useApiMutations([
    patientSubmitFormTask,
    prepareFileUploadForFormTask,
  ]);
  const patientSubmitFormTaskMutateAsync = patientSubmitFormTaskMutation.mutateAsync;
  const prepareFileUploadForFormTaskMutateAsync = prepareFileUploadForFormTaskMutation.mutateAsync;
  const handleError = useHandleError();
  const handleSubmit = React.useCallback(
    async (submission: { responsesById: PatientResponses; formPublishedContentUuid: string }) => {
      if (!currentFormTask) {
        return;
      }

      const { form, uuid: formTaskUuid } = currentFormTask;

      if (!form.publishedContentUuid) {
        return;
      }

      submitting.on();

      const breadCrumb: Pick<Breadcrumb, "level" | "category"> = {
        level: "info",
        category: "form-tasks",
      };

      try {
        addBreadcrumb({
          ...breadCrumb,
          message: "Generating form task PDF",
        });

        // const logo = await getPracticeLogoBase64(practice);
        const logo = await getPracticeLogoWithDimensions(practice);

        const formPdfBlob = await pdf(
          <PatientFormPdf
            practice={practice}
            patientPersonalDetails={patientAttributes}
            formData={form}
            responses={submission.responsesById}
            logo={logo}
          />
        ).toBlob();

        addBreadcrumb({
          ...breadCrumb,
          message: "Fetching form task upload url",
        });

        const fileUploadResponse = await prepareFileUploadForFormTaskMutateAsync({
          practiceUuid,
          formTaskUuid,
          token,
        });
        const fileUpload = fileUploadResponse.data.data;

        addBreadcrumb({
          ...breadCrumb,
          message: "Submitting Form task PDF",
          data: {
            tokenExpiresAt: fileUpload.expiresAt,
            now: Date.now() / SECOND_IN_MS,
          },
        });

        const response = await fetch(fileUpload.url, {
          method: "PUT",
          body: blobToFile(formPdfBlob, `${form.title}.pdf`),
        });

        if (!response.ok) {
          throw new Error(await response.text());
        }

        addBreadcrumb({
          ...breadCrumb,
          message: "Submitting form task response",
        });
        await patientSubmitFormTaskMutateAsync({
          practiceUuid,
          formTaskUuid,
          data: {
            encryptedFileKey: fileUpload.encryptedFileKey,
            formResponse: {
              responses: submission.responsesById as Record<string, ResponseValue>,
              formPublishedContentUuid: form.publishedContentUuid,
            },
          },
          token,
        });
        addBreadcrumb({
          ...breadCrumb,
          message: "Successfully submitted form task",
        });
      } catch (e) {
        handleError(e);
      }

      submitting.off();
    },
    [
      currentFormTask,
      handleError,
      patientAttributes,
      patientSubmitFormTaskMutateAsync,
      practice,
      practiceUuid,
      prepareFileUploadForFormTaskMutateAsync,
      submitting,
      token,
    ]
  );

  const handleIdle = useCallback(() => {
    window.location.reload();
  }, []);

  return print ? (
    <PrintedFormTask practice={practice} formTasksResponse={formTasksResponse} />
  ) : currentFormTask ? (
    <FormTaskChrome
      practice={practice}
      patientName={`${patientAttributes.firstName} ${patientAttributes.lastName}`}
    >
      <IdleTimer {...idleConfig} onIdle={handleIdle} onTogglePrompt={idlePrompt.set} />

      <TaskProgressHeader
        totalSteps={tasks.length}
        step={tasks.indexOf(currentFormTask)}
        className="px-4 mt-4"
      />
      <LoadedPatientForm
        key={currentFormTask.uuid}
        formData={currentFormTask.form}
        responses={EMPTY_RESPONSES}
        patientAttributes={patientAttributes}
        edit
        showInWizard
        uuidOrSlug={currentFormTask.form.uuid}
        onSubmit={handleSubmit}
        fixedFooter
        warnWhenNavigating={!idlePrompt.isOn}
        isSubmitting={submitting.isOn}
        practice={practice}
      />
    </FormTaskChrome>
  ) : isKiosk ? (
    <FormTasksCompletedKiosk practice={practice} />
  ) : (
    <FormTasksCompleted practice={practice} />
  );
};
