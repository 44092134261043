/* eslint-disable @typescript-eslint/naming-convention */
export const colors = {
  screenBackground: "#f6f8fb",
  primaryTheme: "#007ed9",
  secondaryTheme: "#153e90",
  actionLight: "#E5F2FB",
  accentLight: "#e9f1ff",
  shadow: "#94949440",
  greyDark: "#0F172A",
  greyMedium: "#334155",
  greyLight: "#64748B",
  "grey-400": "#94A3B8",
  greyLighter: "#CBD5E1",
  greyLightest: "#E2E8F0",
  whiteLight: "#F1F5F9",
  white: "#fff",
  offWhite: "#F8FAFC",
  redDark: "#c14545",
  red: "#eb5757",
  redLight: "#fbdddd",
  redTeracotta: "#bb6464",
  greenDark: "#1d884a",
  green: "#27ae60",
  greenLight: "#d4efdf",
  blueDark: "#0070c1",
  blue: "#2f80ed",
  blueLight: "#d5e6fb",
  orangeDark: "#be5a02",
  orange: "#e07415",
  orangeLight: "#f9e3d0",
  yellowDark: "#d5ac31",
  yellow: "#f2c94c",
  yellowLight: "#fcf4db",
  magentaDark: "#a20759",
  magenta: "#c41974",
  magentaLight: "#f3d1e3",
  blueCadet: "#73a9ad",
  khaki: "#e6dc88",
  blueShadow: "#748da6",
  plumWeb: "#e1a1f0",
  mossGreen: "#83a04e",
  violet: "#5F55B9",
  violetLight: "#DFDDF1",

  archyBlue: {
    900: "#00538F",
    800: "#005FA3",
    700: "#006BB8",
    600: "#0077CC",
    500: "#007ED9", // primaryTheme
    400: "#0A99FF",
    300: "#33AAFF",
    200: "#5CBBFF",
    100: "#C2E5FF",
    50: "#E5F2FB", // actionLight
  },
  navy: {
    900: "#0A1F46",
    800: "#0D2659",
    700: "#102F6B",
    600: "#12367E",
    500: "#153E90", // secondaryTheme
    400: "#194CB4",
    300: "#1F5CD6",
    200: "#3B73E3",
    100: "#5E8CE8",
    50: "#81A6ED",
  },
  grey: {
    900: "#0F172A", // grey dark
    800: "#1E293B",
    700: "#334155", // grey medium
    600: "#475569",
    500: "#64748B", // grey light
    400: "#94A3B8",
    300: "#CBD5E1", // grey lighter
    200: "#E2E8F0", // grey lightest
    100: "#F1F5F9", // white light
    50: "#F8FAFC", // off-white
  },
  slate: {
    900: "#0F172A",
    800: "#1E293B",
    700: "#334155",
    600: "#475569",
    500: "#64748B",
    400: "#94A3B8",
    300: "#CBD5E1",
    200: "#E2E8F0",
    100: "#F1F5F9",
    50: "#F8FAFC",
  },
  perioChart: {
    bleeding: "#FF8484",
    plaque: "#9BC6FF",
    calculus: "#9FE3BC",
    suppuration: "#F9D866",
  },
};
export const fontFamilies = {
  // List of font families to use inspired by https://ealush.com/emoji-picker-react/
  emoji: [
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Segoe UI",
    "Apple Color Emoji",
    "Twemoji Mozilla",
    "Noto Color Emoji",
    "EmojiOne Color",
    "Android Emoji",
  ],
  archyIcons: ["Archy-Icons"],
  sans: ["OpenSans-Regular", "sans-serif"],
  sansBold: ["OpenSans-Bold", "sans-serif"],
  sansBoldItalic: ["OpenSans-BoldItalic", "sans-serif"],
  sansExtraBold: ["OpenSans-ExtraBold", "sans-serif"],
  sansExtraBoldItalic: ["OpenSans-ExtraBoldItalic", "sans-serif"],
  sansItalic: ["OpenSans-Italic", "sans-serif"],
  sansLight: ["OpenSans-Light", "sans-serif"],
  sansLightItalic: ["OpenSans-LightItalic", "sans-serif"],
  sansSemiBold: ["OpenSans-SemiBold", "sans-serif"],
  sansSemiBoldItalic: ["OpenSans-SemiBoldItalic", "sans-serif"],
  dafoeRegular: ["Mr Dafoe", "sans-serif"],
};
export const fontWeight = {
  semiBold: 600,
  normal: 400,
};

// eslint-disable-next-line import/no-default-export
export default {
  colors,
  fontFamilies,
  fontWeight,
};
