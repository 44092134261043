import { Amplify } from "aws-amplify";
import ReactDOMClient from "react-dom/client";
import { REACT_RENDER_ROOT } from "@libs/utils/constants";
import { initializeSentry } from "@libs/utils/sentry";
import { env } from "env";
import { initI18n } from "i18n/config";
import { App } from "./App";

if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
  initializeSentry({
    appEnv: env.REACT_APP_ENVIRONMENT,
    release: env.REACT_APP_RELEASE_VERSION,
    dsn: "https://d56f43aa4ff4481db34a48f71165d885@o1034289.ingest.sentry.io/6437910",
  });
}

initI18n();

Amplify.configure({
  Auth: {
    userPoolId: env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    endpoint: env.REACT_APP_COGNITO_ENDPOINT,
    oauth: {
      domain: env.REACT_APP_AUTH_DOMAIN,
      scope: ["email", "profile", "openid"],
      redirectSignIn: `${window.location.origin}/oauth/sign-in`,
      redirectSignOut: `${window.location.origin}/post-sign-out`,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

const container = document.querySelector(`#${REACT_RENDER_ROOT}`);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOMClient.createRoot(container!);

root.render(<App />);
