import { useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getPatientAppointments } from "api/patient/queries";
import { NoAppointmentsCard, TimelineTypes } from "components/AppointmentsRoute/NoAppointmentsCard";
import { getSelfBookingConfig } from "api/self-booking/queries";
import { AppointmentCard } from "components/UI/Cards";
import { semibold20 } from "assets/styles/textSize";
import { ToggleGroup } from "components/UI/ToggleGroup";

export const AppointmentsRoute: React.FC = () => {
  const { practiceId, id: patientId } = useAccount();
  const practice = useCurrentPractice();
  const { t } = useTranslation();
  const [upcomingAppointmentsQuery, pastAppointmentsQuery, selfBookableConfigQuery] = useApiQueries([
    getPatientAppointments({
      args: {
        patientId,
        practiceId,
        query: {
          dateMode: "FUTURE",
          includeStates: undefined,
          excludeStates: undefined,
          pageNumber: 1,
          pageSize: 100,
        },
      },
    }),
    getPatientAppointments({
      args: {
        patientId,
        practiceId,
        query: {
          dateMode: "PAST",
          includeStates: undefined,
          excludeStates: undefined,
          pageNumber: 1,
          pageSize: 100,
        },
      },
    }),
    getSelfBookingConfig({
      args: {
        practiceUuid: practice.uuid,
      },
    }),
  ]);
  const upcomingAppointments = upcomingAppointmentsQuery.data;
  const pastAppointments = pastAppointmentsQuery.data;

  const [dtMode, setDateMode] = useState<TimelineTypes>("FUTURE");

  const options: OptionInputOption<TimelineTypes>[] = useMemo(
    () => [
      {
        label: t("app.appointments.page.upcoming", { count: upcomingAppointments?.length ?? 0 }),
        value: "FUTURE",
      },
      {
        label: t("app.appointments.page.past", { count: pastAppointments?.length ?? 0 }),
        value: "PAST",
      },
    ],
    [t, upcomingAppointments?.length, pastAppointments?.length]
  );

  const apts = dtMode === "FUTURE" ? upcomingAppointments : pastAppointments;

  const selfBookingEnabled = selfBookableConfigQuery.data?.enabled || false;

  return (
    <div className="px-4 mt-6 flex justify-center">
      <div className="flex flex-col gap-3 w-full max-w-lg">
        <div className={semibold20}>{t("app.page.labels.appointments")}</div>
        <div className="flex flex-col items-center">
          <ToggleGroup
            size="lg"
            options={options}
            selectedValue={dtMode}
            onChange={(_, option) => {
              setDateMode(option.value);
            }}
          />
        </div>
        <QueryResult
          queries={[upcomingAppointmentsQuery, pastAppointmentsQuery, selfBookableConfigQuery]}
          loading={<Skeleton count={10} className="h-12" />}
        >
          {apts && apts.length <= 0 ? (
            <NoAppointmentsCard appointmentType={dtMode} selfBookingEnabled={selfBookingEnabled} />
          ) : (
            apts &&
            apts.map((item) => {
              return <AppointmentCard key={item.id} appointment={item} />;
            })
          )}
        </QueryResult>
      </div>
      <Outlet />
    </div>
  );
};
