import React from "react";
// import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { UseFinixForm } from "@libs/hooks/useFinixForm";
import { FinixForm } from "@libs/components/UI/FinixForm";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { FinixScriptLoadErrorBanner } from "components/Billing/FinixScriptLoadErrorBanner";
import { UseCardDraft } from "hooks/useCardDraft";

type Props = {
  className?: string;
  title?: React.ReactNode;
  includeDefaultCardPreference?: boolean;
  includeAcknowedgeFuturePayments?: boolean;
  includeFsaCardPreference?: boolean;
  handleFinixFormRef: (ref: HTMLDivElement | null) => void;
} & UseFinixForm &
  UseCardDraft;

// eslint-disable-next-line complexity
export const NewCardForm: React.FC<Props> = ({
  failedLoadingFinixScript,
  className,
  cardDraftState,
  validation,
  updateCardDraft,
  includeAcknowedgeFuturePayments = false,
  includeDefaultCardPreference = false,
  includeFsaCardPreference = false,
  ...finixFormProps
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx("flex flex-col gap-3", className)}>
      {failedLoadingFinixScript && <FinixScriptLoadErrorBanner />}

      <FinixForm {...finixFormProps} />
      <div className="flex flex-col gap-4">
        <div className="flex flex-row flex-wrap gap-4">
          {includeDefaultCardPreference && (
            <Checkbox
              checked={cardDraftState.isDefaultCard}
              disabled={finixFormProps.loadingFinix}
              className="text-sm"
              onChange={(e) => {
                updateCardDraft({
                  isDefaultCard: e.target.checked,
                  isFSACard: false,
                });
              }}
            >
              {t("app.page.labels.account.managePaymentSaveAsDefaultMessage")}
            </Checkbox>
          )}
          {includeFsaCardPreference && (
            <Checkbox
              checked={cardDraftState.isFSACard}
              disabled={finixFormProps.loadingFinix}
              onChange={(e) => {
                updateCardDraft({
                  isFSACard: e.target.checked,
                  isDefaultCard: false,
                });
              }}
            >
              {t("FSA Card")}
            </Checkbox>
          )}
        </div>

        {includeAcknowedgeFuturePayments && (
          <>
            <div className="border-t border-t-greyLighest" />

            <Checkbox
              id="future-payments"
              name="Acknowledgement"
              className="text-sm"
              required
              disabled={finixFormProps.loadingFinix}
              checked={cardDraftState.isChargingAuthorized}
              onChange={(e) => {
                updateCardDraft({
                  isChargingAuthorized: e.target.checked,
                });
              }}
            >
              {t("payment.info.acknowledgement")}
            </Checkbox>
          </>
        )}

        {validation.result.isChargingAuthorized.$error && (
          <FormFieldError>{validation.result.isChargingAuthorized.$error}</FormFieldError>
        )}
      </div>
    </div>
  );
};
