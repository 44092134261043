import { cx } from "@libs/utils/cx";
import { formatDataUrl } from "@libs/utils/formatDataUrl";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { PoweredBy } from "components/UI/PoweredBy";

export type PracticeBrandingProps = {
  size: "sm" | "lg";
  includePoweredBy?: boolean;
  practice: PracticeInfoVO;
};

// eslint-disable-next-line complexity
export const PracticeBranding: React.FC<PracticeBrandingProps> = ({
  includePoweredBy = true,
  size,
  practice,
}) => {
  const thumbnail = formatDataUrl(practice.logo?.thumbnail);

  return (
    <div className="flex gap-2 items-center">
      {thumbnail && <img src={thumbnail} alt={practice.name} className="rounded-sm h-10 w-10" />}
      <div className="flex flex-col">
        <div
          className={cx(
            "text-slate-900",
            size === "sm" ? "text-sm font-sansSemiBold" : "text-xl leading-snug font-sansSemiBold"
          )}
        >
          {practice.name}
        </div>
        {includePoweredBy ? <PoweredBy className="text-slate-900" size={size} /> : null}
      </div>
    </div>
  );
};
