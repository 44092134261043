/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from "react";
import { Style } from "@react-pdf/types";
import { View, StyleSheet } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { FormPatientProcedureVO, FormProcedureTableElementVO } from "@libs/api/generated-api";
import { colors } from "@libs/design.config";
import { formatCurrency } from "@libs/utils/currency";
import { Box } from "components/PatientForms/FormPDFElements/Box";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { Spacer } from "components/PatientForms/FormPDFElements/Spacer";
import { BORDER_RADIUS, SPACE } from "components/PatientForms/FormPDFElements/styles";
import { EMPTY_CELL } from "components/UI/GridTableComponents";
import { getProcedureDescriptions } from "components/PatientForms/utils";
import { usePatientProcedureTotals } from "components/PatientForms/hooks/usePatientProcedureTotals";
import { useGroupedProcedures } from "components/PatientForms/FormElements/PatientFormProcedureTableElement/useGroupedProcedures";

const BASE_COLUMN_WIDTH = 58;
const baseColumnStyles: Style = {
  width: BASE_COLUMN_WIDTH,
  paddingVertical: SPACE.md,
  paddingHorizontal: SPACE.sm,
};
const columnStyles = StyleSheet.create({
  priority: {
    ...baseColumnStyles,
    width: 50,
  },
  cdtCode: {
    ...baseColumnStyles,
  },
  procedureArea: {
    ...baseColumnStyles,
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    width: BASE_COLUMN_WIDTH + 2,
  },
  description: {
    ...baseColumnStyles,
    width: undefined,
    flex: 1,
  },
  ucrRate: {
    ...baseColumnStyles,
    textAlign: "right",
  },
  negotiatedRate: {
    ...baseColumnStyles,
    width: BASE_COLUMN_WIDTH + 2,
    textAlign: "right",
  },
  insuranceAmount: {
    ...baseColumnStyles,
    textAlign: "right",
  },
  primaryInsuranceAmount: {
    ...baseColumnStyles,
    width: BASE_COLUMN_WIDTH + 5,
    textAlign: "right",
  },
  patientAmount: {
    ...baseColumnStyles,
    textAlign: "right",
  },
});

export const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "column",
    border: colors.slate[200],
    borderRadius: BORDER_RADIUS.md,
    borderWidth: 1,
  },
  tableRow: {
    flexDirection: "row",
    paddingHorizontal: SPACE.sm,
  },
  footerCell: {
    flexDirection: "column",
  },
  divider: {
    backgroundColor: colors.slate[200],
    width: "100%",
    height: 1,
  },
});

const TableRow: React.FC<{ children: React.ReactNode; borderBottom?: boolean }> = ({
  children,
  borderBottom = false,
}) => (
  <View
    style={[
      styles.tableRow,
      borderBottom ? { borderBottomWidth: 2, borderBottomColor: colors.slate[300] } : {},
    ]}
  >
    {children}
  </View>
);

const TableTitle: React.FC<{ children: React.ReactNode; style: Style }> = ({ children, style }) => (
  <Text bold style={style}>
    {children}
  </Text>
);

const HeaderRow: React.FC<{ includeUcrFee: boolean; showSecondary: boolean }> = ({
  includeUcrFee,
  showSecondary,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TableRow>
        <TableTitle style={columnStyles.priority}>{t("Priority")}</TableTitle>
        <TableTitle style={columnStyles.cdtCode}>{t("Code")}</TableTitle>
        <TableTitle style={columnStyles.procedureArea}>{t("Tth/Surf")}</TableTitle>
        <TableTitle style={columnStyles.description}>{t("Description")}</TableTitle>
        {includeUcrFee && <TableTitle style={columnStyles.ucrRate}>{t("UCR Fee")}</TableTitle>}
        <TableTitle style={columnStyles.negotiatedRate}>{t("Neg Rate")}</TableTitle>
        {showSecondary ? (
          <>
            <TableTitle style={columnStyles.primaryInsuranceAmount}>{t("Prim Ins Est")}</TableTitle>
            <TableTitle style={columnStyles.insuranceAmount}>{t("Sec Ins Est")}</TableTitle>
          </>
        ) : (
          <TableTitle style={columnStyles.insuranceAmount}>{t("Ins Est")}</TableTitle>
        )}
        <TableTitle style={columnStyles.patientAmount}>{t("Pt Est")}</TableTitle>
      </TableRow>
      <View style={styles.divider} />
    </>
  );
};

const ProcedureRow: React.FC<{
  procedure: FormPatientProcedureVO;
  includeUcrFee: boolean;
  showSecondary: boolean;
}> = ({ procedure, includeUcrFee, showSecondary }) => {
  const {
    priority,
    procedureArea,
    ucrRate,
    cdtCode,
    negotiatedRate,
    insuranceAmount,
    primaryInsuranceAmount,
    secondaryInsuranceAmount,
    patientAmount,
  } = procedure;
  const procedureAreas = procedureArea?.split(",");
  const { t } = useTranslation();

  return (
    <>
      <TableRow>
        <Text style={columnStyles.priority}>{priority ?? EMPTY_CELL}</Text>
        <Text style={columnStyles.cdtCode}>#{cdtCode}</Text>
        <View style={[columnStyles.procedureArea, { flexDirection: "row", flexWrap: "wrap" }]}>
          {procedureAreas ? (
            procedureAreas.map((area, i) => (
              <Text key={i}>
                {area}
                {i === procedureAreas.length - 1 ? null : ", "}
              </Text>
            ))
          ) : (
            <Text>{EMPTY_CELL}</Text>
          )}
        </View>
        <View style={[columnStyles.description, { flexDirection: "column" }]}>
          {getProcedureDescriptions(procedure, t).map((description, i) => (
            <Text key={i}>{description}</Text>
          ))}
        </View>
        {includeUcrFee && <Text style={columnStyles.ucrRate}>{formatCurrency(ucrRate)}</Text>}
        <Text style={columnStyles.negotiatedRate}>{formatCurrency(negotiatedRate)}</Text>
        {showSecondary ? (
          <>
            <Text style={columnStyles.primaryInsuranceAmount}>
              {formatCurrency(primaryInsuranceAmount ?? 0)}
            </Text>
            <Text style={columnStyles.insuranceAmount}>{formatCurrency(secondaryInsuranceAmount ?? 0)}</Text>
          </>
        ) : (
          <Text style={columnStyles.insuranceAmount}>{formatCurrency(insuranceAmount)}</Text>
        )}
        <Text style={columnStyles.patientAmount}>{formatCurrency(patientAmount)}</Text>
      </TableRow>
      <View style={styles.divider} />
    </>
  );
};

const TotalsRow: React.FC<{
  includeUcrFee: boolean;
  showSecondary: boolean;
  ucrRate: number;
  negotiatedRate: number;
  primaryInsuranceAmount: number;
  secondaryInsuranceAmount: number;
  insuranceAmount: number;
  patientAmount: number;
  isSubtotal?: boolean;
}> = ({
  includeUcrFee,
  showSecondary,
  ucrRate,
  negotiatedRate,
  primaryInsuranceAmount,
  secondaryInsuranceAmount,
  insuranceAmount,
  isSubtotal,
  patientAmount,
}) => {
  const { t } = useTranslation();
  const labelProps = {
    size: "sm" as const,
  };

  return (
    <TableRow borderBottom={isSubtotal}>
      <View style={columnStyles.priority} />
      <View style={columnStyles.cdtCode} />
      <View style={columnStyles.procedureArea} />
      <View style={[columnStyles.description, { flexDirection: "column" }]} />
      {includeUcrFee && (
        <View style={[columnStyles.ucrRate, styles.footerCell]}>
          {!isSubtotal && <Text {...labelProps}>{t("UCR Fee")}</Text>}
          <Text bold>{formatCurrency(ucrRate)}</Text>
        </View>
      )}
      <View style={[columnStyles.negotiatedRate, styles.footerCell]}>
        {!isSubtotal && <Text {...labelProps}>{t("Neg Rate")}</Text>}
        <Text bold>{formatCurrency(negotiatedRate)}</Text>
      </View>
      {showSecondary ? (
        <>
          <View style={[columnStyles.primaryInsuranceAmount, styles.footerCell]}>
            {!isSubtotal && <Text {...labelProps}>{t("Prim Ins Est")}</Text>}
            <Text bold>{formatCurrency(primaryInsuranceAmount)}</Text>
          </View>

          <View style={[columnStyles.insuranceAmount, styles.footerCell]}>
            {!isSubtotal && <Text {...labelProps}>{t("Sec Ins Est")}</Text>}
            <Text bold>{formatCurrency(secondaryInsuranceAmount)}</Text>
          </View>
        </>
      ) : (
        <View style={[columnStyles.insuranceAmount, styles.footerCell]}>
          {!isSubtotal && <Text {...labelProps}>{t("Ins Est")}</Text>}
          <Text bold>{formatCurrency(insuranceAmount)}</Text>
        </View>
      )}
      <View style={[columnStyles.patientAmount, styles.footerCell]}>
        {!isSubtotal && <Text {...labelProps}>{t("Pt Est")}</Text>}
        <Text bold>{formatCurrency(patientAmount)}</Text>
      </View>
    </TableRow>
  );
};

const ProceduresGroup: React.FC<{
  procedures: FormPatientProcedureVO[];
  includeUcrFee: boolean;
  hasSecondary: boolean;
  includeSubtotal: boolean;
}> = ({ procedures, includeUcrFee, hasSecondary, includeSubtotal }) => {
  const totals = usePatientProcedureTotals(procedures);

  return (
    <>
      {procedures.map((procedure) => {
        return (
          <ProcedureRow
            key={procedure.id}
            procedure={procedure}
            includeUcrFee={includeUcrFee}
            showSecondary={hasSecondary}
          />
        );
      })}
      {includeSubtotal && (
        <TotalsRow {...totals} isSubtotal includeUcrFee={includeUcrFee} showSecondary={hasSecondary} />
      )}
    </>
  );
};

type Props = {
  element: FormProcedureTableElementVO;
};

export const PatientFormProcedureTableElement: React.FC<Props> = ({ element }) => {
  const { procedures, treatmentPlanName, settings } = element;
  const includeUcrFee = settings.includes("DISPLAY_UCR_RATE");
  const hasSecondary = procedures.some((procedure) => procedure.secondaryInsuranceAmount);
  const { t } = useTranslation();
  const totals = usePatientProcedureTotals(procedures);
  const groupedProcedures = useGroupedProcedures(procedures);

  return (
    <Box>
      <View style={{ flexDirection: "column", gap: SPACE.md }}>
        <Text bold size="lg">
          {treatmentPlanName
            ? t("patientForms.treatmentPlan.treatmentEstimate", { treatmentPlanName })
            : t("patientForms.treatmentPlan.treatmentEstimateNoName")}
        </Text>

        <View style={styles.tableContainer}>
          <HeaderRow includeUcrFee={includeUcrFee} showSecondary={hasSecondary} />
          {groupedProcedures.map((group, i) => {
            return (
              <ProceduresGroup
                key={i}
                procedures={group}
                includeUcrFee={includeUcrFee}
                hasSecondary={hasSecondary}
                includeSubtotal={groupedProcedures.length > 1}
              />
            );
          })}

          <TotalsRow {...totals} includeUcrFee={includeUcrFee} showSecondary={hasSecondary} />
        </View>
      </View>
      <Spacer size="lg" />
    </Box>
  );
};
