import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { ReactComponent as ArchyLogoSmall } from "@libs/assets/components/archy-50-20.svg";
import { normal14, normal10 } from "assets/styles/textSize";

type Props = {
  className?: string;
  textClassName?: string;
  size?: "lg" | "sm";
};

export const PoweredBy: React.FC<Props> = ({ textClassName, className, size = "lg" }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(size === "lg" ? normal14 : normal10, className)}>
      <span className={textClassName}>{t("app.page.labels.placeholder.poweredBy")}</span>
      <ArchyLogoSmall
        className={cx("inline ml-[1px] text-secondaryTheme", size === "lg" ? "h-4" : "h-3.5")}
      />
    </div>
  );
};
