import { useTranslation } from "react-i18next";
import { PatientVO } from "@libs/api/generated-api";
import { useOnboardingOrder } from "components/Onboarding/hooks/useOnboardingOrder";
import { Card } from "components/UI/Cards";
import { InternalLink } from "components/UI/Link";
import { semibold14 } from "assets/styles/textSize";
import { paths } from "router/paths";

const LinearProgress: React.FC<{ percent: number }> = ({ percent }) => {
  return (
    <div
      className={`
        flex-start
        flex
        h-2
        w-full
        overflow-hidden
        rounded-full
        bg-slate-200
        font-sans
        text-xs
        font-medium
      `}
    >
      <div
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        style={{ width: `${percent * 100}%` }}
        className={`
          flex
          items-center
          justify-center
          h-full
          overflow-hidden
          text-white
          break-all
          bg-archyBlue-500
          rounded-full
        `}
      />
    </div>
  );
};

// Show to user that it is a progress bar, by representing 0 percent complete as visible on the bar
const ZERO_COMPLETION_PERCENT = 0.04;

export const OnboardingCard: React.FC<{ onboardingState?: PatientVO["onboardingState"]; body?: string }> = ({
  onboardingState,
  body,
}) => {
  const { t } = useTranslation();
  const onboardingOrder = useOnboardingOrder();

  if (!onboardingState || onboardingState === "COMPLETED") {
    return null;
  }

  const currentStep = onboardingOrder.indexOf(onboardingState);

  const translationParams = {
    currentStep,
    totalSteps: onboardingOrder.length - 1,
  };
  const title = t("home.page.onboardingSteps.title", translationParams);
  const titlePlural = t("home.page.onboardingSteps.titlePlural", translationParams);

  const percentComplete = currentStep / (onboardingOrder.length - 1);

  return (
    <Card inCarousel={false} className="text-sm flex flex-col gap-2">
      <div className="text-base">
        <span className="font-sansSemiBold">Onboarding</span>
        {" - "}
        {currentStep <= 1 ? title : titlePlural}
      </div>

      <LinearProgress percent={percentComplete === 0 ? ZERO_COMPLETION_PERCENT : percentComplete} />
      <div>{body ?? t("home.page.onboardingSteps.body")}</div>
      <InternalLink className={semibold14} to={paths.onboarding()}>
        {t("home.page.onboardingSteps.link")}
      </InternalLink>
    </Card>
  );
};
