import { Trans, useTranslation } from "react-i18next";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { DateOfBirthForm } from "components/DateOfBirthForm";

type Props = {
  practice: PracticeInfoVO;
  onSubmit: (dob: Date) => void;
  error?: boolean;
};

export const ConfirmDOBForm: React.FC<Props> = ({ practice, onSubmit, error }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col h-full items-center">
      <AnonUserMessagePage
        title={
          <Trans
            i18nKey="app.page.messages.welcomeTo"
            values={{ practiceName: practice.name }}
            tOptions={{ interpolation: { escapeValue: true } }}
            shouldUnescape
          />
        }
        subTitle={t("kiosk.dob")}
        logoUrl={practice.logo?.url}
      >
        <DateOfBirthForm error={error} onSubmit={onSubmit} />
      </AnonUserMessagePage>
    </div>
  );
};
