/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { pdf } from "@react-pdf/renderer";
import React from "react";
import { useTranslation } from "react-i18next";
import { PrepareFileUploadRequest, PrepareFileUploadResponse } from "@libs/api/generated-api";
import { blobToFile } from "@libs/utils/dataUrl";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Spinner } from "@libs/components/UI/Spinner";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { useHandleError } from "api/handleErrorResponse";
import { updateOnboardingInfo } from "api/user/mutations";
import { batchPrepareFileUpload } from "api/forms/mutations";
import {
  Props as PdfFormProps,
  PatientFormPdf,
} from "components/PatientForms/FormPDFElements/PatientFormPdf";
import { getLatestFormSubmissionQuery } from "api/forms/queries";
import { getPracticeLogoWithDimensions } from "components/PatientForms/FormPDFElements/utils/getPracticeLogo";
import { RegistrationSuccess } from "components/Onboarding/RegistrationSuccess";
import { useCurrentPatient } from "contexts/PatientContext";

export const useUploadDocuments = ({ practiceId, patientId }: { practiceId: number; patientId: number }) => {
  const [{ mutateAsync: mutateOnboardingAsync }, { mutateAsync: prepareUploadsMutateAsync }] =
    useApiMutations([updateOnboardingInfo, batchPrepareFileUpload]);
  const practice = useCurrentPractice();
  const patient = useCurrentPatient();
  const [uploadState, setUploadState] = React.useState<"PDF_GENERATION" | "UPLOADING" | null | undefined>();
  const handleError = useHandleError();
  const uploadPdfs = React.useCallback(
    async (
      pdfs: {
        formSlug: "DENTAL_HISTORY" | "MEDICAL_HISTORY" | "CONSENT";
        file: File;
      }[]
    ) => {
      setUploadState("UPLOADING");

      let keys: PrepareFileUploadResponse[] = [];

      try {
        const mimeType: PrepareFileUploadRequest = {
          contentType: "application/pdf",
        };
        const { data } = await prepareUploadsMutateAsync({
          practiceId,
          data: {
            requests: [mimeType, mimeType, mimeType],
          },
        });

        keys = data.data.responses ?? [];
      } catch (e) {
        setUploadState(null);
        handleError(e);

        return [];
      }

      if (keys.length === 0) {
        setUploadState(null);

        return [];
      }

      const results = await Promise.all(
        keys.map((pdfUploadParams, i) => {
          return fetch(pdfUploadParams.url, {
            method: "PUT",
            body: pdfs[i].file,
          });
        })
      )
        .then(async (responses) => {
          const failedResponse = responses.find((item) => !item.ok);

          if (failedResponse) {
            // const errorString = `Failed to upload image, error ${response.status} (${response.statusText})`;
            throw new Error(await failedResponse.text());
          }

          const onboardingUpdateResponse = await mutateOnboardingAsync({
            practiceId,
            patientId,
            onboardingState: "PDF_SUBMISSION",
            data: {
              batchConfirmFile: {
                requests: pdfs.map((pdfItem, i) => ({
                  encryptedFileKey: keys[i].encryptedFileKey,
                  formSlug: pdfItem.formSlug,
                })),
              },
            },
          });

          // const linkResponse = await linkMedicalImageMutateAsync(linkImageParams);
          return {
            status: "SUCCESS" as const,
            response: onboardingUpdateResponse,
          };
        })
        .catch((e) => {
          const error: unknown = e;

          handleError(error);

          return {
            status: "FAILED" as const,
            error,
          };
        })
        .finally(() => setUploadState(null));

      return results;
    },
    [handleError, mutateOnboardingAsync, patientId, practiceId, prepareUploadsMutateAsync]
  );
  const [
    { data: dentalLatestSubmission },
    { data: medicalLatestSubmission },
    { data: consentLatestSubmission },
  ] = useApiQueries([
    getLatestFormSubmissionQuery({ args: { practiceId, uuidOrSlug: "DENTAL_HISTORY", patientId } }),
    getLatestFormSubmissionQuery({ args: { practiceId, uuidOrSlug: "MEDICAL_HISTORY", patientId } }),
    getLatestFormSubmissionQuery({ args: { practiceId, uuidOrSlug: "CONSENT", patientId } }),
  ]);
  const generateAndUpload = React.useCallback(
    async (params: { dentalHistory: PdfFormProps; medicalHistory: PdfFormProps; consent: PdfFormProps }) => {
      setUploadState("PDF_GENERATION");

      const logo = await getPracticeLogoWithDimensions(params.consent.practice);

      const dentalInfoPdf = await pdf(<PatientFormPdf {...params.dentalHistory} logo={logo} />).toBlob();
      const medicalInfoPdf = await pdf(<PatientFormPdf {...params.medicalHistory} logo={logo} />).toBlob();
      const consentPdf = await pdf(<PatientFormPdf {...params.consent} logo={logo} />).toBlob();

      uploadPdfs([
        {
          formSlug: "DENTAL_HISTORY",
          file: blobToFile(dentalInfoPdf, "dental-info.pdf"),
        },
        {
          formSlug: "MEDICAL_HISTORY",
          file: blobToFile(medicalInfoPdf, "medical-info.pdf"),
        },
        {
          formSlug: "CONSENT",
          file: blobToFile(consentPdf, "consent.pdf"),
        },
      ]);
    },
    [uploadPdfs]
  );
  const hasInitiatedUpload = React.useRef(false);

  React.useEffect(() => {
    if (
      dentalLatestSubmission &&
      medicalLatestSubmission &&
      consentLatestSubmission &&
      !hasInitiatedUpload.current
    ) {
      hasInitiatedUpload.current = true;

      const sharedProps = {
        patientPersonalDetails: patient.personalDetails,
        practice,
      };

      generateAndUpload({
        dentalHistory: {
          ...sharedProps,
          responses: dentalLatestSubmission.responses,
          formData: dentalLatestSubmission.form,
        },
        medicalHistory: {
          ...sharedProps,
          responses: medicalLatestSubmission.responses,
          formData: medicalLatestSubmission.form,
        },
        consent: {
          ...sharedProps,
          responses: consentLatestSubmission.responses,
          formData: consentLatestSubmission.form,
        },
      });
    }
  }, [
    consentLatestSubmission,
    dentalLatestSubmission,
    generateAndUpload,
    medicalLatestSubmission,
    patient,
    practice,
  ]);

  return { uploadState };
};
export const OnboardingPdfUploaderPage = () => {
  const { t } = useTranslation();
  const { id: patientId, practiceId } = useAccount();
  const { uploadState } = useUploadDocuments({ practiceId, patientId });

  if (uploadState === null) {
    return <RegistrationSuccess />;
  }

  return (
    <div className="h-full min-h-64 flex justify-center items-center">
      <div className="flex flex-row items-center gap-4">
        <Spinner animation="border" variant="primary" />
        <div className="font-sansSemiBold">
          {uploadState === "PDF_GENERATION" ? t("Loading your account") : t("Configuring your account")}
        </div>
      </div>
    </div>
  );
};
