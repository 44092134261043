import {
  MessageSubscriptionVO,
  UpdateMessageSubscriptionRequest,
  UpdatePatientRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { updateCachedData } from "@libs/utils/queryCache";
import { isDefined } from "@libs/utils/types";

export const updatePatient = makeMutation({
  mutationKey: ["practices", "updatePatient"],
  formatParams: (args: { practiceId: number; patientId: number; data: UpdatePatientRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { patientId, practiceId, data }) => {
      if (isDefined(data.personalDetails.ssn)) {
        updateCachedData<string>(
          queryClient,
          {
            queryKey: [
              getQueryKey("practices", "getSsn"),
              {
                practiceId,
                userId: patientId,
              },
            ],
            exact: true,
          },
          (lastSsn) => {
            return data.personalDetails.ssn ?? lastSsn;
          }
        );
      }

      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatient"),
        {
          practiceId,
          patientId,
        },
      ]);
    },
  }),
});

export const updateMessageSubscription = makeMutation({
  mutationKey: ["public", "updateMessageSubscription"],
  formatParams: (args: {
    practiceId?: number;
    practiceUuid: string;
    messageSubscriptionUuid: string;
    updates: UpdateMessageSubscriptionRequest;
    patientId?: number;
  }) => [args.practiceUuid, args.messageSubscriptionUuid, args.updates],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { messageSubscriptionUuid, practiceId, practiceUuid, patientId }) => {
      updateCachedData<MessageSubscriptionVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("public", "getMessageSubscription"),
            { practiceUuid, messageSubscriptionUuid },
          ],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );

      if (practiceId && patientId) {
        updateCachedData<MessageSubscriptionVO>(
          queryClient,
          {
            queryKey: [getQueryKey("practices", "getEmailSubscriptionByPatient"), { practiceId, patientId }],
            exact: true,
          },
          () => {
            return response.data.data;
          }
        );
      }
    },
  }),
});
