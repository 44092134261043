import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { PaymentProfileVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { CheckState } from "@libs/components/UI/Checkbox";
import { normal12, normal14, semibold12 } from "assets/styles/textSize";
import { CreditCardLogo } from "components/Account/CreditCardLogo";
import { useLabelForCardType } from "hooks/useLabelForCardType";

type Props = {
  profile: PaymentProfileVO;
  onClick: (profile: PaymentProfileVO) => void;
  layout?: "radio" | "checkbox";
  selected: boolean;
  disabled?: boolean;
};

const cxStyles = {
  container: ({
    selected,
    layout,
    isDefault,
    disabled,
  }: {
    selected: boolean;
    layout: Props["layout"];
    isDefault: boolean;
    disabled?: boolean;
  }) =>
    cx(
      `flex
       relative
       shadow-[0_0_0.3rem_rgba(188,188,188,0.5)]
       rounded-lg
       gap-5
       pl-4
       items-stretch
       w-full
       bg-white`,
      isDefault || layout === "checkbox" ? "pr-16" : "pr-4",
      selected && "outline outline-1 outline-primaryTheme",
      disabled && "opacity-70 cursor-default",
      !disabled && "hover:outline hover:outline-secondaryTheme"
    ),
  cardDetails: cx("flex flex-col items-start flex-1", normal14),
  cardDetail: cx("text-greyMedium", normal12),
  leftContent: "flex gap-5 items-center flex-1 py-4",
  rightContent: "flex flex-col justify-between items-end",
};

// eslint-disable-next-line complexity
export const PaymentProfile: React.FC<Props> = ({
  selected,
  layout = "radio",
  onClick,
  profile,
  disabled,
}) => {
  const { t } = useTranslation();
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
    (e) => {
      e.preventDefault();
      onClick(profile);
    },
    [profile, onClick]
  );
  const { cardLastFour, cardBrand, cardType, cardExpMonth, cardExpYear } = profile.card ?? {};
  const expiryDate = cardExpMonth && cardExpYear ? `${cardExpMonth}/${cardExpYear}` : "";
  const cardTypeLabel = useLabelForCardType(cardType ?? "UNKNOWN");

  return (
    <button
      type="button"
      className={cxStyles.container({ selected, layout, isDefault: profile.isDefault, disabled })}
      onClick={handleClick}
    >
      <div className={cxStyles.leftContent}>
        <CreditCardLogo brand={cardBrand} className="w-10 h-10" />
        <div className={cxStyles.cardDetails}>
          <div>{`XXXX XXXX XXXX ${cardLastFour ?? ""}`}</div>
          <div>{profile.billingName}</div>
          <div className={cxStyles.cardDetail}>{cardTypeLabel}</div>
          <div className={cxStyles.cardDetail}>
            {t("paymentProfile.expires", { expiryDate, interpolation: { escapeValue: false } })}
          </div>
        </div>
      </div>
      {layout === "checkbox" && (
        <div className="absolute top-3 right-3">
          <CheckState checked={selected} className={cx("w-5 h-5", selected && "text-primaryTheme")} />
        </div>
      )}

      {profile.isDefault && (
        <div
          className={cx(
            `text-secondaryTheme
             bg-archyBlue-50
             bg-otherAction
             py-1
             px-2
             absolute
             bottom-0
             right-0
             rounded-tl-md
             rounded-br-lg`,
            semibold12
          )}
        >
          Default
        </div>
      )}
    </button>
  );
};

const LoadingPaymentProfile = () => {
  return (
    <div
      className={cxStyles.container({ selected: false, layout: "radio", isDefault: false, disabled: true })}
    >
      <div className={cxStyles.leftContent}>
        <Skeleton className="w-8 h-6" />
        <div className={cxStyles.cardDetails}>
          <Skeleton containerClassName="w-full max-w-xs" />
          <Skeleton containerClassName="w-20" />
          <Skeleton containerClassName="w-14" />
          <Skeleton containerClassName="w-12" />
        </div>
      </div>
    </div>
  );
};

export const LoadingPaymentProfiles = () => {
  const cards = Array.from({ length: 3 });

  return (
    <>
      {cards.map((_, i) => {
        return <LoadingPaymentProfile key={i} />;
      })}
    </>
  );
};
