import { FormFieldLayout } from "@libs/types/form";
import { cx } from "@libs/utils/cx";
import { IconSizes, cxIconSizes } from "@libs/components/UI/Icon";

export const cxIconStyles = {
  container: ({ layout, clickable }: { layout?: FormFieldLayout; clickable?: boolean }) =>
    cx(
      "absolute right-0 top-1/2 -translate-y-1/2 z-0",
      layout === "labelLeft" ? "" : "pr-3",
      !clickable && "pointer-events-none"
    ),
  element: ({
    disabled,
    error,
    size,
    actionable,
  }: {
    actionable?: boolean;
    disabled?: boolean;
    error?: string;
    size?: IconSizes;
  }) =>
    cx(
      cxIconSizes[size ?? "sm"],
      disabled ? "text-slate-400" : error ? "text-red" : actionable ? "text-primaryTheme" : "text-slate-700"
    ),
};

export const cxFormFieldStyle = {
  wrapper: "relative",
  input: `
    placeholder:text-slate-400
    focus-visible:outline-none
    dark:bg-slate-800
    dark:text-white
  `,
  controlValueOnly: ({ layout }: { layout: FormFieldLayout }) =>
    cx(
      "block text-sm md:text-xs w-full",
      !["tableValue", "labelLeft", "labelOut"].includes(layout) && "text-greyDark font-sansSemiBold"
    ),
  // eslint-disable-next-line complexity
  control: ({
    hasIcon,
    hasLabel,
    layout,
    fontSizeClassName,
  }: {
    hasIcon: boolean;
    hasLabel: boolean;
    layout: FormFieldLayout;
    fontSizeClassName?: string;
  }) =>
    cx(
      `text-left
       w-full
       disabled:text-slate-400
       disabled:bg-white
       rounded
       block`,
      fontSizeClassName ?? " text-sm md:text-xs",
      layout === "labelLeft" ? "bg-transparent" : "",
      layout === "embedded"
        ? "py-0"
        : layout === "labelIn" || layout === "tableValue"
          ? hasLabel
            ? "py-1"
            : "py-2"
          : layout === "labelOut"
            ? "py-2"
            : "py-0.5",
      hasIcon && "pr-9",
      layout === "labelLeft"
        ? "px-1"
        : layout === "embedded"
          ? "px-0"
          : layout === "tableValue"
            ? "px-2"
            : hasIcon
              ? "pl-4"
              : "px-4"
    ),
  iconContainer: (args: { layout?: FormFieldLayout; clickable?: boolean }) => cxIconStyles.container(args),
  icon: (args: { actionable?: boolean; disabled?: boolean; error?: string; size?: IconSizes }) =>
    cxIconStyles.element(args),
};
