import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MINUTE_IN_SECONDS, SECOND_IN_MS, formatAsISODate } from "@libs/utils/date";
import { half } from "@libs/utils/math";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { preparePatientFormTasksQuery } from "api/forms/queries";
import {
  LoadedPatientFormTasks,
  getFormTaskExpiry,
} from "components/PatientForms/PatientFormTasks/LoadedPatientFormTasks";
import { getPracticeInfoByUuid } from "api/user/queries";
import { DateOfBirthForm } from "components/DateOfBirthForm";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { getIsKioskCodeOrDOBError } from "utils/getIsKioskCodeOrDOBError";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { PublicPracticeInstrumentation } from "components/Main/PublicPracticeInstrumentation";

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const IDLE_TIMEOUT = MINUTE_IN_SECONDS * SECOND_IN_MS * 6;
const PATIENT_IDLE_VALUES = {
  // Reloads page after six minutes (3 minutes of inactivity, 3 minutes with prompt)
  timeout: IDLE_TIMEOUT,
  // Prompts user after 3 minutes
  promptBeforeIdle: half(IDLE_TIMEOUT),
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  eventsThrottle: 10 * SECOND_IN_MS,
  name: "patientForms",
};

// eslint-disable-next-line complexity
export const PatientFormTasksRoute = () => {
  const { t } = useTranslation();
  const params = usePathParams("formTasks");
  const practiceUuid = params.practiceUuid;
  const {
    query: { formTaskUuids, print, dob: dobQueryParam },
  } = useQueryParams("formTasks");
  const [dob, setDob] = React.useState<string | undefined>(dobQueryParam);
  const [expiresAt, setExpiresAt] = React.useState<Date>(getFormTaskExpiry());
  const errorOccurred = useBoolean(false);
  const isPrinting = print === "1";
  const [tasksQuery, practiceQuery] = useApiQueries([
    preparePatientFormTasksQuery({
      args: { practiceUuid, data: { formTaskUuids, dob: dob ?? "" } },
      queryOptions: {
        enabled: Boolean(dob),
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: () => {
          setExpiresAt(getFormTaskExpiry());
        },
        onError: errorOccurred.on,
      },
    }),
    getPracticeInfoByUuid({
      args: { practiceUuid },
    }),
  ]);
  const handleSessionExpired = useCallback(() => {
    tasksQuery.refetch();
    setExpiresAt(getFormTaskExpiry());
  }, [tasksQuery]);
  const isFormSubmissionError = getIsKioskCodeOrDOBError(tasksQuery.error);

  return (dob && !isFormSubmissionError) || isPrinting ? (
    <QueryResult queries={[tasksQuery, practiceQuery]}>
      {tasksQuery.data && practiceQuery.data && (
        <>
          <PublicPracticeInstrumentation practice={practiceQuery.data} />
          <LoadedPatientFormTasks
            formTasksResponse={tasksQuery.data}
            practice={practiceQuery.data}
            token={tasksQuery.data.token}
            print={isPrinting}
            idleConfig={PATIENT_IDLE_VALUES}
            onSessionExpired={handleSessionExpired}
            expiresAt={expiresAt}
          />
        </>
      )}
    </QueryResult>
  ) : (
    <div className="flex flex-col h-full items-center">
      <AnonUserMessagePage
        title={t("Welcome")}
        subTitle={t("app.formTasks.dobEntryTitle")}
        logoUrl={practiceQuery.data?.logo?.url}
      >
        <DateOfBirthForm
          error={errorOccurred.isOn ? isFormSubmissionError : undefined}
          initialDate={dob}
          onChange={errorOccurred.off}
          onSubmit={(selectedDob) => {
            setDob(formatAsISODate(selectedDob));
          }}
        />
      </AnonUserMessagePage>
    </div>
  );
};
